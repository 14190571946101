import React, { useState, useEffect, Suspense } from 'react';
import { Tabs, Tab, Box, CircularProgress,Typography } from '@mui/material';
import SwipeableViews from 'react-swipeable-views';
import { blueGrey } from '@mui/material/colors';

// Lazy load the components
const Update_RMA = React.lazy(() => import('./EditRMA'));
const RMAInvestigation = React.lazy(() => import('./RMAInvestigation'));
const RMAResolution = React.lazy(() => import('./RMAResolution'));
const RMAFollowUpCloseOut = React.lazy(() => import('./RMAFollowUpCloseOut'));

const RMAStages = ({ RMARow, refreshData}) => {
  const [activeTab, setActiveTab] = useState(0);

  // Define stages and map Resolution_Stage to the corresponding tab index
  const stages = [
    { label: 'RMA Created', component: Update_RMA, stage: 'RMA Created' },
    { label: 'Investigation', component: RMAInvestigation, stage: 'Investigation' },
    { label: 'Resolution', component: RMAResolution, stage: 'Resolution' },
    { label: 'Follow Up', component: RMAFollowUpCloseOut, stage: 'Follow Up' },
  ];

  // Define path mapping for special cases (Cancelled, Closed)
  const pathMap = {
    Cancelled: 'RMA Created',
    Closed: 'RMA Created',
  };

  // Set the initial active tab based on RMARow?.Resolution_Stage
  useEffect(() => {
    if (RMARow?.Resolution_Stage) {
      // Handle Cancelled and Closed cases
      const stage = pathMap[RMARow.Resolution_Stage] || RMARow.Resolution_Stage;
      const initialTab = stages.findIndex((item) => item.stage === stage);
      setActiveTab(initialTab >= 0 ? initialTab : 0); // Default to first tab if no match
    }
  }, [RMARow]);

  // Handle Tab Change
  const handleTabChange = (e, index) => {
    setActiveTab(index);
  };

  // Handle Swipeable View Change
  const handleSwipeableViewChange = (index) => {
    setActiveTab(index);
  };

  return (
    <Box sx={{ width: '100%'}}>
      {/* Tabs */}
      <Tabs
  value={activeTab}
  onChange={handleTabChange}
  variant="scrollable"
  scrollButtons="auto"
  aria-label="RMA Stages Tabs"
  sx={{
    borderBottom: 1,
    borderColor: 'divider',

    color: blueGrey[900],
    '& .Mui-selected': {
      color: 'orange', // Active tab text color
    },
    '& .MuiTabs-indicator': {
      backgroundColor: 'orange', // Active tab indicator color
    },
  }}
>
  {stages.map((stage, index) => (
    <Tab key={index} label={stage.label} />
  ))}
</Tabs>

{/* Swipeable Views */}
<SwipeableViews
        index={activeTab}
        onChangeIndex={handleSwipeableViewChange}
      >
        {stages.map((stage, index) => {
          const Component = stage.component;
          return (
            <Box key={index} sx={{ padding: 3 }}>
              {/* <Typography
                variant="h5"
                sx={{ marginBottom: 2, fontWeight: 'bold', textAlign: 'center' }}
              >
                {stage.label}
              </Typography>
              <Typography
                variant="subtitle1"
                sx={{ marginBottom: 3, color: 'gray', textAlign: 'center' }}
              >
                Current Stage: {stage.stage}
              </Typography> */}
              <Suspense fallback={<CircularProgress />}>
                {/* Pass RMARow to the component */}
                <Component RMARow={RMARow} refreshData ={refreshData}/>
              </Suspense>
            </Box>
          );
        })}
      </SwipeableViews>
    </Box>
  );
};

export default RMAStages;
