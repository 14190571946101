import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import {
    blueGrey
  } from '@mui/material/colors'
  import {
    createTheme
  } from '@mui/material/styles';

const theme = createTheme({
    palette: {
        primary: {
            main: blueGrey[900],
        },
        secondary: {
            main: blueGrey[300],
        },
    },
  });
const borderColor = 'black'
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: 'black',
        borderTopWidth: 1,
        borderBottomWidth: 1,
        borderLeftWidth:1,
        fontSize: 9,
        fontStyle: 'bold',

    },
    description: {
        width: '20%',
        textAlign: 'right',
        paddingRight: 8,
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor:blueGrey[100]
    },
    total: {
        width: '80%',
        textAlign: 'center',
        borderRightWidth: 1,
        backgroundColor:blueGrey[100],
        color:'black'
    },
});
const NotesForKO = ({ invoice }) => {
    let cal = 0;
    const NotesForKO = invoice?.Notes_For_KO;

    return (
        <>
            {/* {String(invoice?.Notes_For_KO).trim() !== 'NA' && String(invoice?.Notes_For_KO).trim()  !== 'N/A' && String(invoice?.Notes_For_KO).trim() !== '' &&
             String(invoice?.Notes_For_KO).trim()  !== null &&  String(invoice?.Notes_For_KO).trim()  !== ' ' &&(            )} */}
                <View style={styles.row}>
                    <Text style={styles.description}>NOTES: </Text>
                    <Text style={styles.total}>{invoice?.Notes_For_KO}</Text>
                </View>

        </>
    );
};

export default NotesForKO;