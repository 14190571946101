import React, { useState, useEffect } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  Button,
  Stack,
  Typography,
} from '@mui/material';
import { red } from '@mui/material/colors';

/**
 * EditSKUComponent
 * A component for editing SKU data, including managing multiple pricing rows (date ranges).
 */
const EditSKUComponent = ({
  loading,
  setLoading,
  handleSave,
  initialData,
  errorMessage,
  setErrorMessage,
}) => {
  const [sku, setSku] = useState(null); // Local state for SKU being edited

  /**
   * Populate the `sku` state with initial data, ensuring all fields are properly mapped.
   */
  useEffect(() => {
    setLoading(true); // Indicate loading
    if (initialData) {
      setSku({
        skuID: initialData.skuID, // Populate SKU ID
        partNo: initialData.partNo, // Populate Part Number
        itemDescription: initialData.itemDescription, // Populate Item Description
        unitOfMeasure:initialData.unitOfMeasure,
        vendorPartnerName:initialData.vendorPartnerName,
        pricing: initialData.pricing?.map((pricing) => ({
          id: pricing.id || '', // Populate unique ID for pricing row
          fromDate: pricing.fromDate || '',
          toDate: pricing.toDate || '',
          customerCost: pricing.customerCost || '',
          companyCost: pricing.companyCost || '',
          marginPercent: pricing.marginPercent || '',
        })) || [], // Ensure pricing data is properly populated
      });
      setLoading(false); // Stop loading
    }
  }, [initialData, setLoading]);

  /**
   * Validate the SKU fields and pricing data before saving.
   */
  const validateInputs = () => {
    if (!sku?.partNo) {
      setErrorMessage('Part No is required.');
      return false;
    }

    if (!sku?.itemDescription) {
      setErrorMessage('Item Description is required.');
      return false;
    }
    if (!sku.unitOfMeasure) {
      alert('Unit Of Measure is required for all SKUs.');
      return false;
    }
    if (!sku.vendorPartnerName) {
      alert('Vendor Partner Name is required for all SKUs.');
      return false;
    }
    const pricingRows = sku.pricing || [];
    if (pricingRows.length === 0) {
      setErrorMessage('At least one pricing row is required.');
      return false;
    }

    for (let pricing of pricingRows) {
      if (
        !pricing.customerCost ||
        !pricing.companyCost ||
        !pricing.fromDate ||
        !pricing.toDate
      ) {
        setErrorMessage(
          'All fields in Pricing (Customer Cost, Company Cost, From Date, To Date) are required.'
        );
        return false;
      }
    }

    setErrorMessage(''); // Clear any previous error messages
    return true;
  };

  /**
   * Add a new pricing row with default values.
   */
  const handleAddDateRange = () => {
    setSku((prev) => ({
      ...prev,
      pricing: [
        ...(prev.pricing || []),
        {
          id: '', // New pricing rows won't have an ID initially
          fromDate: '',
          toDate: '',
          customerCost: '',
          companyCost: '',
          marginPercent: '',
        },
      ],
    }));
  };

  /**
   * Remove a pricing row by index.
   */
  const handleRemoveDateRange = (index) => {
    setSku((prev) => ({
      ...prev,
      pricing: prev.pricing.filter((_, i) => i !== index),
    }));
  };

  /**
   * Update a field for the SKU (e.g., partNo, itemDescription).
   */
  const handleChange = (field, value) => {
    setSku((prev) => ({
      ...prev,
      [field]: value,
    }));
  };

  /**
   * Update a specific field in a pricing row.
   */
  const handlePricingChange = (index, field, value) => {
    setSku((prev) => {
      const updated = { ...prev };
      updated.pricing[index][field] = value;
      return updated;
    });
  };

  /**
   * Save the SKU after validation.
   */
  const handleSaveSKU = () => {
    if (validateInputs()) {
      handleSave(sku); // Trigger the parent's save function
    }
  };

  // If SKU data is not ready, render nothing
  if (!sku) return null;

  return (
    <Stack spacing={3} sx={{ marginTop: '20px' }}>
      {/* Display Error Message */}
      {errorMessage && (
        <Typography variant="subtitle2" sx={{ color: red[700] }}>
          {errorMessage}
        </Typography>
      )}

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Vendor/Partner Name</TableCell>
              <TableCell sx={{width:'17.5%',fontSize:'12px'}}>Part No</TableCell>
              <TableCell sx={{width:'25%',fontSize:'12px'}}>Description</TableCell>
              <TableCell sx={{fontSize:'12px'}} >Unit Of Measure</TableCell>
              <TableCell sx={{fontSize:'12px'}}>Date Ranges</TableCell>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Customer Cost</TableCell>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Company Cost</TableCell>
              <TableCell sx={{width:'17.5%',fontSize:'12px'}}>Margin (%)</TableCell>
              <TableCell sx={{fontSize:'12px'}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {/* Main SKU Information */}
            <TableRow >
              <TableCell>
                  <TextField
                  size="small"
                  multiline
                  value={sku.vendorPartnerName}
                  sx={{fontSize:'12px'}}
                  onChange={(e) =>
                    handleChange('vendorPartnerName', e.target.value)
                      }
                  />
            </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  value={sku.partNo}
                  multiline
                  sx={{fontSize:'12px'}}
                  InputProps={{readOnly:true}}
                  onChange={(e) => handleChange('partNo', e.target.value)}
                />
              </TableCell>
              <TableCell>
                <TextField
                  size="small"
                  multiline
                  minRows={3}
                  sx={{fontSize:'12px'}}
                  value={sku.itemDescription}
                  onChange={(e) => handleChange('itemDescription', e.target.value)}
                />
              </TableCell>
              <TableCell>
                    <TextField
                      size="small"
                       width='10px'
                      value={sku.unitOfMeasure}
                      sx={{fontSize:'12px'}}
                      onChange={(e) =>
                      handleChange('unitOfMeasure', e.target.value)
                      }
                      />
                    </TableCell>
              <TableCell colSpan={4}>
                <Button
                  size="small"
                  variant="outlined"
                  sx={{fontSize:'12px'}}
                  color="success"
                  onClick={handleAddDateRange}
                >
                  Add Date Range
                </Button>
              </TableCell>
            </TableRow>

            {/* Pricing Rows */}
            {sku.pricing?.map((pricing, index) => (
              <TableRow key={pricing.id || index} sx={{fontSize:'12px'}}>
                <TableCell colSpan={1}></TableCell>
                <TableCell colSpan={2}></TableCell>
                <TableCell colSpan={1}></TableCell>
                <TableCell >
                  <TextField
                    size="small"
                    type="date"
                    sx={{fontSize:'12px'}}
                    value={pricing.fromDate || ''}
                    onChange={(e) =>
                      handlePricingChange(index, 'fromDate', e.target.value)
                    }
                  />
                  <TextField
                    size="small"
                    type="date"
                    sx={{fontSize:'12px'}}
                    value={pricing.toDate || ''}
                    onChange={(e) =>
                      handlePricingChange(index, 'toDate', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    type="number"
                    sx={{fontSize:'12px'}}
                    value={parseFloat(pricing.customerCost)|| ''}
                    onChange={(e) =>
                      handlePricingChange(index, 'customerCost', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    type="number"
                    value={parseFloat(pricing.companyCost) || ''}
                    sx={{fontSize:'12px'}}
                    onChange={(e) =>
                      handlePricingChange(index, 'companyCost', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    size="small"
                    type="number"
                    value={parseFloat(pricing.marginPercent) || ''}
                    sx={{fontSize:'12px'}}
                    onChange={(e) =>
                      handlePricingChange(index, 'marginPercent', e.target.value)
                    }
                  />
                </TableCell>
                <TableCell>
                  {sku.pricing.length > 1 && (
                    <Button
                      size="small"
                      color="error"
                      onClick={() => handleRemoveDateRange(index)}
                    >
                      Remove
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSaveSKU}
        disabled={loading}
      >
        Save SKU
      </Button>
    </Stack>
  );
};

export default EditSKUComponent;
