import React, { useState } from "react";
import {
  TableRow,
  TableCell,
  Typography,
  Box,
  Collapse,
  IconButton,
  Table,
  TableBody,
  TableRow as InnerTableRow,
  TableCell as InnerTableCell,
  Stack,
  Divider,
} from "@mui/material";
import { blueGrey, orange, green, blue } from "@mui/material/colors";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";

/**
 * Component to display detailed DHS row information.
 * Supports collapsible sections for better organization.
 */
function DHSRow({ row }) {
  // State to manage whether the row's details are expanded
  const [open, setOpen] = useState(false);

  // Toggles the expanded/collapsed state of the row
  const toggleExpand = () => setOpen(!open);

  return (
    <>
      {/* Main Table Row */}
      <TableRow hover sx={{ "&:hover": { backgroundColor: blueGrey[50] } }}>
        {/* Main Request Information */}
        <TableCell>
          <Typography fontWeight="bold" color={blueGrey[900]}>
            {row.REQ_ID}
          </Typography>
          <Typography variant="caption" color={blueGrey[600]}>
            <b>Order Type:</b>{" "}
            {row.Order_Type === "Traditional System Order"
              ? `${row.Order_Type} (${row.Armor_Gender})`
              : row.Order_Type}
          </Typography>
          <Typography variant="body2" mt={1}>
            <b>Contract Number:</b> {row.Contract_Number}
          </Typography>
          <Typography variant="body2">
            <b>Delivery Order:</b> {row.Delivery_Order_Number}
          </Typography>
        </TableCell>

        {/* Customer Information */}
        <TableCell>
          <Typography variant="body2">
            <b>Customer Name:</b> {row.Customer_Name}
          </Typography>
          <Typography variant="body2">
            <b>Email:</b> {row.Customer_Email}
          </Typography>
          <Typography variant="body2">
            <b>Location:</b> {row.Location}
          </Typography>
        </TableCell>

        {/* Expand/Collapse Button */}
        <TableCell align="center">
          <IconButton onClick={toggleExpand}>
            {open ? <ExpandLessIcon /> : <ExpandMoreIcon />}
          </IconButton>
        </TableCell>
      </TableRow>

      {/* Collapsible Section */}
      <TableRow>
        <TableCell colSpan={3} style={{ paddingBottom: 0, paddingTop: 0 }}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box
              sx={{
                margin: 2,
                border: `1px solid ${blueGrey[200]}`,
                borderRadius: 1,
                padding: 2,
              }}
            >
              {/* Header for Additional Details */}
              <Typography variant="h6" gutterBottom>
                Additional Details
              </Typography>
              <Divider sx={{ mb: 2, bgcolor: blueGrey[300] }} />

              {/* Traditional System Order Details */}
              {row.Order_Type === "Traditional System Order" && (
                <Box>
                  <Typography color={orange[900]} fontWeight="bold">
                    Traditional System Order - Measurements
                  </Typography>
                  <Table size="small" sx={{ mb: 2 }}>
                    <TableBody>
                      <InnerTableRow>
                        <InnerTableCell>
                          <b>Weight:</b> {row.Weight_lbs} lbs
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Height:</b> {row.Height_ft_in} ft-in
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Chest:</b> {row.Chest_in} in
                        </InnerTableCell>
                      </InnerTableRow>
                      <InnerTableRow>
                        <InnerTableCell>
                          <b>Waist:</b> {row.Waist_in} in
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Back:</b> {row.Back_in} in
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Front Stand:</b> {row.Front_Stand_in} in
                        </InnerTableCell>
                      </InnerTableRow>
                      <InnerTableRow>
                        <InnerTableCell>
                          <b>Front Sit:</b> {row.Front_Sit_in} in
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Color:</b> {row.Color}
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Placard:</b> {row.Placard}
                        </InnerTableCell>
                      </InnerTableRow>
                    </TableBody>
                  </Table>

                  {/* Female-Specific Measurements */}
                  {row.Armor_Gender === "Female" && (
                    <>
                      <Typography color={blue[900]} fontWeight="bold">
                        Female-Specific Measurements
                      </Typography>
                      <Table size="small" sx={{ mb: 2 }}>
                        <TableBody>
                          <InnerTableRow>
                            <InnerTableCell>
                              <b>Bra Size:</b> {row.Bra_Size}
                            </InnerTableCell>
                            <InnerTableCell>
                              <b>Cup Size:</b> {row.Cup_Size}
                            </InnerTableCell>
                            <InnerTableCell>
                              <b>Tip Apex to Tip Apex:</b>{" "}
                              {row.Tip_Apex_to_Tip_Apex_in} in
                            </InnerTableCell>
                          </InnerTableRow>
                          <InnerTableRow>
                            <InnerTableCell>
                              <b>Tip Apex to Belt:</b>{" "}
                              {row.Tip_Apex_to_Belt_in} in
                            </InnerTableCell>
                            <InnerTableCell>
                              <b>Tip Apex to Clavicle Shoulder:</b>{" "}
                              {row.Tip_Apex_to_Clavicle_Shoulder_in} in
                            </InnerTableCell>
                            <InnerTableCell>
                              <b>Tip Apex to Side:</b>{" "}
                              {row.Tip_Apex_to_Side_in} in
                            </InnerTableCell>
                          </InnerTableRow>
                        </TableBody>
                      </Table>
                    </>
                  )}
                </Box>
              )}

              {/* Scalable System Order Details */}
              {row.Order_Type === "Scalable System Order" && (
                <Box>
                  <Typography color={green[900]} fontWeight="bold">
                    Scalable System Order - Details
                  </Typography>
                  <Table size="small" sx={{ mb: 2 }}>
                    <TableBody>
                      <InnerTableRow>
                        <InnerTableCell>
                          <b>Side Panel Size:</b> {row.Side_Panel_Size}
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Front Panel Size:</b> {row.Front_Panel_Size}
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Back Panel Size:</b> {row.Back_Panel_Size}
                        </InnerTableCell>
                      </InnerTableRow>
                      <InnerTableRow>
                        <InnerTableCell>
                          <b>Base Carrier Size:</b> {row.Base_Carrier_Size}
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Placard:</b> {row.Placard}
                        </InnerTableCell>
                        <InnerTableCell>
                          <b>Refresh Kit:</b>{" "}
                          {row.Refresh_Kit ? "Yes" : "No"}
                        </InnerTableCell>
                      </InnerTableRow>
                    </TableBody>
                  </Table>
                </Box>
              )}

              {/* General Information */}
              <Box>
                <Typography color={blue[800]} fontWeight="bold" mt={2}>
                  General Information
                </Typography>
                <Typography variant="body2">
                  <b>Created Date:</b> {row.Created_Date}
                </Typography>
                <Typography variant="body2">
                  <b>Updated Date:</b> {row.Updated_Date}
                </Typography>
                <Typography variant="body2">
                  <b>Created By:</b> {row.Created_By}
                </Typography>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
}

export default DHSRow;
