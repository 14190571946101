import React, { useState } from 'react';
import {
  Container,
  Button,
  Typography,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  TextField,
  Paper,
  MenuItem,
  Grid,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@mui/material';
import * as XLSX from 'xlsx';
import extractSKUData from './ExtractData';
import { Stack } from '@mui/system';
import { blue, blueGrey, green } from '@mui/material/colors';
import AxiosFunction from '../../../axiosCustomInstance';
import UserAuth from '../../ProtectedRoute/userAuth';
import SKUManagement from './SKUsLandingPage';
import LoadingDialog from '../../Loading';
import downloadTemplateExcel from './DownloadExcel';
import LoadingProgressDialog from './BulkUploadProgressBar';
const BulkUploadSKU = ({ contractTypeID, contractTypeName ,successMessage,setSuccessMessage}) => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [numYears, setNumYears] = useState(0);
  const [dateRanges, setDateRanges] = useState([]);
  const [errorDialog, setErrorDialog] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [isFileUploadEnabled, setFileUploadEnabled] = useState(false);
  const [isExtractEnabled, setExtractEnabled] = useState(false);
  const [loading,setLoading]=useState(false)
  const [progressLoading,setProgressLoading]=useState(false)
  const [progress, setProgress] = useState(0);


  const AxiosAPIInstance=AxiosFunction()
  const {UserName,UserEmail}=UserAuth()
  // Handle year selection
  const handleYearSelection = (e) => {
    const years = parseInt(e.target.value, 10);
    setNumYears(years);
    setDateRanges(Array.from({ length: years }, () => ({ fromDate: '', toDate: '' })));
    setFileUploadEnabled(false); // Reset file upload enable status
    setSelectedFile(null); // Reset file selection
  };

  // Handle date range change
  const handleDateChange = (index, field, value) => {
    const updatedRanges = [...dateRanges];
    updatedRanges[index][field] = value;
    setDateRanges(updatedRanges);

    // Check if all date ranges are filled
    const allFilled = updatedRanges.every(
      (range) => range.fromDate && range.toDate
    );
    setFileUploadEnabled(allFilled);
  };

  // Handle file change
  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setExtractEnabled(!!e.target.files[0]);
  };

/**
 * Handles the extraction of SKU data from the selected Excel file.
 * This function validates file existence, file type, initiates the extraction process,
 * and provides feedback to the user via success or error dialogs.
 */
// const handleExtractData = async () => {
//   setLoading(true)
//   try {
//     // Check if a file has been selected
//     if (!selectedFile) {
//       setErrorMessage('Please select a file to extract data.'); // Notify the user if no file is selected
//       setLoading(false)
//       setErrorDialog(true); // Open the error dialog
//       return; // Exit the function early
//     }

//     // Validate file type
//     if (!['application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'].includes(selectedFile.type)) {
//       setErrorMessage('Invalid file format. Please upload a valid Excel file (.xlsx or .xls).'); // Notify the user of invalid file format
//       setLoading(false)
//       setErrorDialog(true); // Open the error dialog
  
//       return; // Exit if the file type is invalid
//     }

//     // Initiate the data extraction process
//     const extractedData = await extractSKUData(selectedFile, numYears,dateRanges);

//     // Log the extracted data for debugging purposes
//     console.log('Extracted SKU Data:', extractedData);

//     // Call API to upload the extracted data
//      await AxiosAPIInstance.post('/ContractTypes/SKUs/uploadExtractedData', {
//       extractedData,
//       contractTypeID,
//       contractTypeName,
//       updatedBy: UserEmail, // Assuming userEmail is available
//     }).then(async(response)=>{
//       setLoading(false)
//       if (response.status === 201) {
//         setSuccessDialog(true); // Display success dialog
//             setLoading(false)
//       } else {
//         setErrorDialog(true); // Open the error dialog to notify the user
//         setErrorMessage(response.data.message || 'Error uploading extracted data.');
//         throw new Error(response.data.message || 'Error uploading extracted data.');
//       }
//     }).catch((error)=>{
//       setLoading(false)
//     // Differentiate between known errors and unexpected errors
//     if (error?.response) {
//       console.error('Server error:', error?.response.data); // Log server-side errors
//       setErrorMessage(error?.response.data || 'An error occurred while processing the data. Please try again.');
//     } else {
//       console.error('Unexpected error during data extraction:', error.message); // Log unexpected errors
//       setErrorMessage(`An unexpected error occurred during data extraction. ${error.message}`); // Set a generic error message
//     }
//     setErrorDialog(true); // Open the error dialog to notify the user
//     })

//   } catch (error) {
//     setLoading(false)
//     // Differentiate between known errors and unexpected errors
//     if (error?.response) {
//       console.error('Server error:', error?.response.data); // Log server-side errors
//       setErrorMessage(error?.response.data || 'An error occurred while processing the data. Please try again.');
//     } else {
//       console.error('Unexpected error during data extraction:', error.message); // Log unexpected errors
//       setErrorMessage(`An unexpected error occurred during data extraction. ${error.message}`); // Set a generic error message
//     }
//     setErrorDialog(true); // Open the error dialog to notify the user
//   }
// }; 
/**
 * Handles the extraction of SKU data from the selected Excel file.
 */
const handleExtractData = async () => {
  setProgressLoading(true);
  const BATCH_SIZE = 50; // Smaller batch size to avoid parameter limits

  try {
    // Check if a file has been selected
    if (!selectedFile) {
      setErrorMessage('Please select a file to extract data.');
      setProgressLoading(false);
      setErrorDialog(true);
      return;
    }

    // Validate file type
    if (
      ![
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'application/vnd.ms-excel',
      ].includes(selectedFile.type)
    ) {
      setErrorMessage('Invalid file format. Please upload a valid Excel file (.xlsx or .xls).');
      setProgressLoading(false);
      setErrorDialog(true);
      return;
    }

    // Extract the SKU data
    const extractedData = await extractSKUData(selectedFile, numYears, dateRanges);
    // Separate SKUs and pricing
    const skuData = extractedData.map(({ pricing, ...sku }) => sku);
    const pricingData = extractedData.flatMap(({ partNo, pricing }) =>
      pricing.map((price) => ({ ...price, partNo }))
    );
    const totalBatches = Math.ceil(skuData.length / BATCH_SIZE) + Math.ceil(pricingData.length / BATCH_SIZE);
    let currentBatch = 0;
    // Function to process batches
    const processBatch = async (data, endpoint, batchNumber) => {
      try {
        if(endpoint==='/ContractTypes/SKUs/uploadSKUs')
        {
          const response = await AxiosAPIInstance.post(endpoint, {
            extractedData:data,
            contractTypeID,
            contractTypeName,
            updatedBy: UserEmail,
          });
          
        if (response.status === 201) {
          console.log(`Batch ${batchNumber} processed successfully.`);
        } else {
          setErrorMessage(`Error uploading batch data.${response.data}`)
          throw new Error('Error uploading batch data.');
        }
  
        }
        else
        {
          const response = await AxiosAPIInstance.post(endpoint, {
            pricingData:data,
            contractTypeID,
            contractTypeName,
            updatedBy: UserEmail,
          });
          
        if (response.status === 201) {
          console.log(`Batch ${batchNumber} processed successfully.`);
        } else {
          setErrorDialog(true)
          setErrorMessage(`Error uploading batch data.${response.data}`)
          throw new Error('Error uploading batch data.');
        }
  
        }

      } catch (error) {
        console.error(`Error in batch ${batchNumber}:`, error);
        throw error;
      } finally {
          currentBatch += 1;
          setProgress(Math.floor((currentBatch / totalBatches) * 100));
        }
    };

    // Process SKUs in batches
    for (let i = 0; i < skuData.length; i += BATCH_SIZE) {
      const batch = skuData.slice(i, i + BATCH_SIZE);
      const batchNumber = Math.floor(i / BATCH_SIZE) + 1;
      await processBatch(batch, '/ContractTypes/SKUs/uploadSKUs', batchNumber);
    }

    // Process Pricing in batches
    for (let i = 0; i < pricingData.length; i += BATCH_SIZE) {
      const batch = pricingData.slice(i, i + BATCH_SIZE);
      const batchNumber = Math.floor(i / BATCH_SIZE) + 1;
      await processBatch(batch, '/ContractTypes/SKUs/uploadPricing', batchNumber);
    }

    // Show success dialog
    setProgressLoading(false);
    setSuccessDialog(true);
  } catch (error) {

    setProgressLoading(false);
    if (error?.response) {
      console.error('Server error:', error?.response.data);
      setErrorMessage(
        error?.response.data || 'An error occurred while processing the data. Please try again.'
      );
    } else {
      console.error('Unexpected error during data extraction:', error.message);
      setErrorMessage(`Unexpected error: ${error.message}`);
    }
    setErrorDialog(true);
  }
};


  return (
    <Container>
      <LoadingDialog open={loading}/>
      <LoadingProgressDialog open={progressLoading} progress={progress}/>
      <Paper elevation={2} sx={{ padding: 4 }}>
        <Stack direction="column" spacing={2} justifyContent="center">
          {/* Select Number of Years */}
          <Typography variant="h6">Select Number of Years for Pricing</Typography>
          <TextField
            select
            label="Number of Years"
            value={numYears}
            onChange={handleYearSelection}
            sx={{ width: '200px' }}
            size='small'
          >
            {[1, 2, 3, 4, 5,6,7,8,9,10].map((year) => (
              <MenuItem key={year} value={year}>
                {year}
              </MenuItem>
            ))}
          </TextField>


          {/* Display Date Range Fields */}
          {dateRanges.map((range, index) => (
            <Grid container spacing={2} key={index}>
              <Grid item xs={6}>
                <TextField
                  label={`From Date (Year ${index + 1})`}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={range.fromDate}
                  onChange={(e) =>
                    handleDateChange(index, 'fromDate', e.target.value)
                  }
                  fullWidth
                />
              </Grid>
              <Grid item xs={6}>
                <TextField
                  label={`To Date (Year ${index + 1})`}
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={range.toDate}
                  onChange={(e) =>
                    handleDateChange(index, 'toDate', e.target.value)
                  }
                  fullWidth
                />
              </Grid>
            </Grid>
          ))}
          {/* Example Table */}
          <Typography variant="h6" sx={{ marginTop: 4 }}>
             Excel Template
          </Typography>
          <Button
           variant="contained"
            color="primary"
            onClick={() => downloadTemplateExcel(numYears)}
            disabled={!isFileUploadEnabled}
            size='small'
            >
            Download Template
          </Button>
          
          {/* File Upload */}
          <Typography variant="h6" sx={{ marginTop: 2 }}>
            Upload Excel File
          </Typography>
          <TextField
            type="file"
            accept=".xlsx, .xls"
            onChange={handleFileChange}
            disabled={!isFileUploadEnabled}
            sx={{ marginTop: 2 }}
          />

       {/* Extract Button */}
     <Button
  variant="contained"
  size="small"
  onClick={() => {
    // Perform any additional checks or actions before calling the extract function
    if (isExtractEnabled) {
      handleExtractData(); // Call the extract data function
    }
  }}
  disabled={!isExtractEnabled} // Button is enabled only when isExtractEnabled is true
  sx={{
    marginTop: 2,
    backgroundColor: isExtractEnabled ? blueGrey[700] : blueGrey[400], // Different color for disabled state
    color: 'white',
    ':hover': isExtractEnabled
      ? {
          boxShadow: '10px 10px 20px #ccc',
          bgcolor: blueGrey[600],
        }
      : {},
  }}
>
  Extract
</Button>

        </Stack>
      </Paper>

      {/* Success Dialog */}
      <Dialog open={successDialog} onClose={() => setSuccessDialog(false)}>
        <DialogTitle>
          <Alert severity="success">Success</Alert>
        </DialogTitle>
        <DialogContent>
          <Typography>Data extracted successfully!</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setSuccessDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
      {successDialog===true &&<SKUManagement contractTypeID={contractTypeID} contractTypeName={contractTypeName}/>}
      {/* Error Dialog */}
      <Dialog open={errorDialog} onClose={() => setErrorDialog(false)}>
        <DialogTitle>
          <Alert severity="error">Error</Alert>
        </DialogTitle>
        <DialogContent>
          <Typography>{errorMessage}</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setErrorDialog(false)}>OK</Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default BulkUploadSKU;
