import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem, Stack, Divider,
  Grid, Button, Card, CardContent, CardActionArea, CardActions, Slider, TextField, Avatar, IconButton
} from '@mui/material';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, PieChart, Pie,
  Cell, LabelList, Line, ReferenceLine
} from 'recharts';
import { orange, green, red, blueGrey, blue, indigo, purple } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingDialog from '../../../Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import AxiosFunction from '../../../../axiosCustomInstance';
import { Email, Phone } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { pdf } from '@react-pdf/renderer';
import PdfDocument from '../../../PDF/Main';
import { useNavigate, createSearchParams } from 'react-router-dom';
import ApplicationBar from '../../../AppBar/ApplicationBar';
import UserAuth from '../../../ProtectedRoute/userAuth';
import InfoIcon from '@mui/icons-material/Info';
import { selectCurrentUser } from '../../../Login_Registration/authSlice';
import { selectIsAdmin, selectUserRole } from '../../../Login_Registration/authSlice';
import { useDispatch, useSelector } from "react-redux";

// Define custom colors for pie charts
const COLORS = [orange[600], green[600], red[600]];

// Define function to format currency values
const formatCurrency = (value) => `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
const MyRFQsPerformanceDashboard = ({ totalData, salesHeadEmail, dateFiltersfromMainPage }) => {
  const [chartData, setChartData] = useState([]);
  const [chartDataByDollarValue, setChartDataByDollarValue] = useState([]);
  const [chartDataByProfitMargin, setChartDataByProfitMargin] = useState([]);
  const { UserName, UserEmail } = UserAuth();
  const [dialogMssge, setDialogMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dis, setDis] = useState(false);
  const AxiosAPIInstance = AxiosFunction();
  const [pendingProfitMargin, setPendingProfitMargin] = useState({ averageProfitMarginDollar: 0.00, averageProfitMarginPercent: 0.00});
  const [wonProfitMargin, setWonProfitMargin] = useState({ averageProfitMarginDollar: 0.00, averageProfitMarginPercent: 0.00 });
  const [lostProfitMargin, setLostProfitMargin] = useState({ averageProfitMarginDollar: 0.0, averageProfitMarginPercent: 0.00 });
  
  const [totalQuotes, setTotalQuotes] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [averageDealSize, setAverageDealSize] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalProfitPercent, setTotalProfitPercent] = useState(0);

  const [searchTerm, setSearchTerm] = useState('');
  const [clicked, setClicked] = useState(false);
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);

  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(PD);

  // Info Dialog state management
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoContent, setInfoContent] = useState(null);

  const navigate = useNavigate();

  // Handle the info button click and set the content to display in the dialog
  const handleInfoButtonClick = (content) => {
    setInfoContent(content);
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
    setInfoContent(null);
  };

  useEffect(() => {
    if (!totalData || totalData.length === 0) return;

    // Extract start and end dates from the provided date filters
    const startDate = new Date(dateFiltersfromMainPage.createdDateFrom);
    const endDate = new Date(dateFiltersfromMainPage.createdDateTo);

    // Filter data based on created date range
    const filteredData = totalData.filter((entry) => {
      const createdDate = new Date(entry.Created_Date);
      return createdDate >= startDate && createdDate <= endDate;
    });

    let fetchedData;

    if (isAdmin) {
      fetchedData = [...filteredData];
    } else {
      fetchedData = filteredData.filter((item) => item.Sales_Head_Email === UserEmail);
    }

    // Aggregate data dynamically based on the filtered months
    const uniqueMonths = [...new Set(filteredData.map((entry) => {
      const createdDate = new Date(entry.Created_Date);
      return `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;
    }))];

    const allMonths = uniqueMonths.map((month) => ({
      name: month,
      PENDING: 0,
      WON: 0,
      LOST: 0,
      CANCELLED:0

    }));
    const allMonthsByDollar = uniqueMonths.map((month) => ({
      name: month,
      PENDING_DOLLAR: 0.00,
      WON_DOLLAR: 0.00,
      LOST_DOLLAR: 0.00,
      CANCELLED_DOLLAR:0.00,
    }));
    const allMonthsByAverageDealSize = uniqueMonths.map((month) => ({
      name: month,
      PENDING_DEAL: 0.00,
      WON_DEAL: 0.00,
      LOST_DEAL: 0.00,
      CANCELLED_DEAL:0.00,
    }));

    const updatedChartData = fetchedData.reduce((acc, entry) => {
      const createdDate = new Date(entry.Created_Date);
      const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

      const monthIndex = acc.findIndex((item) => item.name === monthYear);

      if (monthIndex !== -1) {
        if (entry.RFQ_Status === 'PENDING') {
          acc[monthIndex].PENDING += 1;
          // acc[monthIndex].PENDING_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
        } else if (entry.RFQ_Status === 'WON') {
          acc[monthIndex].WON += 1;
          // acc[monthIndex].WON_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
        } else if (entry.RFQ_Status === 'LOST') {
          acc[monthIndex].LOST += 1;
          // acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin );
        }
        else if (entry.RFQ_Status === 'CANCELLED') {
          acc[monthIndex].CANCELLED += 1;
          // acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin );
        }
      }

      return acc;
    }, allMonths);

    setChartData(updatedChartData);

    const updatedChartDataByDollarValue = fetchedData.reduce((acc, entry) => {
      const createdDate = new Date(entry.Created_Date);
      const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;
      
      const monthIndex = acc.findIndex((item) => item.name === monthYear);
    
      if (monthIndex !== -1) {
        if (entry.RFQ_Status === 'PENDING') {
          acc[monthIndex].PENDING_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin || 0);
        } else if (entry.RFQ_Status === 'WON') {
          acc[monthIndex].WON_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin || 0);
        } else if (entry.RFQ_Status === 'LOST') {
          acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin || 0);
        }
        else if (entry.RFQ_Status === 'CANCELLED') {
          acc[monthIndex].CANCELLED_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin || 0);
        }
      }
    
      return acc;
    }, allMonthsByDollar);
    
    
   setChartDataByDollarValue(updatedChartDataByDollarValue);

   const updatedChartDataByProfitMargin = fetchedData.reduce((acc, entry) => {
    const createdDate = new Date(entry.Created_Date);
    const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;
  
    // Ensure the 'Actual_Total_Cost_After_Margin' and 'Actual_Total_Cost_Before_Margin' are valid numbers.
    const costBeforeMargin = parseFloat(entry.Actual_Total_Cost_Before_Margin) || 0;
    const costAfterMargin = parseFloat(entry.Actual_Total_Cost_After_Margin) || 0;
  
    // Calculate profit margin by subtracting the cost before margin from the cost after margin
    const profitMargin = costAfterMargin - costBeforeMargin;
  
    // Find the month object in the accumulator array
    const monthIndex = acc.findIndex((item) => item.name === monthYear);
  
    if (monthIndex !== -1) {
      if (entry.RFQ_Status === 'PENDING') {
        acc[monthIndex].PENDING_DEAL += profitMargin;
      } else if (entry.RFQ_Status === 'WON') {
        acc[monthIndex].WON_DEAL += profitMargin;
      } else if (entry.RFQ_Status === 'LOST') {
        acc[monthIndex].LOST_DEAL += profitMargin;
      } else if (entry.RFQ_Status === 'CANCELLED') {
        acc[monthIndex].CANCELLED_DEAL += profitMargin;
      }
    }
  
    return acc;
  }, allMonthsByAverageDealSize);
  
  // Update the state with the calculated profit margins
  setChartDataByProfitMargin(updatedChartDataByProfitMargin);
  
   if (chartDataByProfitMargin.length > 0) {
    setPendingProfitMargin(calculateAverageProfitMarginPerStatus('PENDING'));
    setWonProfitMargin(calculateAverageProfitMarginPerStatus('WON'));
    setLostProfitMargin(calculateAverageProfitMarginPerStatus('LOST'));
  }
  
    // Calculate KPIs
    const totalWonQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'WON').length;
    const totalPendingQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'PENDING').length;
    const totalLostQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'LOST').length;
    const totalQuotesData = totalLostQuotes + totalWonQuotes + totalPendingQuotes;

    const totalRevenueData = fetchedData
      .filter((entry) => entry.RFQ_Status === 'WON')
      .reduce((acc, entry) => acc + parseFloat(entry.Actual_Total_Cost_After_Margin || 0), 0);

    const averageDealSizeData = totalWonQuotes > 0 ? totalRevenueData / totalWonQuotes : 0;
    const conversionRateData = totalQuotesData > 0 ? (totalWonQuotes / totalQuotesData) * 100 : 0;

    const totalProfitData = fetchedData
      .filter((entry) => entry.RFQ_Status === 'WON')
      .reduce((acc, entry) => acc + (parseFloat(entry.Actual_Total_Cost_After_Margin || 0) - parseFloat(entry.Actual_Total_Cost_Before_Margin || 0)), 0);

    const totalProfitPercentData = totalRevenueData > 0 ? (totalProfitData / totalRevenueData) * 100 : 0;

    setTotalQuotes(totalQuotesData);
    setConversionRate(conversionRateData);
    setAverageDealSize(averageDealSizeData);
    setTotalRevenue(totalRevenueData);
    setTotalProfit(totalProfitData);
    setTotalProfitPercent(totalProfitPercentData);

  }, [totalData, dateFiltersfromMainPage, UserEmail, isAdmin]);

  // Render customized labels on bar charts
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    if (value === 0) return null;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };


  const calculateAverageProfitMarginPerStatus = (status) => {
    // Filter data for the specific status
    const filteredData = chartDataByProfitMargin.filter((entry) => entry[status+'_DEAL'] > 0);
  
    // Calculate the total profit margin for the status
    const totalProfitMargin = filteredData.reduce((acc, entry) => acc + entry[status+'_DEAL'], 0);
  
    // Compute the average profit margin in dollars
    const averageProfitMarginDollar = filteredData.length > 0 ? totalProfitMargin / filteredData.length : 0;
  
    // Compute the total dollar value for the status
    const totalDollarValue = chartDataByDollarValue.reduce((acc, entry) => acc + entry[status+'_DOLLAR'], 0);
  
    // Compute the average profit margin percentage
    const averageProfitMarginPercent = totalDollarValue > 0 ? (totalProfitMargin / totalDollarValue) * 100 : 0;

  
    return { averageProfitMarginDollar, averageProfitMarginPercent };
  };
  


  // Function to handle closing of success or error dialogs
  const handleClose = async () => {
    setDis(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setDialogMssge('');
    setClicked(false);
  }; 



  // Functions to generate explanation content for each chart type
  const getInfoContent = (chartType) => {
    setLoading(true)
    switch (chartType) {
      case 'monthlyQuotes':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Monthly Quotes (Count) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart shows the number of quotes (PENDING, WON, and LOST) that were created each month for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the quotes that are still under consideration.</li>
                <li><strong>WON:</strong> Represents the quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the quotes that were not successful.</li>
              </ul>
              Example: If in January 2024, you have 10 PENDING quotes, 5 WON quotes, and 3 LOST quotes, the chart will show 10 (PENDING), 5 (WON), and 3 (LOST) bars for January 2024.
            </DialogContentText>
          </>
        );
      case 'dollarValue':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}} >Monthly Quotes (Dollar Value) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart displays the total dollar value of quotes (PENDING, WON, and LOST) for each month in the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the total dollar value of quotes that are still under consideration.</li>
                <li><strong>WON:</strong> Represents the total dollar value of quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the total dollar value of quotes that were not successful.</li>
              </ul>
              Example: If in February 2024, you have 3 PENDING quotes worth $10,000, 2 WON quotes worth $15,000, and 1 LOST quote worth $5,000, the chart will show bars representing these values.
            </DialogContentText>
          </>
        );
      case 'profitMargin':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Monthly Quotes (Profit Margin) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart illustrates the profit margins for quotes (PENDING, WON, and LOST) each month for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the profit margin for quotes still under consideration.</li>
                <li><strong>WON:</strong> Represents the profit margin for quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the profit margin for quotes that were not successful.</li>
              </ul>
              Example: If in March 2024, you have a PENDING quote with a $2,000 profit margin, a WON quote with a $3,500 profit margin, and a LOST quote with a $1,000 profit margin, the chart will show these values accordingly.
            </DialogContentText>
          </>
        );
      case 'dollarDistribution':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Dollar Value Distribution Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This pie chart breaks down the total dollar value of all quotes into PENDING, WON, and LOST categories for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Shows the percentage of the total dollar value that is still under consideration.</li>
                <li><strong>WON:</strong> Shows the percentage of the total dollar value that has been successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Shows the percentage of the total dollar value that was not successful.</li>
              </ul>
              Example: If the total dollar value of all quotes is $100,000, and $50,000 is WON, $30,000 is PENDING, and $20,000 is LOST, the chart will display the corresponding percentages for each.
            </DialogContentText>
          </>
        );
      case 'profitMarginDistribution':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Profit Margin Distribution Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This pie chart represents the distribution of profit margins across PENDING, WON, and LOST quotes for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Shows the percentage of the total profit margin still under consideration.</li>
                <li><strong>WON:</strong> Shows the percentage of the total profit margin from successfully converted contracts.</li>
                <li><strong>LOST:</strong> Shows the percentage of the total profit margin from unsuccessful quotes.</li>
              </ul>
              Example: If the total profit margin across all quotes is $50,000, and $30,000 is from WON quotes, $10,000 is from PENDING quotes, and $10,000 is from LOST quotes, the chart will display these distributions.
            </DialogContentText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ApplicationBar />
      {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            e.preventDefault();
            handleClose();
            setDis(false)
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      <LoadingDialog open={loading} />

      <Stack direction="row" sx={{ padding:'10px',width: '100%' }} spacing={1}> 
        <Stack direction="row" justifyContent="center" spacing={1}>
            
          {/* Key Metrics */}
 
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <Stack direction="column" justifyContent="center" spacing={2} sx={{ width: '100%',fontSize:'12px' }}>
            <Paper
            elevation={3}
            sx={{
              padding: '10px',
              border: 1,
              borderColor: blueGrey[800],
              borderRadius: '12px',
              fontSize:'12px'
            }}
          >
            <Typography
              component="p"
              sx={{
                fontSize: '12px',
                fontWeight: 'bold',
                color: blueGrey[900],
                marginBottom: 2,
              }}
            >
              Key Performance Indicators
            </Typography>
            <Divider sx={{ bgcolor: orange[500], height: '2px', marginBottom: 2 }} />
            <Grid container spacing={2}>
              {/* Total Quotes */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: blue[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography  sx={{ fontWeight: 'bold',fontSize:'11px', color: blue[800] }}>
                    Total Quotes (-Cancelled)
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: blue[600] }}>
                    {totalQuotes}
                  </Typography>
                </Box>
              </Grid>

              {/* Conversion Rate */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: green[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography  sx={{ fontWeight: 'bold',fontSize:'11px', color: green[800] }}>
                    Conversion Rate (Won)
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: green[600] }}>
                    {conversionRate.toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>

              {/* Average Deal Size */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: orange[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography  sx={{ fontWeight: 'bold',fontSize:'11px', color: orange[800] }}>
                    Average Deal Size
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: orange[600] }}>
                    {formatCurrency(averageDealSize)}
                  </Typography>
                </Box>
              </Grid>

              {/* Total Revenue */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: indigo[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold',fontSize:'11px', color: indigo[800] }}>
                    Total Revenue
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: indigo[600] }}>
                    {formatCurrency(totalRevenue)}
                  </Typography>
                </Box>
              </Grid>

              {/* Total Profit ($) */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: red[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography  sx={{ fontWeight: 'bold',fontSize:'11px', color: red[800] }}>
                    Total Profit ($)
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: red[600] }}>
                    {formatCurrency(totalProfit)}
                  </Typography>
                </Box>
              </Grid>

              {/* Total Profit (%) */}
              <Grid item xs={2}>
                <Box
                  sx={{
                    textAlign: 'center',
                    p: 2,
                    borderRadius: '12px',
                    backgroundColor: purple[50],
                    transition: 'transform 0.2s, box-shadow 0.2s',
                    ':hover': { transform: 'scale(1.05)', boxShadow: 4 },
                  }}
                >
                  <Typography sx={{ fontWeight: 'bold',fontSize:'11px', color: purple[800] }}>
                    Total Profit (%)
                  </Typography>
                  <Typography sx={{ fontSize:'11px', color: purple[600] }}>
                    {totalProfitPercent.toFixed(2)}%
                  </Typography>
                </Box>
              </Grid>
            </Grid>
          </Paper>
 
              </Stack>
            </Grid>

    <Grid container spacing={3}>
  {/* Monthly Quotes Chart */}
  <Grid item xs={12} md={6}>
    <Paper
      elevation={3}
      sx={{
        padding: '10px',
        fontSize: '12.5px',
        borderRadius: '12px',
        backgroundColor: '#f9f9f9',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center"  >
        <Typography variant="h6" gutterBottom>
          Monthly Quote <strong style={{ color: 'red' }}>Numbers</strong>: <strong></strong>
        </Typography>
        <IconButton onClick={() => handleInfoButtonClick(getInfoContent('monthlyQuotes'))}>
          <InfoIcon />
        </IconButton>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 2}}>
            <CartesianGrid strokeDasharray="4 4" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="PENDING" stackId="a" fill={orange[500]}>
              <LabelList dataKey="PENDING" content={renderCustomizedLabel} />
            </Bar>
            <Bar dataKey="WON" stackId="a" fill={green[800]}>
              <LabelList dataKey="WON" content={renderCustomizedLabel} />
            </Bar>
            <Bar dataKey="LOST" stackId="a" fill={red[800]}>
              <LabelList dataKey="LOST" content={renderCustomizedLabel} />
            </Bar>
            <Bar dataKey="CANCELLED" stackId="a" fill={blue[400]}>
              <LabelList dataKey="CANCELLED" content={renderCustomizedLabel} />
            </Bar>
            <Line type="monotone" dataKey="TREND" stroke="#8884d8" dot={false} />
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="body1" align="center"  sx={{ fontSize: '12px' }}>
        Total Pending: <strong>{chartData.reduce((acc, item) => acc + item.PENDING, 0)}</strong> | 
        Total Won: <strong>{chartData.reduce((acc, item) => acc + item.WON, 0)}</strong> | 
        Total Lost: <strong>{chartData.reduce((acc, item) => acc + item.LOST, 0)}</strong>|
        Total Cancelled: <strong>{chartData.reduce((acc, item) => acc + item.CANCELLED, 0)}</strong>
      </Typography>
    </Paper>
  </Grid>

  {/* Dollar Value Chart */}
  <Grid item xs={12} md={6}>
    <Paper
      elevation={3}
      sx={{
        padding: '10px',
        fontSize: '12.5px',
        borderRadius: '12px',
        backgroundColor: '#f9f9f9',
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
      }}
    >
      <Stack direction="row" justifyContent="space-between" alignItems="center" mb={2}>
        <Typography variant="h6" gutterBottom>
          Monthly Quote <strong style={{ color: 'red' }}>Total Dollar Value($)</strong>: <strong></strong>
        </Typography>
        <IconButton onClick={() => handleInfoButtonClick(getInfoContent('dollarValue'))}>
          <InfoIcon />
        </IconButton>
      </Stack>
      <Box sx={{ flexGrow: 1 }}>
        <ResponsiveContainer width="100%" height={250}>
          <BarChart data={chartDataByDollarValue} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
            <CartesianGrid strokeDasharray="6 6" />
            <XAxis dataKey="name" />
            <YAxis tickFormatter={(value) => `$${Number(value).toLocaleString('en-US')}`} />
            <Tooltip formatter={(value) => `$${Number(value).toLocaleString('en-US')}`} />
            <Legend />
            <Bar dataKey="PENDING_DOLLAR" stackId="b" fill={orange[500]} />
            <Bar dataKey="WON_DOLLAR" stackId="b" fill={green[800]} />
            <Bar dataKey="LOST_DOLLAR" stackId="b" fill={red[800]} />
            <Bar dataKey="CANCELLED_DOLLAR" stackId="b" fill={blue[400]} />
            
          </BarChart>
        </ResponsiveContainer>
      </Box>
      <Typography variant="body1" align="center" sx={{ fontSize: '12px' }}>
        Total Pending: <strong>{chartDataByDollarValue.reduce((acc, item) => acc + item.PENDING_DOLLAR, 0).toLocaleString()}</strong> | 
        Total Won: <strong>{chartDataByDollarValue.reduce((acc, item) => acc + item.WON_DOLLAR, 0).toLocaleString()}</strong> | 
        Total Lost: <strong>{chartDataByDollarValue.reduce((acc, item) => acc + item.LOST_DOLLAR, 0).toLocaleString()}</strong>|
        Total Cancelled: <strong>{chartDataByDollarValue.reduce((acc, item) => acc + item.CANCELLED_DOLLAR, 0).toLocaleString()}</strong>
      </Typography>
    </Paper>
  </Grid>
</Grid>

        </Stack>
          </Stack>
    
      {/* Info Dialog for Chart Explanations */}
      <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
        <DialogTitle>
        <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontWeight:'bold',fontFamily:'arial'}}>
        Chart Explanation</Alert>
         </DialogTitle>
        <DialogContent sx={{fontSize:'12.5px'}}>
          {infoContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyRFQsPerformanceDashboard;
