import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    IconButton,
    Avatar,
    Stack,
    TextField,
    Tooltip,
    Alert,
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,Button
} from '@mui/material';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { DatePicker } from '@mui/x-date-pickers';
import { indigo, orange, red, blueGrey } from '@mui/material/colors';
import { PDFDownloadLink } from '@react-pdf/renderer';
import AxiosFunction from '../../../axiosCustomInstance'; // Import custom Axios instance
import LoadingDialog from "../../Loading";
import PackingSlipPdfDocument from '../../PackageSlip/Main'; 
import dayjs from 'dayjs'; // Import dayjs for date manipulation if not already included

// Main component for handling the Packing Slip functionality
const PackingSlipComponent = ({
    row, // Row data representing the current quote
    UserName, // Name of the user
    today, // Current date
    refreshRowData, // Function to refresh row data after an update
    invoiceNumber, // Initial invoice number
    File_Opportunity_Name, // Name for the generated PDF file
    PackingSlipPdfDocument, // PDF document component to generate packing slip
    isPackageDetailsDialogOpen, // Boolean to control the dialog visibility
    onUpdateSuccess, // Function to handle success status updates
    onUpdateError, // Function to handle error status updates
    onUpdateErrorMessage, // Function to set error messages
    setIsPackageDetailsDialogOpen
}) => {
    // State for managing loading state
    const [loading, setLoading] = useState(false);

    // State for storing package details form data
    const [packageDetails, setPackageDetails] = useState({
        RFQRFQID: '',
        From_Address: '',
        To_Address: '',
        Contract_Number: '',
        Shipping_Method: '',
        Tracking_Number: '',
        Ship_Date: '',
        Invoice_Number: '',
        Delivery_Order_Number: '',
        Order_Number: '',
        Total_Boxes: '',
        Sub_Total: '',
        History: '',
        Updated_By: UserName, // Setting the user who last updated the data
    });

    // State to control the visibility of the dialog
    // const [packageDetailsDialog, setPackageDetailsDialog] = useState(isPackageDetailsDialogOpen);

    // State to track if the packing slip has been clicked
    const [packingSlipClicked, setPackingSlipClicked] = useState(false);

    // State to hold data for generating the packing slip PDF
    const [packingSlipData, setPackingSlipData] = useState({});

    // State to track if the update was successful
    const [updateSuccess, setUpdateSuccess] = useState(false);

    // State to track if there was an error during the update
    const [updateError, setUpdateError] = useState(false);

    // State to hold the error message to display
    const [updateErrorMessage, setUpdateErrorMessage] = useState("");

    // State to manage the new invoice number
    const [newInvoiceNumber, setNewInvoiceNumber] = useState(invoiceNumber);

    // Initialize custom Axios instance
    const AxiosAPIInstance = AxiosFunction();


    // Effect hook to load existing packing slip details when the dialog opens
    useEffect(() => {
        const fetchPackingSlipDetails = async () => {
            setLoading(true);
            try {
                // Fetch packing slip details using RFQ ID
                const result = await AxiosAPIInstance.get("/MasterLifeCycle/GetPackingSlipDetailsByRFQID", {
                    params: { RFQRFQID: row?.RFQRFQID }
                });
                if (result?.data) {
                    // Update package details state with fetched data
                    setPackageDetails({
                        RFQRFQID: result?.data.RFQRFQID,
                        From_Address: result?.data.From_Address,
                        To_Address: result?.data.To_Address,
                        Contract_Number: result?.data.Contract_Number,
                        Shipping_Method: result?.data.Shipping_Method,
                        Tracking_Number: result?.data.Tracking_Number,
                        Ship_Date: String(result?.data.Ship_Date).split('T')[0],
                        Invoice_Number: row?.Invoice_Number && row?.Invoice_Number !== 'NA' && row?.Invoice_Number !== 'N/A' ? row?.Invoice_Number : result?.data.Invoice_Number,
                        Delivery_Order_Number: result?.data.Delivery_Order_Number,
                        Order_Number: result?.data.Order_Number,
                        Total_Boxes: result?.data.Total_Boxes,
                        Sub_Total: result?.data.Sub_Total,
                        History: result?.data.History,
                        Updated_By: UserName,
                    });
                    setNewInvoiceNumber(row?.Invoice_Number);
                } else {
                    throw new Error("Unexpected response structure");
                }
            } catch (error) {
                // Handle error if no packing slip details exist
                if (error.response?.data?.message === "No Packing Slip Details Exists for this Quote") {
                    setPackageDetails({
                        RFQRFQID: row?.RFQRFQID,
                        From_Address: 'Aspetto Inc.\n3428 Hwy 297 North\nVidalia, GA 30474',
                        To_Address: 'NA',
                        Contract_Number: 'NA',
                        Shipping_Method: 'NA',
                        Tracking_Number: 'NA',
                        Ship_Date: String(today).split('T')[0],
                        Invoice_Number: row?.Invoice_Number,
                        Delivery_Order_Number: 'NA',
                        Order_Number: 'NA',
                        Total_Boxes: 'NA',
                        Sub_Total: '',
                        History: '',
                        Updated_By: UserName,
                    });
                }
                setUpdateError(true);
                setUpdateSuccess(false);
                setUpdateErrorMessage(error.response?.data?.message || "Cannot Process the request");
            } finally {
                setLoading(false);
            }
        };

        if (isPackageDetailsDialogOpen) {
            fetchPackingSlipDetails();
        }
    }, [isPackageDetailsDialogOpen]);

    // Function to close the packing slip dialog and reset relevant states
    const closePackingSlipDialog = (e) => {
        e?.preventDefault(); // Prevent default action if the event is triggered by a form submission
        // onCloseDialog(); // Use the passed handler to close the dialog
        setLoading(false);
        // Reset dialog and click states
        setIsPackageDetailsDialogOpen(false);
        setPackingSlipClicked(false);

        // Reset package details and packing slip data to initial state
        setPackageDetails({
            RFQRFQID: '',
            Updated_By: UserName,
            From_Address: 'Aspetto Inc.\n3428 Hwy 297 North\nVidalia, GA 30474',
            To_Address: 'NA',
            Contract_Number: 'NA',
            Shipping_Method: 'NA',
            Tracking_Number: 'NA',
            Ship_Date: String(today).split('T')[0],
            Invoice_Number: 'NA',
            Delivery_Order_Number: 'NA',
            Order_Number: 'NA',
            Total_Boxes: 'NA',
            History: 'NA'
        });

        setPackingSlipData({
            RFQRFQID: '',
            Contract_Vehicle: '',
            Status: '',
            Customer_Name: '',
            From_Address: 'NA',
            To_Address: 'NA',
            Total_Boxes: 'NA',
            Ship_Date: String(today).split('T')[0],
            Contract_Number: 'NA',
            Shipping_Method: 'NA',
            items: 'NA',
            Tracking_Number: 'NA',
            Order_Number: 'NA',
            Delivery_Order_Number: 'NA',
            Invoice_Number: '',
            Sub_Total: 'NA',
            From_Email: 'NA',
            Print_Date: String(today).split('T')[0]
        });
    };

    // Function to handle packing slip update and refresh data
    const handlePackingSlipUpdate = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true); // Set loading state to true

        try {
            // API call to update packing slip details
            const response = await AxiosAPIInstance.post("/MasterLifeCycle/PackingSlipCreateOrUpdate", packageDetails);

            if (response?.data) {
                // Update packing slip data with API response
                setPackingSlipData({
                    ...packingSlipData,
                    RFQRFQID: response?.data.RFQRFQID,
                    Contract_Vehicle: response?.data.Contract_Vehicle,
                    Status: response?.data.Status,
                    Customer_Name: response?.data.Customer_Name,
                    From_Address: response?.data.From_Address,
                    To_Address: response?.data.To_Address,
                    Total_Boxes: response?.data.Total_Boxes,
                    Ship_Date: String(response?.data.Ship_Date).split('T')[0],
                    Contract_Number: response?.data.Contract_Number,
                    Shipping_Method: response?.data.Shipping_Method,
                    items: row?.Purchase_Orders,
                    Tracking_Number: response?.data.Tracking_Number,
                    Order_Number: response?.data.Order_Number,
                    Delivery_Order_Number: response?.data.Delivery_Order_Number,
                    Invoice_Number: response?.data.Invoice_Number,
                    Sub_Total: response?.data.Sub_Total,
                    From_Email: response?.data.Sales_Head_Email,
                    Print_Date: today,
                });

                setPackageDetails({
                    RFQRFQID: response?.data.RFQRFQID,
                    From_Address: response?.data.From_Address,
                    To_Address: response?.data.To_Address,
                    Contract_Number: response?.data.Contract_Number,
                    Shipping_Method: response?.data.Shipping_Method,
                    Tracking_Number: response?.data.Tracking_Number,
                    Ship_Date: String(response?.data.Ship_Date).split('T')[0],
                    Invoice_Number: row?.Invoice_Number && row?.Invoice_Number !== 'NA' && row?.Invoice_Number !== 'N/A' ? row?.Invoice_Number : response?.data.Invoice_Number,
                    Delivery_Order_Number: response?.data.Delivery_Order_Number,
                    Order_Number: response?.data.Order_Number,
                    Total_Boxes: response?.data.Total_Boxes,
                    Sub_Total: response?.data.Sub_Total,
                    History: response?.data.History,
                    Updated_By: UserName,
                });

                setNewInvoiceNumber(row?.Invoice_Number);
                setPackingSlipClicked(true); // Mark the packing slip as clicked
                refreshRowData(); // Refresh the parent component's data

                onUpdateSuccess(true); // Set success state in parent component
                onUpdateError(false); // Reset error state in parent component
                onUpdateErrorMessage('Packing Slip Details Updated Successfully'); // Set success message in parent component
                 
                // setPackageDetailsDialog(false); // Close the dialog
            } else {
                throw new Error('Failed to update packing slip.'); // Handle failed update
            }
        } catch (error) {
            setLoading(false); // Reset loading state

            onUpdateError(true); // Set error state in parent component
            onUpdateSuccess(false); // Reset success state in parent component
            onUpdateErrorMessage(error.response?.data || "Cannot Process the request"); // Set error message in parent component
        } finally {
            setLoading(false); // Reset loading state
        }
    };

    // Function to auto-populate the 'To' address based on RFQ data
    const handleToAddressPopulate = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true); // Set loading state to true

        try {
            // Fetch RFQ details to populate the 'To' address
            const response = await AxiosAPIInstance.get("/RFQsSearch/RFQID", { params: { RFQ_ID: row?.RFQRFQID } });
            if (response?.data[0] !== null) {
                setLoading(false); // Reset loading state
                setPackageDetails({
                    ...packageDetails,
                    To_Address: `${response?.data[0].Opportunity_Name}\n\nATTN: ${response?.data[0].Customer_Name}\nADDRESS: ${response?.data[0].Customer_Address}`
                });
            } else {
                setUpdateError(true); // Set error state
                setLoading(false); // Reset loading state
                setUpdateErrorMessage("No Details found"); // Set the error message
            }
        } catch (error) {
            setUpdateError(true); // Set error state
            setLoading(false); // Reset loading state
            setUpdateErrorMessage("No Details found"); // Set error message if an error occurs
        }
    };

    // Function to auto-populate the Shipping Method based on RFQ data
    const handleShippingMethodAutoPopulate = async (e) => {
        e.preventDefault(); // Prevent default form submission
        setLoading(true); // Set loading state to true

        try {
            // Fetch RFQ details to populate the Shipping Method
            const response = await AxiosAPIInstance.get("/RFQsSearch/RFQID", { params: { RFQ_ID: row?.RFQRFQID } });
            if (response?.data[0] !== null) {
                setLoading(false); // Reset loading state
                setPackageDetails({
                    ...packageDetails,
                    Shipping_Method: response?.data[0].Shipping_Method
                });
            } else {
                setUpdateError(true); // Set error state
                setLoading(false); // Reset loading state
                setUpdateErrorMessage("No Shipping Method details found"); // Set the error message
            }
        } catch (error) {
            setUpdateError(true); // Set error state
            setLoading(false); // Reset loading state
            setUpdateErrorMessage("Failed to auto-populate the Shipping Method"); // Set the error message if an error occurs
        }
    };

    // Return the UI for the Packing Slip Dialog and its functionalities
    return (
        <>
            <LoadingDialog open={loading} /> {/* Show loading dialog while data is being fetched or updated */}
            <Dialog
                key={row} // Unique key for the dialog, using the row data
                open={isPackageDetailsDialogOpen} // Control whether the dialog is open
                maxWidth='x-lg' // Set the maximum width of the dialog
                sx={{ justifyContent: 'stretch' }} // Custom styles to stretch the dialog
                onClose={closePackingSlipDialog} // Function to call when the dialog is closed
                aria-labelledby="alert-dialog-title" // ARIA label for accessibility
                aria-describedby="alert-dialog-description" // ARIA description for accessibility
            >
                <DialogTitle id="alert-dialog-title">
                    <Alert variant='filled' sx={{ bgcolor: orange[200],color:blueGrey[900]}}>
                        {row.Customer_Name + ' (' + row?.RFQQuoteNumber + ')'}
                    </Alert>
                </DialogTitle>
                <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
                    <DialogContentText id="alert-dialog-description">
                        <Stack sx={{ textAlign: "center", alignItems: "center", marginTop: '20px' }} justifyContent='center' direction='column' spacing={3}>
                            <Stack direction='column' spacing={2} justifyContent='center'>
                                <Stack direction='row' spacing={4} justifyContent='center'>
                                    <TextField
                                        size='small'
                                        label='From'
                                        name='From_Address'
                                        multiline
                                        minRows={4}
                                        value={packageDetails.From_Address} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, From_Address: e.target.value }) }} // Update state on change
                                        sx={{ width: "350px", fontSize: '13px' }}
                                    />
                                    <Stack sx={{ width: "130px" }}></Stack>
                                    <span>
                                        <TextField
                                            size='small'
                                            label='To'
                                            multiline
                                            minRows={4}
                                            name='To_Address'
                                            value={packageDetails.To_Address} // Controlled input value from state
                                            onChange={(e) => { setPackageDetails({ ...packageDetails, To_Address: e.target.value }) }} // Update state on change
                                            sx={{ width: "350px", fontSize: '13px' }}
                                        />
                                        <Tooltip title="Auto Populate From Quote">
                                            <IconButton
                                                name='To_Address'
                                                variant="text"
                                                sx={{
                                                    color: 'white',
                                                    borderRadius: 1,
                                                }}
                                                onClick={(e) => { handleToAddressPopulate(e, row); }} // Call the function to auto-populate the address
                                            >
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: indigo[800],
                                                        width: "30px",
                                                        height: "30px",
                                                        transition: 'transform 0.2s ease-in-out',
                                                        "&:hover": {
                                                            backgroundColor: indigo[800],
                                                            transform: 'scale(1.2)',
                                                        },
                                                    }}
                                                    variant='rounded'
                                                >
                                                    <FindInPageIcon sx={{ color: "white", backgroundColor: indigo[800], width: "20px", height: "20px" }} />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                </Stack>
                                <Stack direction='row' spacing={4} justifyContent='center'>
                                    <TextField
                                        size='small'
                                        label='Contract Number'
                                        value={packageDetails.Contract_Number} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, Contract_Number: e.target.value }) }} // Update state on change
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                    <span>
                                        <TextField
                                            size='small'
                                            label='Shipping Method'
                                            name='Shipping_Method'
                                            value={packageDetails.Shipping_Method} // Controlled input value from state
                                            onChange={(e) => { setPackageDetails({ ...packageDetails, Shipping_Method: e.target.value }) }} // Update state on change
                                            sx={{ width: "200px", fontSize: '13px' }}
                                        />
                                        <Tooltip title="Auto Populate From Quote">
                                            <IconButton
                                                variant="text"
                                                name='Shipping_Method'
                                                sx={{
                                                    color: 'white',
                                                    borderRadius: 1,
                                                }}
                                                onClick={(e) => { handleShippingMethodAutoPopulate(e, row); }} // Call the function to auto-populate the shipping method
                                            >
                                                <Avatar
                                                    sx={{
                                                        backgroundColor: indigo[800],
                                                        width: "30px",
                                                        height: "30px",
                                                        transition: 'transform 0.2s ease-in-out',
                                                        "&:hover": {
                                                            backgroundColor: indigo[800],
                                                            transform: 'scale(1.2)',
                                                        },
                                                    }}
                                                    variant='rounded'>
                                                    <FindInPageIcon
                                                        sx={{
                                                            backgroundColor: indigo[800],
                                                            width: "20px",
                                                            height: "20px",
                                                        }}
                                                    />
                                                </Avatar>
                                            </IconButton>
                                        </Tooltip>
                                    </span>
                                    <TextField
                                        size='small'
                                        label='Tracking Number'
                                        value={packageDetails.Tracking_Number} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, Tracking_Number: e.target.value }) }} // Update state on change
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                   
                                   <DatePicker
                                   label="Ship Date"
                                   sx={{ width: "200px" }}
                                   value={packageDetails.Ship_Date ? dayjs(packageDetails.Ship_Date) : null} // Convert the date back to dayjs for proper display
                                   renderInput={(params) => (
                                   <TextField
                                   size="small"
                                   variant="standard"
                                   label="Ship Date"
                                   name="Ship Date"
                                   sx={{ width: "200px", fontSize: '13px' }}
                                   {...params}
                                   />
                                   )}
  onChange={(value) => {
    const adjustedDate = dayjs(value).startOf('day').toDate(); // Strip out the time zone component
    setPackageDetails({ ...packageDetails, Ship_Date: adjustedDate });
  }}
/>
                                </Stack>
                                <Stack direction='row' spacing={4} justifyContent='center'>
                                    <TextField
                                        size='small'
                                        value={packageDetails.Invoice_Number} // Controlled input value from state
                                        label='Invoice #'
                                        name='Invoice #'
                                        onChange={(e) => { setNewInvoiceNumber(e.target.value); setPackageDetails({ ...packageDetails, Invoice_Number: e.target.value }) }} // Update state on change and set invoice number
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                    <TextField
                                        size='small'
                                        label='Delivery Order Number'
                                        value={packageDetails.Delivery_Order_Number} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, Delivery_Order_Number: e.target.value }) }} // Update state on change
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                    <Stack sx={{ width: '15px' }}></Stack>
                                    <TextField
                                        size='small'
                                        label='Order Number'
                                        value={packageDetails.Order_Number} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, Order_Number: e.target.value }) }} // Update state on change
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                    <TextField
                                        size='small'
                                        label='Total Boxes'
                                        value={packageDetails.Total_Boxes} // Controlled input value from state
                                        onChange={(e) => { setPackageDetails({ ...packageDetails, Total_Boxes: e.target.value }) }} // Update state on change
                                        sx={{ width: "200px", fontSize: '13px' }}
                                    />
                                </Stack>
                            </Stack>

                            <Stack>
                                <div>
                                    <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
                                        <TableHead>
                                            <TableRow>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">CLIN</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Part Number</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Item Description</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">QTY</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Unit Price($)</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">Total Cost($)</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {row?.Purchase_Orders !== null && row.Purchase_Orders?.map((skurow, index) => (
                                                <TableRow key={index}>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.ID}</TableCell>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.SKU_ID}</TableCell>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.SKU_Description}</TableCell>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{skurow.QTY}</TableCell>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(skurow.Customer_Unit_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                    <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">{Number(skurow.Total_SKU_Cost).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</TableCell>
                                                </TableRow>
                                            ))}
                                            <TableRow>
                                                <TableCell colSpan={5} sx={{ border: 1, borderColor: 'text.primary', color: "black", backgroundColor: orange[200], fontFamily: 'Verdana (sans-serif)', fontSize: '11px', fontWeight: 'bold' }} align="center">SUB TOTAL</TableCell>
                                                <TableCell sx={{ border: 1, borderColor: 'text.primary', fontSize: '11px' }} align="center">
                                                    ${(row?.Purchase_Orders !== null) && row.Purchase_Orders?.reduce((total, current) => Number(total) + Number(current.Total_SKU_Cost), 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                                </TableCell>
                                            </TableRow>
                                        </TableBody>
                                    </Table>
                                </div>
                            </Stack>
                        </Stack>
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                {/* <Tooltip title="Generate PDF">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={(e) => { e.preventDefault(); HandlePDFClick(row) }}>
                <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <PictureAsPdfIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip> */}
                    <IconButton
                        name="Update"
                        variant='contained'
                        color='success'
                        onClick={(e) => {
                            setPackageDetails({ ...packageDetails, RFQRFQID: row?.RFQRFQID });
                            setPackageDetails({
                                ...packageDetails,
                                Sub_Total: (row?.Purchase_Orders !== null) && row.Purchase_Orders?.reduce((total, current) => Number(total) + Number(current.Total_SKU_Cost), 0).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
                            });
                            handlePackingSlipUpdate(e);
                        }}>
                        <Avatar variant="rounded" sx={{ backgroundColor: blueGrey[800], width: "80px", fontSize: "11px", height: "30px", ":hover": { boxShadow: "10px 10px 20px #ccc" } }}>
                            Generate
                            <Tooltip title="Generate PDF">
                             
                                    <Avatar sx={{ backgroundColor: red[800], width: "20px", height: "20px", transition: 'transform 0.2s ease-in-out' }} variant='rounded'>
                                        <PictureAsPdfIcon sx={{ color: "white", backgroundColor: red[800], width: "20px", height: "20px" }} />
                                    </Avatar>
                              
                            </Tooltip>
                            <br></br>
                        </Avatar>
                    </IconButton>
                    {packingSlipClicked === true && (
                        <PDFDownloadLink
                            component="button"
                            color="secondary"
                            document={<PackingSlipPdfDocument invoicedata={packingSlipData} />} // Pass the current packing slip data to the PDF document
                            fileName={File_Opportunity_Name + '_PackingSlip'}>
                            {({ blob, url, loading, error }) =>
                                loading ? "Loading..." : "Download"
                            }
                        </PDFDownloadLink>)}
                    <Button name="Cancel" variant='outlined' color='warning' size='small' onClick={closePackingSlipDialog}>
                     close
                    </Button>
                </DialogActions>
            </Dialog>
    
        </>
    );
};

export default PackingSlipComponent;
