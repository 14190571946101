import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  Typography,
  Button,
  IconButton,
  Tooltip,
  Stack,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Alert,
  TextField,
  TablePagination,TableSortLabel
} from '@mui/material';
import { blueGrey, green, orange, red } from '@mui/material/colors';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import AxiosFunction from '../../../axiosCustomInstance';
import EditSKUComponent from './EditSKUPricing';
import LoadingDialog from '../../Loading';
import UserAuth from '../../ProtectedRoute/userAuth';

/**
 * Helper function to generate random light colors for column backgrounds.
 */
const getRandomColor = () => {
  const letters = '89ABCDEF';
  let color = '#';
  for (let i = 0; i < 6; i++) {
    color += letters[Math.floor(Math.random() * letters.length)];
  }
  return color;
};

const SKUDisplayTable = ({ contractTypeID, contractTypeName,successMessage,setSuccessMessage }) => {
  const [skus, setSkus] = useState([]); // List of SKUs with pricing
  const [columnColors, setColumnColors] = useState({}); // Colors for date range columns
  const [editDialogOpen, setEditDialogOpen] = useState(false); // State for edit dialog
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false); // State for delete dialog
  const [selectedSku, setSelectedSku] = useState(null); // Currently selected SKU
  const [errorMessage, setErrorMessage] = useState(''); // Error message
  const [loading, setLoading] = useState(false); // Loading state
  const AxiosAPIInstance = AxiosFunction(); // Axios instance for API calls
  const { UserEmail } = UserAuth();
  const [searchTerm, setSearchTerm] = useState('');
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('');
  const [filteredSkus, setFilteredSkus] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  /**
   * Dynamically assigns a consistent random color to each column.
   */
  const assignColorToColumn = (key) => {
    if (!columnColors[key]) {
      setColumnColors((prev) => ({ ...prev, [key]: getRandomColor() }));
    }
    return columnColors[key];
  };

  /**
   * Fetch SKUs for the given contract type ID and associated pricing.
   */
  React.useEffect(() => {
    if (!contractTypeID) {
      setErrorMessage('Contract Type ID is required to load SKUs.');
      setSkus([]); // Clear existing SKUs
      return;
    }

    setLoading(true);
    setErrorMessage('');

    AxiosAPIInstance.get('/ContractTypes/SKUs/getSkus', {
      params: { contractTypeID },
    })
      .then((res) => {
        if (res?.data?.length) {
          const formattedSkus = res.data.map((sku) => ({
            ...sku,
            pricing: sku.pricing || [],
          }));
          setSkus(formattedSkus || []);
        } else {
          setSkus([]);
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data || 'Error fetching SKUs.');
        setSkus([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [contractTypeID, deleteDialogOpen, editDialogOpen,successMessage,setSuccessMessage]);


  React.useEffect(() => {
    const filtered = skus.filter(
      (sku) =>
        sku.partNo.toLowerCase().includes(searchTerm.toLowerCase()) ||
        sku.itemDescription.toLowerCase().includes(searchTerm.toLowerCase())
    );
    setFilteredSkus(filtered);
  }, [searchTerm, skus]);

  const handleSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
    const sorted = [...filteredSkus].sort((a, b) => {
      if (a[property] < b[property]) return order === 'asc' ? -1 : 1;
      if (a[property] > b[property]) return order === 'asc' ? 1 : -1;
      return 0;
    });
    setFilteredSkus(sorted);
  };
  /**
   * Handles opening the edit dialog with the selected SKU's data.
   */
  const handleEditDialogOpen = (sku) => {
    setSelectedSku(sku);
    setEditDialogOpen(true);
  };

  /**
   * Handles closing the edit dialog.
   */
  const handleEditDialogClose = () => {
    setEditDialogOpen(false);
    setSelectedSku(null);
    setErrorMessage('');
  };

  /**
   * Handles opening the delete dialog with the selected SKU's data.
   */
  const handleDeleteDialogOpen = (sku) => {
    setSelectedSku(sku);
    setDeleteDialogOpen(true);
  };

  /**
   * Handles closing the delete dialog.
   */
  const handleDeleteDialogClose = () => {
    setDeleteDialogOpen(false);
  };

  /**
   * Handles saving the updated SKU and pricing.
   */
  const handleSaveClick = async (updatedSku) => {
    setLoading(true);
    try {
      const payload = {
        partNo: selectedSku.partNo,
        pricing: updatedSku.pricing,
        contractTypeContractTypeID: contractTypeID,
        itemDescription:updatedSku.itemDescription,
        unitOfMeasure:updatedSku.unitOfMeasure,
        updatedBy: UserEmail,
        contractTypeName,
      };

      const response = await AxiosAPIInstance.patch('/ContractTypes/SKUs/update', payload);

      if (response.status === 200) {
        setSkus((prevSkus) =>
          prevSkus.map((sku) =>
            sku.partNo === selectedSku.partNo ? { ...sku, pricing: updatedSku.pricing } : sku
          )
        );
        setLoading(false);
        handleEditDialogClose();
      } else {
        throw new Error('Failed to update the SKU.');
      }
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response?.data || 'An error occurred while updating the SKU.');
    }
  };

  /**
   * Handles deleting the selected SKU and its associated pricing.
   */
  const handleDeleteSku = async () => {
    setLoading(true);
    setDeleteDialogOpen(false);

    try {
      await AxiosAPIInstance.delete(`/ContractTypes/SKUs/delete/${selectedSku.skuID}`, {

      });
      setSkus((prevSkus) => prevSkus.filter((sku) => sku.partNo !== selectedSku.partNo));
      setSelectedSku(null);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setErrorMessage(error.response?.data || 'Error occurred while deleting the SKU.');
    }
  };

  /**
   * Extracts all unique date ranges from SKUPricing for dynamic columns.
   */
  const allDateRanges = Array.from(
    skus.reduce((acc, sku) => {
      sku.pricing.forEach((price) => {
        acc.add(`${price.fromDate} to ${price.toDate}`);
      });
      return acc;
    }, new Set())
  ).sort();

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    const value = event.target.value === 'All' ? filteredSkus.length : parseInt(event.target.value, 10);
    setRowsPerPage(value);
    setPage(0);
  };
  return (
    <Stack spacing={2} sx={{ marginTop: '20px',width:'100%'}} >
      <LoadingDialog open={loading} />
      {errorMessage && (
        <Typography variant="subtitle2" sx={{ color: red[900], marginBottom: 2 }}>
          {errorMessage}
        </Typography>
      )}
      {/* Edit Dialog */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TextField
          label="Search"
          helperText='Search by Part No OR Description'
          variant="outlined"
          size="small"
          sx={{ width: 300 }}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <Typography variant="subtitle2" sx={{ color: red[900] }}>
          Total Existing SKUs: {filteredSkus.length}
        </Typography>
      </Stack>
      <Dialog open={editDialogOpen} onClose={handleEditDialogClose} maxWidth="lg" fullWidth>
        <DialogTitle>
          <Alert variant="filled" sx={{ bgcolor: orange[300], color: blueGrey[900] }}>
            Edit SKU: {selectedSku?.partNo}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <EditSKUComponent
            initialData={selectedSku}
            loading={loading}
            setLoading={setLoading}
            handleSave={handleSaveClick}
            errorMessage={errorMessage}
            setErrorMessage={setErrorMessage}
          />
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="small" onClick={handleEditDialogClose}>
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Dialog */}
      <Dialog open={deleteDialogOpen} onClose={handleDeleteDialogClose}>
        <DialogTitle>
          <Alert variant="filled" sx={{ bgcolor: orange[300], color: blueGrey[900] }}>
            Delete SKU: {selectedSku?.partNo}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <Typography>
            Are you sure you want to delete SKU <strong>{selectedSku?.partNo}</strong> and all its
            associated pricing?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" size="small" onClick={handleDeleteSku}>
            Yes
          </Button>
          <Button variant="contained" color="primary" size="small" onClick={handleDeleteDialogClose}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      {/* Table */}
      <TableContainer
  component={Paper}
  sx={{
    boxShadow: 3,
    borderRadius: '8px',
    maxHeight: '500px', // Set the maximum height for scrolling
    overflowY: 'auto',  // Enable vertical scrolling
  }}
>
  <Table stickyHeader>
    <TableHead>
      <TableRow>
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '11.5px', padding: '6px', backgroundColor: blueGrey[900],color:'white',"&:hover": {
                    backgroundColor: blueGrey[100],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  },}}>
          <TableSortLabel
            active={orderBy === 'partNo'}
            direction={orderBy === 'partNo' ? order : 'asc'}
            onClick={() => handleSort('partNo')}
            sx={{
              '& .MuiTableSortLabel-icon': {
                color: orange[900], // Set arrow color to orange
              }
            }}
          >
            Part No
          </TableSortLabel>
        </TableCell>
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '11.5px', padding: '6px', backgroundColor: blueGrey[900] ,color:'white',"&:hover": {
                    backgroundColor: blueGrey[100],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  },}}>
          <TableSortLabel
            active={orderBy === 'itemDescription'}
            direction={orderBy === 'itemDescription' ? order : 'asc'}
            onClick={() => handleSort('itemDescription')}
          >
            Description
          </TableSortLabel>
        </TableCell>
        {allDateRanges.map((dateRange) => (
          <TableCell
            key={dateRange}
            colSpan={2}
            sx={{
              fontWeight: 'bold',
              textAlign: 'center',
              backgroundColor: assignColorToColumn(dateRange),
              fontSize: '11.5px',
              padding: '6px',
              whiteSpace: 'nowrap'
            }}
          >
            {dateRange}
          </TableCell>
        ))}
        <TableCell sx={{ fontWeight: 'bold', textAlign: 'center', fontSize: '11.5px', padding: '6px', backgroundColor: blueGrey[900],color:'white',"&:hover": {
                    backgroundColor: blueGrey[100],
                    transform: 'scale(1.1)', // Zoom in effect on hover
                  }, }}>
          Actions
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell sx={{ padding: '6px' }}></TableCell>
        <TableCell sx={{ padding: '6px' }}></TableCell>
        {allDateRanges.map((dateRange) => (
          <React.Fragment key={dateRange}>
            <TableCell
              sx={{
                textAlign: 'center',
                backgroundColor: assignColorToColumn(dateRange),
                fontSize: '11.5px',
                padding: '6px',
              }}
            >
              Customer Cost
            </TableCell>
            <TableCell
              sx={{
                textAlign: 'center',
                backgroundColor: assignColorToColumn(dateRange),
                fontSize: '11.5px',
                padding: '6px',
              }}
            >
              Company Cost
            </TableCell>
          </React.Fragment>
        ))}
        <TableCell sx={{ padding: '6px' }}></TableCell>
      </TableRow>
    </TableHead>
    <TableBody>
      {filteredSkus.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((sku) => (
        <TableRow key={sku.partNo}>
          <TableCell sx={{ fontSize: '11.5px', padding: '6px' }}>{sku.partNo}</TableCell>
          <TableCell sx={{ fontSize: '11.5px', padding: '6px' }}>{sku.itemDescription}</TableCell>
          {allDateRanges.map((dateRange) => {
            const pricing = sku.pricing.find(
              (p) => `${p.fromDate} to ${p.toDate}` === dateRange
            ) || { customerCost: '', companyCost: '' };
            return (
              <React.Fragment key={`${sku.partNo}-${dateRange}`}>
                <TableCell
                  sx={{
                    backgroundColor: assignColorToColumn(dateRange),
                    fontSize: '11.5px',
                    padding: '6px',
                  }}
                >
                  <div
                    style={{
                      maxHeight: '50px', // Adjust height as needed
                      overflowY: 'auto',
                      padding: '4px',
                    }}
                  >
                    ${pricing.customerCost}
                  </div>
                </TableCell>
                <TableCell
                  sx={{
                    backgroundColor: assignColorToColumn(dateRange),
                    fontSize: '11.5px',
                    padding: '6px',
                  }}
                >
                  <div
                    style={{
                      maxHeight: '50px', // Adjust height as needed
                      overflowY: 'auto',
                      padding: '4px',
                    }}
                  >
                    ${pricing.companyCost}
                  </div>
                </TableCell>
              </React.Fragment>
            );
          })}
          <TableCell>
            <Stack direction="row" spacing={1}>
              <Tooltip title="Edit">
                <IconButton onClick={() => handleEditDialogOpen(sku)} sx={{ color: green[500] }}>
                  <EditIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Delete">
                <IconButton onClick={() => handleDeleteDialogOpen(sku)} sx={{ color: red[500] }}>
                  <DeleteIcon />
                </IconButton>
              </Tooltip>
            </Stack>
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  </Table>
  <TablePagination
    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: filteredSkus.length }]}
    component="div"
    count={filteredSkus.length}
    rowsPerPage={rowsPerPage}
    page={page}
    onPageChange={handleChangePage}
    onRowsPerPageChange={handleChangeRowsPerPage}
  />
</TableContainer>
    </Stack>
  );
};

export default SKUDisplayTable;
