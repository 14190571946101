import React, { useState } from "react";
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import { Button, Stack, TextField, MenuItem, Divider } from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { blueGrey, orange } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useNavigate } from 'react-router-dom';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { Alert } from '@mui/material';
import ApplicationBar from "../AppBar/ApplicationBar";
import TableFooter from '@mui/material/TableFooter';
import TablePagination from '@mui/material/TablePagination';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import LastPageIcon from '@mui/icons-material/LastPage';
import SalesRow from "./RMATableRowHelper";
import AxiosFunction from "../../axiosCustomInstance";
import LoadingDialog from "../Loading";
import TableSortLabel from '@mui/material/TableSortLabel';
import { useTheme } from '@mui/material/styles';
import UserAuth from "../ProtectedRoute/userAuth";
// Creating a custom theme for Material-UI components
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});

// Component for handling pagination actions in the table
function TablePaginationActions(props) {
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  // Handle click on "First Page" button
  const handleFirstPageButtonClick = (event) => {
    onPageChange(event, 0);
  };

  // Handle click on "Previous Page" button
  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  // Handle click on "Next Page" button
  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  // Handle click on "Last Page" button
  const handleLastPageButtonClick = (event) => {
    onPageChange(event, Math.max(0, Math.ceil(count / rowsPerPage) - 1));
  };

  return (
    <Box sx={{ flexShrink: 0, ml: 2.5 }}>
      <IconButton
        onClick={handleFirstPageButtonClick}
        disabled={page === 0}
        aria-label="first page"
      >
        {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
      </IconButton>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
      </IconButton>
      <IconButton
        onClick={handleLastPageButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="last page"
      >
        {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
      </IconButton>
    </Box>
  );
}

// Main RMAs Search component
function RMAsSearch() {
  const [totalData, setTotalData] = useState([{}]); // State to hold the data fetched from the server
  const [getRMANum, setgetRMANum] = useState(""); // State to hold the input value for RMA number
  const [getRMAManufacturerNum, setgetRMAManufacturerNum] = useState(""); // State to hold the input value for RMA number
  const [statusError, setStatusError] = useState(false); // State to handle status error dialog visibility
  const [statusErrorMsge, setStatusErrorMsge] = useState(""); // State to hold the status error message
  const [stus, setStus] = useState("All"); // State to hold the selected status value
  const [rMANumError, setRMANumError] = useState(false); // State to handle RMA number error dialog visibility
  const [searchFilterError, setSearchFilterError] = useState(false); // State to handle search filter error dialog visibility
  const [rMANumErrorMsge, setRMANumErrorMsge] = useState(""); // State to hold the RMA number error message
  const [searchFilterErrorMsge, setSearchFilterErrorMsge] = useState(""); // State to hold the search filter error message
  const navigate = useNavigate(); // React Router hook to navigate programmatically
  const currentYear = new Date().getFullYear();
  const [page, setPage] = useState(0); // State to handle the current page in the pagination
  const [rowsPerPage, setRowsPerPage] = useState(5); // State to handle the number of rows per page in the table
  const AxiosAPIInstance = AxiosFunction(); // Custom Axios instance for API calls
  const [loading, setLoading] = useState(false); // State to handle loading dialog visibility
  const [orderBy, setOrderBy] = useState(''); // State to handle the sorting order by column
  const [order, setOrder] = useState('asc'); // State to handle the sorting order direction (ascending/descending)
  const [customerNameRMANoMfgRMANo, setCustomerNameRMANoMfgRMANo] = useState(""); // State to hold the input value for customer name
  const [fromDate, setFromDate] = useState(new Date(currentYear, 0, 1)); // State to hold the selected "From" date
  const [toDate, setToDate] = useState(new Date(currentYear, 11, 31)); // State to hold the selected "To" date
  const [salesHeadName, setSalesHeadName] = useState(""); // State to store the Sales Head's name
  let [usersData, setUsersData] = useState([{ User_email: "" }]); // State to store user data
  const { UserName, UserEmail } = UserAuth(); // Extracting UserName and UserEmail from the UserAuth hook
  // Handle page change in table pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };
// useEffect hook to fetch users' data when the component is mounted
React.useEffect(() => {
  setLoading(true); // Start loading spinner
  const fetchUsersData = async () => {
    try {
      const result = await AxiosAPIInstance.get("/UserAuth/users"); // Fetch users data from API
      setLoading(false); // Stop loading spinner
      const users = [...result?.data]; // Store the fetched data
      setUsersData(users); // Update state with user data
      setRMANumError(false);
      setSalesHeadName(UserEmail)
    } catch (error) {
      setLoading(false); // Stop loading spinner on error
      setRMANumError(true);
      setRMANumErrorMsge("No Records Found");
      setTotalData([]);
    }}
    const fetchAllRMAs=async()=>{
      try {
        // Fetch data based on the provided filters
        const response = await AxiosAPIInstance.get("/RMAs/GetAllRMAs", {
          params: {
            // RMA_ID: getRMANum,
            // Manufacturer_RMA_ID: getRMAManufacturerNum,
            Status: stus,
            CustomerNameRmaNoMfgRmaNo: customerNameRMANoMfgRMANo,
            FromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
            ToDate: toDate ? toDate.toISOString().split('T')[0] : null,
            salesHeadName:salesHeadName
          },
        });
  
        if (response?.data && response?.data.length > 0) {
          setTotalData(response?.data); // Update totalData state with the fetched data
        } else {
          setRMANumError(true);
          setRMANumErrorMsge("No Records Found");
          setTotalData([]);
        }
      } catch (err) {
        setRMANumError(true);
        setRMANumErrorMsge(err.response?.data || "An error occurred");
        setTotalData([]);
      } finally {
        setLoading(false);
      }
    }
    fetchAllRMAs()
    fetchUsersData()
  },[])
  // Handle rows per page change in table pagination
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Sort comparator function for stable sorting
  function stableSort(array, comparator) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
  }

  // Comparator function for sorting by order
  function getComparator(order, orderBy) {
    return order === 'desc'
      ? (a, b) => descendingComparator(a, b, orderBy)
      : (a, b) => -descendingComparator(a, b, orderBy);
  }

  // Descending comparator for sorting data
  function descendingComparator(a, b, orderBy) {
    if (orderBy === 'Actual_Total_Cost_After_Margin') {
      const decimalA = new Number(a[orderBy]);
      const decimalB = new Number(b[orderBy]);

      if (decimalB < decimalA) {
        return -1;
      }
      if (decimalB > decimalA) {
        return 1;
      }
      return 0;
    } else {
      if (b[orderBy] < a[orderBy]) {
        return -1;
      }
      if (b[orderBy] > a[orderBy]) {
        return 1;
      }
      return 0;
    }
  }

  // Handle sorting request in table headers
  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handle dialog close
  const handleClose = (e) => {
    setRMANumError(false);
    setRMANumErrorMsge("");
    setStatusError(false);
    setStatusErrorMsge("");
    setSearchFilterError(false);
    setSearchFilterErrorMsge("");

  };
  const refreshData = async () => {
    setLoading(true);

    try {
      // Fetch data based on the provided filters
      const response = await AxiosAPIInstance.get("/RMAs/GetAllRMAs", {
        params: {
          // RMA_ID: getRMANum,
          // Manufacturer_RMA_ID: getRMAManufacturerNum,
          Status: stus,
          CustomerNameRmaNoMfgRmaNo: customerNameRMANoMfgRMANo,
          FromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
          ToDate: toDate ? toDate.toISOString().split('T')[0] : null,
          salesHeadName:salesHeadName
        },
      });

      if (response?.data && response?.data.length > 0) {
        setTotalData(response?.data); // Update totalData state with the fetched data
      } else {
        setRMANumError(true);
        setRMANumErrorMsge("No Records Found");
        setTotalData([]);
      }
    } catch (err) {
      setRMANumError(true);
      setRMANumErrorMsge(err.response?.data || "An error occurred");
      setTotalData([]);
    } finally {
      setLoading(false);
    }
  };
  
  // Fetch data based on filters
  const Get_Data_Click = async (e) => {
    setPage(0);
    e.preventDefault();
    setLoading(true);

    try {
      // Fetch data based on the provided filters
      const response = await AxiosAPIInstance.get("/RMAs/GetAllRMAs", {
        params: {
          // RMA_ID: getRMANum,
          // Manufacturer_RMA_ID: getRMAManufacturerNum,
          Status: stus,
          CustomerNameRmaNoMfgRmaNo: customerNameRMANoMfgRMANo,
          FromDate: fromDate ? fromDate.toISOString().split('T')[0] : null,
          ToDate: toDate ? toDate.toISOString().split('T')[0] : null,
          salesHeadName:salesHeadName
        },
      });

      if (response?.data && response?.data.length > 0) {
        setTotalData(response?.data); // Update totalData state with the fetched data
      } else {
        setRMANumError(true);
        setRMANumErrorMsge("No Records Found");
        setTotalData([]);
      }
    } catch (err) {
      setRMANumError(true);
      setRMANumErrorMsge(err.response?.data || "An error occurred");
      setTotalData([]);
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
  
      {/* Error Dialog */}
      {rMANumError && (
        <Dialog
          open={rMANumError}
          onClose={handleClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert
              variant="filled"
              severity={rMANumErrorMsge === 'No Records Found' ? 'warning' : 'error'}
            >
              {rMANumErrorMsge}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
              {rMANumErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
  
      <Stack sx={{ marginTop: '60px', padding: '20px' }} direction='column' spacing={3} justifyContent='center'>
        {/* Header Section */}
        <Stack direction="row" justifyContent="center" >

          <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: theme.palette.primary.main,
          }}
        >
               Return Merchandise Authorization (RMAs)
        </Typography>
          {/* <Button variant="contained" color="primary" size="medium" onClick={handleAddNewRMA}>
            + New RMA
          </Button> */}
        </Stack>
  
        {/* Filters Section */}
        <Paper elevation={3} sx={{ padding: '20px', borderRadius: '10px' }}>
          <Stack direction="column" justifyContent='center'>
          <Stack direction="row" spacing={3}  justifyContent='center' >
          <Stack direction="column" justifyContent='center' spacing={2}  >
            <TextField
              fullWidth
              label="Search"
              size="small"
              helperText="Search by RMA ID, Manufacturer RMA Number, Customer, or Agency Name"
              value={customerNameRMANoMfgRMANo}
              onChange={(e) => {
                setCustomerNameRMANoMfgRMANo(e.target.value);
                setTotalData([]);
              }}
            />
            <FormControl sx={{ minWidth: '350px' }}>
              <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
              <Select
                label="Sales Head Email ID"
                labelId="Sales-Head-id"
                value={String(salesHeadName).toLowerCase()}
                size="small"
                onChange={(e) => {
                  setSalesHeadName(e.target.value);
                  setTotalData([]);
                }}
              >
                <MenuItem value="all">All</MenuItem>
                {usersData?.map((row, index) => (
                  <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>
                    {String(row?.User_email).toLowerCase()}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            </Stack>
            <Stack direction="column" spacing={2}>
              <DatePicker
                label="Created Date (From)"
                maxDate={new Date()}
                value={fromDate}
                onChange={(date) => {
                  setFromDate(date);
                  setTotalData([]);
                }}
                renderInput={(params) => <TextField size="small" sx={{ minWidth: '200px' }}{...params} />}
              />
              <DatePicker
               
                label="Created Date (To)"
                minDate={fromDate}
                value={toDate}
                onChange={(date) => {
                  setToDate(date);
                  setTotalData([]);
                }}
                renderInput={(params) => <TextField size="small" sx={{ minWidth: '200px' }}{...params} />}
              />
            </Stack>
            <FormControl sx={{ minWidth: '200px' }}>
              <InputLabel id="RMA_Status">Status</InputLabel>
              <Select
                labelId="RMA_Status"
                id="RMA_Status-id"
                value={stus}
                size="small"
                label="RMA Status"
                onChange={(e) => {
                  setStus(e.target.value);
                  setTotalData([]);
                }}
              >
                <MenuItem value="All">All</MenuItem>
                <MenuItem value="RMA Created">RMA Created</MenuItem>
                <MenuItem value="Investigation">Investigation</MenuItem>
                <MenuItem value="Resolution">Resolution</MenuItem>
                <MenuItem value="Follow UP">Follow Up</MenuItem>
                <MenuItem value="Closed">Closed</MenuItem>
                <MenuItem value="RMA Reopened">RMA Re-Opened</MenuItem>
                <MenuItem value="Cancelled">Cancelled</MenuItem>
              </Select>
            </FormControl>
            <Button variant="contained" color="warning" size="small" sx={{ height:'30px',width:'100px' }} onClick={Get_Data_Click}>
              Search
            </Button>
          </Stack>
          <Stack justifyContent='center'>

          </Stack>
        
          </Stack>
        </Paper>
  
        {/* Results Section */}
        {totalData.length > 0 && (
          <Paper elevation={3} sx={{ borderRadius: '10px', overflow: 'hidden' }}>
            <TableContainer component={Paper}>
              <Table>
                <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableRow>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">
                      RMA ID
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">
                      Customer's Info & Problem Description
                    </TableCell>
                    <TableCell
                      sx={{ fontWeight: 'bold', color: 'white', cursor: 'pointer' }}
                      align="center"
                    >
                      <TableSortLabel
                        active
                        direction={orderBy === 'Created_Date' ? order : 'asc'}
                        onClick={() => handleRequestSort('Created_Date')}
                      >
                        Sort by Created Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ fontWeight: 'bold', color: 'white' }} align="center">
                      Status & Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {(rowsPerPage > 0
                    ? stableSort(totalData, getComparator(order, orderBy)).slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                    : totalData
                  )?.map((row, index) => (
                    <SalesRow key={index} row={row} usersData={usersData} refreshData={refreshData} />
                  ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                      count={totalData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Stack>
    </>
  );
  
}

export default RMAsSearch;
