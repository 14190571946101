import * as XLSX from 'xlsx';
import validateExcelFile from './ValidateExcelFile';

/**
 * Extracts SKU data from an Excel file and validates it.
 * Handles the mapping of unit pricing for multiple years and uses provided date ranges for each year.
 *
 * @param {File} file - The uploaded Excel file containing SKU data.
 * @param {number} numYears - The number of years for which pricing data needs to be extracted.
 * @param {Array} dateRanges - Array of date range objects, each containing `fromDate` and `toDate`.
 * @returns {Promise<Array>} - Resolves with the extracted data in JSON format or rejects with an error message.
 */
const extractSKUData = (file, numYears, dateRanges) => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();

    fileReader.onload = (event) => {
      try {
        const data = new Uint8Array(event.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const worksheet = workbook.Sheets['SKUs'];
        if (!worksheet) throw new Error("Sheet 'SKUs' not found in the Excel file.");

        const jsonData = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
        const headers = jsonData[0];
        if (!headers) throw new Error('The file appears to be empty or does not contain valid headers.');

        const missingFields = validateExcelFile(headers, numYears);
        if (missingFields) {
          throw new Error(`Missing required fields: ${missingFields.join(', ')}`);
        }

        const rows = jsonData.slice(1).map((row) => {
          const mappedRow = {};
          headers.forEach((header, index) => {
            mappedRow[header] = row[index];
          });

          const unitPricing = [];
          for (let i = 0; i < numYears; i++) {
            const yearKey = `Year ${i + 1}`;
            if (mappedRow[`${yearKey} Customer Cost`] && mappedRow[`${yearKey} Company Cost`]) {
              const { fromDate, toDate } = dateRanges[i] || {};
              unitPricing.push({
                fromDate: fromDate || `${i + 2023}-01-01`,
                toDate: toDate || `${i + 2023}-12-31`,
                unitOfMeasure:mappedRow['Unit Of Measure']||'ea',
                vendorPartnerName:mappedRow['Vendor Or Partner Name']||'NA',
                customerCost: parseFloat(mappedRow[`${yearKey} Customer Cost`] || 0),
                companyCost: parseFloat(mappedRow[`${yearKey} Company Cost`] || 0),
                marginPercent: parseFloat(mappedRow[`${yearKey} Margin Percent`] || 0), // Include marginPercent if applicable
              });
            }
          }

          return {
            partNo: mappedRow['Part No'],
            itemDescription: mappedRow['Description'],
            unitOfMeasure:mappedRow['Unit Of Measure'],
            vendorPartnerName:mappedRow['Vendor Or Partner Name'],
            contractTypeContractTypeID: mappedRow['Contract Type ID'], // Map Contract Type ID
            contractTypeName: mappedRow['Contract Type Name'], // Map Contract Type Name
            pricing: unitPricing, // Organized pricing data
          };
        });

        resolve(rows);
      } catch (error) {
        console.error('Error extracting SKU data:', error.message);
        reject(error.message);
      }
    };

    fileReader.onerror = () => reject('Failed to read the file. Please try again.');
    fileReader.readAsArrayBuffer(file);
  });
};

export default extractSKUData;
