import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Box,
  Typography,
  Alert,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  CircularProgress,
} from '@mui/material';
import { orange, blueGrey, blue, green, purple, red } from '@mui/material/colors';
import AxiosFunction from '../../../axiosCustomInstance';
import UserAuth from '../../ProtectedRoute/userAuth';
import LoadingDialog from '../../Loading';
const EditContractTypeDialog = ({ open, onClose, contractTypeID }) => {
  const [newContractType, setNewContractType] = useState('');
  const [contractTypeDescription, setContractTypeDescription] = useState('');
  const [quoteNoLetterCode, setQuoteNoLetterCode] = useState('');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const {UserName,UserEmail}=UserAuth()
  const AxiosAPIInstance=AxiosFunction()
  useEffect(() => {
    if (open && contractTypeID) {
      loadContractTypeData();
    }
  }, [open, contractTypeID]);

  const loadContractTypeData = async () => {
    setLoading(true);
    setError('');
    try {
      const response = await AxiosAPIInstance.get(`/ContractTypes/getContractTypeByID/${contractTypeID}`);
  
      // Adjust this based on the response structure
      const contractTypeData = response?.data;
      if (!contractTypeData) {
        throw new Error('No data received from API');
      }
  
      const {
        contractTypeName = '',
        contractTypeDescription = '',
        QuoteNoLetterCode = '',
      } = Array.isArray(contractTypeData) ? contractTypeData[0] : contractTypeData;
  
      setNewContractType(contractTypeName);
      setContractTypeDescription(contractTypeDescription);
      setQuoteNoLetterCode(QuoteNoLetterCode);
    } catch (err) {
      setError(`Failed to load contract type data. Please try again.${err}`);
      console.error('Error loading data:', err);
    } finally {
      setLoading(false);
    }
  };
  
  const handleSave = async () => {
    setLoading(true);
    setError('');
    setSuccessMessage('');
    try {
      const updatedData = {
        contractTypeName: newContractType,
        contractTypeDescription,
        QuoteNoLetterCode: quoteNoLetterCode,
      };
      await AxiosAPIInstance.patch(`/ContractTypes/Update/${contractTypeID}`, updatedData);
      setSuccessMessage('Contract type updated successfully.');
      loadContractTypeData(); // Reload data after saving
    } catch (err) {
      setError('Failed to update contract type. Please try again.');
      console.error('Error updating data:', err);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <LoadingDialog open={loading}/>
      <DialogTitle>
        <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900] }}>
          Edit Contract Type
        </Alert>
      </DialogTitle>
      <DialogContent>
        {loading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', my: 4 }}>
            <CircularProgress />
          </Box>
        ) : (
          <Stack direction="column" spacing={4} sx={{ marginTop: '20px' }} justifyContent="center">
            {error && (
              <Alert severity="error" sx={{ marginBottom: 2 }}>
                {error}
              </Alert>
            )}
            {successMessage && (
              <Alert severity="success" sx={{ marginBottom: 2 }}>
                {successMessage}
              </Alert>
            )}
            <TextField
              sx={{ marginTop: '20px' }}
              size="small"
              label="New Contract Type"
              required
              value={newContractType}
              onChange={(e) => setNewContractType(e.target.value)}
            />
            <TextField
              size="small"
              label="Description"
              multiline
              required
              minRows={3}
              value={contractTypeDescription}
              onChange={(e) => setContractTypeDescription(e.target.value)}
            />
            <FormControl fullWidth>
              <InputLabel id="Letter_Code">Letter Code For Quote No</InputLabel>
              <Select
                labelId="Letter_Code"
                id="Letter-Code-id"
                value={quoteNoLetterCode}
                size="small"
                label="Letter Code For Quote No"
                onChange={(e) => setQuoteNoLetterCode(e.target.value)}
                required
              >
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="T">T</MenuItem>
              </Select>
            </FormControl>
            <Box sx={{ mt: 2, p: 2, border: '1px dashed grey', borderRadius: '8px' }}>
              <Typography variant="subtitle1" gutterBottom>
                Example Quote No: <strong>F240911JB.1R1</strong>
              </Typography>
              <Stack direction="column" spacing={4}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <Box sx={{ bgcolor: orange[200], p: 1, borderRadius: '4px' }}>
                    <Typography variant="body2">
                      <strong>F</strong>: Contract Type (e.g., Federal)
                    </Typography>
                  </Box>
                  <Box sx={{ bgcolor: blue[200], p: 1, borderRadius: '4px' }}>
                    <Typography variant="body2">
                      <strong>240911</strong>: Date (YYMMDD)
                    </Typography>
                  </Box>
                  <Box sx={{ bgcolor: green[200], p: 1, borderRadius: '4px' }}>
                    <Typography variant="body2">
                      <strong>JB</strong>: User Initials
                    </Typography>
                  </Box>
                  <Box sx={{ bgcolor: purple[200], p: 1, borderRadius: '4px' }}>
                    <Typography variant="body2">
                      <strong>.1</strong>: Quote number of that user on any given day
                    </Typography>
                  </Box>
                  <Box sx={{ bgcolor: red[200], p: 1, borderRadius: '4px' }}>
                    <Typography variant="body2">
                      <strong>R1</strong>: Revision number
                    </Typography>
                  </Box>
                </Stack>
              </Stack>
            </Box>
          </Stack>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary" disabled={loading}>
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={loading}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EditContractTypeDialog;
