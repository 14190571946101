import React from "react";
import {
  Box,
  Stack,
  TextField,
  FormControl,
  Select,
  MenuItem,
  InputLabel,
  Button,
  Checkbox,
  FormControlLabel,
  Card,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

function FilterSection({
  getDHSNum,
  setDHSNum,
  orderType,
  setOrderType,
  startDate,
  setStartDate,
  endDate,
  setEndDate,
  includeFiles,
  setIncludeFiles,
  searchText,
  setSearchText,
  onSearch,
  onClear,
}) {
  return (
    <Card
      sx={{
        p: 3,
        mb: 3,
        borderRadius: 2,
        boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.1)",
        backgroundColor: "#f9f9f9",
      }}
    >

      <Stack direction="row" spacing={2}  justifyContent="center">
        {/* DHS ID */}
        
      <Stack direction="column" spacing={1} justifyContent="center">
        {/* Search Text */}
          <TextField
          label="Search"
          helperText='DHS REQ ID/ Search Customer (Name/Email)'
          size="small"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          sx={{ flex: 1.5 }}
        />

        <TextField
          label="DHS ID"
          size="small"
          value={getDHSNum}
          onChange={(e) => setDHSNum(e.target.value)}
          sx={{ minWidth: 150 }}
        />
</Stack>
        {/* Order Type */}
        <FormControl size="small" sx={{ minWidth: 200 }} variant="standard">
          <InputLabel>Order Type</InputLabel>
          <Select value={orderType} onChange={(e) => setOrderType(e.target.value)}>
            <MenuItem value="All">All</MenuItem>
            <MenuItem value="Traditional System Order">Traditional System Order</MenuItem>
            <MenuItem value="Scalable System Order">Scalable System Order</MenuItem>
          </Select>
        </FormControl>
        <Stack direction='column' spacing={2}>
    {/* Date Range */}
         <TextField
          type="date"
          label="Created Date(From)"
          InputLabelProps={{ shrink: true }}
          size="small"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
        />
        <TextField
          type="date"
          label="Created Date(To)"
          InputLabelProps={{ shrink: true }}
          size="small"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
        />
        </Stack>
        <Stack direction="column" spacing={1}>
        {/* Include Files */}
        <FormControlLabel
          control={
            <Checkbox
              checked={includeFiles}
              onChange={(e) => setIncludeFiles(e.target.checked)}
            />
          }
          label="Include Files"
        />
          <Button
            variant="contained"
            color="primary"
            size='small'
            startIcon={<SearchIcon />}
            onClick={onSearch}
          >
            Search
          </Button>
          <Button
            variant="outlined"
            color="secondary"
            size='small'
            startIcon={<ClearIcon />}
            onClick={onClear}
          >
            Clear
          </Button>
        </Stack>
      </Stack>
    </Card>
  );
}

export default FilterSection;
