import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider} from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DatePicker } from "@mui/x-date-pickers";
import RemoveRoadIcon from '@mui/icons-material/RemoveRoad';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import DescriptionIcon from '@mui/icons-material/Description';
import AddLinkIcon from '@mui/icons-material/AddLink';
import { green, blueGrey,red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import Select from '@mui/material/Select';
import axios from "../../axiosCustomInstance";
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import { orange,indigo } from '@mui/material/colors';
import userAuth from "../ProtectedRoute/userAuth"
import LoadingDialog from '../Loading'

const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});
function  RFI_CLIENT_INPUT()
{
  const PD = new Date(); // Creating a new Date object to get current date and time
  const AxiosAPIInstance = AxiosFunction(); // Creating an Axios instance for API calls
  const { UserName, UserEmail } = userAuth(); // Destructuring user authentication data
  
  // Initializing state for form values with default values and user authentication data
  const [Form_Value, Set_Form_Value] = useState({
      Opportunity_Name: "",
      Solicitation_Number: "",
      Brief: "",
      Contract_Vehicle: "GSA",
      Organization_Name: "",
      KO_Name: "",
      Contracting_Specialist: "",
      KO_email: "",
      KO_phone: "",
      KO_address: "",
      Published_date: null,
      Due_date: null,
      Estimated_$_Amount: "",
      Expected_RFP: 0,
      Incumbent_Info: "",
      Proposal_Stage: "",
      Team_Support: "",
      Teaming_partners_Vendors: "",
      Potential_Roadblocks: "",
      Govwin_Link: "",
      Sam_gov_link: "",
      Sales_Head_Email: UserEmail,
      Sales_Head_Name: UserName,
      Sales_Head_Phone: "540-547-8487",
      Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())), // Setting created date
      Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())), // Setting last updated date
      Updated_By: "TBD", // Initial value for the user who updated the form
      Version_No: parseInt(0), // Initial version number
      Updating_Comments: "RFI Created" // Initial comment for form update
  });
  
  // State for controlling form disable state
  const [dis, setDis] = useState(false);
  
  // Navigation hook for redirection
  const navigate = useNavigate();
  
  // State for RFI message
  const [rfiMssge, setRFIMssge] = useState('');
  
  // State for success dialog visibility
  const [successDialog, setSuccessDialog] = useState(false);
  
  // State for error dialog visibility
  const [errorDialog, setErrorDialog] = useState(false);
  
  // State for controlling opening and closing of a dialog
  const [open, setOpen] = useState(false);
  
  // State for controlling loading indicator
  const [loading, setLoading] = useState(false);
  
  const handleClose = (e) => {
    // Close all dialogs when handleClose function is called
    setOpen(false); // Close main dialog
    setErrorDialog(false); // Close error dialog
    setSuccessDialog(false); // Close success dialog
};

const HandleSubmit = async (event) => {
    setLoading(true); // Set loading state to true
    setDis(true); // Disable form submission button
    event.preventDefault(); // Prevent default form submission behavior

    try {
        // Send a POST request to create RFI
        await AxiosAPIInstance.post("/RFIs/CreateRFI", Form_Value).then(
            async (response) => {
                setLoading(false); // Set loading state to false
                setSuccessDialog(true); // Display success dialog
                setErrorDialog(false); // Hide error dialog
                setRFIMssge(response?.data); // Set RFI message
                setDis(false); // Enable form submission button
                // Reset form values to initial state after successful submission
                Set_Form_Value({
                    ...Form_Value,
                    Opportunity_Name: "",
                    Solicitation_Number: "",
                    Brief: "",
                    Contract_Vehicle: "",
                    Organization_Name: "",
                    KO_Name: "",
                    Contracting_Specialist: "",
                    KO_email: "",
                    KO_phone: "",
                    KO_address: "",
                    Published_date: null,
                    Due_date: null,
                    Estimated_$_Amount: "",
                    Sales_Head_Email: UserEmail,
                    Sales_Head_Name: UserName,
                    Sales_Head_Phone: '540-547-8487',
                    Expected_RFP: 0,
                    Incumbent_Info: "",
                    Proposal_Stage: "",
                    Team_Support: "",
                    Teaming_partners_Vendors: "",
                    Potential_Roadblocks: "",
                    Govwin_Link: "",
                    Sam_gov_link: "",
                    Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear()))
                });
            }
        ).catch((error) => {
            setLoading(false); // Set loading state to false
            setSuccessDialog(false); // Hide success dialog
            setErrorDialog(true); // Display error dialog
            setRFIMssge(error.response?.data); // Set RFI message from error response
            setDis(false); // Enable form submission button
        });
    } catch (error) {
        console.error(error); // Log any unexpected errors
    }
};

return(
  <>
  {<ApplicationBar></ApplicationBar>}
  
    {open===true&&(<Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {"Are you sure you want to exit this page?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            All the changes will be lost if you decide to exit this page
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={handleClose} href="/Dashboard">Yes</Button>
          <Button name="No" onClick={handleClose} autoFocus>
            No
          </Button>
        </DialogActions>
      </Dialog>)}
      {successDialog===true&&(<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfiMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false)
          }
          } href="/Dashboard/RFIInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog===true&&(<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
        {rfiMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
          {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes"  onClick={(e)=>{
          handleClose();
          e.preventDefault();
          setDis(false);}} href="/Dashboard/RFInput">OK</Button>
        </DialogActions>
      </Dialog>)}
  <Stack component="form" onSubmit={HandleSubmit} justifyContent='center' sx={{marginTop:"100px"}}>
 <Stack direction="column" spacing={2.5} justifyContent='center' sx={{marginTop:"0px"}}>
  <Stack direction="row" justifyContent="center">
  <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Verdana, sans-serif",
              color: theme.palette.primary.main,
            }}
          >
            Request For Information / Sources Sought (RFIs)
          </Typography>
    </Stack>

        <Stack direction="row" spacing={4} justifyContent="center">
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Opportunity Name" value={Form_Value.Opportunity_Name} required={true} onChange={(e)=>Set_Form_Value({...Form_Value,Opportunity_Name:e.target.value})}></TextField>
        <TextField  size="small" variant="standard" sx={{width:"300px"}} label="Solicitation Number" helperText="Enter NA if you don't have one.Please don't make up your own number." value={Form_Value.Solicitation_Number} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Solicitation_Number:String(e.target.value).trim().toUpperCase()})}></TextField>
        <TextField  variant="standard" size="small" sx={{width:"300px"}} multiline={true}  rowsMax={20} label="Organization Name" value={Form_Value.Organization_Name} onChange={(e)=>Set_Form_Value({...Form_Value,Organization_Name:e.target.value})}></TextField>

         <FormControl size="small" sx={{width:"300px"}}>
        <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
        <Select size="small"
          labelId="Contract-Vehicle"
          variant="standard"
          id="Contract-Vehicle-id"
          value={Form_Value.Contract_Vehicle}
          required={true}
          label="Contract Vehicle"
          onChange={(e)=>{Set_Form_Value({...Form_Value,Contract_Vehicle:e.target.value})}}
          sx={{width:"300px"}}>
          <MenuItem size="small" name="G" value={"GSA"}>GSA</MenuItem>
          <MenuItem size="small" name="SO" value={"SAM/Open Market"}>SAM/Open Market</MenuItem>
          <MenuItem size="small" name="UN" value={"Unison"}>Unison</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>Non-GSA Direct Inquiry</MenuItem>
          <MenuItem size="small" name="DI" value={"Direct Inquiry"}>GSA Direct Inquiry</MenuItem>
          <MenuItem size="small" name="CR" value={"Commercial/Retail"}>Commercial/Retail</MenuItem>
        </Select>
      </FormControl>
        </Stack>
        <Stack direction="row" spacing={4} justifyContent='center'>
       
        <TextField variant="standard"  size="small" sx={{width:"300px"}} label="CO's name" value={Form_Value.KO_Name}  onChange={(e)=>Set_Form_Value({...Form_Value,KO_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField variant="standard"  size="small" type="email" sx={{width:"300px"}} label="CO's email" value={Form_Value.KO_email}  onChange={(e)=>Set_Form_Value({...Form_Value,KO_email:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField variant="standard" size="small" sx={{width:"300px"}} label="CO's phone" value={Form_Value.KO_phone}  onChange={(e)=>Set_Form_Value({...Form_Value,KO_phone:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField variant="standard" size="small" multiline={true} rowsMax={10} sx={{width:"300px"}} label="CO's address"  value={Form_Value.KO_address} onChange={(e)=>Set_Form_Value({...Form_Value,KO_address:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactMailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
           
        </Stack>
        <Stack direction="row" spacing={4} justifyContent='center' >
        <DatePicker label="Published date" sx={{width:"300px"}}  value={Form_Value.Published_date} maxDate={new Date()} renderInput={(params)=><TextField  variant="standard" size="small"  sx={{width:"300px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Published_date:value})}></DatePicker>
          <DatePicker label="Due date" sx={{width:"300px"}} value={Form_Value.Due_date} minDate={Form_Value.Published_date} renderInput={(params)=><TextField  variant="standard"  size="small"  sx={{width:"300px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Due_date:value})}></DatePicker>
          <TextField variant="standard"  size="small" sx={{width:"300px"}} type="number"  label="Estimated Amount" value={Form_Value.Estimated_$_Amount} onChange={(e)=>Set_Form_Value({...Form_Value,Estimated_$_Amount:e.target.value})} inputProps={{min:"0"}} InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <AttachMoneyIcon />
              </InputAdornment>
            ),
          }}></TextField>
        <TextField variant="standard" size="small" sx={{width:"300px"}} label="Contracting Specialist" value={Form_Value.Contracting_Specialist}  onChange={(e)=>Set_Form_Value({...Form_Value,Contracting_Specialist:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
    </Stack>
    <Stack direction="row" spacing={4}>

          </Stack>
          <Stack direction="row" spacing={4} justifyContent='center'>
          <TextField  variant="standard" size="small" sx={{width:"300px"}} multiline={true}  rowsMax={20} label="Incumbent Info" value={Form_Value.Incumbent_Info} onChange={(e)=>Set_Form_Value({...Form_Value,Incumbent_Info:e.target.value})}></TextField>
          <TextField  variant="standard" size="small" sx={{width:"300px"}} label="Proposal Stage"  value={Form_Value.Proposal_Stage} onChange={(e)=>Set_Form_Value({...Form_Value,Proposal_Stage:e.target.value})}></TextField>
          <TextField  variant="standard" size="small" sx={{width:"300px"}} multiline={true}  rowsMax={20} label="Team Support" value={Form_Value.Team_Support} onChange={(e)=>Set_Form_Value({...Form_Value,Team_Support:e.target.value})}></TextField>
          <TextField variant="standard" size="small" sx={{width:"300px"}} multiline={true}  rowsMax={20} label="Teaming partners/Vendors" value={Form_Value.Teaming_partners_Vendors} onChange={(e)=>Set_Form_Value({...Form_Value,Teaming_partners_Vendors:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Diversity3Icon />
            </InputAdornment>
          ),
        }}></TextField>
          </Stack>
        <Stack direction="row" spacing={4} justifyContent='center'>
        <TextField  variant="standard" size="small" sx={{width:"300px"}} multiline={true}  rowsMax={20} label="Potential Roadblocks" value={Form_Value.Potential_Roadblocks} onChange={(e)=>Set_Form_Value({...Form_Value,Potential_Roadblocks:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <RemoveRoadIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField variant="standard" size="small" sx={{width:"300px"}} label="Govwin Link"  type="url" value={Form_Value.Govwin_Link} onChange={(e)=>Set_Form_Value({...Form_Value,Govwin_Link:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AddLinkIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField  variant="standard" size="small" sx={{width:"300px"}}label="Sam.gov link" type="url"  value={Form_Value.Sam_gov_link} onChange={(e)=>Set_Form_Value({...Form_Value,Sam_gov_link:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <AddLinkIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField variant="standard"  size="small" sx={{width:"300px"}} label="Expected RFP"  value={Form_Value.Expected_RFP} onChange={(e)=>Set_Form_Value({...Form_Value,Expected_RFP:e.target.value})}></TextField>
        </Stack>
        <Stack direction="row" spacing={4} justifyContent='center'>
        <TextField   size="small" variant="standard" sx={{width:"300px"}} multiline={true} rowsMax={20} label="Brief" value={Form_Value.Brief}  onChange={(e)=>Set_Form_Value({...Form_Value,Brief:e.target.value})}  InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <DescriptionIcon />
            </InputAdornment>
          ),
        }}></TextField>
        <TextField variant="standard"  size="small" sx={{width:"300px"}} label="Sales Head Name" value={UserName}  onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Name:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <PersonIcon />
            </InputAdornment>
          ),
        }}></TextField>
         <TextField variant="standard"  size="small" type="email" sx={{width:"300px"}} label="Sales Head Email" value={UserEmail}  onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Email:String(e.target.value).trim().toLowerCase()})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <EmailIcon />
            </InputAdornment>
          ),
        }}>
        </TextField>
        <TextField variant="standard" size="small" sx={{width:"300px"}} label="Sales Head Phone" value="540-547-8487"  onChange={(e)=>Set_Form_Value({...Form_Value,Sales_Head_Phone:e.target.value})} InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <ContactPhoneIcon />
            </InputAdornment>
          ),
        }}></TextField>
    
        </Stack >
        <Stack direction="row" justifyContent="center">
        <Button  sx={{width:"200px",bgcolor:[orange[600]],":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[orange[500]]}}} size='small' variant="contained" type="submit" disabled={dis}>Create</Button>
        </Stack>
       
        </Stack>  
</Stack>
</>
)
}
export default RFI_CLIENT_INPUT