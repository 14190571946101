import React, { useState, useEffect } from 'react';
import {
  Container, Typography, Box, Paper, FormControl, InputLabel, Select, MenuItem, Stack, Divider,
  Grid, Button, Card, CardContent, CardActionArea, CardActions, Slider, TextField, Avatar, IconButton
} from '@mui/material';
import {
  BarChart, Bar, XAxis, YAxis, Tooltip, Legend, CartesianGrid, ResponsiveContainer, PieChart, Pie,
  Cell, LabelList, Line, ReferenceLine
} from 'recharts';
import { orange, green, red, blueGrey, blue, indigo } from '@mui/material/colors';
import { DatePicker } from '@mui/x-date-pickers';
import LoadingDialog from '../Loading';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import AxiosFunction from '../../axiosCustomInstance';
import { Email, Phone } from '@mui/icons-material';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { pdf } from '@react-pdf/renderer';
import PdfDocument from '../PDF/Main';
import { useNavigate, createSearchParams } from 'react-router-dom';
import ApplicationBar from '../AppBar/ApplicationBar';
import UserAuth from '../ProtectedRoute/userAuth';
import InfoIcon from '@mui/icons-material/Info';
import MetricsDashboard from '../RevenueGoals/OtherGoalMetrics';
import PerformanceGoalsMetricsDashboard from './PerformanceAndGoals';
import { selectCurrentUser } from '../Login_Registration/authSlice';
import { selectIsAdmin,selectUserRole } from '../Login_Registration/authSlice';
import { useDispatch, useSelector } from "react-redux";

// Define custom colors for pie charts
const COLORS = [orange[600], green[600], red[600]];

// Define function to format currency values
const formatCurrency = (value) => `$${parseFloat(value).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;

const MyPerformanceDashboard = () => {
  const [chartData, setChartData] = useState([]);
  const [chartDataByDollarValue, setChartDataByDollarValue] = useState([]);
  const [chartDataByProfitMargin, setChartDataByProfitMargin] = useState([]);
  const { UserName, UserEmail } = UserAuth();
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [selectedContractVehicle, setSelectedContractVehicle] = useState('All');
  const [dialogMssge, setDialogMssge] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [loading, setLoading] = useState(false);
  const [dis, setDis] = useState(false);
  const AxiosAPIInstance = AxiosFunction();
  const [usersData, setUsersData] = useState([{ User_email: '' }]);
  const [salesHeadError, setSalesHeadError] = useState(false);
  const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState('');
  const [barHeights, setBarHeights] = useState([]);
  const [averageValue, setAverageValue] = useState(0);
  const [pieChartData, setPieChartData] = useState([]);
  const [pieChartDataProfitMargin, setPieChartDataProfitMargin] = useState([]);
  const [totalQuotes, setTotalQuotes] = useState(0);
  const [conversionRate, setConversionRate] = useState(0);
  const [averageDealSize, setAverageDealSize] = useState(0);
  const [totalRevenue, setTotalRevenue] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [totalProfitPercent, setTotalProfitPercent] = useState(0);
  const [wonQuotes, setWonQuotes] = useState([]);
  const [lostQuotes, setLostQuotes] = useState([]);
  const [pendingQuotes, setPendingQuotes] = useState([]);
  const [allQuotes, setAllQuotes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [clicked, setClicked] = useState(false);
  const currentYear = new Date().getFullYear(); // Getting the current year
  const [stus, setStus] = useState("ALL"); // State to store the current status filter
  const isAdmin = useSelector(selectIsAdmin);
  const userRole = useSelector(selectUserRole);
  const [dateFilters, setDateFilters] = useState({
    dueDateFrom: '',
    dueDateTo: '',
    publishedDateFrom: '',
    publishedDateTo: '',
    createdDateFrom:new Date(currentYear, 0, 1),
    createdDateTo:new Date(currentYear, 11, 31) 
  });
  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', {
    dateStyle: 'medium',
  }).format(PD);

  const [Form_Value, Set_Form_Value] = useState([{
    Opportunity_Name: '', RFQ_ID: '', Invoice_No: '', Contract_Vehicle: '', KO_name: '', KO_email: '',
    KO_phone: '', KO_address: '', Published_date: null, Due_date: null, Brief: '', Organization_Name: '',
    Customer_Name: '', Customer_Email: '', Customer_Phone: '', Customer_Address: '', Shipping_Method: '', Sales_Head_Name: '', Sales_Head_Email: String(UserEmail).toLowerCase() || 'sales@aspettoinc.com', Sales_Head_Ph: '',
    Shipping_Terms: 'FOB Origin', Estimated_Delivery: '', Payment_Terms: 'NET30', Notes: '', Shipping_Cost: Number(parseFloat(0).toFixed(2)),
    Tax: Number(parseFloat(0).toFixed(2)), Actual_Total_Cost_Before_Margin: parseFloat(0.00).toFixed(2), Actual_Total_Cost_After_Margin: parseFloat(0.00).toFixed(2),
    Created_Date: today,
    Last_Updated_Date: today, RFQ_Status: 'PENDING',
    Updated_By: 'TBD', Version_No: parseInt(0), Updating_Comments: 'RFQ Created under PENDING Status',
  }]);

  const [sortOrder, setSortOrder] = useState('asc'); // State for sorting order
  const [sortField, setSortField] = useState('Created_Date'); // State for sorting field
  const [dateRange, setDateRange] = useState([null, null]); // State for date range filter
  const [marginRange, setMarginRange] = useState([0, 100]); // State for margin range filter

  // Info Dialog state management
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const [infoContent, setInfoContent] = useState(null);

  const navigate = useNavigate();

  // Handle the info button click and set the content to display in the dialog
  const handleInfoButtonClick = (content) => {
    setInfoContent(content);
    setInfoDialogOpen(true);
  };

  const handleInfoDialogClose = () => {
    setInfoDialogOpen(false);
    setInfoContent(null);
  };

  // Fetch data on component mount and whenever relevant state changes
  useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true);
      setClicked(false);
      try {
        const result = await AxiosAPIInstance.get('/UserAuth/users');
        setLoading(false);
        const users = [...result?.data];
        Set_Form_Value({ ...Form_Value, Sales_Head_Email: String(UserEmail).toLowerCase() });
        setUsersData(users);
        setSalesHeadError(false);
        setSalesHeadErrorMsge('');
      } catch (error) {
        setLoading(false);
        setSalesHeadError(true);
        setSalesHeadErrorMsge(error?.response.data);
      }
    };

    fetchUsersData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      setDis(true);
      setLoading(true);

      try {
        const response = await AxiosAPIInstance.get('/GetYourPerformance', {
          params: {
            Sales_Head_Email: String(Form_Value.Sales_Head_Email).toLowerCase(),
            Contract_Vehicle: selectedContractVehicle,
            Year: selectedYear,
          },
        });

        const fetchedData = response.data;

        // Prepare chart data by months
        const allMonths = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            PENDING_DOLLAR: 0,
            WON_DOLLAR: 0,
            LOST_DOLLAR: 0,
          };
        });

        // Aggregate data for charting
        const updatedChartData = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex((item) => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += 1;
              acc[monthIndex].PENDING_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += 1;
              acc[monthIndex].WON_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += 1;
              acc[monthIndex].LOST_DOLLAR += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonths);

        updatedChartData.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartData(updatedChartData);

        // Prepare dollar value data by months
        const allMonthsByDollarValue = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
          };
        });

        const updatedChartDataByDollarValue = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex((item) => item.name === monthYear);

          if (monthIndex !== -1) {
            if (entry.RFQ_Status === 'PENDING') {
              acc[monthIndex].PENDING += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'WON') {
              acc[monthIndex].WON += parseFloat(entry.Actual_Total_Cost_After_Margin);
            } else if (entry.RFQ_Status === 'LOST') {
              acc[monthIndex].LOST += parseFloat(entry.Actual_Total_Cost_After_Margin);
            }
          }

          return acc;
        }, allMonthsByDollarValue);

        updatedChartDataByDollarValue.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByDollarValue(updatedChartDataByDollarValue);

        // Prepare profit margin data by months
        const allMonthsByProfitMargin = Array.from({ length: 12 }, (_, index) => {
          const monthDate = new Date(selectedYear, index, 1);
          return {
            name: `${monthDate.toLocaleString('default', { month: 'short' })} ${selectedYear}`,
            PENDING: 0,
            WON: 0,
            LOST: 0,
            height: 0,
          };
        });

        const updatedMonthsByProfitMargin = fetchedData.reduce((acc, entry) => {
          const createdDate = new Date(entry.Created_Date);
          const monthYear = `${createdDate.toLocaleString('default', { month: 'short' })} ${createdDate.getFullYear()}`;

          const monthIndex = acc.findIndex((item) => item.name === monthYear);

          if (monthIndex !== -1) {
            const height = parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin);

            acc[monthIndex].PENDING += entry.RFQ_Status === 'PENDING' ? height : 0;
            acc[monthIndex].WON += entry.RFQ_Status === 'WON' ? height : 0;
            acc[monthIndex].LOST += entry.RFQ_Status === 'LOST' ? height : 0;
            acc[monthIndex].height += height;
          }

          return acc;
        }, allMonthsByProfitMargin);

        updatedMonthsByProfitMargin.sort((a, b) => {
          const dateA = new Date(a.name);
          const dateB = new Date(b.name);
          return dateA - dateB;
        });

        setChartDataByProfitMargin(updatedMonthsByProfitMargin);
        setBarHeights(updatedMonthsByProfitMargin.map((entry) => entry.height));

        // Calculate average height for reference line in profit margin chart
        const calculateAverageHeight = () => {
          const averageHeight = updatedMonthsByProfitMargin.reduce((acc, entry) => acc + entry.height, 0) / updatedMonthsByProfitMargin.length;
          return averageHeight;
        };

        setAverageValue(calculateAverageHeight());

        // Prepare data for pie charts
        const pieData = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedChartDataByDollarValue.forEach((entry) => {
          pieData[0].value += entry.PENDING;
          pieData[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieData[1].value += entry.WON;
          pieData[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieData[2].value += entry.LOST;
          pieData[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartData(pieData);

        const pieDataProfitMargin = [
          { name: 'PENDING', value: 0, dollarValue: 0 },
          { name: 'WON', value: 0, dollarValue: 0 },
          { name: 'LOST', value: 0, dollarValue: 0 },
        ];

        updatedMonthsByProfitMargin.forEach((entry) => {
          pieDataProfitMargin[0].value += entry.PENDING;
          pieDataProfitMargin[0].dollarValue += parseFloat(entry.PENDING_DOLLAR);
          pieDataProfitMargin[1].value += entry.WON;
          pieDataProfitMargin[1].dollarValue += parseFloat(entry.WON_DOLLAR);
          pieDataProfitMargin[2].value += entry.LOST;
          pieDataProfitMargin[2].dollarValue += parseFloat(entry.LOST_DOLLAR);
        });

        setPieChartDataProfitMargin(pieDataProfitMargin);

        const allQuotes = fetchedData;
        const totalWonQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'WON').length;
        const totalPendingQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'PENDING').length;
        const totalLostQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'LOST').length;
        const totalQuotes = totalLostQuotes + totalWonQuotes + totalPendingQuotes;

        const totalRevenue = fetchedData.filter((entry) => entry.RFQ_Status === 'WON').reduce((acc, entry) => acc + parseFloat(entry.Actual_Total_Cost_After_Margin), 0);
        const averageDealSize = totalWonQuotes > 0 ? totalRevenue / totalWonQuotes : 0;
        const conversionRate = totalQuotes > 0 ? (totalWonQuotes / totalQuotes) * 100 : 0;

        const totalProfit = fetchedData.filter((entry) => entry.RFQ_Status === 'WON').reduce((acc, entry) => acc + (parseFloat(entry.Actual_Total_Cost_After_Margin) - parseFloat(entry.Actual_Total_Cost_Before_Margin)), 0);
        const totalProfitPercent = totalRevenue > 0 ? (totalProfit / totalRevenue) * 100 : 0;

        setTotalQuotes(totalQuotes);
        setConversionRate(conversionRate);
        setAverageDealSize(averageDealSize);
        setTotalRevenue(totalRevenue);
        setTotalProfit(totalProfit);
        setTotalProfitPercent(totalProfitPercent);

        const wonQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'WON');
        setWonQuotes(wonQuotes);
        const pendingQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'PENDING');
        setPendingQuotes(pendingQuotes);
        const lostQuotes = fetchedData.filter((entry) => entry.RFQ_Status === 'LOST');
        setLostQuotes(lostQuotes);

        setAllQuotes(allQuotes);
      } catch (error) {
        setErrorDialog(true);
        setSuccessDialog(false);
        setDialogMssge(error?.response?.data);
      } finally {
        setDis(false);
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedYear, selectedContractVehicle, Form_Value?.Sales_Head_Email]);

  // Render customized labels on bar charts
  const renderCustomizedLabel = (props) => {
    const { x, y, width, height, value } = props;
    if (value === 0) return null;
    return (
      <text
        x={x + width / 2}
        y={y + height / 2}
        fill="#fff"
        textAnchor="middle"
        dominantBaseline="middle"
      >
        {value}
      </text>
    );
  };

  // Handle year selection change
  const handleYearChange = (event) => {
    setSelectedYear(event.target.value);
    setClicked(false);
  };

  // Handle contract vehicle selection change
  const handleContractVehicleChange = (event) => {
    setSelectedContractVehicle(event.target.value);
    setClicked(false);
  };

  // Calculate average profit margin per status
  const calculateAverageProfitMarginPerStatus = (status) => {
    const filteredData = chartDataByProfitMargin.filter((entry) => entry[status] > 0);
    const totalProfitMargin = filteredData.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginDollar = filteredData.length > 0 ? totalProfitMargin / filteredData.length : 0;
    const totalDollarValue = chartDataByDollarValue.reduce((acc, entry) => acc + entry[status], 0);
    const averageProfitMarginPercent = totalDollarValue > 0 ? (totalProfitMargin / totalDollarValue) * 100 : 0;

    return { averageProfitMarginDollar, averageProfitMarginPercent };
  };

  const pendingProfitMargin = calculateAverageProfitMarginPerStatus('PENDING');
  const wonProfitMargin = calculateAverageProfitMarginPerStatus('WON');
  const lostProfitMargin = calculateAverageProfitMarginPerStatus('LOST');

  // Handle search term change for filtering quotes
  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value.toLowerCase());
  };

  // Handle sorting field change
  const handleSortChange = (event) => {
    setSortField(event.target.value);
  };

  // Handle sorting order change
  const handleSortOrderChange = (event) => {
    setSortOrder(event.target.value);
  };

  // Handle date range change for filtering quotes
  const handleDateRangeChange = (newRange) => {
    setDateRange(newRange);
  };

  // Handle margin range change for filtering quotes
  const handleMarginRangeChange = (event, newRange) => {
    setMarginRange(newRange);
  };
  const handleDateFilterChange = (field, value) => {
    setDateFilters({
      ...dateFilters,
      [field]: value,
    });
  };
  
  // Filter and sort quotes based on search, sorting, and filtering criteria
  const filteredQuotes = allQuotes.filter((quote) =>
    quote.Opportunity_Name.toLowerCase().includes(searchTerm) ||
    quote.Customer_Email.toLowerCase().includes(searchTerm) ||
    quote.Customer_Phone.includes(searchTerm) ||
    quote.Contract_Vehicle.toLowerCase().includes(searchTerm) ||
    quote.Invoice_No.toLowerCase().includes(searchTerm) ||
    quote.Customer_Name.toLowerCase().includes(searchTerm) ||
    quote.KO_name.toLowerCase().includes(searchTerm) ||
    quote.Organization_Name.toLowerCase().includes(searchTerm)
  );

  const sortedQuotes = [...filteredQuotes].sort((a, b) => {
    if (sortField === 'Created_Date') {
      const dateA = new Date(a.Created_Date);
      const dateB = new Date(b.Created_Date);
      return sortOrder === 'asc' ? dateA - dateB : dateB - dateA;
    } else if (sortField === 'Revenue') {
      const revenueA = parseFloat(a.Actual_Total_Cost_After_Margin);
      const revenueB = parseFloat(b.Actual_Total_Cost_After_Margin);
      return sortOrder === 'asc' ? revenueA - revenueB : revenueB - revenueA;
    }
    return 0;
  });

  const filteredAndSortedQuotes = sortedQuotes.filter((quote) => {
    const quoteDate = new Date(quote.Created_Date);
    const margin = ((parseFloat(quote.Actual_Total_Cost_After_Margin) - parseFloat(quote.Actual_Total_Cost_Before_Margin)) / parseFloat(quote.Actual_Total_Cost_After_Margin)) * 100;

 
    return (
      (dateRange[0] === null || quoteDate >= dateRange[0]) &&
      (dateRange[1] === null || quoteDate <= dateRange[1]) &&
      margin >= marginRange[0] &&
      margin <= marginRange[1] &&
      (quote.RFQ_Status).trim()===stus
    );
  });

  let File_Opportunity_Name = '';
  const [InvoiceData, setInvoiceData] = useState({
    invoice_no: '',
    Contract_Vehicle: '',
    KO_name: '',
    KO_email: '',
    KO_phone: '',
    KO_address: '',
    Print_Date: String(today),
    Valid_For: '30 Days',
    trans_date: '',
    due_date: '',
    Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
    items: [{}],
    Tax: 0,
    Shipping_Cost: 0,
    Total_Cost: 0,
    Shipping_Details: {
      Organization_Name: '',
      KO_name: '',
      Sales_Head_Info: {},
      Customer_Name: '',
      Customer_Email: '',
      Customer_Phone: '',
      Customer_Address: '',
      Shipping_Method: '',
      Shipping_Terms: '',
      Estimated_Delivery: '',
      Payment_Terms: '',
      Notes: '',
    },
  });

  // Function to navigate to the UpdateRFQ page with parameters
  const RFQID_Search = (quote) => {
    navigate({
      pathname: '/Dashboard/UpdateRFQ',
      search: createSearchParams({
        RID: quote?.RFQ_ID,
        RStatus: quote?.RFQ_Status,
        VNo: 'LifeCycle',
      }).toString(),
    });
  };

  // Handle PDF click to generate a PDF of the selected quote
  const HandlePDFClick = async (quote) => {
    const arr = String(quote.Sales_Head_Name).toLowerCase().split(' ');
    for (let i = 0; i < arr.length; i++) {
      arr[i] = arr[i].charAt(0).toUpperCase() + arr[i].slice(1);
    }
    const KO_arr = String(quote.KO_name).toLowerCase().split(' ');
    for (let i = 0; i < KO_arr.length; i++) {
      KO_arr[i] = KO_arr[i].charAt(0).toUpperCase() + KO_arr[i].slice(1);
    }

    const CO_arr = String(quote.Customer_Name).toLowerCase().split(' ');
    for (let i = 0; i < CO_arr.length; i++) {
      CO_arr[i] = CO_arr[i].charAt(0).toUpperCase() + CO_arr[i].slice(1);
    }

    const updatedInvoiceData = {
      ...InvoiceData,
      invoice_num: String(quote.Invoice_No),
      Contract_Vehicle: quote.Contract_Vehicle,
      KO_name: KO_arr.join(' '),
      KO_email: quote.KO_email,
      KO_phone: quote.KO_phone,
      KO_address: quote.KO_address,
      Print_Date: String(today),
      trans_date: String(quote.Last_Updated_Date).split('T')[0],
      due_date: quote.Due_date,
      Valid_For: quote.Valid_For,
      Address: 'ASPETTO INC, 1691 EMANCIPATION HWY, FREDERICKSBURG, VA-22401',
      items: quote.Purchase_Orders,
      Tax: quote.Tax,
      Shipping_Cost: quote.Shipping_Cost,
      Total_Cost: quote.Actual_Total_Cost_After_Margin,
      Other_Cost_1: quote.Other_Cost_1,
      Other_Cost_2: quote.Other_Cost_2,
      Other_Cost_3: quote.Other_Cost_3,
      Other_Cost_Name1: quote.Other_Cost_Name1,
      Other_Cost_Name2: quote.Other_Cost_Name2,
      Other_Cost_Name3: quote.Other_Cost_Name3,
      Shipping_Details: {
        Organization_Name: quote.Organization_Name,
        KO_name: quote.KO_name,
        Sales_Head_Info: { Name: arr.join(' '), Email: quote.Sales_Head_Email, Phone: quote.Sales_Head_Ph },
        Customer_Name: CO_arr.join(' '),
        Customer_Email: quote.Customer_Email,
        Customer_Phone: quote.Customer_Phone,
        Customer_Address: quote.Customer_Address,
        Shipping_Method: quote.Shipping_Method,
        Shipping_Terms: quote.Shipping_Terms,
        Estimated_Delivery: quote.Estimated_Delivery,
        Payment_Terms: quote.Payment_Terms,
        Notes: quote.Notes,
      },
    };
    const OP_arr = String(quote.Opportunity_Name).split('.');
    for (let i = 0; i < OP_arr.length; i++) {
      OP_arr[i] = OP_arr[i].charAt(0).toUpperCase() + OP_arr[i].slice(1);
    }
    File_Opportunity_Name = OP_arr.join(' ');

    setInvoiceData(updatedInvoiceData);  // Set state

    setTimeout(async () => {  // Use a timeout to ensure state is updated before generating PDF
      const pdfBlob = await pdf(<PdfDocument invoicedata={updatedInvoiceData} />).toBlob();
      const url = URL.createObjectURL(pdfBlob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `${File_Opportunity_Name} ${String(quote.Invoice_No).split('.')[0]}${String(quote.Invoice_No).split('.')[1]}.pdf`;
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
      URL.revokeObjectURL(url);
    }, 0);  // Delay the PDF generation to allow state update
  };

  // Function to handle closing of success or error dialogs
  const handleClose = async () => {
    setDis(false);
    setErrorDialog(false);
    setSuccessDialog(false);
    setDialogMssge('');
    setClicked(false);
  };

  // Apply filtering and sorting to totalData
  const applyFiltersAndSorting = () => {
  // Filter rows based on search term, date filters, and checkbox filters
  const filtered = filteredAndSortedQuotes.filter((row) => {
    // Search term filter
    const withinDateRange = (field, from, to) => {
      if (!from && !to) return true;
      const date = new Date(row[field]);
      const fromDate = from ? new Date(from) : null;
      const toDate = to ? new Date(to) : null;
      return (!fromDate || date >= fromDate) && (!toDate || date <= toDate);
    };
    const matchesDateFilters = withinDateRange("dueDate", dateFilters.dueDateFrom, dateFilters.dueDateTo) &&
                               withinDateRange("publishedDate", dateFilters.poDateFrom, dateFilters.poDateTo) &&
                               withinDateRange("createDate", dateFilters.createdDateFrom, dateFilters.createdDateTo);
  

    // Combine all filters
    return matchesDateFilters ;
  });
  
  
  };
  
  var filteredData = applyFiltersAndSorting();
  // Functions to generate explanation content for each chart type
  const getInfoContent = (chartType) => {
    setLoading(true)
    switch (chartType) {
      case 'monthlyQuotes':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Monthly Quotes (Count) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart shows the number of quotes (PENDING, WON, and LOST) that were created each month for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the quotes that are still under consideration.</li>
                <li><strong>WON:</strong> Represents the quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the quotes that were not successful.</li>
              </ul>
              Example: If in January 2024, you have 10 PENDING quotes, 5 WON quotes, and 3 LOST quotes, the chart will show 10 (PENDING), 5 (WON), and 3 (LOST) bars for January 2024.
            </DialogContentText>
          </>
        );
      case 'dollarValue':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}} >Monthly Quotes (Dollar Value) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart displays the total dollar value of quotes (PENDING, WON, and LOST) for each month in the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the total dollar value of quotes that are still under consideration.</li>
                <li><strong>WON:</strong> Represents the total dollar value of quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the total dollar value of quotes that were not successful.</li>
              </ul>
              Example: If in February 2024, you have 3 PENDING quotes worth $10,000, 2 WON quotes worth $15,000, and 1 LOST quote worth $5,000, the chart will show bars representing these values.
            </DialogContentText>
          </>
        );
      case 'profitMargin':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Monthly Quotes (Profit Margin) Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This bar chart illustrates the profit margins for quotes (PENDING, WON, and LOST) each month for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Represents the profit margin for quotes still under consideration.</li>
                <li><strong>WON:</strong> Represents the profit margin for quotes that were successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Represents the profit margin for quotes that were not successful.</li>
              </ul>
              Example: If in March 2024, you have a PENDING quote with a $2,000 profit margin, a WON quote with a $3,500 profit margin, and a LOST quote with a $1,000 profit margin, the chart will show these values accordingly.
            </DialogContentText>
          </>
        );
      case 'dollarDistribution':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Dollar Value Distribution Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This pie chart breaks down the total dollar value of all quotes into PENDING, WON, and LOST categories for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Shows the percentage of the total dollar value that is still under consideration.</li>
                <li><strong>WON:</strong> Shows the percentage of the total dollar value that has been successfully converted into contracts.</li>
                <li><strong>LOST:</strong> Shows the percentage of the total dollar value that was not successful.</li>
              </ul>
              Example: If the total dollar value of all quotes is $100,000, and $50,000 is WON, $30,000 is PENDING, and $20,000 is LOST, the chart will display the corresponding percentages for each.
            </DialogContentText>
          </>
        );
      case 'profitMarginDistribution':
        setLoading(false)
        return (
          <>
            <Typography sx={{fontSize:'15px',fontWeight:'bold'}}>Profit Margin Distribution Explanation</Typography>
            <DialogContentText sx={{fontSize:'13px'}}>
              This pie chart represents the distribution of profit margins across PENDING, WON, and LOST quotes for the selected year and contract vehicle.
              <ul>
                <li><strong>PENDING:</strong> Shows the percentage of the total profit margin still under consideration.</li>
                <li><strong>WON:</strong> Shows the percentage of the total profit margin from successfully converted contracts.</li>
                <li><strong>LOST:</strong> Shows the percentage of the total profit margin from unsuccessful quotes.</li>
              </ul>
              Example: If the total profit margin across all quotes is $50,000, and $30,000 is from WON quotes, $10,000 is from PENDING quotes, and $10,000 is from LOST quotes, the chart will display these distributions.
            </DialogContentText>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <ApplicationBar />
      {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            e.preventDefault();
            handleClose();
            setDis(false)
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {dialogMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
            {dialogMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="OK" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/RFQInput">OK</Button>
        </DialogActions>
      </Dialog>)}
      <LoadingDialog open={loading} />

      <Stack direction="row" sx={{ marginTop: '80px',padding:'20px' }} spacing={2}> 
        <Stack direction="column" justifyContent="left" spacing={2}>
            <Typography component="p" sx={{ borderColor: 'divider', fontSize: '25px', fontWeight: 'bold', padding: 1 }}>
              Performance Dashboard
            </Typography>
          <Box display="flex" justifyContent="left" mb={4}>
            <Stack direction="row" spacing={2} justifyContent="left">
              <FormControl size="small" sx={{ width: '250px' }}>
                <InputLabel id="Select-Year">Select Year</InputLabel>
                <Select value={selectedYear} onChange={handleYearChange} variant="standard">
                  {[...Array(5)].map((_, index) => {
                    const year = new Date().getFullYear() - index;
                    return (
                      <MenuItem key={year} value={year}>
                        {year}
                      </MenuItem>
                    );
                  })}
                </Select>
              </FormControl>
              <FormControl size="small" sx={{ width: '150px' }}>
                <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
                <Select
                  size="small"
                  labelId="Contract-Vehicle"
                  id="Contract-Vehicle-id"
                  value={selectedContractVehicle}
                  variant="standard"
                  label="Contract Vehicle"
                  onChange={(e) => handleContractVehicleChange(e)}
                  sx={{ width: '150px' }}
                >
                  <MenuItem size="small" value="All">All</MenuItem>
                  <MenuItem size="small" value="Federal">Federal</MenuItem>
                  <MenuItem size="small" value="Military">Military</MenuItem>
                  <MenuItem size="small" value="Tactical">Tactical</MenuItem>
                  <MenuItem size="small" value="GSA">GSA</MenuItem>
                  <MenuItem size="small" value="FBOP">FBOP</MenuItem>
                  <MenuItem size="small" value="DHS-CBP">DHS-CBP</MenuItem>
                  <MenuItem size="small" value="SAM/Open Market">SAM/Open Market</MenuItem>
                  <MenuItem size="small" value="Unison">Unison</MenuItem>
                  <MenuItem size="small" value="Direct Inquiry">Direct Inquiry</MenuItem>
                  <MenuItem size="small" value="Commercial">Commercial</MenuItem>
                </Select>
              </FormControl>
              {isAdmin===true? (<FormControl>
               <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
                <Select
                sx={{ width: '300px' }}
                variant="standard"
                label="Sales Head Email ID"
                labelId="Sales-Head-id"
                value={String(Form_Value.Sales_Head_Email).toLowerCase()}
                size="small"
                onChange={(e) => Set_Form_Value({ ...Form_Value, Sales_Head_Email: e.target.value })}
                >
               <MenuItem value="all">All</MenuItem>
             {usersData?.map((row, index) => (
                <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>
                 {String(row?.User_email).toLowerCase()}
                </MenuItem>
                ))}
               </Select>
               </FormControl>
                  ):( <FormControl>
                    <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
                     <Select
                     sx={{ width: '300px' }}
                     variant="standard"
                     label="Sales Head Email ID"
                     labelId="Sales-Head-id"
                     value={String(Form_Value.Sales_Head_Email).toLowerCase()}
                     size="small"
                     onChange={(e) => Set_Form_Value({ ...Form_Value, Sales_Head_Email: e.target.value })}
                     >
                    <MenuItem value="all">All</MenuItem>
                    <MenuItem value={String(UserEmail).toLowerCase()}>{UserEmail}</MenuItem>
                    </Select>
                    </FormControl>
     )} 
             
            </Stack>
          </Box>
          {/* Key Metrics */}
 
            <Grid item xs={12}>
              <Paper elevation={3} style={{ padding: '20px', border: 1, borderColor: blueGrey[800], }}>
              <Stack direction="column" justifyContent="left" sx={{ width: '100%' }}>
            <Typography component="p" sx={{ borderColor: 'divider', fontSize: '20px', fontWeight: 'bold', padding: 1,color:blueGrey[900]}}>
            Key Performance Indicators
            </Typography>
            <Divider sx={{ bgcolor: 'orange', width: '100%', height: '1.5px' ,marginBottom:'10px'}} orientation="horizontal" />
            </Stack>

                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Total Quotes(-Cancelled)</Typography>
                    <Typography   sx={{fontSize:'13px' }}>{totalQuotes}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Conversion Rate(Won)</Typography>
                    <Typography  sx={{fontSize:'13px' }} >{conversionRate.toFixed(2)}%</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Average Deal Size</Typography>
                    <Typography sx={{fontSize:'13px' }} >{formatCurrency(averageDealSize)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Total Revenue</Typography>
                    <Typography   sx={{fontSize:'13px' }}>{formatCurrency(totalRevenue)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Total Profit ($)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{formatCurrency(totalProfit)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Total Profit (%)</Typography>
                    <Typography variant="h6" >{totalProfitPercent.toFixed(2)}%</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Average Profit Margin Per Status */}
            <Grid item xs={12}>
              <Paper elevation={3} style={{ padding: '20px', border: 1, borderColor: blueGrey[800], }}>
              <Stack direction="column" justifyContent="left" sx={{ width: '100%' }}>
            <Typography component="p" sx={{ borderColor: 'divider', fontSize: '20px', fontWeight: 'bold', padding: 1 ,color:blueGrey[900]}}>
            Average Profit Margin Per Quote
            </Typography>
            <Divider sx={{ bgcolor: 'orange', width: '100%', height: '1.5px',marginBottom:'10px' }} orientation="horizontal" />
            </Stack>
      
                <Grid container spacing={2}>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Pending ($)</Typography>
                    <Typography sx={{fontSize:'13px' }}>{formatCurrency(pendingProfitMargin.averageProfitMarginDollar)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Pending (%)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{pendingProfitMargin.averageProfitMarginPercent.toFixed(2)}%</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Won ($)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{formatCurrency(wonProfitMargin.averageProfitMarginDollar)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Won (%)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{wonProfitMargin.averageProfitMarginPercent.toFixed(2)}%</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Lost ($)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{formatCurrency(lostProfitMargin.averageProfitMarginDollar)}</Typography>
                  </Grid>
                  <Grid item xs={2}>
                    <Typography variant="h5" sx={{fontWeight:'bold'}}>Lost (%)</Typography>
                    <Typography  sx={{fontSize:'13px' }}>{lostProfitMargin.averageProfitMarginPercent.toFixed(2)}%</Typography>
                  </Grid>
                </Grid>
              </Paper>
            </Grid>

            {/* Monthly Quotes Chart */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '10px',fontSize:'13px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h7" gutterBottom>
                    Monthly Quotes for: <strong> {selectedYear} - {selectedContractVehicle}</strong>
                  </Typography>
                  <IconButton onClick={() => handleInfoButtonClick(getInfoContent('monthlyQuotes'))}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={chartData} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip />
                    <Legend />
                    <Bar dataKey="PENDING" stackId="a" fill={orange[500]}>
                      <LabelList dataKey="PENDING" content={renderCustomizedLabel} />
                    </Bar>
                    <Bar dataKey="WON" stackId="a" fill={green[500]}>
                      <LabelList dataKey="WON" content={renderCustomizedLabel} />
                    </Bar>
                    <Bar dataKey="LOST" stackId="a" fill={red[500]}>
                      <LabelList dataKey="LOST" content={renderCustomizedLabel} />
                    </Bar>
                    <Line type="monotone" dataKey="TREND" stroke="#8884d8" dot={false} />
                  </BarChart>
                </ResponsiveContainer>
                <Typography variant="body1" align="center" mt={2} sx={{fontSize:'13px' }}>
                  Total Pending: <strong>{chartData.reduce((acc, item) => acc + item.PENDING, 0)}</strong> | Total Won: <strong>{chartData.reduce((acc, item) => acc + item.WON, 0)}</strong> | Total Lost: <strong>{chartData.reduce((acc, item) => acc + item.LOST, 0)}</strong>
                </Typography>
              </Paper>
            </Grid>

            {/* Dollar Value Chart */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '10px',fontSize:'13px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h8" gutterBottom>
                    Monthly Quotes <strong style={{ color: 'red' }}>Total Dollar Value($)</strong>: <strong> {selectedYear} - {selectedContractVehicle}</strong>
                  </Typography>
                  <IconButton onClick={() => handleInfoButtonClick(getInfoContent('dollarValue'))}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={chartDataByDollarValue} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="4 4" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                    <Legend />
                    <Bar dataKey="PENDING" stackId="b" fill={orange[500]} />
                    <Bar dataKey="WON" stackId="b" fill={green[500]} />
                    <Bar dataKey="LOST" stackId="b" fill={red[500]} />
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            {/* Profit Margin Chart */}
            <Grid item xs={12} md={6}>
              <Paper elevation={3} style={{ padding: '10px',fontSize:'13px'  }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h8" gutterBottom>
                    Monthly Quotes <strong style={{ color: 'red' }}>Profit Margin($)</strong>: <strong> {selectedYear} - {selectedContractVehicle}</strong>
                  </Typography>
                  <IconButton onClick={() => handleInfoButtonClick(getInfoContent('profitMargin'))}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
                <ResponsiveContainer width="100%" height={250}>
                  <BarChart data={chartDataByProfitMargin} margin={{ top: 20, right: 30, left: 20, bottom: 5 }}>
                    <CartesianGrid strokeDasharray="3 3" />
                    <XAxis dataKey="name" />
                    <YAxis />
                    <Tooltip formatter={(value) => `$${value.toLocaleString()}`} />
                    <Legend />
                    <Bar dataKey="PENDING" stackId="b" fill={orange[500]} />
                    <Bar dataKey="WON" stackId="b" fill={green[500]} />
                    <Bar dataKey="LOST" stackId="b" fill={red[500]} />
                    <ReferenceLine y={averageValue} stroke="blue" strokeDasharray="3 3" label="Average Height" />
                  </BarChart>
                </ResponsiveContainer>
              </Paper>
            </Grid>

            {/* Dollar Value Distribution Pie Chart */}
            <Grid item xs={12} md={6}>
              {/* <Paper elevation={3} style={{ padding: '10px',fontSize:'13.5px' }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h8" gutterBottom>
                    Dollar Value Distribution for: <strong>{selectedYear} - {selectedContractVehicle}</strong>
                  </Typography>
                  <IconButton onClick={() => handleInfoButtonClick(getInfoContent('dollarDistribution'))}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={pieChartData}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      label={({ value }) => formatCurrency(value)}
                    >
                      {pieChartData.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend iconSize={10} layout="vertical" verticalAlign="middle" align="right" />
                    <Tooltip formatter={formatCurrency} />
                  </PieChart>
                </ResponsiveContainer>
              </Paper> */}
            </Grid>

            {/* Profit Margin Distribution Pie Chart */}
            <Grid item xs={12} md={6}>
              {/* <Paper elevation={3} style={{ padding: '10px',fontSize:'13.5px'  }}>
                <Stack direction="row" justifyContent="space-between" alignItems="center">
                  <Typography variant="h8" gutterBottom>
                    Total Profit Margin Distribution: <strong>{selectedYear} - {selectedContractVehicle}</strong>
                  </Typography>
                  <IconButton onClick={() => handleInfoButtonClick(getInfoContent('profitMarginDistribution'))}>
                    <InfoIcon />
                  </IconButton>
                </Stack>
                <ResponsiveContainer width="100%" height={250}>
                  <PieChart>
                    <Pie
                      dataKey="value"
                      data={pieChartDataProfitMargin}
                      cx="50%"
                      cy="50%"
                      innerRadius={60}
                      outerRadius={80}
                      fill="#8884d8"
                      label={({ value }) => formatCurrency(value)}
                    >
                      {pieChartDataProfitMargin.map((entry, index) => (
                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                      ))}
                    </Pie>
                    <Legend iconSize={10} layout="vertical" verticalAlign="middle" align="right" />
                    <Tooltip formatter={formatCurrency} />
                  </PieChart>
                </ResponsiveContainer>
              </Paper> */}
            </Grid>
        </Stack>
           <PerformanceGoalsMetricsDashboard
           user={Form_Value.Sales_Head_Email}
           contractVehicle={selectedContractVehicle}
           year={selectedYear}
           />
      

          </Stack>
    
      {/* Info Dialog for Chart Explanations */}
      <Dialog open={infoDialogOpen} onClose={handleInfoDialogClose}>
        <DialogTitle>
        <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontWeight:'bold',fontFamily:'arial'}}>
        Chart Explanation</Alert>
         </DialogTitle>
        <DialogContent sx={{fontSize:'12.5px'}}>
          {infoContent}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleInfoDialogClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default MyPerformanceDashboard;
