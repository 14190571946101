/**
 * Validates the headers of the uploaded Excel file to ensure all required fields are present.
 * Dynamically adds fields based on the number of years selected by the user.
 * 
 * @param {Array} headers - The array of column headers extracted from the Excel file.
 * @param {number} numYears - The number of years selected by the user.
 * @returns {Array|null} - Returns an array of missing fields if validation fails, otherwise returns null.
 */
const validateExcelFile = (headers, numYears) => {
    // Dynamically generate the expected fields based on the number of years
    const expectedFields = [
      'Part No', // Example required field
      'Description', // Example required field
      ...Array.from({ length: numYears }, (_, i) => `Year ${i + 1} Customer Cost`),
      ...Array.from({ length: numYears }, (_, i) => `Year ${i + 1} Company Cost`),
    ];
  
    // Find missing fields
    const missingFields = expectedFields.filter((field) => !headers.includes(field));
  
    // Return missing fields or null if validation passes
    return missingFields.length === 0 ? null : missingFields;
  };
  
  export default validateExcelFile