import React, { useState } from 'react';
import {
    Container, TextField, Button, Grid, Typography, Alert, IconButton, Stack, Divider, Checkbox,
    FormControlLabel, MenuItem, Select, FormControl, InputLabel, Rating
} from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import ApplicationBar from '../AppBar/ApplicationBar';
import { blueGrey, orange } from '@mui/material/colors';
import UserAuth from '../ProtectedRoute/userAuth';

const VendorPage = () => {
    const { UserEmail } = UserAuth();

    const [newVendor, setNewVendor] = useState({
        Vendor_ID: '',
        Vendor_Name: '',
        Primary_Contact: [{ name: '', jobTitle: '', email: '', phone: '' }],
        Addresses: [{ type: 'Office', street: '', city: '', state: '', zip: '' }],
        Tax_ID: '',
        Business_Type: '',
        Products_Provided: '',
        Payment_Terms: '',
        Preferred_Payment_Method: '',
        Bank_Account_Details: '',
        Credit_Limit: '',
        Contract_Agreement: null,
        Insurance_Compliance: null,
        Performance: { Quality_Rating: '', Delivery_Rating: '', Avg_Lead_Time: '', Discounts: '' },
        Notes: '',
        Created_By: UserEmail,
        Updated_By: UserEmail,
    });

    const [errors, setErrors] = useState({});
    const [submitError, setSubmitError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const [manualEntry, setManualEntry] = useState(false);
    const generateVendorID = () => {
        const acronym = newVendor.Vendor_Name.split(' ').map(word => word[0]).join('').toUpperCase().slice(0, 4);
        const randomID = Math.floor(1000 + Math.random() * 9000);
        const vendorID = `ASP-${acronym}-${randomID}`;
        setNewVendor({ ...newVendor, Vendor_ID: vendorID });
    };
    const validateForm = () => {
        let tempErrors = {};
        if (!newVendor.Vendor_Name) tempErrors.Vendor_Name = "Vendor Name is required";

        newVendor.Addresses.forEach((address, index) => {
            if (!address.street) tempErrors[`addressStreet${index}`] = "Street is required";
            if (!address.city) tempErrors[`addressCity${index}`] = "City is required";
            if (!address.state) tempErrors[`addressState${index}`] = "State is required";
            if (!address.zip) tempErrors[`addressZip${index}`] = "Zip Code is required";
        });

        setErrors(tempErrors);
        return Object.keys(tempErrors).length === 0;
    };

    const handleAddVendor = async () => {
        setSubmitError('');
        setSuccessMessage('');
        if (!validateForm()) return;

        try {
            setSuccessMessage('Vendor added successfully!');
            setNewVendor({
                Vendor_ID: '',
                Vendor_Name: '',
                Primary_Contact: [{ name: '', jobTitle: '', email: '', phone: '' }],
                Addresses: [{ type: 'Office', street: '', city: '', state: '', zip: '' }],
                Tax_ID: '',
                Business_Type: '',
                Products_Provided: '',
                Payment_Terms: '',
                Preferred_Payment_Method: '',
                Bank_Account_Details: '',
                Credit_Limit: '',
                Contract_Agreement: null,
                Insurance_Compliance: null,
                Performance: { Quality_Rating: '', Delivery_Rating: '', Avg_Lead_Time: '', Discounts: '' },
                Notes: '',
                Created_By: UserEmail,
                Updated_By: UserEmail,
            });
        } catch (error) {
            setSubmitError('Failed to add vendor. Please try again later.');
        }
    };

    const handleAddAddress = () => {
        setNewVendor({ ...newVendor, Addresses: [...newVendor.Addresses, { type: 'Office', street: '', city: '', state: '', zip: '' }] });
    };

    const handleRemoveAddress = (index) => {
        const newAddresses = [...newVendor.Addresses];
        newAddresses.splice(index, 1);
        setNewVendor({ ...newVendor, Addresses: newAddresses });
    };

    const handleAddPOCs = () => {
        setNewVendor({ ...newVendor, Primary_Contact: [...newVendor.Primary_Contact, { name: '', jobTitle: '', email: '', phone: '' }] });
    };

    const handleRemovePOCs = (index) => {
        const newPOCs = [...newVendor.Primary_Contact];
        newPOCs.splice(index, 1);
        setNewVendor({ ...newVendor, Primary_Contact: newPOCs });
    };



    const handleFileChange = (e, field) => {
        const file = e.target.files[0];
        setNewVendor({ ...newVendor, [field]: file });
    };

    return (
        <Container>
            <ApplicationBar />
            <Stack justifyContent="center" direction="row" sx={{ marginTop: '80px' }}>
                <Typography component="p" sx={{ fontSize: "25px", fontWeight: "bold" }}>New Vendor</Typography>
            </Stack>
            <Stack justifyContent="center" direction="row">
                <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px", marginBottom: '20px' }} />
            </Stack>

            {submitError && <Alert severity="error" style={{ marginBottom: '1rem' }}>{submitError}</Alert>}
            {successMessage && <Alert severity="success" style={{ marginBottom: '1rem' }}>{successMessage}</Alert>}

            {/* Vendor Information */}
            <Typography component="p" sx={{ fontSize: "18px", fontWeight: "bold", marginTop: '20px' }}>Vendor Information</Typography>
            <Grid container spacing={2}>
                <Grid item xs={10}>
                    <TextField
                        label="Vendor ID"
                        fullWidth
                        value={newVendor.Vendor_ID}
                        onChange={(e) => setNewVendor({ ...newVendor, Vendor_ID: e.target.value })}
                        InputProps={{ readOnly: !manualEntry }}
                    />
                </Grid>
                <Grid item xs={2}>
                    <Button variant="outlined" color="secondary" fullWidth onClick={generateVendorID} disabled={manualEntry}>
                        Generate ID
                    </Button>
                </Grid>
                <Grid item xs={12}>
                    <FormControlLabel
                        control={<Checkbox checked={manualEntry} onChange={(e) => setManualEntry(e.target.checked)} />}
                        label="Enter Manually"
                    />
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Vendor Name"
                        fullWidth
                        value={newVendor.Vendor_Name}
                        onChange={(e) => setNewVendor({ ...newVendor, Vendor_Name: e.target.value })}
                        error={Boolean(errors.Vendor_Name)}
                        helperText={errors.Vendor_Name}
                    />
                </Grid>
            </Grid>

            {/* Primary Contact */}
            <Typography component="p" sx={{ fontSize: "18px", fontWeight: "bold", marginTop: '20px' }}>Primary Contact</Typography>
            {newVendor.Primary_Contact.map((contact, index) => (
                <Grid container spacing={2} key={index}>
                    <Grid item xs={3}>
                        <TextField
                            label="Contact Name"
                            fullWidth
                            value={contact.name}
                            onChange={(e) => {
                                const updatedContacts = [...newVendor.Primary_Contact];
                                updatedContacts[index].name = e.target.value;
                                setNewVendor({ ...newVendor, Primary_Contact: updatedContacts });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Job Title"
                            fullWidth
                            value={contact.jobTitle}
                            onChange={(e) => {
                                const updatedContacts = [...newVendor.Primary_Contact];
                                updatedContacts[index].jobTitle = e.target.value;
                                setNewVendor({ ...newVendor, Primary_Contact: updatedContacts });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Email"
                            fullWidth
                            value={contact.email}
                            onChange={(e) => {
                                const updatedContacts = [...newVendor.Primary_Contact];
                                updatedContacts[index].email = e.target.value;
                                setNewVendor({ ...newVendor, Primary_Contact: updatedContacts });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="Phone"
                            fullWidth
                            value={contact.phone}
                            onChange={(e) => {
                                const updatedContacts = [...newVendor.Primary_Contact];
                                updatedContacts[index].phone = e.target.value;
                                setNewVendor({ ...newVendor, Primary_Contact: updatedContacts });
                            }}
                        />
                    </Grid>
                    {newVendor.Primary_Contact.length > 1 && (
                        <Grid item xs={1}>
                            <IconButton color="error" onClick={() => handleRemovePOCs(index)}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            ))}
            <Button startIcon={<AddCircleIcon />} onClick={handleAddPOCs} sx={{ mt: 2 }}>Add Contact</Button>

    

            {/* Addresses */}
            <Typography component="p" sx={{ fontSize: "18px", fontWeight: "bold", marginTop: '20px' }}>Addresses</Typography>
            {newVendor.Addresses.map((address, index) => (
                <Grid container spacing={2} key={index}>
          <Grid item xs={2}>
    <FormControl fullWidth>
        <InputLabel>Type</InputLabel>
        <Select
            label="Type"
            value={address.type}
            onChange={(e) => {
                const newAddresses = [...newVendor.Addresses];
                newAddresses[index].type = e.target.value;
                setNewVendor({ ...newVendor, Addresses: newAddresses });
            }}
        >
            <MenuItem value="Office">Office</MenuItem>
            <MenuItem value="Billing">Billing</MenuItem>
            <MenuItem value="Shipping">Shipping</MenuItem>
        </Select>
             </FormControl>
          </Grid>

                    <Grid item xs={3}>
                        <TextField
                            label="Street"
                            fullWidth
                            helperText={address.type==='Office'?'Office/Business Address - Main location for correspondence and visits'
                                :address.type==='Billing'?'Billing Address - Address for invoices or billing communications':'Shipping Address - Address for goods delivery'}
                            value={address.street}
                            onChange={(e) => {
                                const newAddresses = [...newVendor.Addresses];
                                newAddresses[index].street = e.target.value;
                                setNewVendor({ ...newVendor, Addresses: newAddresses });
                            }}
                        />
                    </Grid>
                    <Grid item xs={3}>
                        <TextField
                            label="City"
                            fullWidth
                            value={address.city}
                            onChange={(e) => {
                                const newAddresses = [...newVendor.Addresses];
                                newAddresses[index].city = e.target.value;
                                setNewVendor({ ...newVendor, Addresses: newAddresses });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="State"
                            fullWidth
                            value={address.state}
                            onChange={(e) => {
                                const newAddresses = [...newVendor.Addresses];
                                newAddresses[index].state = e.target.value;
                                setNewVendor({ ...newVendor, Addresses: newAddresses });
                            }}
                        />
                    </Grid>
                    <Grid item xs={2}>
                        <TextField
                            label="Zip Code"
                            fullWidth
                            value={address.zip}
                            onChange={(e) => {
                                const newAddresses = [...newVendor.Addresses];
                                newAddresses[index].zip = e.target.value;
                                setNewVendor({ ...newVendor, Addresses: newAddresses });
                            }}
                        />
                    </Grid>
                    {newVendor.Addresses.length > 1 && (
                        <Grid item xs={1}>
                            <IconButton color="error" onClick={() => handleRemoveAddress(index)}>
                                <RemoveCircleIcon />
                            </IconButton>
                        </Grid>
                    )}
                </Grid>
            ))}
            <Button startIcon={<AddCircleIcon />} onClick={handleAddAddress} sx={{ mt: 2 }}>
                Add Address
            </Button>

            {/* Financial Information */}
            <Typography component="p" sx={{ fontSize: "18px", fontWeight: "bold", marginTop: '20px' }}>Financial Information</Typography>
            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <TextField
                        label="Tax ID / VAT Number"
                        fullWidth
                        value={newVendor.Tax_ID}
                        onChange={(e) => setNewVendor({ ...newVendor, Tax_ID: e.target.value })}
                    />
                </Grid>
                <Grid item xs={4}>
                    <FormControl fullWidth>
                        <InputLabel>Business Type</InputLabel>
                        <Select
                            value={newVendor.Business_Type}
                            onChange={(e) => setNewVendor({ ...newVendor, Business_Type: e.target.value })}
                        >
                            <MenuItem value="Manufacturer">Manufacturer</MenuItem>
                            <MenuItem value="Distributor">Distributor</MenuItem>
                            <MenuItem value="Wholesaler">Wholesaler</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={4}>
                    <TextField
                        label="Products Provided"
                        fullWidth
                        value={newVendor.Products_Provided}
                        onChange={(e) => setNewVendor({ ...newVendor, Products_Provided: e.target.value })}
                    />
                </Grid>
            </Grid>

            {/* Performance and Document Uploads */}
            <Typography component="p" sx={{ fontSize: "18px", fontWeight: "bold", marginTop: '20px' }}>Performance & Document Uploads</Typography>
            <Grid container spacing={2}>
                <Grid item xs={3}>
                    <TextField
                        label="Quality Rating"
                        fullWidth
                        value={newVendor.Performance.Quality_Rating}
                        onChange={(e) => setNewVendor({ ...newVendor, Performance: { ...newVendor.Performance, Quality_Rating: e.target.value } })}
                        inputProps={{ type: 'number', min: 0, max: 5 }}
                    />
                    <Rating
                        value={parseFloat(newVendor.Performance.Quality_Rating) || 0}
                        max={5}
                        precision={0.5}
                        readOnly
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Delivery Rating"
                        fullWidth
                        value={newVendor.Performance.Delivery_Rating}
                        onChange={(e) => setNewVendor({ ...newVendor, Performance: { ...newVendor.Performance, Delivery_Rating: e.target.value } })}
                        inputProps={{ type: 'number', min: 0, max: 5 }}
                    />
                    <Rating
                        value={parseFloat(newVendor.Performance.Delivery_Rating) || 0}
                        max={5}
                        precision={0.5}
                        readOnly
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Average Lead Time"
                        fullWidth
                        value={newVendor.Performance.Avg_Lead_Time}
                        onChange={(e) => setNewVendor({ ...newVendor, Performance: { ...newVendor.Performance, Avg_Lead_Time: e.target.value } })}
                    />
                </Grid>
                <Grid item xs={3}>
                    <TextField
                        label="Discounts or Special Terms"
                        fullWidth
                        value={newVendor.Performance.Discounts}
                        onChange={(e) => setNewVendor({ ...newVendor, Performance: { ...newVendor.Performance, Discounts: e.target.value } })}
                    />
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                    >
                        Upload Contract Agreement
                        <input
                            type="file"
                            hidden
                            onChange={(e) => handleFileChange(e, 'Contract_Agreement')}
                        />
                    </Button>
                </Grid>
                <Grid item xs={6}>
                    <Button
                        variant="contained"
                        component="label"
                        fullWidth
                    >
                        Upload Insurance Compliance
                        <input
                            type="file"
                            hidden
                            onChange={(e) => handleFileChange(e, 'Insurance_Compliance')}
                        />
                    </Button>
                </Grid>
            </Grid>

            <Button variant="contained" color="primary" onClick={handleAddVendor} sx={{ mt: 3 }}>Add Vendor</Button>
        </Container>
    );
};

export default VendorPage;
