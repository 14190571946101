import React, { useState } from 'react';
import {
  Button, Dialog, DialogActions, DialogContent, DialogTitle,
  Checkbox, FormControlLabel, FormGroup, Box,Alert
} from '@mui/material';
import { blueGrey, orange, yellow } from '@mui/material/colors';
import SaveAlt from '@mui/icons-material/SaveAlt';
import * as XLSX from 'xlsx';

const FieldSelectionDialog = ({ open, onClose, onDownload }) => {
  const allFields = [
    'Opportunity Name', 'Quote No', 'Contract Vehicle', 'KO name', 'KO email', 'KO phone', 'KO address', 
    'Published Date', 'Due Date', 'Brief', 'Organization Name', 'Notes For PO', 'Customer Name', 
    'Customer Email', 'Customer Phone', 'Customer Address', 'Shipping Method', 'Sales Head Name', 
    'Sales Head Email', 'Sales Head Ph', 'Shipping Terms', 'Estimated Delivery', 'Payment Terms', 
    'Notes', 'Shipping Cost($)', 'Tax($)', 'Other Cost1($)', 'Other Cost2($)', 'Other Cost3($)', 
    'Other Cost Name1', 'Other Cost Name2', 'Other Cost Name3', 'Total Cost Before Margin($)', 
    'Total Cost After Margin($)', 'Created Date', 'Last Updated Date', 'Status', 'Updated By', 
    'Updating Comments',
  ];

  const [selectedFields, setSelectedFields] = useState(allFields);
  const [pickFields, setPickFields] = useState(false);

  const handleFieldChange = (field) => {
    setSelectedFields((prev) =>
      prev.includes(field)
        ? prev.filter((item) => item !== field)
        : [...prev, field]
    );
  };

  const handleAllFieldsToggle = () => {
    setPickFields(false);
    setSelectedFields(allFields);
  };

  const handlePickFieldsToggle = () => {
    setPickFields(true);
    setSelectedFields([]); // Reset selected fields
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle><Alert variant="filled"  sx={{ bgcolor: orange[300],color:blueGrey[900]}}>
        Excel Details
      </Alert></DialogTitle>
      <DialogContent>
        <FormGroup>
          <FormControlLabel
            control={
              <Checkbox
                checked={!pickFields}
                onChange={handleAllFieldsToggle}
              />
            }
            label="All Fields"
          />
          <FormControlLabel
            control={
              <Checkbox
                checked={pickFields}
                onChange={handlePickFieldsToggle}
              />
            }
            label="Pick Fields"
          />
        </FormGroup>
        {pickFields && (
          <Box sx={{ maxHeight: 300, overflowY: 'auto' }}>
            {allFields.map((field) => (
              <FormControlLabel
                key={field}
                control={
                  <Checkbox
                    checked={selectedFields.includes(field)}
                    onChange={() => handleFieldChange(field)}
                  />
                }
                label={field}
              />
            ))}
          </Box>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} sx={{color:orange[600]}} size='small'>Close</Button>
        <Button
          variant="contained"
          onClick={() => onDownload(selectedFields)}
          disabled={selectedFields.length === 0}
          size='small'
        >
          Download
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default FieldSelectionDialog