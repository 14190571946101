import React, { useEffect } from "react";
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import {
  TableBody,Paper,styled 
} from '@mui/material';
import Typography from '@mui/material/Typography';
import { useState} from "react";
import {Avatar, Button,Tooltip,Snackbar,TextField,Divider} from '@mui/material'
import { blueGrey, cyan, deepOrange, red } from '@mui/material/colors'
import { createTheme } from '@mui/material/styles';
import { Link,createSearchParams,useNavigate} from 'react-router-dom';
import { orange,indigo,yellow,green,blue,teal } from '@mui/material/colors';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import AddIcon from '@mui/icons-material/Add';    
import CloseIcon from '@mui/icons-material/Close'; 
import PhoneIcon from '@mui/icons-material/Phone'; 
import ContactMailIcon from '@mui/icons-material/ContactMail';
import PersonIcon from '@mui/icons-material/Person';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import TodayIcon from '@mui/icons-material/Today';
import EmailIcon from '@mui/icons-material/Email';
import ApartmentIcon from '@mui/icons-material/Apartment';
import DescriptionIcon from '@mui/icons-material/Description';
import CategoryIcon from '@mui/icons-material/Category';
import HourglassTopIcon from '@mui/icons-material/HourglassTop';
import CancelIcon from '@mui/icons-material/Cancel';
import { Stack } from "@mui/system";
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import SearchIcon from '@mui/icons-material/Search';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import UpdateIcon from '@mui/icons-material/Update';
import DoneAllIcon from '@mui/icons-material/DoneAll';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import FollowTheSignsIcon from '@mui/icons-material/FollowTheSigns';
import FindInPageIcon from '@mui/icons-material/FindInPage';
import GeneralEmailDialog from "../Email/KOorCustomerGeneralEmail";
import UserAuth from "../ProtectedRoute/userAuth";
import AxiosFunction from "../../axiosCustomInstance"; // Ensure you have your custom Axios instance
import LoadingDialog from "../Loading";
import RMAStages from "./SwipableView";
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[500],
    },
  },
});


const color1 = blueGrey[500];
const color2 = blueGrey[50];

function RMARow(props) {
  const { row, usersData,refreshData} = props;
  const navigate = useNavigate();

  const [areYouSureDialog, setAreYouSureDialog] = useState(false);
  const [isKOorCustomerGeneralDialogOpen, setIsKOorCustomerGeneralDialogOpen] = useState(false);
  const [toEmail, setToEmail] = useState('');
  const [isSnackbarOpen, setIsSnackbarOpen] = useState(false);
  const [alertMessage, setAlertMessage] = useState('');
  const [alertSeverity, setAlertSeverity] = useState('success');
  const [loading, setLoading] = useState(false);
  const [cancelledClicked, setCancelledClicked] = useState(false);
  const [copyRMAclicked, setCopyRMAclicked] = useState(false);
  const [copyALLRMA, setCopyALLRMA] = useState(false);
  const [copyRMAStages, setCopyRMAStages] = useState({RMA_Create:false,RMA_Investigation:false,RMA_Resolution:false,RMA_FollowUpCloseOut:false});
  const [copyRMADialog, setCopyRMADialog] = useState(false);
  const {UserName,UserEmail}=UserAuth()
  const AxiosAPIInstance = AxiosFunction(); // Initialize Axios instance
  const [cancelDetails, setCancelDetails] = useState({
    RMA_ID: '',
    Cancelled: false,
    CancellationReason: ''
  });
  const [activeTab, setActiveTab] = useState(0);
  const [isSwipableDialogOpen, setIsSwipableDialogOpen] = useState(false);
  const [editData, setEditData] = useState(null); // Store data for the dialog
  const handleClose = () => {
    setCancelledClicked(false);
    setCancelDetails({ RMA_ID: '', Cancelled: false, CancellationReason: '' });
  };

  const steps = ['RMA Created', 'Investigation', 'Resolution', 'Follow Up', 'Closed'];
  const stepsWithCancel = [...steps, 'Cancelled'];
  const steps2 = ['RMA Re-Opened', 'Investigation', 'Resolution', 'Follow Up', 'Closed'];
  const steps2WithCancel = [...steps2, 'Cancelled'];

  const stages = {
    rmacreated: { label: 'RMA Created', color: blue[700], icon: <DescriptionIcon /> },
    investigation: { label: 'Investigation', color: orange[700], icon: <FindInPageIcon /> },
    resolution: { label: 'Resolution', color: green[700], icon: <CheckCircleOutlineIcon /> },
    followup: { label: 'Follow Up', color: blue[700], icon: <FollowTheSignsIcon /> },
    closed: { label: 'Closed', color: red[700], icon: <CheckCircleIcon /> },
    cancelled: { label: 'Cancelled', color: orange[700], icon: <CancelIcon /> },
  };

  const CustomStepIcon = styled('div')(({ theme, active, completed }) => ({
    backgroundColor: active || completed ? orange[300] : 'rgba(0, 0, 0, 0.26)',
    color: blueGrey[900],
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: '50%',
    width: 24,
    height: 24,
    fontSize: 12,
    padding: 6,
  }));
 

  const handleRMACancelStatusUpdate = async (e, row) => {
    e.preventDefault(); // Prevent the default form submission

  
    // Ensure cancellation reason is provided
    if (!cancelDetails.CancellationReason.trim()) {
      alert("Please provide a reason for cancellation.");
      return;
    }
  
    try {
      // Prepare the payload for the backend
      const payload = {
        RMA_ID: row?.RMA_ID,
        Updated_By: UserEmail, // Replace with dynamic user data
        Cancellation_Description: cancelDetails.CancellationReason,
      };
  
      // Show loading indicator (optional)
      setLoading(true);
  
      // Send the request to the backend
      const response = await AxiosAPIInstance.patch("/RMAs/CancelRMA", payload);
  
      if (response.status === 200) {
        // Success: Notify the user
        alert(`RMA ${row?.RMA_ID} has been successfully cancelled.`);
        // Optionally close the dialog and refresh the data
        setCancelledClicked(false);
        refreshData(); // Implement your own function to refresh the data
      } else {
        // Unexpected response status
        console.error("Unexpected response:", response);
        alert("Something went wrong. Please try again later.");
      }
    } catch (error) {
      // Error handling
      console.error("Error cancelling RMA:", error);
      if (error.response) {
        // Backend provided an error message
        alert(error.response.data || "An error occurred while cancelling the RMA.");
      } else {
        // General error
        alert("Unable to connect to the server. Please check your network connection.");
      }
    } finally {
      // Reset loading indicator (optional)
      setLoading(false);
    }
  };
  
  const renderStepper = (steps, activeStep) => {
    // Determine stages to render for cancelled RMAs or non-cancelled RMAs
    const stagesToRender =
      row?.Resolution_Stage === "Cancelled"
        ? stepsWithCancel.slice(0, stepsWithCancel.indexOf(row?.Stage_Before_Cancellation) + 1).concat("Cancelled")
        : steps;
  
    // Determine the active step for the stepper
    const activeStepIndex =
      row?.Resolution_Stage === "Cancelled"
        ? stagesToRender.indexOf("Cancelled")
        : activeStep;
  
        return (
          <Stepper activeStep={activeStepIndex} alternativeLabel>
            {stagesToRender.map((label) => {
              const stageKey = label.toLowerCase().replace(" ", "");
              const stage = stages[stageKey];
              return (
                <Step key={label}>
                  <StepLabel
                    StepIconComponent={(props) => (
                      <CustomStepIcon {...props}>
                        {stage ? stage.icon : <div>?</div>}
                      </CustomStepIcon>
                    )}
                  >
                    {label}
                  </StepLabel>
                </Step>
              );
            })}
          </Stepper>
        );
      };

  // const RMAID_Search = async (RMARow) => {
  //   const pathMap = {
  //     "RMA Created": "/Dashboard/EditRMA",
  //     "Investigation": "/Dashboard/RMAInvestigation",
  //     "Resolution": "/Dashboard/RMAResolution",
  //     "Follow Up": "/Dashboard/RMACloseOut",
  //     "Closed": "/Dashboard/EditRMA",
  //     "Cancelled": "/Dashboard/EditRMA",
  //   };
  //   const targetPath = pathMap[RMARow?.Resolution_Stage] || pathMap[RMARow?.ReOpened_Flag];
  //   if (targetPath) {
  //     navigate({
  //       pathname: targetPath,
  //       search: createSearchParams({ RMAID: RMARow?.RMA_ID }).toString()
  //     });
  //   }
  // };
  const handleDialogClose = () => {
    setIsSwipableDialogOpen(false);
  };

  const handleDialogOpen = () => {
    setIsSwipableDialogOpen(true);
  };
  const handleAreYouSureDialogClose = (e) => {
    e.preventDefault();
    setAreYouSureDialog(false);
  };
  const handleCopyRMADialogClose = (e) => {
    e.preventDefault();
    setCopyRMADialog(false);
  };
  const handleSnackbarClose = () => {
    setIsSnackbarOpen(false);
  };

  const handleKoOrCustomerGeneralEmailSuccess = () => {
    setAlertMessage('Email sent successfully');
    setAlertSeverity('success');
    setIsSnackbarOpen(true);
    setIsKOorCustomerGeneralDialogOpen(false);
  };

  const handleDialogError = (error) => {
    setAlertMessage(`Error: ${error.message}`);
    setAlertSeverity('error');
    setIsSnackbarOpen(true);
  };

  const handleOpenKOorCustomerGeneralEmailOpenDialog = (e) => {
    e.preventDefault();
    setIsKOorCustomerGeneralDialogOpen(true);
  };

  return (
    <React.Fragment>
      <LoadingDialog open={loading} />
      {areYouSureDialog && (
        <Dialog
          open={areYouSureDialog}
          onClose={handleAreYouSureDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity='warning'>Are You Sure You Want to Re-Open?</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>Things to Remember:</b><br />
              1. Revision Number will be updated.<br />
              2. Once submitted, it can only be accessed from Retrieve/Update RMAs Tab.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => { handleDialogOpen();setAreYouSureDialog(false) }}>YES</Button>
            <Button onClick={(e) => { e.preventDefault(); setAreYouSureDialog(false) }}>NO</Button>
          </DialogActions>
        </Dialog>
      )}
     {copyRMADialog && (
        <Dialog
          open={copyRMADialog}
          onClose={handleCopyRMADialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity='warning'>Are You Sure You Want to Re-Open?</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              <b>Things to Remember:</b><br />
              1. Revision Number will be updated.<br />
              2. Once submitted, it can only be accessed from Retrieve/Update RMAs Tab.
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={(e) => { handleDialogOpen();setCopyRMADialog(false) }}>YES</Button>
            <Button onClick={(e) => { e.preventDefault(); setCopyRMADialog(false) }}>NO</Button>
          </DialogActions>
        </Dialog>
      )}
{/* Dialog with Swipeable Views */}
{isSwipableDialogOpen && (
        <Dialog
          open={isSwipableDialogOpen}
          onClose={handleDialogClose}
           maxWidth='lg'
          fullWidth
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert variant="filled" sx={{ bgcolor: orange[200],color:blueGrey[900],fontSize:'15px',fontFamily:'arial'}}>
           {row?.RMA_ID} (Current Stage: {row?.Resolution_Stage})
                </Alert>
          </DialogTitle>
          <DialogContent>
            <RMAStages RMARow={row} refreshData={refreshData} />
            {/* {RMAID_Search(row)} */}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

{cancelledClicked===true&&(<Dialog
    key={row}
    open={cancelledClicked}
    maxWidth='lg'
    sx={{justifyContent:"stretch"}}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description" >
    <DialogTitle id="alert-dialog-title">
    <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>{row?.Agency_Name +'---'+row?.Product_Service+'('+row?.RMA_ID+')'}</Alert>
    </DialogTitle>
    <DialogContent sx={{flexGrow:'inherit',justifyContent:'inherit'}} >
      <DialogContentText id="alert-dialog-description">
      <Stack sx={{textAlign:"center",alignItems:"center",marginTop:'20px'}} justifyContent='center' direction='column' spacing={3}>
        <Stack sx={{textAlign:"center",alignItems:"center"}} direction='row' justifyContent='center' spacing={4}>
        <TextField  size='small' fullWidth sx={{width:'400px'}} value={cancelDetails.CancellationReason} label='Cancellation Reason' multiline={true} minRows={5}
         required name='Cancellation Reason' onChange={(e)=>setCancelDetails({...cancelDetails,CancellationReason:e.target.value})}></TextField>
        </Stack>
        <Stack sx={{textAlign:"center",alignItems:"center"}} justifyContent='center' direction='row' spacing={4}>
     </Stack>
     <Stack>
    <Divider sx={{color:orange[900],bgcolor:red[900],width:"100%",height:"1px"}} orientation='horizontal'></Divider>
    </Stack>

      </Stack>
      </DialogContentText>
    </DialogContent>
    <DialogActions>
    <Button
  name="Update"
  variant="contained"
  size="small"
  onClick={(e) => handleRMACancelStatusUpdate(e, row)}
>
  Update
</Button>
     <Button name="Cancel" variant='outlined' color='warning' size='small'
     onClick={(e,row)=>{
handleClose()
      }}> Cancel </Button>
    </DialogActions>
  </Dialog>)}
        
      {isKOorCustomerGeneralDialogOpen && (
        <>
          <GeneralEmailDialog
            row={row}
            onSuccess={handleKoOrCustomerGeneralEmailSuccess}
            onError={handleDialogError}
            usersData={usersData}
            toEmail={toEmail}
            loading={loading}
            setLoading={setLoading}
            setIsKOorCustomerGeneralDialogOpen={setIsKOorCustomerGeneralDialogOpen}
            isKOorCustomerGeneralDialogOpen={isKOorCustomerGeneralDialogOpen}
            defaultSubject={`Return Merchandise Authorization (${row?.RMA_ID})----(${row?.Agency_Name})---${row.Initial_Cause}`}
            DialogBoxTitle="Return Merchandise Authorization"
            DialogBoxTitleID={row.RMA_ID}
          />
          <Snackbar
            open={isSnackbarOpen}
            autoHideDuration={6000}
            onClose={handleSnackbarClose}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          >
            <Alert onClose={handleSnackbarClose} severity={alertSeverity} sx={{ width: '100%' }}>
              {alertMessage}
            </Alert>
          </Snackbar>
        </>
      )}

<TableRow>
 
 <TableCell sx={{}}align="center">{row?.RMA_ID}</TableCell>
 <TableCell sx={{}}align="left">

<Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1,fontSize:'12.5px' }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', ffontSize:'12.5px',alignContent:'center'}}><ApartmentIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Agency Name</Typography>

<Typography variant="body1" sx={{ fontSize:'12.5px',fontWeight:'bold',marginLeft:3.5,color:blue[900]}}>{row?.Agency_Name}</Typography>
</div>
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px'}}><CategoryIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Product/Service</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Product_Service}</Typography>
</div>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
<div style={{ width: '100%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><DescriptionIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Detailed Description</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Detailed_Description}</Typography>
</div>
<div style={{ width: '100%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><DescriptionIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Manufacturer RMA Number</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Manufacturer_RMA_ID}</Typography>
</div>
</div>

<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Name</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Name}</Typography>
</div>
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px'}}><AlternateEmailIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Email</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Email}</Typography>
</div>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PhoneIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Phone</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:3.5 }}>{row?.Customer_Phone}</Typography>
</div>
<div style={{ width: '55%' }}> {/* Adjusted width */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><ContactMailIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Customer Address</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:3.5}}>{row?.Customer_Address}</Typography>
</div>
</div>
</Paper>
   </TableCell>
 <TableCell sx={{}}align="Left">
 <Paper elevation={3} sx={{ borderRadius: 1, padding: 2, border: 1 }}>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Created By</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{row?.Completed_By_Email}</Typography>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<Typography variant="body1" sx={{ fontWeight: 'bold',fontSize:'12.5px' }}><TodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Created On</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{String(row?.Created_Date).split('T')[0]}</Typography>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><PersonIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Updated By</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2 }}>{row?.Updated_By}</Typography>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between' }}>
<Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><TodayIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Updated On</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px',marginLeft:2}}>{String(row?.Last_Updated_Date).split('T')[0]}</Typography>
</div>
<div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginTop: '8px' }}> {/* Added marginTop */}
{/* <Typography variant="body1" sx={{ fontWeight: 'bold', fontSize:'12.5px' }}><HourglassTopIcon sx={{color:orange[900],height:'20px',width:'20px'}}/> Re-Opened</Typography>
<Typography variant="body1" sx={{ fontSize:'12.5px' ,marginLeft:2}}>{row?.ReOpened_Rev===null?0:row?.ReOpened_Rev+" times"}</Typography> */}
</div>
</Paper>
</TableCell>
        <TableCell align="center">
          {row?.Resolution_Stage !== "RMA Reopened" && (
            <Box sx={{ width: '100%' }}>
              {row?.Cancelled
                ? renderStepper(stepsWithCancel, stepsWithCancel.indexOf(row?.Resolution_Stage))
                : renderStepper(steps, steps.indexOf(row?.Resolution_Stage))}
            </Box>
          )}
          <Stack direction="row" spacing={2} sx={{ marginTop: '10px' }}>
            <Tooltip title={row?.Resolution_Stage === "Closed" ? "Re-Open" : row?.Resolution_Stage === "Cancelled" ?"Re-Open" : "Edit"}>
              <IconButton
                onClick={(e) => {
                  e.preventDefault();
                  if (row?.Resolution_Stage === "Closed" ||row?.Resolution_Stage === "Cancelled" ) {
                    setAreYouSureDialog(true);
                    
                  } else {
                    // RMAID_Search(row);
                    handleDialogOpen()
                  }
                }}
              >
                <Avatar sx={{
                  backgroundColor: row?.Resolution_Stage === "Closed" ? red[800] : row?.Resolution_Stage === "Cancelled" ? red[800] : indigo[800],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: row?.Resolution_Stage === "Closed" ? red[400] : row?.Resolution_Stage === "Cancelled" ? red[800] : indigo[400],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }}
                variant='rounded'>
                  <ModeEditIcon sx={{ width: "20px", height: "20px" }} />
                  {row?.Resolution_Stage === "Closed" ? "Re-Open" :row?.Resolution_Stage === "Cancelled" ? "Re-Open" : "Update"}
                </Avatar>
              </IconButton>
            </Tooltip>
            {row?.Resolution_Stage !== "Closed" && row?.Resolution_Stage !== "Cancelled" &&(
              <Tooltip title="Cancel">
                <IconButton onClick={() =>
                   setCancelledClicked(true)
                   }>
                  <Avatar sx={{
                    backgroundColor: blue[700],
                    ":hover": {
                      boxShadow: "10px 10px 20px #ccc",
                      backgroundColor: blue[500],
                    },
                    width: "80px",
                    fontSize: '12.5px',
                    height: '25px'
                  }}
                  variant='rounded'>
                    <CancelIcon sx={{ width: "20px", height: "20px" }} />Cancel
                  </Avatar>
                </IconButton>
              </Tooltip>
            )}
            <Tooltip title="Clone">
              <IconButton onClick={(e) => { e.preventDefault(); }}>
                <Avatar sx={{
                  backgroundColor: cyan[900],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: cyan[700],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }}
                variant='rounded'>
                  <ContentCopyIcon sx={{ width: "20px", height: "20px" }} />Clone
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="Email Customer">
              <IconButton
                onClick={(e) => {
                  handleOpenKOorCustomerGeneralEmailOpenDialog(e);
                  setToEmail(row?.Customer_Email);
                }}
              >
                <Avatar sx={{
                  backgroundColor: indigo[900],
                  ":hover": {
                    boxShadow: "10px 10px 20px #ccc",
                    backgroundColor: indigo[700],
                  },
                  width: "80px",
                  fontSize: '12.5px',
                  height: '25px'
                }}
                variant="rounded">
                  <EmailIcon sx={{ color: 'white', width: '20px', height: '20px' }} />Email
                </Avatar>
              </IconButton>
            </Tooltip>
          </Stack>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default RMARow;