import React from "react";
import { Box, Button, TextField, Paper, Stack, Typography, AppBar, Toolbar, IconButton, Menu, MenuItem, Divider } from '@mui/material';
import EmailIcon from '@mui/icons-material/Email';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState } from "react";
import ContactPhoneIcon from '@mui/icons-material/ContactPhone';
import PersonIcon from '@mui/icons-material/Person';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { DatePicker } from "@mui/x-date-pickers";
import DescriptionIcon from '@mui/icons-material/Description';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Alert } from '@mui/material'
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import { orange, indigo, blue, blueGrey, red } from '@mui/material/colors';
import AddTaskIcon from '@mui/icons-material/AddTask';
import userAuth from "../ProtectedRoute/userAuth"
import LoadingDialog from "../Loading";

function NewRMA() {

  const PD = new Date()
  const AxiosAPIInstance = AxiosFunction()
  const {
    UserName,
    UserEmail
  } = userAuth()

  const [Form_Value, Set_Form_Value] = useState({
    Agency_Name: "", RMA_ID: "", Detailed_Description: "", Initial_Cause: "", Purchase_Order: "",
    Customer_Name: "", Completed_By_Name: UserName, Completed_By_Email: UserEmail, Customer_Email: "", Completed_By_Phone: "540-547-8487", Customer_Phone: "", Customer_Address: "", Shipping_To_Address: "", Return_Date: null, Reported_Date: null,
    Tracking_Number: "",
    Manufacturer_RMA_ID: "",
    Product_Service: "",
    Resolution_Stage: "RMA Created",
    Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
    Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
    Updated_By: UserEmail,
    Version_No: parseInt(0),
    ReOpened_Rev: parseInt(0),
    ReOpened_Flag: "RMA Reopened",
    Updating_Comments: "RMA Created"
  })


  const [dis, setDis] = useState(false)
  const navigate = useNavigate()
  const [rfiMssge, setRFIMssge] = useState('')
  const [successDialog, setSuccessDialog] = useState(false)
  const [errorDialog, setErrorDialog] = useState(false)
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  const handleClose = (e) => {
    setOpen(false);
    setErrorDialog(false)
    setSuccessDialog(false)
  };
  const HandleSubmit = async (event) => {
    setDis(true)
    setLoading(true)
    event.preventDefault()
    await AxiosAPIInstance.post("/RMAs/CreateRMA", Form_Value).then(
      async (response) => {
        setLoading(false)
        setSuccessDialog(true)
        setErrorDialog(false)
        setRFIMssge(response?.data)
        setDis(false)
        Set_Form_Value({
          ...Form_Value, Agency_Name: "", RMA_ID: "", Detailed_Description: "", Initial_Cause: "", Purchase_Order: "",
          Customer_Name: "", Completed_By_Name: UserName, Completed_By_Email: UserEmail, Customer_Email: "", Completed_By_Phone: "", Customer_Phone: "", Customer_Address: "", Shipping_To_Address: "", Return_Date: null, Reported_Date: null,
          Tracking_Number: "",
          Manufacturer_RMA_ID: "",
          Product_Service: "",
          Resolution_Stage: "RMA Created",
          Created_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
          Last_Updated_Date: (String(Number(PD.getUTCMonth()) + 1) + "-" + String(PD.getUTCDate()) + "-" + String(PD.getUTCFullYear())),
          Updated_By: "TBD",
          Version_No: parseInt(0),
          Updating_Comments: "RMA Created"
        })
      }
    ).catch((error) => {
      setLoading(false)
      setSuccessDialog(false)
      setErrorDialog(true)
      setRFIMssge(error.response?.data)
      setDis(false)
    })
    setLoading(false)
  }

  return (
    <>
      <LoadingDialog open={loading} />
      {successDialog === true && (<Dialog
        open={successDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false)
          }
          } href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      {errorDialog === true && (<Dialog
        open={errorDialog}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {rfiMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description" sx={{ textAlign: 'center' }}>
            {rfiMssge}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button name="Yes" onClick={(e) => {
            handleClose();
            e.preventDefault();
            setDis(false);
          }} href="/Dashboard/NewRMA">OK</Button>
        </DialogActions>
      </Dialog>)}
      <ApplicationBar></ApplicationBar>
      <Stack justifyContent="center" sx={{ marginTop: "80px" }}>
        <Stack direction="column" justifyContent="center" spacing={1.5}>
        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: blueGrey[900],
          }}>
               Return Merchandise Authorization (RMAs)
        </Typography>
     
       
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" sx={{ width: "250px" }} required label="Organization/Agency's Name" value={Form_Value.Agency_Name} onChange={(e) => Set_Form_Value({ ...Form_Value, Agency_Name: e.target.value })} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <BusinessIcon />
                </InputAdornment>
              ),
            }}></TextField>
            <TextField size="small" variant="standard" label="Customer's Name" sx={{ width: "250px" }} value={Form_Value.Customer_Name} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Customer_Name: e.target.value })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ),
              }}></TextField>
            <TextField size="small" required variant="standard" sx={{ width: "250px" }} type="email" label="Customer's Email" value={Form_Value.Customer_Email}
              onChange={(e) => {
                Set_Form_Value({ ...Form_Value, Customer_Email: e.target.value })
              }
              } InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ),
              }}>
            </TextField>
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" multiline={true} sx={{ width: "250px" }} rowsMax={20} label="Purchase Order" value={Form_Value.Brief} onChange={(e) => Set_Form_Value({ ...Form_Value, Brief: e.target.value })} InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <DescriptionIcon />
                </InputAdornment>
              ),
            }}></TextField>
            <TextField size="small" variant="standard" required sx={{ width: "250px" }} label="Customer'sPhone" value={Form_Value.Customer_Phone} onChange={(e) => {
              Set_Form_Value({ ...Form_Value, Customer_Phone: e.target.value })
            }
            } InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <ContactPhoneIcon />
                </InputAdornment>
              ),
            }}></TextField>
            <TextField size="small" required variant="standard" sx={{ width: "250px" }} multiline={true} rowsMax={10} label="Customer's Address" value={Form_Value.Customer_Address}
              onChange={(e) => {
                Set_Form_Value({ ...Form_Value, Customer_Address: e.target.value })
              }
              } InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactMailIcon />
                  </InputAdornment>
                ),
              }}>
            </TextField>

          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" required variant="standard" sx={{ width: "250px" }} multiline={true} rowsMax={10} label="Shipping Address" value={Form_Value.Shipping_To_Address}
              onChange={(e) => {
                Set_Form_Value({ ...Form_Value, Shipping_To_Address: e.target.value })
              }
              } InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ContactMailIcon />
                  </InputAdornment>
                ),
              }}>
            </TextField>
            <TextField size="small" variant="standard" sx={{ width: "250px" }} label="Product/Service" value={Form_Value.Product_Service} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Product_Service: e.target.value })}></TextField>
            <TextField size="small" variant="standard" sx={{ width: "250px" }} multiline={true} rowsMax={20} label="Initial Cause" value={Form_Value.Initial_Cause} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Initial_Cause: e.target.value })}></TextField>

          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" sx={{ width: "800px" }} label="Detailed Problem Description" multiline={20} minRows={3} value={Form_Value.Detailed_Description} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Detailed_Description: e.target.value })}
              InputProps={{
                startAdornment: (<InputAdornment position="start">
                  <DescriptionIcon />
                </InputAdornment>)
              }}>
            </TextField>
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" multiline={true} sx={{ width: "250px" }} rowsMax={20} label="Tracking Number" value={Form_Value.Tracking_Number} onChange={(e) => Set_Form_Value({ ...Form_Value, Tracking_Number: e.target.value })}></TextField>
            <DatePicker label="Return Date" value={Form_Value.Return_Date} renderInput={(params) => <TextField size="small" variant="standard" sx={{ width: "250px" }} {...params} />} onChange={(value) => Set_Form_Value({ ...Form_Value, Return_Date: value })}>
            </DatePicker>
            <DatePicker label="Reported Date" value={Form_Value.Reported_Date} sx={{ width: "250px" }} renderInput={(params) => <TextField size="small" variant="standard" required sx={{ width: "250px" }} {...params} />} onChange={(value) => Set_Form_Value({ ...Form_Value, Reported_Date: value })}>
            </DatePicker>

          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" multiline={true} sx={{ width: "250px" }} rowsMax={20} label="Manufacturer RMA Number" value={Form_Value.Manufacturer_RMA_ID} onChange={(e) => Set_Form_Value({ ...Form_Value, Manufacturer_RMA_ID: e.target.value })}></TextField>
          </Stack>
          <Stack direction="row" justifyContent="center">
            <Typography component="p" sx={{ alignContent: "center", borderColor: 'divider', fontSize: "19px", fontWeight: "bold", padding: 1 }}>Completed By</Typography>
          </Stack>

          <Stack direction="row" spacing={3} justifyContent="center">
            <TextField size="small" variant="standard" label="Name" sx={{ width: "250px" }} value={UserName} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Completed_By_Name: UserEmail })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PersonIcon />
                  </InputAdornment>
                ), readOnly: "true"
              }}></TextField>
            <TextField size="small" variant="standard" label="Email" sx={{ width: "300px" }} value={UserEmail} required={true} onChange={(e) => Set_Form_Value({ ...Form_Value, Completed_By_Email: UserEmail })}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon />
                  </InputAdornment>
                ), readOnly: "true"
              }}></TextField>
          </Stack>
          <Stack>

          </Stack>
        </Stack >
        <Stack direction="row" justifyContent="center">
          <Button sx={{ width: "200px", bgcolor: [indigo[700]],":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[indigo[500]]},height:'35px'}} size='small' variant="contained" type="submit" onClick={HandleSubmit} disabled={dis}>Create</Button>
        </Stack>

      </Stack>
    </>
  );
}
export default NewRMA