import * as XLSX from 'xlsx';

/**
 * Generates and downloads an Excel template for SKU data.
 * 
 * @param {number} numYears - Number of years for which the template is generated.
 */
const downloadTemplateExcel = (numYears) => {
  if (!numYears || numYears <= 0) {
    console.error('Number of years must be greater than 0.');
    return;
  }

  // const demoData = [
  //   {
  //     'Vendor/Partner Name': '',
  //     'Part No': '',
  //     'Description': '',
  //     'Unit Of Measure': 'ea',
  //     ...Object.fromEntries(
  //       Array.from({ length: numYears }, (_, i) => [
  //         `Year ${i + 1} Customer Cost`,
  //         `Year ${i + 1} Company Cost`,
  //         `Year ${i + 1} Margin Percent`,
  //         '',
  //       ])
  //     ),
  //   },
  // ];
  const demoData = [
    {
      'Vendor/Partner Name': '',
      'Part No': '',
      'Description': '',
      'Unit Of Measure': 'ea',
      ...Object.fromEntries(
        Array.from({ length: numYears }, (_, i) => [
          `Year ${i + 1} Customer Cost`,
          '',
        ])
      ),
      ...Object.fromEntries(
        Array.from({ length: numYears }, (_, i) => [
          `Year ${i + 1} Company Cost`,
          '',
        ])
      ),
      ...Object.fromEntries(
        Array.from({ length: numYears }, (_, i) => [
          `Year ${i + 1} Margin Percent`,
          '',
        ])
      ),
    },
  ];
  const worksheet = XLSX.utils.json_to_sheet(demoData);
  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, 'SKUs');

  XLSX.writeFile(workbook, 'SKU_Upload_Template.xlsx');
};

export default downloadTemplateExcel;
