import React, { useState, useEffect,useMemo} from 'react';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  IconButton,
  Typography,
  Dialog,
  DialogContent,
  DialogTitle,
  Button,
  Tooltip,
  TextField,
  CircularProgress,
  Box,
  InputAdornment,Avatar,Alert,
  DialogActions,DialogContentText
} from '@mui/material';
import {
  Edit as EditIcon,
  History as HistoryIcon,
  Visibility as VisibilityIcon,
  Info as InfoIcon,
  Search as SearchIcon,
  AddCircleOutline,
} from '@mui/icons-material';
import AxiosFunction from '../../../axiosCustomInstance'; // Replace with your Axios API instance
import AddContractTypeDialog from './NewContractType';
import ApplicationBar from '../../AppBar/ApplicationBar';
import { blue, blueGrey, green, orange } from '@mui/material/colors';
import { Stack } from '@mui/system';
import SKUManagement from './SKUsLandingPage';
import UserAuth from '../../ProtectedRoute/userAuth';
import EditContractTypeDialog from './EditContractType';

const ContractTypesLandingPage = () => {
  const [contractTypes, setContractTypes] = useState([]);
  const [filteredContractTypes, setFilteredContractTypes] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [openNewContractDialog, setOpenNewContractDialog]=useState(false);
  const [openHistoryDialog, setOpenHistoryDialog] = useState(false);
  const [openContractTypeInfoDialog, setContractTypeInfoDialog] = useState(false);
  const [openSkusDialog, setOpenSkusDialog] = useState(false);
  const [selectedContract, setSelectedContract] = useState(null);
  const [errorSuccessMssge, setErrorSuccessMssge] = useState('');
  const {UserName,UserEmail}=UserAuth()
  const [rfqMssge, setRFQMssge] = useState('')  // State variable for RFQ message
  const [successDialog, setSuccessDialog] = useState(false)  // State variable for success dialog status
  const [errorDialog, setErrorDialog] = useState(false)  // State variable for error dialog status   
    // State variable to store a mapping of user emails to assigned colors for avatars.
    const [userColors, setUserColors] = useState({});
    // Function to assign a random color to a user based on their email.
  // This color is used to create a unique avatar color for each user.
  const assignColorToUser = (userEmail) => {
    if (!userColors[userEmail]) { // If the user doesn't have a color assigned yet
      setUserColors(prevColors => ({ ...prevColors, [userEmail]: getRandomColor() })); // Assign a new random color
    }
    return userColors[userEmail]; // Return the assigned color
  };
// Function to generate a random color
const generateRandomColors = (contracts) => {
  return contracts.reduce((acc, contract) => {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor((Math.random() * contract.contractTypeID.charCodeAt(0)) % 16)];
    }
    acc[contract.id] = color;
    return acc;
  }, {});
};

const getRandomColor = (existingColors = []) => {
  const letters = 'CDEF'; // Use only the highest range for lighter shades
  
  // Convert a hex color to RGB
  const hexToRgb = (hex) => {
    const bigint = parseInt(hex.slice(1), 16);
    return [
      (bigint >> 16) & 255, // Red
      (bigint >> 8) & 255,  // Green
      bigint & 255,         // Blue
    ];
  };

  // Calculate color difference using Euclidean distance
  const colorDifference = (rgb1, rgb2) => {
    return Math.sqrt(
      Math.pow(rgb1[0] - rgb2[0], 2) +
      Math.pow(rgb1[1] - rgb2[1], 2) +
      Math.pow(rgb1[2] - rgb2[2], 2)
    );
  };

  let newColor;

  do {
    // Generate a random lighter color
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * letters.length)];
    }
    newColor = color;

    // Convert existing colors to RGB
    const existingRgbColors = existingColors.map(hexToRgb);

    // Convert new color to RGB
    const newRgb = hexToRgb(newColor);

    // Check if the new color is too close to existing ones
    const isTooSimilar = existingRgbColors.some((existingRgb) => {
      return colorDifference(existingRgb, newRgb) < 50; // Threshold for similarity
    });

    if (!isTooSimilar) break; // Accept the color if it is not too similar
  } while (true);

  return newColor;
};


  const AxiosAPIInstance=AxiosFunction()
  React.useEffect(() => {
    const fetchContractTypes = async () => {
      try {
        setLoading(true);
        const response = await AxiosAPIInstance.get('/ContractTypes/getContractTypes');
        setContractTypes(response?.data || []);
        setFilteredContractTypes(response?.data || []);
        setErrorSuccessMssge('Contract Types Loaded Successfully');
      } catch (error) {
        console.error('Error loading contract types:', error);
        setErrorSuccessMssge('Contract Types Loading Failed');
      } finally {
        setLoading(false);
      }
    };

    fetchContractTypes();
  }, [successDialog]);

  const handleSearch = (event) => {
    const term = event.target.value.toLowerCase();
    setSearchTerm(term);
    setFilteredContractTypes(
      contractTypes.filter((contract) =>
        contract.name.toLowerCase().includes(term)
      )
    );
  };


const bgColors = useMemo(() => generateRandomColors(filteredContractTypes), [filteredContractTypes]);

  const handleEditClick = (contract) => {
    setSelectedContract(contract);
    setOpenEditDialog(true);
  };

  const handleAddNewContractClick = () => {
    setOpenNewContractDialog(true);
  };
  
  const handleHistoryClick = (contract) => {
    setSelectedContract(contract);
    setOpenHistoryDialog(true);
  };

 const handleInfoClick=(contract)=>{
  setContractTypeInfoDialog(true)
    setSelectedContract(contract);
 }
  const handleSkusClick = (contract) => {
    setSelectedContract(contract);
    setOpenSkusDialog(true);
  };

  const handleDialogClose = () => {
    setOpenEditDialog(false);
    setOpenHistoryDialog(false);
    setOpenSkusDialog(false);
    setSelectedContract(null);
    setContractTypeInfoDialog(false)
    setSuccessDialog(false)
    setErrorDialog(false)
    setOpenNewContractDialog(false);
  };
  // Save new contract type
  const handleSaveNewContractType = async(data) => {
    setLoading(true)
    if (!data?.newContractType) {
      setRFQMssge('Contract type cannot be empty');
      return;
    }
  
    await AxiosAPIInstance.post('/ContractTypes/New', 
      { 
        contractTypeDescription:data?.contractTypeDescription,
        contractTypeName:data?.newContractType, 
        createdByName:UserName, 
        quoteNoLetterCode:data?.quoteNoLetterCode,
        quoteNoLetterCodeDescription:'NA',
        contractTypeEmailExtension:'NA',
        createdByEmail:String(UserEmail).trim().toLocaleLowerCase()
        })
      .then(
        async(response) => {
        setContractTypes([...contractTypes, data?.newContractType]);
        setRFQMssge(response?.data)
        setSuccessDialog(true)
        setErrorDialog(false)
        setLoading(false)
        
      })
      .catch(
        async(error) => {
        setRFQMssge(error?.response?.data)
        setSuccessDialog(false)
        setErrorDialog(true)
        setLoading(false)
        console.error(error);
      });
  };
  return (
    <>
    {successDialog === true && (
  <Dialog
    open={successDialog}
    onClose={handleDialogClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying a success alert if 'rfqMssge' is available */}
      {rfqMssge && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying message */}
        {rfqMssge}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog and reset state */}
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        handleDialogClose();
      }} >OK</Button>
    </DialogActions>
  </Dialog>
)}

{/*Rendering dialog to display SKU details if 'errorDialog' state is true */}
{errorDialog === true && (
  <Dialog
    open={errorDialog}
    onClose={handleDialogClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying an error alert if 'rfqMssge' is available */}
      {rfqMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying error message */}
        {rfqMssge}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog */}
      <Button name="OK" onClick={(e) => {
        handleDialogClose();
        e.preventDefault();
      }} href="/Dashboard/RFQInput">OK</Button>
    </DialogActions>
  </Dialog>
)}

    <ApplicationBar/>
    <Box sx={{ padding: '20px',marginTop:'60px' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          marginBottom: '20px',
        }}
      >
          <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: blueGrey[900],
            marginBottom:'20px'
          }}
        >
         Contract Types
        </Typography>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Button
            variant="contained"
            sx={{bgcolor:orange[600],':hover':{bgcolor:orange[400]}}}
            size='small'
            startIcon={<AddCircleOutline />}
            onClick={handleAddNewContractClick}
          >
            Add New Contract Type
          </Button>
  
          {/* Search Box */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
          </Box>
        </Box>
        {/*<TextField
          size="small"
          placeholder="Search Contract Types"
          value={searchTerm}
          onChange={handleSearch}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <SearchIcon />
              </InputAdornment>
            ),
          }}
        />*/}
      </Box>

      {loading ? (
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '60vh' }}>
          <CircularProgress />
        </Box>
      ) : (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: '20px' }}>
        {filteredContractTypes.map((contract) => (
          <Card key={contract.id} sx={{ width: 300, boxShadow: 3 }}>
            <CardHeader
              title={
                <div style={{ display: 'flex', alignItems: 'center', backgroundColor:assignColorToUser(contract.contractTypeID), padding: '10px', borderRadius: '8px' }}>
                  <Typography
                    sx={{
                      fontSize: '22px',
                      fontWeight: 'bold',
                      fontFamily: 'Verdana, sans-serif',
                      textAlign: 'center',
                      color: blueGrey[900],
                      marginBottom: '20px',
                    }}
                  >
                    {contract.contractTypeName}
                  </Typography>
                  <Tooltip title={contract.description}>
                    <IconButton size="small" sx={{ ml: 1 }} onClick={(e) => handleInfoClick(contract)}>
                      <InfoIcon fontSize="small" />
                    </IconButton>
                  </Tooltip>
                </div>
              }
              sx={{ backgroundColor:assignColorToUser(contract.contractTypeID) }}
            />
            <CardContent>
              <Stack direction="row" spacing={1} alignItems="center">
                <Typography sx={{ fontSize: '13px', fontWeight: 'bold' }}>Quote Prefix:</Typography>
                <Avatar sx={{ bgcolor: 'primary.main', fontSize: '13px', width: 24, height: 24 }}>
                  {contract.quoteNoLetterCode}
                </Avatar>
              </Stack>
            </CardContent>
            <CardActions>
      {/* Edit Contract Type */}
      <Tooltip title="Edit Contract Type" arrow>
        <IconButton
          sx={{
            backgroundColor: green[600],
            color: 'white',
            padding: 1,
            fontSize: '12px',
            transition: 'transform 0.2s ease-in-out, background-color 0.2s',
            ':hover': { backgroundColor: green[700], transform: 'scale(1.05)' },
            borderRadius: '8px',
          }}
          onClick={() => handleEditClick(contract)}
        >
          <EditIcon sx={{ fontSize: '20px' }} /> Edit
        </IconButton>
      </Tooltip>

      {/* View Update History */}
      <Tooltip title="View Update History" arrow>
        <IconButton
          sx={{
            backgroundColor: orange[600],
            color: 'white',
            padding: 1,
            fontSize: '12px',
            transition: 'transform 0.2s ease-in-out, background-color 0.2s',
            ':hover': { backgroundColor: orange[700], transform: 'scale(1.05)' },
            borderRadius: '8px',
          }}
          onClick={() => handleHistoryClick(contract)}
        >
          <HistoryIcon sx={{ fontSize: '20px' }} />History
        </IconButton>
      </Tooltip>

      {/* View SKUs */}
      <Tooltip title="View/Manage SKUs" arrow>
        <IconButton
          sx={{
            backgroundColor: blue[600],
            color: 'white',
            padding: 1,
            fontSize: '12px',
            transition: 'transform 0.2s ease-in-out, background-color 0.2s',
            ':hover': { backgroundColor: blue[700], transform: 'scale(1.05)' },
            borderRadius: '8px',
          }}
          onClick={() => handleSkusClick(contract)}
        >
         <VisibilityIcon sx={{ fontSize: '20px' }} /> SKUs 
        </IconButton>
      </Tooltip>
            </CardActions>
          </Card>
        ))}
      </Box>
      )}

      {/* Edit Contract Type Dialog */}
      <EditContractTypeDialog
        open={openEditDialog}
        onClose={handleDialogClose}
        onSave={handleSaveNewContractType}
        contractTypeID={selectedContract?.contractTypeID}
      />
      {/* Edit Contract Type Dialog */}
      <AddContractTypeDialog
        open={openNewContractDialog}
        onClose={handleDialogClose}
        onSave={handleSaveNewContractType}
      />

      {/* History Dialog */}
      <Dialog open={openHistoryDialog} onClose={handleDialogClose}>
        <DialogTitle>
        <Alert variant="filled" sx={{ backgroundColor: orange[200],color:'black'}}>Update History</Alert>
          </DialogTitle>
        <DialogContent>
          {selectedContract && selectedContract.history ? (
            selectedContract.history.map((history, index) => (
              <Typography key={index}>
                {history.date}: {history.details} by {history.updatedBy}
              </Typography>
            ))
          ) : (
            <Typography>No history available</Typography>
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDialogClose} size='small' variant='outlined' sx={{color:orange[600]}}>Close</Button>
        </DialogActions>
      </Dialog>
      
      {/* History Dialog */}
      <Dialog open={openContractTypeInfoDialog} onClose={handleDialogClose}>
        <DialogTitle>
        <Alert variant="filled" sx={{ backgroundColor: orange[200],color:'black'}}>Info</Alert>
          </DialogTitle>
        <DialogContent>
          {selectedContract && selectedContract.contractTypeDescription ? (
              <Typography>
               {selectedContract?.contractTypeDescription}
              </Typography>
          ) : (
            <Typography>No Contract Type Description available</Typography>
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDialogClose} size='small' variant='outlined' sx={{color:orange[600]}}>Close</Button>
        </DialogActions>
      </Dialog>

      {/* SKUs Dialog */}
      <Dialog open={openSkusDialog} onClose={handleDialogClose} maxWidth="lg" fullWidth >
         <DialogTitle>
        <Alert variant="filled" sx={{ backgroundColor: orange[200],color:'black'}}>SKUs</Alert>
        </DialogTitle>
        <DialogContent>
          {selectedContract ? (
          <SKUManagement contractTypeID={selectedContract?.contractTypeID} contractTypeName={selectedContract?.contractTypeName}/>
          ) : (
            <Typography>No SKUs available</Typography>
          )}
        </DialogContent>
        <DialogActions>
        <Button onClick={handleDialogClose} size='small' variant='outlined' sx={{color:orange[600]}}>Close</Button>
        </DialogActions>
      </Dialog>
    </Box>
    </>
    
  );
};

export default ContractTypesLandingPage;
