const formatDateTime = (isoDateString) => {
    const date = new Date(isoDateString);
  
    const formattedDate = new Intl.DateTimeFormat('en-US', {
      day: '2-digit',
      month: 'short', // Short month (e.g., Nov)
      year: 'numeric',
    }).format(date);
  
    const formattedTime = date.toLocaleTimeString('en-US', {
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: false, // 24-hour format
    });
  
    return `${formattedDate}, ${formattedTime}`;
  };
  
export default formatDateTime