import React, { useEffect, useState } from "react";
import {
Box, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControl, IconButton, InputAdornment, InputLabel,
MenuItem, Paper, Select, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, TableSortLabel, TextField, Typography, Alert,
FormControlLabel,Checkbox
} from '@mui/material';
import { DatePicker } from "@mui/x-date-pickers";
import { blueGrey, yellow, orange, blue, indigo } from '@mui/material/colors';
import { createTheme, useTheme } from '@mui/material/styles';
import { useNavigate, createSearchParams } from 'react-router-dom';
import { SaveAlt, Search as SearchIcon, Visibility as VisibilityIcon, PictureAsPdf as PictureAsPdfIcon } from '@mui/icons-material';
import * as XLSX from 'xlsx';
import ApplicationBar from "../../AppBar/ApplicationBar";
import SalesRow from "./TableHelpers/salesTableRowHelper";
import AxiosFunction from "../../../axiosCustomInstance";
import LoadingDialog from '../../Loading';
import UserAuth from "../../ProtectedRoute/userAuth";
import TablePaginationActions from "./TableHelpers/TablePaginationActions";
import excel from '../../Images/excel.png'
import PdfDocument from "../../PDF/Main";
import { pdf } from '@react-pdf/renderer';
import { Card, CardContent, Grid } from "@mui/material";
import RFQsMetricsDashboard from "./MetricsandNumbers/rfqSearchMetricsDashboard";
import MyRFQsPerformanceDashboard from "./MetricsandNumbers/MyRFQsPerformanceDashboard";
import FieldSelectionDialog from "./TableHelpers/RFQExcelFields";
import AdvancedSearchDateFilters from "./SearchFilters/AdvancedSearchDateFilters";
import MainFilters from "./SearchFilters/MainFilters";
import dayjs from "dayjs";
import { selectIsAdmin } from "../../Login_Registration/authSlice";
import { useDispatch, useSelector } from "react-redux";
const theme = createTheme({
palette: {
primary: { main: blueGrey[900] },
secondary: { main: blueGrey[500] },
},
});

const RFQs_Search_Update = () => {
// Importing necessary hooks and components from React and other libraries
const [GetClick, SetGetClick] = useState(false); // State to track if the search button is clicked
const [totalData, setTotalData] = useState([]); // State to store the fetched data
const [KOorCustomerName, setKOorCustomerName] = useState(""); // State to store the Sales Head's name
const [salesHeadName, setSalesHeadName] = useState(""); // State to store the Sales Head's name
let [usersData, setUsersData] = useState([{ User_email: "" }]); // State to store user data
const [opportunityName, setOpportunityName] = useState(""); // State to store the Opportunity Name
const { UserName, UserEmail } = UserAuth(); // Extracting UserName and UserEmail from the UserAuth hook
const [getInvoiceNum, setgetInvoiceNum] = useState(""); // State to store the Invoice Number
const currentYear = new Date().getFullYear(); // Getting the current year
const [excelDialogOpen, setExcelDialogOpen] = useState(false);
const [open1, setOpen1] = useState(false); // State to handle the opening of a modal or dialog
const [stus, setStus] = useState("ALL"); // State to store the current status filter
const [dis, setDis] = useState(false); // State to handle the disabled state of a button
const [oppError, setOppError] = useState(false); // State to track if there's an error with the Opportunity Name
const [dateError, setDateError] = useState(false); // State to track if there's a date-related error
const [salesHeadError, setSalesHeadError] = useState(false); // State to track if there's an error with the Sales Head field
const [salesHeadErrorMsge, setSalesHeadErrorMsge] = useState(""); // State to store the Sales Head error message
const [invoiceNumError, setInvoiceNumError] = useState(false); // State to track if there's an error with the Invoice Number
const [searchFilterError, setSearchFilterError] = useState(false); // State to track if there's an error with the search filter
const [oppErrorMsge, setOppErrorMsge] = useState(""); // State to store the Opportunity error message
const [dateErrorMsge, setDateErrorMsge] = useState(""); // State to store the Date error message
const [invoiceNumErrorMsge, setInvoiceNumErrorMsge] = useState(""); // State to store the Invoice Number error message
const [searchFilterErrorMsge, setSearchFilterErrorMsge] = useState(""); // State to store the search filter error message
const [contractVehicle, setContractVehicle] = useState('All'); // State to store the selected Contract Vehicle
const [orderBy, setOrderBy] = useState(''); // State to handle sorting column
const [order, setOrder] = useState('asc'); // State to handle sorting order (ascending or descending)
const [searchTerm, setSearchTerm] = useState(''); // State to store the search term for filtering results
const PD = new Date(); // Current date object
const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD); // Formatting the current date
const navigate = useNavigate(); // React Router hook for navigation
const [page, setPage] = useState(0); // State to track the current page in pagination
const [rowsPerPage, setRowsPerPage] = useState(5); // State to track the number of rows per page in pagination
const [clearSeachFiltersClicked,setClearSearchFiltersClicked]=useState(false)
const AxiosAPIInstance = AxiosFunction(); // Custom Axios instance for making API requests
const [loading, setLoading] = useState(false); // State to track loading state
const [contractTypes, setContractTypes] = useState([]);
// Get today's date
const todayUsingDayjs= dayjs();
// Calculate one year ago
const oneYearAgo = todayUsingDayjs.subtract(1, "year");
const oneYearAgoTodayDate=oneYearAgo.format("YYYY-MM-DD")
const isAdmin = useSelector(selectIsAdmin);

const [dateFilters, setDateFilters] = useState({
  dueDateFrom: '',
  dueDateTo: '',
  publishedDateFrom: '',
  publishedDateTo: '',
  createdDateFrom:oneYearAgoTodayDate,
  createdDateTo:todayUsingDayjs?.format("YYYY-MM-DD")})


  // const [dateFilters, setDateFilters] = useState({
  //   dueDateFrom: '',
  //   dueDateTo: '',
  //   publishedDateFrom: '',
  //   publishedDateTo: '',
  //   createdDateFrom:oneYearAgoTodayDate,
  //   createdDateTo:todayUsingDayjs?.format("YYYY-MM-DD")})
  // Parent Component
  const [status, setStatus] = useState({
    ordered: false,
    shipped: false,
    invoiced: false,
    alreadyPaid: false,
    orderComplete: false,
  });
  
  const handleStatusChange = (field, value) => {
    setStatus(prev => ({ ...prev, [field]: value }));
  };
// Calculate empty rows for pagination when data does not fill a page
const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - totalData?.length) : 0;

// Handler to change the current page in pagination
const handleChangePage = (event, newPage) => { setPage(newPage); };

// Handler to change the number of rows per page in pagination
const handleChangeRowsPerPage = (event) => {
  setRowsPerPage(parseInt(event.target.value, 10)); // Parse and set the number of rows per page
  setPage(0); // Reset to the first page when rows per page changes
};

// Function to perform a stable sort on the data array
const stableSort = (array, comparator) => {
  const stabilizedThis = array.map((el, index) => [el, index]); // Map elements with their original index
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]); // Compare elements
    if (order !== 0) return order;
    return a[1] - b[1]; // Preserve original order in case of a tie
  });
  return stabilizedThis.map((el) => el[0]); // Return sorted array
};

// Function to get a comparator function based on the sort order and column
const getComparator = (order, orderBy) => {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
};

// Comparator function for descending sort order
const descendingComparator = (a, b, orderBy) => {
  if (orderBy === 'Actual_Total_Cost_After_Margin') {
    const decimalA = new Number(a[orderBy]); // Convert value to number
    const decimalB = new Number(b[orderBy]);
    if (decimalB < decimalA) return -1;
    if (decimalB > decimalA) return 1;
    return 0;
  } else {
    if (b[orderBy] < a[orderBy]) return -1;
    if (b[orderBy] > a[orderBy]) return 1;
    return 0;
  }
};

// Handler for sorting requests in table headers
const handleRequestSort = (property) => {
  const isAsc = orderBy === property && order === 'asc';
  setOrder(isAsc ? 'desc' : 'asc'); // Toggle sorting order
  setOrderBy(property); // Set the column to sort by
};

// Handler for search term input change
const handleSearchChange = (event) => {
  setSearchTerm(event.target.value.toLowerCase()); // Convert search term to lowercase

};

// useEffect hook to fetch users' data when the component is mounted
useEffect(() => {
  setLoading(true); // Start loading spinner

    
  const fetchUsersData = async () => {
    try {
      const result = await AxiosAPIInstance.get("/UserAuth/users"); // Fetch users data from API
      const users = [...result?.data]; // Store the fetched data
      setUsersData(users); // Update state with user data
      setSalesHeadError(false); // Reset any Sales Head error
    } catch (error) {
      setLoading(false); // Stop loading spinner on error
      setSalesHeadError(true); // Set Sales Head error state
    }
    setSalesHeadName(String(UserEmail).toLowerCase().trim()); // Set the Sales Head name from user email
    await AxiosAPIInstance.get("/RFQsSearch/SalesHead", { params: { Sales_Head_Email: String(UserEmail).trim().toLowerCase(), RFQ_Status: stus, 
      ContractVehicle: contractVehicle,Created_Date_From:dateFilters?.createdDateFrom,Created_Date_To:dateFilters?.createdDateTo} })
      .then(async (response) => {
        setLoading(false); // Stop loading spinner
        if (response?.data.length > 0) {
          SetGetClick(true); // Indicate that data was successfully fetched
          await setTotalData(response?.data); // Update state with the fetched data
          setDis(false); // Enable buttons or inputs
        } else {
          SetGetClick(false); // No data was found
          setSalesHeadError(true); // Set Sales Head error state
          setTotalData([]); // Clear data
          setSalesHeadErrorMsge("No Records Found"); // Set error message

        }
      })
      .catch((err) => {
        setLoading(false); // Stop loading spinner on error
        SetGetClick(false); // Indicate that the fetch was unsuccessful
        setSalesHeadError(true); // Set Sales Head error state
        setSalesHeadErrorMsge(err.response?.data); // Set error message from response

        setTotalData([]); // Clear data
        setDis(false); // Enable buttons or inputs
      });
  };
  fetchUsersData(); // Call the function to fetch users data
  
}, [clearSeachFiltersClicked]);

// useEffect hook to reset data when the status changes
useEffect(() => {
  setTotalData([]); // Clear the data
  SetGetClick(false); // Indicate that no data is being displayed
}, [stus]);

// Handler to close various error dialogs
const handleClose = (e) => {
  setOpen1(false); // Close any open modals or dialogs
  setInvoiceNumError(false); // Reset Invoice Number error
  setOppError(false); // Reset Opportunity Name error
  setSalesHeadError(false); // Reset Sales Head error
  setSearchFilterError(false); // Reset search filter error
  setDateError(false); // Reset date error
  setOppErrorMsge(""); // Clear Opportunity error message
  setSalesHeadError(""); // Clear Sales Head error message
  setInvoiceNumErrorMsge(""); // Clear Invoice Number error message
  setDateErrorMsge(""); // Clear Date error message
};
const handleDateFilterChange = (field, value) => {
  setDateFilters({
    ...dateFilters,
    [field]: value,
  });
};

// Apply filtering and sorting to totalData
const applyFiltersAndSorting = () => {
// Filter rows based on search term, date filters, and checkbox filters
const filtered = totalData.filter((row) => {
  // Search term filter
  const matchesSearchTerm = row?.Organization_Name?.toLowerCase().includes(searchTerm) || 
                            row?.Opportunity_Name?.toLowerCase().includes(searchTerm) || 
                            row?.KO_email?.toLowerCase().includes(searchTerm)||
                            row?.KO_name?.toLowerCase().includes(searchTerm)||
                            row?.Customer_Email?.toLowerCase().includes(searchTerm)||
                            row?.Customer_Name?.toLowerCase().includes(searchTerm)

  // Date range filter
  const withinDateRange = (field, from, to) => {
    if (!from && !to) return true;
    const date = new Date(row[field]);
    const fromDate = from ? new Date(from) : null;
    const toDate = to ? new Date(to) : null;
    return (!fromDate || date >= fromDate) && (!toDate || date <= toDate);
  };
  const matchesDateFilters = withinDateRange("dueDate", dateFilters.dueDateFrom, dateFilters.dueDateTo) &&
                             withinDateRange("publishedDate", dateFilters.poDateFrom, dateFilters.poDateTo) &&
                             withinDateRange("createDate", dateFilters.createdDateFrom, dateFilters.createdDateTo);

  // Checkbox filters
  const matchesCheckboxFilters = (!status.shipmentBooked || row.shipmentBooked === status.shipmentBooked) &&
  (!status.orderShipmentEmailSent || row.orderShipmentEmailSent === status.orderShipmentEmailSent) &&
  (!status.invoiceRequestSent || row.invoiceRequestSent === status.invoiceRequestSent) &&
  (!status.serialNumbersReceived || row.serialNumbersReceived === status.serialNumbersReceived) &&
  (!status.orderUploaded || row.orderUploaded === status.orderUploaded);
  // Combine all filters
  return matchesSearchTerm && matchesDateFilters ;
});


};

var filteredData = applyFiltersAndSorting();
//send this refreshed row back to styled component.
// Handler to fetch data based on the selected filters
const Get_Data_Click = async (e) => {
  e.preventDefault(); // Prevent default form submission
  setDis(true); // Disable buttons or inputs
  setLoading(true); // Start loading spinner
  setTotalData([]); // Clear the data
  setPage(0); // Reset pagination to the first page

  // Building search parameters object
  const searchParams = {
    Sales_Head_Email: salesHeadName ? String(salesHeadName).trim().toLowerCase() : undefined,
    Opportunity_Name: opportunityName ? String(opportunityName).trim() : undefined,
    Invoice_No: getInvoiceNum ? getInvoiceNum : undefined,
    Created_Date_From: dateFilters.createdDateFrom,
    Created_Date_To: dateFilters.createdDateTo,
    Published_Date_From: dateFilters.publishedDateFrom,
    Published_Date_To: dateFilters.publishedDateTo,
    Due_Date_From: dateFilters.dueDateFrom,
    Due_Date_To: dateFilters.dueDateTo,
    RFQ_Status: stus,
    ContractVehicle: contractVehicle,
    KOorCustomerName:KOorCustomerName
  };

  // Determine the endpoint based on the provided search parameters
  let selectedEndpoint = "/RFQsSearch/StatusOnly";
  if (searchParams.Invoice_No) {
    selectedEndpoint = "/RFQsSearch/QuoteNumber";
  } else if (searchParams.Sales_Head_Email && searchParams.Opportunity_Name && searchParams.Created_Date_From && searchParams.Created_Date_To) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/SalesHead/DateRange";
  } else if (searchParams.Opportunity_Name && searchParams.Created_Date_From && searchParams.Created_Date_To) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/DateRange";
  } else if (searchParams.Sales_Head_Email && searchParams.Created_Date_From && searchParams.Created_Date_To) {
    selectedEndpoint = "/RFQsSearch/SalesHead/DateRange";
  } else if (searchParams.Sales_Head_Email && searchParams.Opportunity_Name) {
    selectedEndpoint = "/RFQsSearch/OpportunityName/SalesHead";
  } else if (searchParams.Sales_Head_Email) {
    selectedEndpoint = "/RFQsSearch/SalesHead";
  } else if (searchParams.Opportunity_Name) {
    selectedEndpoint = "/RFQsSearch/OpportunityName";
  } else if (searchParams.Created_Date_From && searchParams.Created_Date_To) {
    selectedEndpoint = "/RFQsSearch/DateSearch";
  }

  // Make the API request with the selected endpoint and search parameters
  await AxiosAPIInstance.get(selectedEndpoint, { params: searchParams })
    .then(async (response) => {
      setLoading(false); // Stop loading spinner
      if (response?.data.length > 0) {
        await setTotalData(response?.data); // Update state with the fetched data
        setDis(false); // Enable buttons or inputs
        SetGetClick(true); // Indicate that data was successfully fetched
      } else {
        SetGetClick(false); // No data was found
        setDateError(true); // Set date error state
        setDateErrorMsge("No Records Found"); // Set error message
        setTotalData([]); // Clear data
      }
    })
    .catch((err) => {
      setLoading(false); // Stop loading spinner on error
      SetGetClick(false); // Indicate that the fetch was unsuccessful
      setDateError(true); // Set date error state
      setDateErrorMsge(err.response?.data); // Set error message from response
      setTotalData([]); // Clear data
      setDis(false); // Enable buttons or inputs
    });
  setLoading(false); // Stop loading spinner
  setDis(false); // Enable buttons or inputs
};


const handleGenerateExcel = (selectedFields) => {
  try {
    // Validate that there is data to export
    if (!totalData || totalData.length === 0) {
      throw new Error('No data available to export');
    }

    // Validate that fields are selected
    if (!selectedFields || selectedFields.length === 0) {
      throw new Error('No fields selected for export');
    }

    // Filter and format data for Excel export
    const formattedData = totalData.map((row) => {
      const filteredRow = {};
      selectedFields.forEach((field) => {
        switch (field) {
          case 'Opportunity Name':
            filteredRow[field] = row.Opportunity_Name || '';
            break;
          case 'Quote No':
            filteredRow[field] = row.Invoice_No || '';
            break;
          case 'Contract Vehicle':
            filteredRow[field] = row.Contract_Vehicle || '';
            break;
          case 'KO name':
            filteredRow[field] = row.KO_name || '';
            break;
          case 'KO email':
            filteredRow[field] = row.KO_email || '';
            break;
          case 'KO phone':
            filteredRow[field] = row.KO_phone || '';
            break;
          case 'KO address':
            filteredRow[field] = row.KO_address || '';
            break;
          case 'Published Date':
            filteredRow[field] = row.Published_date ? String(row.Published_date).split('T')[0] : '';
            break;
          case 'Due Date':
            filteredRow[field] = row.Due_date ? String(row.Due_date).split('T')[0] : '';
            break;
            case 'Brief':
              filteredRow[field] = row.Brief;
              break;
            case 'Organization Name':
              filteredRow[field] = row.Organization_Name;
              break;
            case 'Notes For PO':
              filteredRow[field] = row.Notes_For_PO;
              break;
            case 'Customer Name':
              filteredRow[field] = row.Customer_Name;
              break;
            case 'Customer Email':
              filteredRow[field] = row.Customer_Email;
              break;
            case 'Customer Phone':
              filteredRow[field] = row.Customer_Phone;
              break;
            case 'Customer Address':
              filteredRow[field] = row.Customer_Address;
              break;
            case 'Shipping Method':
              filteredRow[field] = row.Shipping_Method;
              break;
            case 'Sales Head Name':
              filteredRow[field] = row.Sales_Head_Name;
              break;
            case 'Sales Head Email':
              filteredRow[field] = row.Sales_Head_Email;
              break;
            case 'Sales Head Ph':
              filteredRow[field] = row.Sales_Head_Ph;
              break;
            case 'Shipping Terms':
              filteredRow[field] = row.Shipping_Terms;
              break;
            case 'Estimated Delivery':
              filteredRow[field] = row.Estimated_Delivery;
              break;
            case 'Payment Terms':
              filteredRow[field] = row.Payment_Terms;
              break;
            case 'Notes':
              filteredRow[field] = row.Notes;
              break;
            case 'Shipping Cost($)':
              filteredRow[field] = row.Shipping_Cost;
              break;
            case 'Tax($)':
              filteredRow[field] = row.Tax;
              break;
            case 'Other Cost1($)':
              filteredRow[field] = row.Other_Cost_1;
              break;
            case 'Other Cost2($)':
              filteredRow[field] = row.Other_Cost_2;
              break;
            case 'Other Cost3($)':
              filteredRow[field] = row.Other_Cost_3;
              break;
            case 'Other Cost Name1':
              filteredRow[field] = row.Other_Cost_Name1;
              break;
            case 'Other Cost Name2':
              filteredRow[field] = row.Other_Cost_Name2;
              break;
            case 'Other Cost Name3':
              filteredRow[field] = row.Other_Cost_Name3;
              break;
            case 'Total Cost Before Margin($)':
              filteredRow[field] = row.Actual_Total_Cost_Before_Margin;
              break;
            case 'Total Cost After Margin($)':
              filteredRow[field] = row.Actual_Total_Cost_After_Margin;
              break;
            case 'Created Date':
              filteredRow[field] = String(row.Created_Date).split('T')[0];
              break;
            case 'Last Updated Date':
              filteredRow[field] = String(row.Last_Updated_Date).split('T')[0];
              break;
            case 'Status':
              filteredRow[field] = row.RFQ_Status;
              break;
            case 'Updated By':
              filteredRow[field] = row.Updated_By;
              break;
            case 'Updating Comments':
              filteredRow[field] = row.Updating_Comments;
              break;
              default:
                filteredRow[field] = '';
                break;
            }
          });
          return filteredRow;
        });
    
        // Ensure formattedData is not empty
        if (formattedData.length === 0) {
          throw new Error('No valid data to export');
        }
    
        // Create a new workbook and worksheet
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(formattedData);
    
        XLSX.utils.book_append_sheet(wb, ws, 'ASPETTO SALES'); // Append sheet to workbook
        const today = new Date(); // Get current date
        const excelFileName = 'ASPETTO SALES-' + String(today).split('T')[0] + '(' + 'STATUS:' + stus + ')' + '.xlsx'; // Generate file name
        XLSX.writeFile(wb, excelFileName); // Write Excel file to disk
        console.log('Excel file generated successfully');
      } catch (error) {
        console.error('Error generating Excel file:', error.message);
        alert(error.message); // Display error to the user
      }
    };

// Handler to navigate to the RFQ update page when a specific quote is clicked
const RFQID_Search = (quote) => {
  navigate({
    pathname: "/Dashboard/UpdateRFQ",
    search: createSearchParams({
      RID: quote?.RFQ_ID,
      RStatus: quote?.RFQ_Status,
      VNo: 'LifeCycle',
    }).toString()
  });
};
  // Clear Filters function
  const handleClearFilters = () => {
    setPage(0)
    setDateFilters({
      dueDateFrom: '',
      dueDateTo: '',
      publishedDateFrom: '',
      publishedDateTo: '',
      createdDateFrom:new Date(currentYear, 0, 1),
      createdDateTo: new Date(currentYear, 11, 31),
    });
    setSearchTerm('');
    setContractVehicle('All'); // Reset contract type selection if applicable
    setStus('ALL')
    setSalesHeadName(UserEmail)
  };

// Filter the quotes based on the search term
const filteredQuotes = totalData.filter((quote) =>
  quote.Customer_Email.toLowerCase().includes(searchTerm) ||
  quote.Customer_Name.toLowerCase().includes(searchTerm) ||
  quote.KO_name.toLowerCase().includes(searchTerm) ||
  quote.KO_email.toLowerCase().includes(searchTerm) ||
  quote.Organization_Name.toLowerCase().includes(searchTerm)||
  quote.Opportunity_Name.toLowerCase().includes(searchTerm)
);


const calculateMetrics = () => {
  const pastDue = totalData.filter(
    (quote) => new Date() > new Date(quote.Due_date)  && quote.RFQ_Status === "PENDING"
  ).length;

  const pending = totalData.filter((quote) => quote.RFQ_Status === "PENDING").length;
  const total = totalData.length;

  return { pastDue, pending, total };
};

const { pastDue,pending, total } = calculateMetrics();

React.useEffect(() => {
  const fetchContractTypes = async () => {
    try {
      setLoading(true);
      const response = await AxiosAPIInstance.get('/ContractTypes/getContractTypes')
      setContractTypes(response?.data || []);
 
    } catch (error) {
      console.error("Error loading contract types:", error);
      setSearchFilterError(true)
      setSearchFilterErrorMsge("Contract Types Loaded Successfully");
    } finally {
      setLoading(false);
    }
  };

  fetchContractTypes();
}, []);


  return (
    <>
      <ApplicationBar />
      <LoadingDialog open={loading} />
      {invoiceNumError && (
        <Dialog open={invoiceNumError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={invoiceNumErrorMsge === "No Records Found" ? "warning" : "error"}>
              {invoiceNumErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {invoiceNumErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {oppError && (
        <Dialog open={oppError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={oppErrorMsge === "No Records Found" ? "warning" : "error"}>
              {oppErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {oppErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {salesHeadError && (
        <Dialog open={salesHeadError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={salesHeadErrorMsge === "No Records Found" ? "warning" : "error"}>
              {salesHeadErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {salesHeadErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {dateError && (
        <Dialog open={dateError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity={dateErrorMsge === "No Records Found" ? "warning" : "error"}>
              {dateErrorMsge === "No Records Found" ? "status" : "error"}
            </Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {dateErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {searchFilterError && (
        <Dialog open={searchFilterError} onClose={handleClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
            <Alert variant='filled' severity="error">ERROR</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{ textAlign: "center", color: 'black' }}>
              {searchFilterErrorMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      <Stack direction='column' spacing={0} justifyContent="center">

        <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: theme.palette.primary.main,
            marginTop:'60px'
          }}
        >
        Request For Quotes (RFQs)
        </Typography>

   
        <Stack direction='row' justifyContent="center" spacing={1} sx={{marginTop:'20px'}} >
        <Stack direction='column' justifyContent="center" spacing={1} >
          <Stack direction='row' justifyContent="center" spacing={1}>
          <MainFilters
        getInvoiceNum={getInvoiceNum}
        setgetInvoiceNum={setgetInvoiceNum}
        searchTerm={searchTerm}
        handleSearchChange={handleSearchChange}
        salesHeadName={salesHeadName}
        setSalesHeadName={setSalesHeadName}
        usersData={usersData}
        stus={stus}
        setStus={setStus}
        contractVehicle={contractVehicle}
        setContractVehicle={setContractVehicle}
        contractTypes={contractTypes}
        SetGetClick={() => console.log('Filter clicked')}
        setTotalData={() => console.log('Total data set')}
        setPage={() => console.log('Page changed')}
      />

        </Stack>
        
        </Stack>
        {stus==='WON'&& (  <Box margin="20px" display="flex" flexDirection="column">
          {/* Already Paid */}
<FormControlLabel sx={{fontSize:'12px'}}
  control={
    <Checkbox
      checked={status.alreadyPaid} sx={{fontSize:'12px'}}
      size='small'
      onChange={(e) => handleStatusChange('alreadyPaid', e.target.checked)}
    />
  }
  label="Already Paid"
/>

{/* Order Shipment Email Sent */}
<FormControlLabel
  control={
    <Checkbox
      checked={status.shipped}sx={{fontSize:'12px'}}
      size='small'
      onChange={(e) => handleStatusChange('shipped', e.target.checked)}
    />
  }
  label="Shipped"
/>

{/* Invoice Request Sent */}
<FormControlLabel
  control={
    <Checkbox
      checked={status.invoiced}sx={{fontSize:'12px'}}
      size='small'
      onChange={(e) => handleStatusChange('invoiced', e.target.checked)}
    />
  }
  label="Invoiced"
/>
{/* Invoice Request Sent */}
<FormControlLabel
  control={
    <Checkbox
      checked={status.ordered}sx={{fontSize:'12px'}}
      size='small'
      onChange={(e) => handleStatusChange('ordered', e.target.checked)}
    />
  }
  label="Ordered"
/>
<FormControlLabel
  control={
    <Checkbox
      checked={status.orderComplete}sx={{fontSize:'12px'}}
      size='small'
      onChange={(e) => handleStatusChange('orderComplete', e.target.checked)}
    />
  }
  label="Order Complete"
/>
</Box>)}
    { /*Date Filters*/}
    <AdvancedSearchDateFilters
     dateFilters={dateFilters}
     handleDateFilterChange={handleDateFilterChange}
    />
  </Stack>

        <Stack sx={{ marginTop: "10px", marginBottom: "10px" }} spacing={1} direction="row" justifyContent="center">
          <Button sx={{ width: "100px" }} size="small" variant="contained" color='success' disabled={dis} onClick={Get_Data_Click}>Get</Button>
          <Button
            disabled={!GetClick}
            variant="contained"
            sx={{ backgroundColor: yellow[900], fontSize: "10px", ":hover": { backgroundColor: yellow[800] }, height: '35px' }}
            startIcon={<SaveAlt />}
            onClick={() => setExcelDialogOpen(true)}
            size="small">
            <img src={excel} alt="ASPETTO" height="35px" width="35px"></img>
          </Button>
          <FieldSelectionDialog
        open={excelDialogOpen}
        onClose={() => setExcelDialogOpen(false)}
        onDownload={(fields) => {
          handleGenerateExcel(fields);
          setExcelDialogOpen(false);
        }}
      />
          <Button sx={{ width: "100px",backgroundColor:indigo[900],":hover": { backgroundColor: indigo[500],}}} size="small" variant="contained"  onClick={(e)=>{
            if(clearSeachFiltersClicked===true) 
              {
                setClearSearchFiltersClicked(false)
              }
              else
              {
                setClearSearchFiltersClicked(true)
              }
            handleClearFilters(e)}
            }>Clear Filters</Button>
        </Stack>

        <RFQsMetricsDashboard totalData={totalData} salesHeadEmail={salesHeadName} dateFiltersfromMainPage={dateFilters} />
        <MyRFQsPerformanceDashboard totalData={totalData} dateFiltersfromMainPage={dateFilters} />
        <br />
        {GetClick === true && totalData.length > 0 && (
          <TableContainer>
            <Table>
              <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', width: "16%", padding: 0.5 }} align="center">Contract Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5, width: "23%" }} align="center">Contracting Officer's Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5, width: "23%" }} align="center">Customer's Info</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", backgroundColor: orange[600], fontFamily: 'Verdana (sans-serif)', cursor: 'pointer', padding: 0.5, width: "30%", "&:hover": { backgroundColor: orange[300], color: orange[800] } }} align="center">
                    <TableSortLabel active direction={orderBy === 'Created_Date' ? order : 'asc'} onClick={() => handleRequestSort('Created_Date')}>
                      <b>Sort by Created Date</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', padding: 0.5, color: 'white', width: "10%", fontFamily: 'Verdana, sans-serif', backgroundColor: blue[600], cursor: 'pointer', "&:hover": { backgroundColor: blue[300], color: orange[800] } }} align="center">
                    <TableSortLabel active direction={orderBy === 'Actual_Total_Cost_After_Margin' ? order : 'asc'} onClick={() => handleRequestSort('Actual_Total_Cost_After_Margin')}>
                      <b>Sale($)</b>
                    </TableSortLabel>
                  </TableCell>
                  <TableCell sx={{ fontWeight: 'bold', color: "white", fontFamily: 'Verdana (sans-serif)', padding: 0.5 }} align="center">Actions</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {(rowsPerPage > 0
                  ? stableSort(filteredQuotes, getComparator(order, orderBy)).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  : filteredQuotes
                ).map((row, index) => (
                  <SalesRow key={index} row={row} usersData={usersData} />
                ))}
                {emptyRows > 0 && (
                  <TableRow style={{ height: 53 * emptyRows }}>
                    <TableCell colSpan={6} />
                  </TableRow>
                )}
              </TableBody>
              <TableFooter>
                <TableRow>
                  <TablePagination
                    rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
                    count={filteredQuotes?.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    SelectProps={{
                      inputProps: {
                        'aria-label': 'rows per page',
                      },
                      native: true,
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                    ActionsComponent={TablePaginationActions}
                  />
                </TableRow>
              </TableFooter>
            </Table>
          </TableContainer>
        )}
      </Stack>
    </>
  );
}
export default RFQs_Search_Update;



