import * as React from 'react';
import { Route, Routes } from 'react-router-dom';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { ThemeProvider } from '@mui/material/styles';
import theme from './Theme';
import Layout from './Pages/LayoutComponents/Layout';
import HomePage from './Pages/Home/Home';
import Login_Form from './Pages/Login_Registration/login';
import ProfilePage from '../src/Pages/User/Profile';
import EmailVerificationSuccess from './Pages/Login_Registration/EmailVerficiation';
import PasswordResetEmailPage from './Pages/Login_Registration/ResetPassword/resetPasswordEmail';
import ResetUserPasswordPage from './Pages/Login_Registration/ResetPassword/resetPassword';
import UserRegistration from './Pages/Login_Registration/UserRegristration';
import RFQInput from './Pages/RFQ/rfqCreate';
import RFQUpdate from './Pages/RFQ/rfqUpdate';
import RFQDrafts from './Pages/RFQ/RFQDrafts/rfqdrafts';
import RFQsSearchUpdate from './Pages/RFQ/RFQsSearch/rfqsSearch';
import POsSearch from './Pages/RFQ/RFQsSearch/rfqsPOsSearch';
import RFQCopy from './Pages/RFQ/rfqCreatecopy';
import SKUsRetrieve from './Pages/SKUs/SKUs';
import SKUsDHSRetrieve from './Pages/SKUs/SKUs_DHS';
import RFIInput from './Pages/RFI/rfiCreate';
import RFIsSearchUpdate from './Pages/RFI/rfisSearch';
import RFIUpdate from './Pages/RFI/rfiUpdate';
import DashLayout from './Pages/LayoutComponents/DashLayout';
import NewRMA from './Pages/RMA/NewRMA';
import UpdateRMA from './Pages/RMA/EditRMA';
import RMAsSearch from './Pages/RMA/RMAsSearch';
import RMAInvestigation from './Pages/RMA/RMAInvestigation';
import RMACloseOut from './Pages/RMA/RMAFollowUpCloseOut';
import RMAResolution from './Pages/RMA/RMAResolution';
import PageUnderDev from './Pages/PageUnderDevelopment/PageUnderDev';
import Master_Life_Cycle from './Pages/LifeCycle/LifeCycleMaster';
import FBOP_Life_Cycle from './Pages/LifeCycle/LifeCycleFBOP';
import GSA_Life_Cycle from './Pages/LifeCycle/LifeCycleGSA';
import DHS_Life_Cycle from './Pages/LifeCycle/LifeCycleDHS';
import RequiredAuth from './Pages/ProtectedRoute/RequiredAuth';
import DHSCustomerEmailPage from './Pages/DHSCustomerOrdersFormHandling/CustomerEmailVerification';
import DHSReqsSearch from './Pages/DHSCustomerOrdersFormHandling/DHS_Customer_FilesUploadsSearch/DHSsSearchInternal';
import DHSCUstomerInputPortal from './Pages/DHSCustomerOrdersFormHandling/DHSCustomerPortal';
import DHSCustomerPortalInternalSales from './Pages/DHSCustomerOrdersFormHandling/DHSCustomerPortalInternalSales';
import DHSCBPBodyArmorInstructions from './Pages/DHSCustomerOrdersFormHandling/DHSCBPBodyArmorInstructions';
import DHSCBPBodyArmorInstructionsExternal from './Pages/DHSCustomerOrdersFormHandling/DHSCBPBodyArmorInstructionsExternal';
import RetrieveUserNameEmail from './Pages/Login_Registration/ResetPassword/retrieveUserNameEmail';
import GenerateQR from './Pages/QRCodeGenerator/QRCodeGenerator';
import MyPerformanceDashboard from './Pages/MyPerformance/MyPerformanceDashboard';
import PDFDataExtractor from './Pages/CUSTOMER_DATA_EXTRACTOR/PDFDataExtractor';
import AdminPage from './Pages/Admin/AdminPage';
import RevenueGoalForm from './Pages/RevenueGoals/RevenueGoals';
import DHSCBP_RFQs_Search_Update from './Pages/DHSCustomerOrdersFormHandling/DHSCBPRFQsSearch/DHSCBPQuoteSearch';
import RFQ_CLIENT_INPUT_MULTI_VARIANT from './Pages/RFQ/rfqMultiVariantQuote';
import RFQsSearchViewTwo from './Pages/RFQ/RFQsSearch/RFQsSearchViewTwo';
import BugReport from './Pages/Bugs/SubmitBug';
import BugList from './Pages/Bugs/BugsSearch';
import RoleManagement from './Pages/Admin/AdminManageRoles';
import NewDHSTrackerRow from './Pages/DHSTracker/NewDHSTrackerRow'
import TrackerTable from './Pages/DHSTracker/TrackerTable';
import DHSTrackerExcelUploader from './Pages/DHSTracker/ExcelUpload'
import VendorPage from './Pages/Vendors/NewVendor';
import renderVariants from './Pages/RFQ/rfqMultiVariantQuote';
import ContractTypesLandingPage from './Pages/ContractTypes/ContractTypes/ContractTypesLandingPage';
function App() {
  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Routes>
          <Route path="verify/:token" element={<EmailVerificationSuccess />} />
          <Route path="pass-reset-update/:token" element={<ResetUserPasswordPage />} />
          <Route path="DHS-CBP" element={<DHSCustomerEmailPage />} />
          <Route path="DHS-CBP/ProductInstructionsRepository" element={<DHSCBPBodyArmorInstructionsExternal />} />
          <Route path="DHS_CBP_CustomerInfoRequestForm/:token" element={<DHSCUstomerInputPortal />} />
          <Route path="/" element={<Layout />}>
            <Route index element={<Login_Form />} />
            <Route path="pass-reset" element={<PasswordResetEmailPage />} />
            <Route path="RetrieveUserNameEmail" element={<RetrieveUserNameEmail />} />
            <Route path="UserRegistartion" element={<UserRegistration />} />
            <Route element={<RequiredAuth />}>
              <Route path="Dashboard" element={<DashLayout />}>
                <Route index element={<HomePage />} />
                <Route path="Profile" element={<ProfilePage />} />
                <Route path="AdminPage" element={<AdminPage />} />
                <Route path="Admin/ManageRoles" element={<RoleManagement />} />
                <Route path="CreateRFQ" element={<RFQInput />} />
                <Route path="CreateRFQ/MultiVariant" element={<RFQ_CLIENT_INPUT_MULTI_VARIANT />} />
                <Route path="CreateRFI" element={<RFIInput />} />
                <Route path="POsSearch" element={<POsSearch />} />
                <Route path="RFQsSearch" element={<RFQsSearchUpdate />} />
                <Route path="RFQsSearch/ViewTwo" element={<RFQsSearchViewTwo />} />
                <Route path="RFIsSearch" element={<RFIsSearchUpdate />} />
                <Route path="ExtractData/PDFs" element={<PDFDataExtractor />} />
                <Route path="UpdateRFQ" element={<RFQUpdate />} />
                <Route path="RFQCopy" element={<RFQCopy />} />
                <Route path="UpdateRFI" element={<RFIUpdate />} />
                <Route path="NewRMA" element={<NewRMA />} />
                <Route path="EditRMA" element={<UpdateRMA />} />
                <Route path="RMAInvestigation" element={<RMAInvestigation />} />
                <Route path="RMAResolution" element={<RMAResolution />} />
                <Route path="RMACloseOut" element={<RMACloseOut />} />
                <Route path="RMAsSearch" element={<RMAsSearch />} />
                <Route path="RFQDrafts" element={<RFQDrafts />} />
                <Route path="renderVariants" element={<renderVariants />} />
                <Route path="SubmitBug" element={<BugReport />} />
                <Route path="BugList" element={<BugList />} />
                <Route path="DHSReqsSearch" element={<DHSReqsSearch />} />
                <Route path="DHSTracker" element={<NewDHSTrackerRow />} />
                <Route path="DHSTracker/Retrieve" element={<TrackerTable />} />
                <Route path="DHSTracker/ExcelUpload" element={<DHSTrackerExcelUploader />} />
                <Route path="DHSRFQsOutSidePortalsSearch" element={<DHSCBP_RFQs_Search_Update />} />
                <Route path="DHSCBPCustomerInfoRequestForm/InternalSales" element={<DHSCustomerPortalInternalSales />} />
                <Route path="DHS-CBP-BodyArmor-Instructions" element={<DHSCBPBodyArmorInstructions />} />
                <Route path="RevenueGoals/Set" element={<RevenueGoalForm />} />
                <Route path="GenerateQR" element={<GenerateQR />} />
                <Route path="ContractTypeLandingPage" element={<ContractTypesLandingPage/>}></Route>
                <Route path="MyPerformanceDashboard" element={<MyPerformanceDashboard />} />
                <Route path="SKUs/SlateSolutions" element={<SKUsRetrieve />} />
                <Route path="SKUs/DHS" element={<SKUsDHSRetrieve />} />
                <Route path="LifeCycle/FBOP" element={<FBOP_Life_Cycle />} />
                <Route path="LifeCycle/DHS-CBP" element={<DHS_Life_Cycle />} />
                <Route path="LifeCycle/GSA" element={<GSA_Life_Cycle />} />
                <Route path="LifeCycle/Master" element={<Master_Life_Cycle />} />
                <Route path="LifeCycle/PipeLine" element={<PageUnderDev />} />
                <Route path="LifeCycle/Bids" element={<PageUnderDev />} />
                <Route path="Vendors/NewVendor" element={<VendorPage />} />
                <Route path="PageUnderDev" element={<PageUnderDev />} />
              </Route>
            </Route>
          </Route>
        </Routes>
      </LocalizationProvider>
    </ThemeProvider>
  );
}

export default App;
