import React, { useState } from "react";
import {
  Box,
  Stack,
  Typography,
  TextField,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableFooter,
  TableHead,
  TablePagination,
  TableRow,
  Divider,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Alert,
  Paper,
  Link,
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import { createTheme } from "@mui/material/styles";
import LoadingDialog from "../Loading";
import AxiosFunction from "../../axiosCustomInstance";
import TablePaginationActions from "../HelperFunctions/TablePaginationHelperFunction";
import ApplicationBar from "../AppBar/ApplicationBar";
import { blue } from "@mui/material/colors";
import AdvancedSearchDateFilters from "../RFQ/RFQsSearch/SearchFilters/AdvancedSearchDateFilters";

const theme = createTheme({
  palette: {
    primary: {
      main: "#37474f",
    },
    secondary: {
      main: "#607d8b",
    },
  },
});

function RFIs_Search_Update() {
  const currentYear = new Date().getFullYear();
  const [solicitationNumber, setSolicitationNumber] = useState("");
  const [opportunityName, setOpportunityName] = useState("");

  const [totalData, setTotalData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const AxiosAPIInstance = AxiosFunction();
  const [dateFilters, setDateFilters] = useState({
    dueDateFrom: '',
    dueDateTo: '',
    publishedDateFrom: '',
    publishedDateTo: '',
    createdDateFrom:new Date(currentYear, 0, 1),
    createdDateTo:new Date(currentYear, 11, 31) 
  });
  const fetchData = async () => {
    setLoading(true);
    setError(false);
    try {
      if (
        !solicitationNumber.trim() &&
        !opportunityName.trim() &&
        !dateFilters.createdDateTo &&
        !dateFilters.publishedDateFrom &&
        !dateFilters.publishedDateTo &&
        !dateFilters.dueDateFrom &&
        !dateFilters.dueDateTo 
      ) {
        throw new Error("Please provide at least one search filter.");
      }

      const params = {
        Solicitation_Number: solicitationNumber.trim().toUpperCase(),
        Opportunity_Name: opportunityName.trim(),
        Created_Date_From: dateFilters.createdDateFrom,
        Created_Date_To: dateFilters.createdDateTo,
        Published_Date_From: dateFilters.publishedDateFrom,
        Published_Date_To: dateFilters.publishedDateTo,
        Due_Date_From: dateFilters.dueDateFrom,
        Due_Date_To: dateFilters.dueDateTo,
      };

      const response = await AxiosAPIInstance.get("/RFIsSearch/search", { params });

      if (response.data.length === 0) {
        throw new Error("No records found for the applied filters.");
      }

      setTotalData(response.data);
    } catch (error) {
      setError(true);
      setErrorMessage(error.response?.data || error.message || "An unknown error occurred.");
    } finally {
      setLoading(false);
    }
  };

  const clearFilters = () => {
    setSolicitationNumber("");
    setOpportunityName("");
    setDateFilters({
      dueDateFrom: '',
      dueDateTo: '',
      publishedDateFrom: '',
      publishedDateTo: '',
      createdDateFrom: new Date(currentYear, 0, 1),
      createdDateTo: new Date(currentYear, 11, 31),
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };
  const handleDateFilterChange = (field, value) => {
    setDateFilters({
      ...dateFilters,
      [field]: value,
    });
  };
  
  return (
    <>
      <LoadingDialog open={loading} />
      <ApplicationBar />

      {error && (
        <Dialog
          open={error}
          onClose={() => setError(false)}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            <Alert severity="error">Error</Alert>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">{errorMessage}</DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setError(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      )}

      <Stack direction="column" spacing={3} sx={{ marginTop: "60px", padding: "20px" }}>
        {/* Header Section */}
        <Stack direction="row" justifyContent="center">
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: "bold",
              fontFamily: "Verdana, sans-serif",
              color: theme.palette.primary.main,
            }}
          >
            Request For Information / Sources Sought (RFIs)
          </Typography>
        </Stack>

        {/* Filters Section */}
        <Paper elevation={3} sx={{ padding: "20px", borderRadius: "8px" }}>
          <Stack spacing={3} direction="row" justifyContent='center'>
            <Box
              sx={{
                display: "flex",
                flexWrap: "wrap",
                gap: "20px",
                justifyContent: "center",
              }}
            >
              <Stack direction="column" spacing={1}>
              <TextField
                label="Solicitation Number"
                value={solicitationNumber}
                onChange={(e) => setSolicitationNumber(e.target.value)}
                size="small"
                sx={{ width: "300px" }}
              />
              <TextField
                label="Opportunity Name"
                value={opportunityName}
                onChange={(e) => setOpportunityName(e.target.value)}
                size="small"
                sx={{ width: "300px" }}
              />
             </Stack>
              <Stack direction="column" spacing={1}>
                { /*Date Filters*/}
               <AdvancedSearchDateFilters
               dateFilters={dateFilters}
               handleDateFilterChange={handleDateFilterChange}
               />
              </Stack>
            </Box>
            <Stack direction="column" spacing={2} justifyContent="center">
              <Button
                variant="contained"
                color="warning"
                size="small"
                sx={{ height: "35px" }}
                onClick={fetchData}
              >
                Search
              </Button>
              <Button
                variant="outlined"
                size="small"
                sx={{ height: "35px" }}
                onClick={clearFilters}
              >
                Clear Filters
              </Button>
            </Stack>
          </Stack>
        </Paper>

        {/* Results Section */}
        {totalData.length > 0 && (
          <Paper elevation={3} sx={{ borderRadius: "8px", overflow: "hidden" }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ backgroundColor: theme.palette.primary.main }}>
                  <TableRow>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Opportunity Name
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Solicitation Number
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Contract Vehicle
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Contracting Officer's Info
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      URLs
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Published Date & Due Date
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Dollar Amount ($)
                    </TableCell>
                    <TableCell sx={{ color: "white", fontWeight: "bold" }} align="center">
                      Proposal Stage/Actions
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {totalData
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row, index) => (
                      <TableRow key={index}>
                        <TableCell align="center">{row.Opportunity_Name}</TableCell>
                        <TableCell align="center">{row.Solicitation_Number}</TableCell>
                        <TableCell align="center">{row.Contract_Vehicle}</TableCell>
                        <TableCell align="center">{row.Contracting_Officer_Info}</TableCell>
                        <TableCell align="center">
                          <Link
                            href={row.Govwin_Link}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: blue[700], fontWeight: "bold" }}
                          >
                            Govwin
                          </Link>
                          <br></br>
                          <Link
                            href={row.Sam_gov_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            sx={{ color: blue[700], fontWeight: "bold" }}
                          >
                            Sam.gov
                          </Link>
                        </TableCell>
                        <TableCell align="center">
                          {row.Published_date} / {row.Due_date}
                        </TableCell>
                        <TableCell align="center">{row.Estimated_$_Amount}</TableCell>
                        <TableCell align="center">{row.Proposal_Stage}</TableCell>
                      </TableRow>
                    ))}
                </TableBody>
                <TableFooter>
                  <TableRow>
                    <TablePagination
                      rowsPerPageOptions={[5, 10, 25]}
                      count={totalData.length}
                      rowsPerPage={rowsPerPage}
                      page={page}
                      onPageChange={handleChangePage}
                      onRowsPerPageChange={handleChangeRowsPerPage}
                      ActionsComponent={TablePaginationActions}
                    />
                  </TableRow>
                </TableFooter>
              </Table>
            </TableContainer>
          </Paper>
        )}
      </Stack>
    </>
  );
}

export default RFIs_Search_Update;
