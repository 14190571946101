import React, { useState } from 'react';
import {
  Table,
  TableHead,
  TableBody,
  TableRow,
  TableCell,
  TableContainer,
  Paper,
  TextField,
  Button,
  IconButton,
  Stack,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import LoadingDialog from '../../Loading';
/**
 * AddSKUComponent
 * Component to manage and add SKU data with multiple pricing rows (date ranges).
 *
 * Props:
 * - handleSave: Function to handle saving SKU data to the backend.
 */
const AddSKUComponent = ({ handleSave }) => {
  // State to manage the list of SKUs and their pricing details
  const [skus, setSkus] = useState([
    { vendorPartnerName:'',
      partNo: '',
      itemDescription: '',
      unitOfMeasure:'ea',
      pricing: [
        {
          fromDate: '',
          toDate: '',
          customerCost: '',
          companyCost: '',
          marginPercent: '',
        },
      ],
    },
  ]);
 const [loading,setLoading]=useState(false)
  /**
   * validateInputs
   * Validates inputs for all SKUs and their pricing rows.
   * Ensures all required fields are filled in before saving.
   *
   * @returns {boolean} - True if inputs are valid, false otherwise.
   */
  const validateInputs = () => {
    for (let sku of skus) {
      if (!sku.partNo) {
        alert('Part No is required for all SKUs.');
        return false;
      }

      if (!sku.itemDescription) {
        alert('Item Description is required for all SKUs.');
        return false;
      }
      if (!sku.unitOfMeasure) {
        alert('Unit Of Measure is required for all SKUs.');
        return false;
      }
      if (!sku.vendorPartnerName) {
        alert('Vendor Partner Name is required for all SKUs.');
        return false;
      }
      for (let pricing of sku.pricing) {
        if (
          !pricing.fromDate ||
          !pricing.toDate ||
          !pricing.customerCost ||
          !pricing.companyCost ||
          !pricing.marginPercent
        ) {
          alert(
            'All fields in Pricing (From Date, To Date, Customer Cost, Company Cost, Margin Percent) are required.'
          );
          return false;
        }
      }
    }
    return true;
  };

  /**
   * handleAddSKU
   * Adds a new SKU with an empty pricing row to the list.
   */
  const handleAddSKU = () => {
    setSkus((prev) => [
      ...prev,
      { vendorPartnerName:'',
        partNo: '',
        itemDescription: '',
        unitOfMeasure:'ea',
        pricing: [
          {
            fromDate: '',
            toDate: '',
            customerCost: '',
            companyCost: '',
            marginPercent: '',
          },
        ],
      },
    ]);
  };

  /**
   * handleRemoveSKU
   * Removes an SKU from the list by index.
   *
   * @param {number} index - Index of the SKU to remove.
   */
  const handleRemoveSKU = (index) => {
    setSkus((prev) => prev.filter((_, i) => i !== index));
  };

  /**
   * handleAddPricingRow
   * Adds a new pricing row to a specific SKU.
   *
   * @param {number} skuIndex - Index of the SKU to add the pricing row to.
   */
  const handleAddPricingRow = (skuIndex) => {
    setSkus((prev) => {
      const updated = [...prev];
      updated[skuIndex].pricing.push({
        fromDate: '',
        toDate: '',
        customerCost: '',
        companyCost: '',
        marginPercent: '',
      });
      return updated;
    });
  };

  /**
   * handleRemovePricingRow
   * Removes a specific pricing row from a SKU.
   *
   * @param {number} skuIndex - Index of the SKU.
   * @param {number} pricingIndex - Index of the pricing row to remove.
   */
  const handleRemovePricingRow = (skuIndex, pricingIndex) => {
    setSkus((prev) => {
      const updated = [...prev];
      updated[skuIndex].pricing = updated[skuIndex].pricing.filter(
        (_, i) => i !== pricingIndex
      );
      return updated;
    });
  };

  /**
   * handleChange
   * Updates a field value for a specific SKU.
   *
   * @param {number} skuIndex - Index of the SKU.
   * @param {string} field - Field name to update.
   * @param {string} value - New value for the field.
   */
  const handleChange = (skuIndex, field, value) => {
    setSkus((prev) => {
      const updated = [...prev];
      updated[skuIndex][field] = value;
      return updated;
    });
  };

  /**
   * handlePricingChange
   * Updates a field value for a specific pricing row of a SKU.
   *
   * @param {number} skuIndex - Index of the SKU.
   * @param {number} pricingIndex - Index of the pricing row.
   * @param {string} field - Field name to update.
   * @param {string} value - New value for the field.
   */
  const handlePricingChange = (skuIndex, pricingIndex, field, value) => {
    setSkus((prev) => {
      const updated = [...prev];
      updated[skuIndex].pricing[pricingIndex][field] = value;
      return updated;
    });
  };

  /**
   * handleSaveSKU
   * Validates and saves the SKU data by calling the `handleSave` function.
   */
  const handleSaveSKU = () => {
    if (validateInputs()) {
      handleSave(skus);
    }
  };

  return (
    <Stack spacing={3} sx={{ marginTop: '20px' }}>
      {/* Table to display SKUs and their pricing details */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Vendor/Partner Name</TableCell>
              <TableCell sx={{width:'17.5%',fontSize:'12px'}}>Part No</TableCell>
              <TableCell sx={{width:'20%',fontSize:'12px'}}>Description</TableCell>
              <TableCell sx={{fontSize:'12px',width:'5%'}} >Unit Of Measure</TableCell>
              <TableCell sx={{fontSize:'12px'}}>Date Ranges</TableCell>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Customer Cost</TableCell>
              <TableCell sx={{width:'15%',fontSize:'12px'}}>Company Cost</TableCell>
              <TableCell sx={{width:'17.5%',fontSize:'12px'}}>Margin (%)</TableCell>
              <TableCell sx={{fontSize:'12px',}}>Actions</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {skus.map((sku, skuIndex) => (
              <React.Fragment key={skuIndex}>
                {/* Main SKU Row */}
                <TableRow>
                <TableCell>
                    <TextField
                      size="small"
                      value={sku.vendorPartnerName}
                      required
                      multiline
                      sx={{fontSize:'12px'}}
                      onChange={(e) =>
                        handleChange(skuIndex, 'vendorPartnerName', e.target.value)
                      }
                    ></TextField>
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={sku.partNo}
                      required
                      multiline
                      sx={{fontSize:'12px'}}
                      onChange={(e) =>
                        handleChange(skuIndex, 'partNo', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      sx={{fontSize:'12px'}}
                      minRows={3}
                      value={sku.itemDescription}
                      multiline
                      required
                      onChange={(e) =>
                        handleChange(skuIndex, 'itemDescription', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      size="small"
                      value={sku.unitOfMeasure}
                      required
                      sx={{fontSize:'12px'}}
                      onChange={(e) =>
                        handleChange(skuIndex, 'unitOfMeasure', e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell colSpan={4}>
                    <Button
                      size="small"
                      variant="outlined"
                      sx={{fontSize:'12px'}}
                      color="success"
                      onClick={() => handleAddPricingRow(skuIndex)}
                      startIcon={<AddCircleOutlineIcon />}
                    >
                      Add Pricing Row
                    </Button>
                  </TableCell>
                  <TableCell>
                    {skus.length > 1 && (
                      <IconButton
                        size="small"
                        color="error"
                        onClick={() => handleRemoveSKU(skuIndex)}
                      >
                        <RemoveCircleOutlineIcon />
                      </IconButton>
                    )}
                  </TableCell>
                </TableRow>
                {/* Pricing Rows */}
                {sku.pricing.map((pricing, pricingIndex) => (
                  <TableRow key={pricingIndex}>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell colSpan={2}></TableCell>
                    <TableCell colSpan={1}></TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="date"
                        required
                        value={pricing.fromDate}
                        sx={{fontSize:'12px'}}
                        onChange={(e) =>
                          handlePricingChange(
                            skuIndex,
                            pricingIndex,
                            'fromDate',
                            e.target.value
                          )
                        }
                      />
                      <TextField
                        size="small"
                        type="date"
                        required
                        value={pricing.toDate}
                        sx={{fontSize:'12px'}}
                        onChange={(e) =>
                          handlePricingChange(
                            skuIndex,
                            pricingIndex,
                            'toDate',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        required
                        value={pricing.customerCost}
                        sx={{fontSize:'12px'}}
                        onChange={(e) =>
                          handlePricingChange(
                            skuIndex,
                            pricingIndex,
                            'customerCost',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                        required
                        value={pricing.companyCost}
                        sx={{fontSize:'12px'}}
                        onChange={(e) =>
                          handlePricingChange(
                            skuIndex,
                            pricingIndex,
                            'companyCost',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      <TextField
                        size="small"
                        type="number"
                    
                        value={pricing.marginPercent}
                        sx={{fontSize:'12px'}}
                        onChange={(e) =>
                          handlePricingChange(
                            skuIndex,
                            pricingIndex,
                            'marginPercent',
                            e.target.value
                          )
                        }
                      />
                    </TableCell>
                    <TableCell>
                      {sku.pricing.length > 1 && (
                        <IconButton
                          size="small"
                          color="error"
                          onClick={() =>
                            handleRemovePricingRow(skuIndex, pricingIndex)
                          }
                        >
                          <RemoveCircleOutlineIcon />
                        </IconButton>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Add New SKU Button */}
      <Button
        variant="outlined"
        size="small"
        color="success"
        onClick={handleAddSKU}
        startIcon={<AddCircleOutlineIcon />}
      >
        Add SKU
      </Button>

      {/* Save All SKUs Button */}
      <Button
        variant="contained"
        size="small"
        color="primary"
        onClick={handleSaveSKU}
      >
        Save All SKUs
      </Button>
    </Stack>
  );
};

export default AddSKUComponent;
