import React, { useState } from "react";
import FilterSection from "./FilterSection"; // Import the new component
import {
  Box,
  Typography,
  Paper,
  Table,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Pagination,
  Stack,
  Button,
} from "@mui/material";
import DHSRow from "./DHSTableRowHelper";
import AxiosFunction from "../../../axiosCustomInstance";
import LoadingDialog from "../../Loading";
import ApplicationBar from "../../AppBar/ApplicationBar";
import { blueGrey } from "@mui/material/colors";
import FileDownloadButton from "./DHSExcelFileDownloadInternal";

function DHSReqsSearch() {
  const [loading, setLoading] = useState(false);
  const [totalData, setTotalData] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage] = useState(5);
  const [searchText, setSearchText] = useState("");
  const [getDHSNum, setDHSNum] = useState("");
  const [orderType, setOrderType] = useState("All");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [includeFiles, setIncludeFiles] = useState(false);
  const AxiosAPIInstance = AxiosFunction();

  const handleSearch = async () => {
    setLoading(true);
    try {
      const params = {
        REQ_ID: getDHSNum,
        Order_Type: orderType,
        searchText,
        startDate,
        endDate,
        includeFiles,
      };

      const { data } = await AxiosAPIInstance.get("/DHSCustomer/DHSReqsSearch/searchDHSRequests", {
        params,
      });
      setTotalData(data?.length ? data : []);
    } catch (error) {
      console.error(error);
      alert("Error fetching data. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setDHSNum("");
    setOrderType("All");
    setStartDate("");
    setEndDate("");
    setSearchText("");
    setIncludeFiles(false);
    setTotalData([]);
  };

  const handlePageChange = (_, newPage) => {
    setPage(newPage);
  };

  const displayedRows = totalData.slice((page - 1) * rowsPerPage, page * rowsPerPage);

  const handleDownload = (fileName) => {
    // Download logic here
    console.log("Downloading file:", fileName);
    // Replace with your API call to download the file
  };

  return (
    <Box>
      {/* Application Bar */}
      <ApplicationBar />

      {/* Loading Indicator */}
      <LoadingDialog open={loading} />

      {/* Title */}
      <Typography
          sx={{
            fontSize: "25px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: blueGrey[900],
            marginTop:'60px'
          }}
        >
       DHS Requests
        </Typography>


      {/* Filter Section */}
      <FilterSection
        getDHSNum={getDHSNum}
        setDHSNum={setDHSNum}
        orderType={orderType}
        setOrderType={setOrderType}
        startDate={startDate}
        setStartDate={setStartDate}
        endDate={endDate}
        setEndDate={setEndDate}
        includeFiles={includeFiles}
        setIncludeFiles={setIncludeFiles}
        searchText={searchText}
        setSearchText={setSearchText}
        onSearch={handleSearch}
        onClear={handleClear}
      />

      {/* Data Table */}
      <Paper elevation={3} sx={{ p: 3, mx: 3 }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell sx={{ bgcolor: blueGrey[600], color: "white" }}>
                  <Typography fontWeight="bold">Request Details</Typography>
                </TableCell>
        
                <TableCell sx={{ bgcolor: blueGrey[600], color: "white" }}>
                  <Typography fontWeight="bold">File Upload Info</Typography>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {displayedRows.map((row, index) => (
                <TableRow key={index}>
                  {/* Request Details */}
                  <TableCell>
                    <DHSRow row={row} />
                  </TableCell>

                  {/* File Upload Info */}
                  <TableCell>
                    {row.Excel_Sheet_Upload ? (
                      <>
                        <Typography variant="body2">
                          <b>File Name:</b> {row.File_Name}
                        </Typography>
                        <FileDownloadButton filename={row.File_Name} />
                      </>
                    ) : (
                      <Typography variant="body2" color="text.secondary">
                        No File Uploaded
                      </Typography>
                    )}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <Stack direction="row" justifyContent="center" mt={3}>
          <Pagination
            count={Math.ceil(totalData.length / rowsPerPage)}
            page={page}
            onChange={handlePageChange}
            color="primary"
          />
        </Stack>
      </Paper>
    </Box>
  );
}

export default DHSReqsSearch;
