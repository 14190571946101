import { 
  Box, 
  Typography, 
  Paper, 
  Tooltip, 
  TextField 
} from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import { useState, useEffect } from "react";
import { blueGrey, orange } from "@mui/material/colors";
import dayjs from "dayjs";

function AdvancedSearchDateFilters({ dateFilters, handleDateFilterChange }) {
  const [showAdvancedFilters, setShowAdvancedFilters] = useState(false);

  useEffect(() => {
    // Get today's date
    const today = dayjs();
  
    // Calculate one year ago
    const oneYearAgo = today.subtract(1, "year");
  
    // Format as 'YYYY-MM-DD'
    const createdDateFrom = oneYearAgo.format("YYYY-MM-DD");
    const createdDateTo = today.format("YYYY-MM-DD");
  
    // Debug logs
    // console.log("From Date (One Year Ago):", createdDateFrom);
    // console.log("To Date (Today):", createdDateTo);
  
    // Update the filters
    handleDateFilterChange("createdDateFrom", createdDateFrom);
    handleDateFilterChange("createdDateTo", createdDateTo);
  }, [handleDateFilterChange]);
  

  return (
    <Paper
      elevation={showAdvancedFilters ? 3 : 1}
      sx={{
        padding: showAdvancedFilters ? "20px" : "10px",
        borderRadius: "12px",
        boxShadow: showAdvancedFilters
          ? "0px 4px 10px rgba(0, 0, 0, 0.1)"
          : "0px 2px 4px rgba(0, 0, 0, 0.05)",
        transition: "all 0.3s ease",
        cursor: "pointer",
        "&:hover": !showAdvancedFilters && {
          boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.1)",
        },
      }}
      onClick={() => !showAdvancedFilters && setShowAdvancedFilters(true)}
    >
      <Box
        display="flex"
        flexWrap="wrap"
        gap="8px"
        sx={{ transition: "all 0.3s ease", fontSize: '12px' }}
      >
        {[
          { title: "Created Date", from: "createdDateFrom", to: "createdDateTo" },
          { title: "Due Date", from: "dueDateFrom", to: "dueDateTo" },
          { title: "Published Date", from: "publishedDateFrom", to: "publishedDateTo" },
        ].map((filter, index) => (
          <Box
            key={index}
            display="flex"
            flexDirection="column"
            alignItems="center"
            padding="8px"
            borderRadius="12px"
            sx={{
              backgroundColor: "white",
              boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.1)",
              minWidth: "70px",
              maxWidth: "170px",
              transition: "transform 0.2s ease",
              "&:hover": {
                transform: "scale(1.02)",
                boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.2)",
              },
              fontSize: '12px',
            }}
          >
            <Typography
              variant="subtitle1"
              fontWeight="bold"
              color={blueGrey[800]}
              marginBottom="8px"
              display="flex"
              alignItems="center"
              sx={{ fontSize: '13px' }}
            >
              <CalendarTodayIcon sx={{ marginRight: "8px", color: orange[800] }} />
              {filter.title}
            </Typography>
            <Tooltip title={`Select ${filter.title.toLowerCase()} (from)`}>
              <DatePicker
                label="From"
                value={dateFilters[filter.from] ? dayjs(dateFilters[filter.from]) : null}
                onChange={(date) =>
                  handleDateFilterChange(
                    filter.from,
                    date ? dayjs(date).format("YYYY-MM-DD") : ""
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" fullWidth sx={{ marginBottom: "10px" }} />}
              />
            </Tooltip>
            <Tooltip title={`Select ${filter.title.toLowerCase()} (to)`}>
              <DatePicker
                label="To"
                value={dateFilters[filter.to] ? dayjs(dateFilters[filter.to]) : null}
                onChange={(date) =>
                  handleDateFilterChange(
                    filter.to,
                    date ? dayjs(date).format("YYYY-MM-DD") : ""
                  )
                }
                renderInput={(params) => <TextField {...params} size="small" fullWidth />}
              />
            </Tooltip>
          </Box>
        ))}
      </Box>
    </Paper>
  );
}

export default AdvancedSearchDateFilters;
