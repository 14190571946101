import React, { useState, useEffect } from "react";
import {
  MenuItem,
  Select,
  FormControl,
  InputLabel,
  Stack,
  TextField,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Tooltip,
  Typography,
  Alert,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
} from "@mui/material";
import AddBoxIcon from "@mui/icons-material/AddBox";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import { blueGrey, green, orange, red } from "@mui/material/colors";
import AxiosFunction from "../../../axiosCustomInstance";
import UserAuth from "../../ProtectedRoute/userAuth";
import LoadingDialog from "../../Loading";

const SKUsManager = ({ contractVehicleID, alreadyAddedSKUs, onAddSKU, onRemoveSKU }) => {
  const [dateRanges, setDateRanges] = useState([]);
  const [selectedRange, setSelectedRange] = useState("");
  const [filteredSKUs, setFilteredSKUs] = useState([]);
  const [searchInput, setSearchInput] = useState("");
  const [sortConfig, setSortConfig] = useState({ key: "", direction: "asc" });
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [notification, setNotification] = useState({ open: false, message: "" });
  const AxiosAPIInstance = AxiosFunction();
  const { UserEmail, UserName } = UserAuth();
  const today = new Date();

  // Fetch SKUs grouped by date range
  useEffect(() => {
    const fetchSKUs = async () => {
      try {
        setLoading(true);
        const response = await AxiosAPIInstance.get(
          "/ContractTypes/SKUs/getSKUsByContractVehicle",
          { params: { contractVehicleID } }
        );

        const groupedSKUs = Object.keys(response.data).map((key) => ({
          key,
          fromDate: response.data[key].fromDate,
          toDate: response.data[key].toDate,
          skus: response.data[key].skus || [],
        }));

        const defaultRange = groupedSKUs.find(
          (range) =>
            new Date(range.fromDate) <= today && new Date(range.toDate) >= today
        );

        setDateRanges(groupedSKUs);
        setSelectedRange(defaultRange?.key || groupedSKUs[0]?.key);
        setFilteredSKUs(defaultRange?.skus || groupedSKUs[0]?.skus || []);
      } catch (error) {
        console.error("Error fetching SKUs:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchSKUs();
  }, [contractVehicleID]);

  // Filter SKUs based on search input and selected date range
  useEffect(() => {
    const selectedGroup =
      dateRanges.find((range) => range.key === selectedRange)?.skus || [];

    const searchTerm = searchInput.toLowerCase();
    const filtered = selectedGroup.filter(
      (sku) =>
        !alreadyAddedSKUs.includes(sku.skuID) &&
        (sku.partNo.toLowerCase().includes(searchTerm) ||
          sku.itemDescription.toLowerCase().includes(searchTerm))
    );

    setFilteredSKUs(filtered);
  }, [searchInput, selectedRange, dateRanges, alreadyAddedSKUs]);

  // Sorting functionality
  const sortSKUs = (key) => {
    const direction =
      sortConfig.key === key && sortConfig.direction === "asc" ? "desc" : "asc";
    setSortConfig({ key, direction });

    const sorted = [...filteredSKUs].sort((a, b) => {
      if (a[key] < b[key]) return direction === "asc" ? -1 : 1;
      if (a[key] > b[key]) return direction === "asc" ? 1 : -1;
      return 0;
    });

    setFilteredSKUs(sorted);
  };

  // Handle adding an SKU
  const handleAddSKU = (sku) => {
    if (!sku || typeof sku.customerCost === "undefined") {
      setErrorMessage(`Pricing for SKU ${sku.partNo} does not exist for the current date.`);
      return;
    }

    setErrorMessage(""); // Clear any previous error
    onAddSKU(sku);
    setNotification({ open: true, message: `SKU "${sku.partNo}" added successfully.` });
  };

  // Handle removing an SKU
  const handleRemoveSKU = (sku) => {
    onRemoveSKU(sku);
    setNotification({ open: true, message: `SKU "${sku.partNo}" removed successfully.` });
  };

  // Close notification dialog
  const closeNotification = () => {
    setNotification({ open: false, message: "" });
  };

  return (
    <Stack spacing={3} sx={{ marginTop: "20px" }}>
      <LoadingDialog open={loading} />

      {/* Dropdown for Date Ranges */}
      <FormControl fullWidth>
        <InputLabel>Select Date Range</InputLabel>
        <Select
          variant="standard"
          value={selectedRange}
          onChange={(e) => setSelectedRange(e.target.value)}
        >
          {dateRanges.map((range) => (
            <MenuItem key={range.key} value={range.key}>
              {`${range.fromDate} - ${range.toDate}`}
            </MenuItem>
          ))}
        </Select>
      </FormControl>

      {/* Error Message */}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}

      {/* Search Field and Total SKUs */}
      <Stack direction="row" justifyContent="space-between" alignItems="center">
        <TextField
          label="Search SKUs"
          variant="outlined"
          value={searchInput}
          onChange={(e) => setSearchInput(e.target.value)}
        />
        <Typography variant="subtitle2" sx={{ color: red[900] }}>
          Total SKUs: {filteredSKUs.length}
        </Typography>
      </Stack>

      {/* SKUs Table */}
      <TableContainer component={Paper}>
        <Table>
          <TableHead sx={{ bgcolor: blueGrey[700] }}>
            <TableRow>
              {[
                { label: "Action", key: "action" },
                { label: "Part No", key: "partNo" },
                { label: "Description", key: "itemDescription" },
                { label: "Pricing", key: "customerCost" },
              ].map((column) => (
                <TableCell key={column.key} sx={{ color: "white" }}>
                  <Stack direction="row" alignItems="center">
                    {column.label}
                    {column.key !== "action" && (
                      <IconButton
                        size="small"
                        onClick={() => sortSKUs(column.key)}
                        sx={{ color: orange[500] }}
                      >
                        {sortConfig.key === column.key && sortConfig.direction === "asc" ? (
                          <ArrowUpwardIcon />
                        ) : (
                          <ArrowDownwardIcon />
                        )}
                      </IconButton>
                    )}
                  </Stack>
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredSKUs.map((sku) => (
              <TableRow key={sku.skuID}>
                <TableCell>
                  {alreadyAddedSKUs.includes(sku.skuID) ? (
                    <Tooltip title={`Remove SKU# ${sku.partNo}`}>
                      <IconButton
                        onClick={() => handleRemoveSKU(sku)}
                        sx={{ color: "red" }}
                      >
                        <RemoveCircleIcon />
                      </IconButton>
                    </Tooltip>
                  ) : (
                    <Tooltip title={`Add SKU# ${sku.partNo}`}>
                      <IconButton
                        onClick={() => handleAddSKU(sku)}
                        sx={{ color: "green" }}
                      >
                        <AddBoxIcon />
                      </IconButton>
                    </Tooltip>
                  )}
                </TableCell>
                <TableCell>{sku.partNo}</TableCell>
                <TableCell>{sku.itemDescription}</TableCell>
                <TableCell>${sku.customerCost}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      {/* Notification Dialog */}
      <Dialog open={notification.open} onClose={closeNotification}>
        <DialogTitle><Alert variant="filled"  sx={{ bgcolor: green[300],color:'white'}}>
                SKU Details
              </Alert></DialogTitle>
        <DialogContent>
          <DialogContentText>{notification.message}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeNotification} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
};

export default SKUsManager;
