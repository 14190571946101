import React, { useState, useEffect } from 'react';
import {
  AppBar,
  Toolbar,
  Typography,
  Box,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Alert,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Collapse,
  Stack,
  Tooltip,DialogContentText,
  Container,Select,MenuItem,InputLabel,FormControl
} from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  Folder as FolderIcon,
  InsertDriveFile as FileIcon,
  Add as AddIcon,
  ExpandLess,
  ExpandMore,
  Edit as EditIcon,
  Info as InfoIcon,
  UploadFile as UploadFileIcon,
  Download as DownloadIcon,
  Visibility as VisibilityIcon,
} from '@mui/icons-material';
import AxiosFunction from '../../axiosCustomInstance';
import LoadingDialog from '../Loading';
import { orange, blueGrey } from '@mui/material/colors';
import { selectIsAdmin } from '../Login_Registration/authSlice';
import UserAuth from '../ProtectedRoute/userAuth';
import ApplicationBar from '../AppBar/ApplicationBar';
import { useSelector } from 'react-redux';
import DeleteIcon from '@mui/icons-material/Delete';
import GenerateQR from '../QRCodeGenerator/QRCodeGenerator';
import formatDateTime from '../HelperFunctions/HumanReadableDateAndTimeStamp';

const DHSCBPBodyArmorInstructions = () => {
  const [folderList, setFolderList] = useState([]);
  const [fileList, setFileList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [folderDialogOpen, setFolderDialogOpen] = useState(false);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [videoDialogOpen, setVideoDialogOpen] = useState(false);
  const [folderName, setFolderName] = useState('');
  const [folderDescription, setFolderDescription] = useState('');
  const [folderToEdit, setFolderToEdit] = useState(null);
  const [selectedFolder, setSelectedFolder] = useState(null);
  const [fileDescription, setFileDescription] = useState('');
  const [file, setFile] = useState(null);
  const [currentVideo, setCurrentVideo] = useState('');
  const [contractVehicle, setContractVehicle] = useState('Tactical');
  const [fileType, setFileType] = useState('OTHER');
  const [fileExtension, setFileExtension] = useState('PDF');
  const [message, setMessage] = useState('');
  const [successDialog, setSuccessDialog] = useState(false);
  const [errorDialog, setErrorDialog] = useState(false);
  const [openFolders, setOpenFolders] = useState({});
  const [searchQuery, setSearchQuery] = useState('');
  const AxiosAPIInstance = AxiosFunction();
  const { UserName, UserEmail } = UserAuth();
  const isAdmin = useSelector(selectIsAdmin);
  const [uploadFileDialogID,setUploadFileDialogID] =useState('')
  const [areYouSureDialog,setAreYouSureDialog]=useState(false)
  const [areYouSureDialogDecision,setAreYouSureDialogDecision]=useState(false)
  const [deleteFileID,setDeleteFileID]=useState(false)

  React.useEffect(() => {
    fetchFolders();

  }, [successDialog]);

  const fetchFolders = async () => {
    try {
      setLoading(true);
      const response = await AxiosAPIInstance.get('/Folders/list');
      setFolderList(response.data);
    } catch (error) {
      setErrorDialog(true);
      setMessage('Failed to fetch folders.');
    } finally {
      setLoading(false);
    }
  };
  //fetch files in a folder
  const fetchFiles = async (folderID) => {
    try {
      setLoading(true);
      const response = await AxiosAPIInstance.get(`/Files/folders/${folderID}/files`);
      setFileList(response.data);
      setSelectedFolder(folderID);
    } catch (error) {
      setErrorDialog(true);
      setMessage('Failed to fetch files.');
    } finally {
      setLoading(false);
    }
  };

  const toggleFolder = (folderID) => {
    setOpenFolders((prev) => ({ ...prev, [folderID]: !prev[folderID] }));
    if (!openFolders[folderID]) fetchFiles(folderID);
  };


  const handleSearch = async () => {
    if (!searchQuery.trim()) {
      setErrorDialog(true);
      setMessage('Please enter a search query.');
      return;
    }
  
    try {
      setLoading(true); // Show loading indicator
      const response = await AxiosAPIInstance.get(`/Folders/search`, {
        params: { query: searchQuery.trim() }, // Send query as parameter
      });
  
      if (response.status === 200) {
        const { folders, files } = response.data; // Expect folders and files in response
        setFolderList(folders);
        setFileList(files);
  
        if (folders.length === 0 && files.length === 0) {
          setMessage('No results found.');
          setErrorDialog(true);
        }
      } else {
        throw new Error('Failed to fetch search results.');
      }
    } catch (error) {
      console.error('Search Error:', error);
      setErrorDialog(true);
      setMessage(
        error.response?.data?.message || 'An error occurred while performing the search.'
      );
    } finally {
      setLoading(false); // Hide loading indicator
    }
  };
  // Function to handle folder name update
  const handleUpdateFolderName = async (folderID, newName) => {
    if (!newName.trim()) {
      setErrorDialog(true);
      setMessage('Folder name cannot be empty.');
      return;
    }
    try {
      setLoading(true);
      await AxiosAPIInstance.put(`/Folders/update/${folderID}`, {
        Folder_Name: newName.trim(),
        Updated_By: UserName,
        Updated_By_Email: UserEmail,
      });
      // Update the folder list locally
      setFolderList((prev) =>
        prev.map((folder) =>
          folder.Folder_ID === folderID
            ? { ...folder, Folder_Name: newName.trim(), isEditing: false }
            : folder
        )
      );
      setSuccessDialog(true);
      setMessage('Folder name updated successfully.');
    } catch (error) {
      setErrorDialog(true);
      setMessage('Failed to update folder name.');
    } finally {
      setLoading(false);
    }
  };

// Upload a file
const uploadFile = async ( folderId) => {
  setLoading(true);
  try {
    const fileData=file
    const Folder_ID=folderId
    const metadata={
      Uploaded_By_Name: UserName,
      Uploaded_By_Email: UserEmail,
      File_Type: fileType,
      File_Description: fileDescription,
      File_Extension: fileExtension,
    }
    const formData = new FormData();
    formData.append('file', fileData);
    formData.append('Folder_ID', folderId);
    Object.keys(metadata).forEach((key) => formData.append(key, metadata[key]));

    const response = await AxiosAPIInstance.post(`/Files/upload`, formData, {
      headers: { 'Content-Type': 'multipart/form-data' },
    });
    setLoading(false);
    setSuccessDialog(true);
    setMessage('File Uploaded successfully.');

  } catch (error) {
    setLoading(false);
    setSuccessDialog(false);
    setErrorDialog(true)
    setMessage('File Upload Failed.');
  }
};
const handleClose =async()=>{
  setErrorDialog(false)
  setSuccessDialog(false)
  setAreYouSureDialog(false)
  setMessage('')
}
  const renderFolders = (folders, parentID = null) => {
    return folders
      .filter((folder) => folder.Parent_Folder_ID === parentID)
      .map((folder) => (
        <Box key={folder.Folder_ID}>
          <ListItem>
            <ListItemIcon>
              <FolderIcon sx={{ color: orange[500] }} />
            </ListItemIcon>
            <ListItemText
              primary={`${folder.Folder_Name} (${folder.Files_Count || 0} files)`}
              secondary={`Created By: ${folder.Created_By}, Last Updated: ${folder.Last_Updated_At}`}
            />
            <Tooltip title="Upload File">
              <IconButton onClick={() => openFileDialog(folder.Folder_ID)}>
                <UploadFileIcon />
              </IconButton>
            </Tooltip>
            <Tooltip title="Expand/Collapse">
              <IconButton onClick={() => toggleFolder(folder.Folder_ID)}>
                {openFolders[folder.Folder_ID] ? <ExpandLess /> : <ExpandMore />}
              </IconButton>
            </Tooltip>
          </ListItem>
          <Collapse in={openFolders[folder.Folder_ID]} timeout="auto" unmountOnExit>
            <List component="div" disablePadding>
              {fileList
                .filter((file) => file.Folder_ID === folder.Folder_ID)
                .map((file) => (
                  <ListItem key={file.File_ID}>
                    <ListItemIcon>
                      <FileIcon sx={{ color: blueGrey[500] }} />
                    </ListItemIcon>
                    <ListItemText primary={file.File_Name} />
                    <Tooltip title="Download">
                      <IconButton onClick={() => handleDownloadFile(file.File_ID, file.File_Name)}>
                        <DownloadIcon />
                      </IconButton>
                    </Tooltip>
                    {file.File_Extension === 'MP4' && (
                      <Tooltip title="View Video">
                        <IconButton onClick={() => openVideoDialog(file.File_URL)}>
                          <VisibilityIcon />
                        </IconButton>
                      </Tooltip>
                    )}
                  </ListItem>
                ))}
              {fileList.filter((file) => file.Folder_ID === folder.Folder_ID).length === 0 && (
                <Typography variant="body2" sx={{ pl: 6 }}>
                  No files available.
                </Typography>
              )}
            </List>
          </Collapse>
        </Box>
      ));
  };

  const openFileDialog = (folderID) => {
    setSelectedFolder(folderID);
    setFileDialogOpen(true);
  };

  const openVideoDialog = (videoURL) => {
    setCurrentVideo(videoURL);
    setVideoDialogOpen(true);
  };

  const handleDownloadFile = async (fileID, fileName) => {
    try {
      const response = await AxiosAPIInstance.get(`/Files/download/${fileID}`, {
        responseType: 'blob',
      });
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);
      document.body.appendChild(link);
      link.click();
      link.remove();
    } catch (error) {
      setErrorDialog(true);
      setMessage('Failed to download file.');
    }
  };
  const handleCreateFolder = async () => {
    if (!folderName.trim()) {
      setErrorDialog(true);
      setMessage('Folder name is required.');
      return;
    }
    try {
      setLoading(true); // Show loading dialog
      const response = await AxiosAPIInstance.post('/Folders/create', {
        Folder_Name: folderName.trim(),
        Folder_Description: folderDescription.trim(),
        Parent_Folder_ID: null, // Set parent folder ID if implementing nested folders
        Created_By: UserEmail, // Send user info for tracking
      });
  
      if (response.status === 201) {
        // Successfully created folder
        setSuccessDialog(true);
        setMessage('Folder created successfully.');
        fetchFolders(); // Refresh folder list
        setFolderDialogOpen(false); // Close dialog
        setFolderName(''); // Reset fields
        setFolderDescription('');
      } else {
        throw new Error('Failed to create folder');
      }
    } catch (error) {
      setErrorDialog(true);
      setMessage(error.response?.data?.message || 'Failed to create folder.');
    } finally {
      setLoading(false); // Hide loading dialog
    }
  };
  // Delete a file
const handleDelete = async (fileID,folderId) => {
  setLoading(true)
  try {
   await AxiosAPIInstance.delete(`/Files/${fileID}/${folderId}`,{params:{UserName:UserName,UserEmail:UserEmail}}).then(async(response)=>{
    setLoading(false)
    setSuccessDialog(true);
    setMessage(response?.data);
    setLoading(false)
   }).catch((error)=>{
    setSuccessDialog(false);
    setErrorDialog(true)
    setMessage(error?.response?.data);
    setLoading(false)
   })

  } catch (error) {
    setSuccessDialog(false);
    setErrorDialog(true)
    setMessage('File Deletion Failed.');
    setLoading(false)
  }
};
  return (
    <>
      <ApplicationBar />
      {/*Rendering success dialog if 'successDialog' state is true */}
      {areYouSureDialog === true && (
        <Dialog
          open={areYouSureDialog}
          onClose={handleClose()}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: "400px" }}>
            <Alert variant='filled' severity='warning'>Warning</Alert>
          </DialogTitle>
          <DialogContent sx={{ width: "400px" }}>
            <DialogContentText id="alert-dialog-description">
              Once deleted, it is deleted permanently. Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="YES" onClick={(e) => { handleDelete(e) }}>YES</Button>
            <Button name="NO" onClick={(e) => { e.preventDefault(); setAreYouSureDialog(false); setAreYouSureDialogDecision(false); }}>
              NO
            </Button>
          </DialogActions>
        </Dialog>
      )}
{successDialog === true && (
  <Dialog
    open={successDialog}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying a success alert if 'rfqMssge' is available */}
      {message && <Alert variant='filled' severity='success'>SUCCESS</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying message */}
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog and reset state */}
      <Button name="OK" onClick={(e) => {
        e.preventDefault();
        handleClose();
      }} >OK</Button>
    </DialogActions>
  </Dialog>
)}

{/*Rendering dialog to display SKU details if 'errorDialog' state is true */}
{errorDialog === true && (
  <Dialog
    open={errorDialog}
    onClose={handleClose}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle id="alert-dialog-title">
      {/* Displaying an error alert if 'rfqMssge' is available */}
      {message && <Alert variant='filled' severity='error'>ERROR</Alert>}
    </DialogTitle>
    <DialogContent>
      <DialogContentText id="alert-dialog-description" sx={{ color: 'black' }}>
        {/* Displaying error message */}
        {message}
      </DialogContentText>
    </DialogContent>
    <DialogActions>
      {/* Button to close dialog */}
      <Button name="OK" onClick={(e) => {
        handleClose();
        e.preventDefault();
      }} >OK</Button>
    </DialogActions>
  </Dialog>
)}
      <Container sx={{ marginTop: '80px' }}>
        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 2 }}>
          <Button
            variant="contained"
            sx={{bgcolor:orange[600],':hover':{bgcolor:orange[400]}}}
            size='small'
            startIcon={<AddIcon />}
            onClick={() => setFolderDialogOpen(true)}
          >
            New Folder
          </Button>
  
          {/* Search Box */}
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
            <TextField
              size="small"
              placeholder="Search folders or files"
              variant="outlined"
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              sx={{ bgcolor: 'white', borderRadius: 1 }}
            />
            <IconButton onClick={handleSearch}>
              <SearchIcon />
            </IconButton>
          </Box>
        </Box>
  
        <List>
  {folderList.map((folder) => (
    <Box key={folder.Folder_ID} sx={{ mb: 1 }}>
      <Box sx={{ display: 'flex', alignItems: 'center' }}>
        {/* Arrow for Expand/Collapse */}
        <IconButton onClick={() => toggleFolder(folder.Folder_ID)}>
          {openFolders[folder.Folder_ID] ? <ExpandLess /> : <ExpandMore />}
        </IconButton>

        {/* Yellow Folder Icon */}
        <FolderIcon sx={{ color: orange[500], mr: 1 }} />

        {/* Folder Name and File Count */}
        <ListItem sx={{ flexGrow: 1, padding: 0 }}>
          {folder.isEditing ? (
            <TextField
              value={folder.editedName}
              onChange={(e) =>
                setFolderList((prev) =>
                  prev.map((f) =>
                    f.Folder_ID === folder.Folder_ID ? { ...f, editedName: e.target.value } : f
                  )
                )
              }
              size="small"
              fullWidth
            />
          ) : (
            <ListItemText
              primary={`${folder.Folder_Name} (${folder.File_Count || 0} files)`}
              secondary={`Created By: ${folder.Created_By}, Last Updated: ${formatDateTime(String(folder.updatedAt))}`}
            />
          )}
        </ListItem>

        {/* Edit Icon */}
        {!folder.isEditing ? (
          <IconButton
            onClick={() =>
              setFolderList((prev) =>
                prev.map((f) =>
                  f.Folder_ID === folder.Folder_ID ? { ...f, isEditing: true } : f
                )
              )
            }
          >
            <EditIcon />
          </IconButton>
        ) : (
          <Button
            variant="contained"
            size="small"
            onClick={() => handleUpdateFolderName(folder.Folder_ID, folder.editedName)}
          >
            Save
          </Button>
        )}

        {/* Upload File */}
        <Tooltip title="Upload File">
          <IconButton onClick={() => 
            { setFileDialogOpen(true)
              setUploadFileDialogID(folder.Folder_ID)
              }}>
            <UploadFileIcon />
          </IconButton>
        </Tooltip>
          {/* Info */}
          {/* <Tooltip title="Folder History">
          <IconButton onClick={() => 
            { setFileDialogOpen(true)
              setUploadFileDialogID(folder.Folder_ID)
              }}>
            <InfoIcon />
          </IconButton>
        </Tooltip> */}
      </Box>

      {/* Collapsible Section for Files */}
      <Collapse in={openFolders[folder.Folder_ID]} timeout="auto" unmountOnExit>
        <List component="div" disablePadding>
          {fileList
            .filter((file) => file.Folder_ID === folder.Folder_ID)
            .map((file) => (
              <ListItem key={file.File_ID} sx={{ pl: 4 }}>
                <ListItemIcon>
                  <FileIcon sx={{ color: blueGrey[500] }} />
                </ListItemIcon>
                <ListItemText primary={file.File_Name} />
                <Tooltip title="Download">
                  <IconButton onClick={() => handleDownloadFile(file.File_ID, file.File_Name)}>
                    <DownloadIcon />
                  </IconButton>
                </Tooltip>
              {/* delete File */}
             <Tooltip title="Delete File">
              <IconButton onClick={(e) => 
               { 
              e?.preventDefault()
              handleDelete(file.File_ID,file.Folder_ID)
              setDeleteFileID(file.File_ID)
              }}>
              <DeleteIcon />
             </IconButton>
              </Tooltip>
                {file.File_Extension === 'MP4' && (
                  <Tooltip title="View Video">
                    <IconButton onClick={() => openVideoDialog(file.File_URL)}>
                      <VisibilityIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </ListItem>
            ))}

          {/* No Files Message */}
          {fileList.filter((file) => file.Folder_ID === folder.Folder_ID).length === 0 && (
            <Typography variant="body2" sx={{ pl: 6 }}>
              No files available.
            </Typography>
          )}
        </List>
      </Collapse>
    </Box>
  ))}
</List>
        {/* File Upload Dialog */}
        <Dialog open={fileDialogOpen} onClose={() => setFileDialogOpen(false)}>
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900] }}>
            Upload File
          </Alert>
          <DialogContent>
            <Stack spacing={2}>
              {/* File Selection */}
              <TextField type="file" fullWidth onChange={(e) => setFile(e.target.files[0])} />
  
              {/* File Description */}
              <TextField
                fullWidth
                label="File Description"
                required
                multiline
                minRows={3}
                value={fileDescription}
                onChange={(e) => setFileDescription(e.target.value)}
              />
  
              {/* Contract Vehicle Dropdown */}
              <FormControl fullWidth>
                <InputLabel id="contract-vehicle-label">Contract Vehicle</InputLabel>
                <Select
                  labelId="contract-vehicle-label"
                  id="contract-vehicle-select"
                  value={contractVehicle}
                  variant="standard"
                  onChange={(e) => setContractVehicle(e.target.value)}
                >
                  <MenuItem value="Federal">Federal</MenuItem>
                  <MenuItem value="Military">Military</MenuItem>
                  <MenuItem value="Tactical">Tactical</MenuItem>
                  <MenuItem value="GSA">GSA</MenuItem>
                  <MenuItem value="FBOP">FBOP</MenuItem>
                  <MenuItem value="DHS-CBP">DHS-CBP</MenuItem>
                  <MenuItem value="SAM/Open Market">SAM/Open Market</MenuItem>
                  <MenuItem value="Unison">Unison</MenuItem>
                  <MenuItem value="Direct Inquiry">Direct Inquiry</MenuItem>
                  <MenuItem value="Commercial">Commercial</MenuItem>
                  <MenuItem value="Engineering">Engineering</MenuItem>
                </Select>
              </FormControl>
  
              {/* File Type Dropdown */}
              <FormControl fullWidth>
                <InputLabel id="file-type-label">File Type</InputLabel>
                <Select
                  labelId="file-type-label"
                  id="file-type-select"
                  value={fileType}
                  variant="standard"
                  onChange={(e) => setFileType(e.target.value)}
                >
                  <MenuItem value="GENERAL">General</MenuItem>
                  <MenuItem value="PRODUCT DEMO">Product Demo</MenuItem>
                  <MenuItem value="PRODUCT INSTRUCTIONS">Product Instructions</MenuItem>
                  <MenuItem value="OTHER">Other</MenuItem>
                </Select>
              </FormControl>
  
              {/* File Extension Dropdown */}
              <FormControl fullWidth>
                <InputLabel id="file-extension-label">File Extension</InputLabel>
                <Select
                  labelId="file-extension-label"
                  id="file-extension-select"
                  value={fileExtension}
                  variant="standard"
                  onChange={(e) => setFileExtension(e.target.value)}
                >
                  <MenuItem value="MP4">.MP4</MenuItem>
                  <MenuItem value="PDF">.PDF</MenuItem>
                  <MenuItem value="XLSX">.XLSX</MenuItem>
                  <MenuItem value="DOC">.DOC</MenuItem>
                  <MenuItem value="CSV">.CSV</MenuItem>
                  <MenuItem value="TXT">.TXT</MenuItem>
                  <MenuItem value="JPG">.JPG</MenuItem>
                  <MenuItem value="PNG">.PNG</MenuItem>
                </Select>
              </FormControl>
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button  size='small' onClick={() => setFileDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              onClick={(fol) => {
                uploadFile(uploadFileDialogID)
                setFileDialogOpen(false);
              }}
              size='small'
              disabled={!file || !fileDescription}
            >
                 
              Upload
            </Button>
          </DialogActions>
        </Dialog>
  
        {/* Folder Creation Dialog */}
        <Dialog open={folderDialogOpen} onClose={() => setFolderDialogOpen(false)}>
        <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900] }}>
          Create New Folder
          </Alert>
          <DialogContent>
            <Stack spacing={2}>
              <TextField
                label="Folder Name"
                fullWidth
                required
                value={folderName}
                onChange={(e) => setFolderName(e.target.value)}
              />
              <TextField
                label="Folder Description"
                fullWidth
                multiline
                rows={3}
                value={folderDescription}
                onChange={(e) => setFolderDescription(e.target.value)}
              />
            </Stack>
          </DialogContent>
          <DialogActions>
            <Button size='small' onClick={() => setFolderDialogOpen(false)}>Cancel</Button>
            <Button
              variant="contained"
              size='small'
              onClick={handleCreateFolder}
              disabled={!folderName.trim()}
            >
              Create
            </Button>
          </DialogActions>
        </Dialog>
        <GenerateQR/>
        {/* Video Viewing Dialog */}
        <Dialog open={videoDialogOpen} onClose={() => setVideoDialogOpen(false)}>
          <DialogTitle>Video Preview</DialogTitle>
          <DialogContent>
            <video controls width="100%">
              <source src={currentVideo} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => setVideoDialogOpen(false)}>Close</Button>
          </DialogActions>
        </Dialog>
      </Container>
    </>
  );
}  
export default DHSCBPBodyArmorInstructions;
