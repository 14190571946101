import React from 'react';
import { Dialog, DialogContent, Typography, CircularProgress, Box } from '@mui/material';

const LoadingProgressDialog = ({ open, progress }) => (
  <Dialog
    open={open}
    maxWidth="xs"
    fullWidth
    sx={{
      '& .MuiPaper-root': {
        border: '1px solid #ccc',
        borderRadius: '8px',
        boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
        backgroundColor: '#f9f9f9',
      },
    }}
  >
    <DialogContent>
      <Box display="flex" flexDirection="column" alignItems="center" justifyContent="center" p={2}>
        <Box position="relative" display="inline-flex">
          <CircularProgress
            variant="determinate"
            value={progress}
            size={100}
            thickness={4}
            sx={{
              color: 'orange',
            }}
          />
          <Box
            position="absolute"
            top="50%"
            left="50%"
            transform="translate(-50%, -50%)"
          >
            <Typography
              variant="h5"
              component="div"
              fontWeight="bold"
              sx={{
                color: '#333',
              }}
            >
              {progress}%
            </Typography>
          </Box>
        </Box>
        <Typography
          variant="h6"
          align="center"
          mt={2}
          sx={{
            fontFamily: 'Georgia, serif',
            color: '#333',
          }}
        >
          Processing...
        </Typography>
      </Box>
    </DialogContent>
  </Dialog>
);

export default LoadingProgressDialog;
