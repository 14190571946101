import {
    Box,
    Paper,
    TextField,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    Typography,
    Stack,
  } from "@mui/material";
  import { orange, blueGrey } from "@mui/material/colors";
  
  function MainFilters({
    getInvoiceNum,
    setgetInvoiceNum,
    searchTerm,
    handleSearchChange,
    salesHeadName,
    setSalesHeadName,
    usersData,
    stus,
    setStus,
    contractVehicle,
    setContractVehicle,
    contractTypes,
    SetGetClick,
    setTotalData,
    setPage,
  }) {
    return (
      <Paper
        elevation={1}
        sx={{
          padding: "20px",
          marginTop:'-60px',
          borderRadius: "12px",
          boxShadow: "0 4px 10px rgba(0, 0, 0, 0.1)",
        }}
      >
        {/* Filters Section */}
        <Stack
          direction="row"
          spacing={3}
          flexWrap="wrap"
          alignItems="center"
          sx={{ gap: "10px" }}
        >
        <Stack spacing={2}>
          {/* Quote Number Filter */}
          <TextField
            size="small"
            label="Quote Number"
            value={getInvoiceNum}
            onChange={(e) => {
              setgetInvoiceNum(e.target.value);
              SetGetClick(false);
            }}
            sx={{ minWidth: "250px" }}
          />
  
          {/* General Search Filter */}
          <TextField
            size="small"
            label="Search"
            variant="outlined"
            helperText="Search by Customer's/KO's Names & Emails/Organization Name/Opportunity Name"
            value={searchTerm}
            onChange={handleSearchChange}
            sx={{ flex: 1, minWidth: "200px" }}
          />
          </Stack>
        <Stack spacing={2}>
 {/* Sales Head Email Filter */}
       <FormControl size="small" sx={{ minWidth: "250px" }}>
            <InputLabel id="Sales-Head-id">Sales Head Email ID</InputLabel>
            <Select
              labelId="Sales-Head-id"
              value={String(salesHeadName).toLowerCase()}
              onChange={(e) => {
                SetGetClick(false);
                setSalesHeadName(e.target.value);
                setTotalData([]);
              }}
              variant="standard"
            >
              <MenuItem value="all">All</MenuItem>
              {usersData?.map((row, index) => (
                <MenuItem key={index} value={String(row?.User_email).toLowerCase()}>
                  {String(row?.User_email).toLowerCase()}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
  
          {/* Status Filter */}
          <FormControl size="small" sx={{ minWidth: "150px" }}>
            <InputLabel id="Quote_Status">Status</InputLabel>
            <Select
              labelId="Quote_Status"
              value={stus}
              onChange={(e) => setStus(e.target.value)}
              variant="standard"
            >
              <MenuItem value="ALL">ALL</MenuItem>
              <MenuItem value="PENDING">PENDING</MenuItem>
              <MenuItem value="WON">WON</MenuItem>
              <MenuItem value="LOST">LOST</MenuItem>
              <MenuItem value="CANCELLED">CANCELLED</MenuItem>
            </Select>
          </FormControl>
  
          {/* Contract Vehicle Filter */}
          <FormControl size="small" sx={{ minWidth: "250px" }}>
            <InputLabel id="Contract-Vehicle">Contract Vehicle</InputLabel>
            <Select
              labelId="Contract-Vehicle"
              variant="standard"
              value={contractVehicle}
              onChange={(e) => {
                setTotalData([]);
                setPage(0);
                SetGetClick(false);
                setContractVehicle(e.target.value);
              }}
            >
              <MenuItem value="All">All</MenuItem>
              {contractTypes.length > 0 ? (
                contractTypes.map((type, index) => (
                  <MenuItem key={index} value={type?.contractTypeName}>
                    {type?.contractTypeName}
                  </MenuItem>
                ))
              ) : (
                <MenuItem value="" disabled>
                  No contract types available
                </MenuItem>
              )}
            </Select>
          </FormControl>

        </Stack>
         
        </Stack>
      </Paper>
    );
  }
  
  export default MainFilters;
  