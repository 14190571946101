// Importing necessary components and hooks from MUI and React
import {
  Box, Button, TextField, Paper, Stack, Typography, Grid, IconButton,
  Avatar, Tooltip, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, TableSortLabel, Dialog,
  DialogActions, DialogContent, DialogContentText, DialogTitle, Alert, FormControl, InputLabel, MenuItem, Select, Divider
} from '@mui/material';
import { useState, useEffect } from 'react';
import { useNavigate, createSearchParams } from 'react-router-dom';
import ApplicationBar from "../../AppBar/ApplicationBar";
import UserAuth from '../../ProtectedRoute/userAuth';
import ModeEdit from '@mui/icons-material/ModeEdit';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import SendIcon from '@mui/icons-material/Send';
import DisabledByDefaultIcon from '@mui/icons-material/DisabledByDefault';
import { blueGrey, orange, indigo, red, green, blue } from '@mui/material/colors';
import { createTheme } from '@mui/material/styles';
import { styled } from '@mui/material/styles';
import AxiosFunction from '../../../axiosCustomInstance';
import LoadingDialog from '../../Loading';
import HistoryIcon from '@mui/icons-material/History';
import { color } from '@mui/system';

// Creating a theme for MUI components
const theme = createTheme({
  palette: {
    primary: {
      main: blueGrey[900],
    },
    secondary: {
      main: blueGrey[300],
    },
  },
});

const RFQDrafts = () => {
  // Destructuring user details from UserAuth
  const { UserName, UserEmail } = UserAuth();
  const navigate = useNavigate();
  const PD = new Date();
  const today = new Intl.DateTimeFormat('en-US', { dateStyle: 'medium' }).format(PD);

  // State variables for various dialogs, messages, loading state, etc.
  const [errorAndSuccessDialog, setErrorAndSuccessDialog] = useState(false);
  const [successDialogMsge, setSuccessDialogMsge] = useState("");
  const [errorDialogMsge, setErrorDialogMsge] = useState("");
  const [errorStatus, setErrorStatus] = useState(false);
  const [areYouSureDialog, setAreYouSureDialog] = useState(false);
  const [areYouSureDialogDecision, setAreYouSureDialogDecision] = useState(false);
  const [tranferQuoteDialog, setTranferQuoteDialog] = useState(false);
  const [TransferToEmaill, setTransferToEmail] = useState("");
  const [TransferToComments, setTransferToComments] = useState("");
  const [rfqID, setRFQID] = useState("");
  const [zeroDraftsMessage, setZeroDraftsMessage] = useState(false);
  const [deleteWorkingSalesHead, setDeleteSalesHeadEmail] = useState(false);
  const [order, setOrder] = useState('asc');
  const [orderBy, setOrderBy] = useState('Opportunity_Name');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [loading, setLoading] = useState(false);
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  const [historyContent, setHistoryContent] = useState("");
  const AxiosAPIInstance = AxiosFunction();
  const [Form_Value, Set_Form_Value] = useState([{
    Opportunity_Name: '', RFQ_ID: '', Invoice_No: '', Contract_Vehicle: 'GSA', KO_name: '', KO_email: '',
    KO_phone: '', KO_address: '', Published_date: null, Due_date: null, Brief: '', Organization_Name: '',
    Customer_Name: '', Customer_Email: '', Customer_Phone: '', Customer_Address: '', Shipping_Method: '', Sales_Head_Name: '', Sales_Head_Email: String(UserEmail).toLowerCase() || "sales@aspettoinc.com", Sales_Head_Ph: "",
    Shipping_Terms: 'FOB Origin', Estimated_Delivery: '', Payment_Terms: 'NET30', Notes: '', Shipping_Cost: Number(parseFloat(0).toFixed(2)),
    Tax: Number(parseFloat(0).toFixed(2)),
    Created_Date: today,
    Last_Updated_Date: today, RFQ_Status: 'PENDING',
    Updated_By: 'TBD', Version_No: parseInt(0), Updating_Comments: 'RFQ Created under PENDING Status'
  }]);
  let [usersData, setUsersData] = useState([{ User_email: "" }]);

  // Fetching RFQ drafts data on component mount
  useEffect(() => {
    const fetchDraftsData = async () => {
      setLoading(true);
      try {
        const response = await AxiosAPIInstance.get("/RFQDrafts/GetAllDrafts", {
          params: { Working_Sales_Head: String(UserEmail).toLowerCase() },
        });
        setLoading(false);
        const FormData = [...response?.data];
        if (FormData.length === 0) {
          setZeroDraftsMessage(true);
        } else {
          setZeroDraftsMessage(false);
        }
        Set_Form_Value(FormData);
      } catch (error) {
        setLoading(false);
        setErrorAndSuccessDialog(true);
        setErrorDialogMsge(error?.response.data);
        setErrorStatus(true);
      }
    };
    fetchDraftsData();
  }, [errorAndSuccessDialog]);

  // Fetching users data on component mount
  useEffect(() => {
    const fetchUsersData = async () => {
      setLoading(true);
      try {
        const result = await AxiosAPIInstance.get("/UserAuth/users");
        setLoading(false);
        const users = [...result?.data];
        setUsersData(users);
        setErrorAndSuccessDialog(false);
        setErrorStatus(false);
        setErrorDialogMsge("");
      } catch (error) {
        setLoading(false);
        setErrorAndSuccessDialog(true);
        setErrorDialogMsge(error?.response.data);
        setErrorStatus(true);
      }
    };
    fetchUsersData();
  }, []);

  // Handling closing of error and success dialogs
  const handleErrorSuccesAreYouSureClose = (e) => {
    e.preventDefault();
    if (errorStatus === true) {
      setErrorStatus(false);
      setAreYouSureDialog(false);
      setAreYouSureDialogDecision(false);
      setErrorAndSuccessDialog(false);
      setSuccessDialogMsge("");
      setErrorDialogMsge("");
    } else {
      setAreYouSureDialog(false);
      setAreYouSureDialogDecision(false);
      setErrorAndSuccessDialog(false);
      setSuccessDialogMsge("");
      setErrorDialogMsge("");
      setTransferToEmail("");
      setRFQID("");
      setDeleteSalesHeadEmail("");
      setTransferToComments("");
    }
  };

  // Handling transfer of RFQ to another user
  const handleTransfer = async (e, RFQrow) => {
    e.preventDefault();
    setRFQID(RFQrow?.RFQ_ID);
    setTranferQuoteDialog(true);
    setTransferToEmail("");
    setTransferToComments("");
  };

  // Handling delete confirmation dialog
  const handleDeleteDialog = async (e, RFQrow) => {
    e.preventDefault();
    setErrorStatus(false);
    setAreYouSureDialog(true);
    setRFQID(RFQrow?.RFQ_ID);
    setDeleteSalesHeadEmail(RFQrow?.Working_Sales_Head);
  };

  // Handling edit action
  const handleEdit = async (e, RFQrow) => {
    try {
      e.preventDefault();
      navigate({
        pathname: "/Dashboard/CreateRFQ",
        search: createSearchParams({ RID: RFQrow?.RFQ_ID, SHEm: String(RFQrow?.Working_Sales_Head).toLowerCase() }).toString()
      });
    } catch (error) {
      setErrorAndSuccessDialog(true);
      setErrorStatus(true);
      setErrorDialogMsge("Cannot Complete your request");
    }
  };

  // Handling delete action
  const handleDelete = async (e) => {
    e.preventDefault();
    setAreYouSureDialogDecision(true);
    setLoading(true);
    try {
      await AxiosAPIInstance.delete("/RFQDrafts/DeleteRFQDrafts", {
        params: { RFQ_ID: rfqID, Working_Sales_Head: String(deleteWorkingSalesHead).toLowerCase() }
      }).then((response) => {
        setLoading(false);
        setAreYouSureDialog(false);
        setAreYouSureDialogDecision(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(false);
        setSuccessDialogMsge(response?.data);
      }).catch((error) => {
        setLoading(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(true);
        setErrorDialogMsge("Some Error Occurred While Deleting the Draft");
      });
    } catch (error) {
      setLoading(false);
      setErrorAndSuccessDialog(true);
      setErrorStatus(true);
      setErrorDialogMsge("Some Error Occurred While Deleting the Draft");
    }
  };

  // Handling transfer of RFQ to another user
  const handleTransferTo = async (e, RFQrow) => {
    e.preventDefault();
    const Body = {
      RFQ_ID: rfqID,
      TransferToEmail: String(TransferToEmaill).toLowerCase(),
      Transfer_Comments: TransferToComments
    };
    try {
      await AxiosAPIInstance.post("/RFQDrafts/TransferDrafts", Body).then((response) => {
        setLoading(false);
        setTranferQuoteDialog(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(false);
        setSuccessDialogMsge(response?.data);
      }).catch((error) => {
        setLoading(false);
        setErrorAndSuccessDialog(true);
        setErrorStatus(true);
        setErrorDialogMsge(error?.response.data);
      });
    } catch (error) {
      setLoading(false);
      setErrorAndSuccessDialog(true);
      setErrorStatus(true);
      setErrorDialogMsge(error?.response.data);
    }
  };

  // Handling sorting of table columns
  const handleRequestSort = (property) => (event) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  // Handling change of table page
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  // Handling change of rows per page in the table
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // Handling opening of history dialog
  const openHistoryDialog = (history) => {
    setHistoryContent(history);
    setHistoryDialogOpen(true);
  };

  // Handling closing of history dialog
  const handleHistoryDialogClose = () => {
    setHistoryDialogOpen(false);
  };

  // Sorting rows for display in the table
  const sortedRows = Form_Value.slice().sort((a, b) => {
    if (order === 'asc') {
      return a[orderBy] < b[orderBy] ? -1 : 1;
    } else {
      return a[orderBy] > b[orderBy] ? -1 : 1;
    }
  });

  return (
    <>
      <LoadingDialog open={loading} />
      {<ApplicationBar />}
      {errorAndSuccessDialog === true && (
        <Dialog
          open={errorAndSuccessDialog}
          onClose={handleErrorSuccesAreYouSureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: "400px" }}>
            {!errorStatus ? <Alert variant='filled' severity='success'>Success</Alert> : <Alert variant='filled' severity='error'>Error</Alert>}
          </DialogTitle>
          <DialogContent sx={{ width: "400px" }}>
            <DialogContentText id="alert-dialog-description">
              {!errorStatus ? successDialogMsge : errorDialogMsge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="OK" onClick={(e) => {
              handleErrorSuccesAreYouSureClose(e)
            }}>OK</Button>
          </DialogActions>
        </Dialog>
      )}
      {areYouSureDialog === true && (
        <Dialog
          open={areYouSureDialog}
          onClose={handleErrorSuccesAreYouSureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: "400px" }}>
            <Alert variant='filled' severity='warning'>Warning</Alert>
          </DialogTitle>
          <DialogContent sx={{ width: "400px" }}>
            <DialogContentText id="alert-dialog-description">
              Once deleted, it is deleted permanently. Are you sure you want to delete?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="YES" onClick={(e) => { handleDelete(e) }}>YES</Button>
            <Button name="NO" onClick={(e) => { e.preventDefault(); setAreYouSureDialog(false); setAreYouSureDialogDecision(false); navigate("/Dashboard/RFQDrafts") }}>
              NO
            </Button>
          </DialogActions>
        </Dialog>
      )}
      {zeroDraftsMessage === true && (
        <Stack sx={{ marginTop: "290px", alignContent: "center", textAlign: "center" }}>
          <Typography variant='h3'> Currently, you have zero drafts saved.</Typography>
        </Stack>
      )}
      {zeroDraftsMessage === false && (
        <Box sx={{ marginTop: "100px", marginLeft: "20px", marginRight: "20px" }}>
            <Typography sx={{ fontSize: "25px", fontWeight: 'bold', fontFamily: 'Verdana (sans-serif)' }}>RFQ Drafts</Typography>
          <Stack sx={{ marginTop: "10px", marginBottom: "10px" }} spacing={1} direction="row" justifyContent="center">
            <Divider sx={{ color: blueGrey[900], bgcolor: orange[800], width: "100%", height: "1.5px" }} orientation='horizontal'></Divider>
          </Stack>
          <br />
          <Paper sx={{ width: '100%', mb: 2, boxShadow: 3 }}>
            <TableContainer>
              <Table>
                <TableHead sx={{ bgcolor: blueGrey[800], alignContent: 'center' }}>
                  <TableRow sx={{ color: 'whitesmoke' }}>
                    <TableCell sx={{ color: 'whitesmoke' }}>
                      <TableSortLabel sx={{ color: 'whitesmoke' }} active={orderBy === 'Opportunity_Name'} direction={orderBy === 'Opportunity_Name' ? order : 'asc'} onClick={handleRequestSort('Opportunity_Name')}>
                        Opportunity Name
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>
                      <TableSortLabel sx={{ color: 'whitesmoke' }} active={orderBy === 'Invoice_No'} direction={orderBy === 'Invoice_No' ? order : 'asc'} onClick={handleRequestSort('Invoice_No')}>
                        Invoice No
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>
                      <TableSortLabel sx={{ color: 'whitesmoke' }} active={orderBy === 'Created_Date'} direction={orderBy === 'Created_Date' ? order : 'asc'} onClick={handleRequestSort('Created_Date')}>
                        Created Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>
                      <TableSortLabel sx={{ color: 'whitesmoke' }} active={orderBy === 'Updated_By'} direction={orderBy === 'Updated_By' ? order : 'asc'} onClick={handleRequestSort('Updated_By')}>
                        Updated By
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>
                      <TableSortLabel sx={{ color: 'whitesmoke' }} active={orderBy === 'Last_Updated_Date'} direction={orderBy === 'Last_Updated_Date' ? order : 'asc'} onClick={handleRequestSort('Last_Updated_Date')}>
                        Updated Date
                      </TableSortLabel>
                    </TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>Transfer Comments History</TableCell>
                    <TableCell sx={{ color: 'whitesmoke' }}>Actions</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {sortedRows.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((RFQrow, index) => (
                      <TableRow key={RFQrow.RFQ_ID} >
                        <TableCell>{RFQrow.Opportunity_Name}</TableCell>
                        <TableCell>{RFQrow.Invoice_No}</TableCell>
                        <TableCell>{String(RFQrow.Created_Date).split("T")[0]}</TableCell>
                        <TableCell>{RFQrow.Updated_By}</TableCell>
                        <TableCell>{String(RFQrow.Last_Updated_Date).split("T")[0]}</TableCell>
                        <TableCell>{RFQrow.Transfer_Comments}</TableCell>
                        <TableCell>
                          <Tooltip title="Delegate this Quote to your Co-worker's Bin">
                            <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: green[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleTransfer(e, RFQrow) }}>
                              <Avatar sx={{ backgroundColor: green[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: green[800], transform: 'scale(1.1)' } }} variant="rounded">
                                <SendIcon sx={{ color: 'white', backgroundColor: green[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Delete">
                            <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleDeleteDialog(e, RFQrow) }}>
                              <Avatar sx={{ backgroundColor: red[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                                <DisabledByDefaultIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="Edit">
                            <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' } }} onClick={(e) => { handleEdit(e, RFQrow) }}>
                              <Avatar sx={{ backgroundColor: indigo[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' } }} variant="rounded">
                                <ModeEdit sx={{ color: 'white', backgroundColor: indigo[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                          <Tooltip title="View History">
                            <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: blue[100], transform: 'scale(1.1)' } }} onClick={() => openHistoryDialog(RFQrow.Transfer_History)}>
                              <Avatar sx={{ backgroundColor: blue[800], width: '25px', height: '25px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: blue[800], transform: 'scale(1.1)' } }} variant="rounded">
                                <HistoryIcon sx={{ color: 'white', backgroundColor: blue[800], width: '20px', height: '20px' }} />
                              </Avatar>
                            </IconButton>
                          </Tooltip>
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              rowsPerPageOptions={[5, 10, 25]}
              component="div"
              count={Form_Value.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </Paper>
        </Box>
      )}
      {tranferQuoteDialog === true && (
        <Dialog
          open={tranferQuoteDialog}
          onClose={handleErrorSuccesAreYouSureClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: "450px" }}>
            <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>Please Enter Recipient Details:</Alert>
          </DialogTitle>
          <DialogContent sx={{ width: "450px", height: "280px" }}>
            <DialogContentText id="alert-dialog-description">
              <Stack sx={{ textAlign: "center", alignItems: "center" }}>
                <b>RFQ ID</b><TextField size='small' value={rfqID} disabled sx={{ width: "300px" }}></TextField>
                <br />
                <FormControl>
                  <InputLabel id="Transfer-to-email-id">Select User Email ID</InputLabel>
                  <Select
                    labelId="Transfer-to-email-id"
                    value={TransferToEmaill}
                    variant='standard'
                    style={{ width: 300 }}
                    onChange={(e) => { setTransferToEmail(e.target.value) }}
                  >
                    {usersData?.map((row, index) => (
                      <MenuItem value={row?.User_email}>{row?.User_email}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
                <br />
                <b>Comments: </b><TextField multiline maxRows={10} minRows={3} size='small' value={TransferToComments} onChange={(e) => { setTransferToComments(e.target.value) }} sx={{ width: "300px" }}></TextField>
              </Stack>
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Tooltip title="send">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: green[100], transform: 'scale(1.1)' } }} onClick={(e) => { setLoading(true); handleTransferTo(e) }}>
                <Avatar sx={{ backgroundColor: green[800], width: '30px', height: '30px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: green[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <SendIcon sx={{ color: 'white', backgroundColor: green[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
            <Tooltip title="cancel">
              <IconButton variant="text" sx={{ color: 'white', padding: '10px', borderRadius: 1, transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[100], transform: 'scale(1.1)' } }} onClick={(e) => { setTranferQuoteDialog(false); setTransferToEmail(""); setTransferToComments("") }}>
                <Avatar sx={{ backgroundColor: red[800], width: '30px', height: '30px', transition: 'transform 0.2s ease-in-out', ":hover": { backgroundColor: red[800], transform: 'scale(1.1)' } }} variant="rounded">
                  <DisabledByDefaultIcon sx={{ color: 'white', backgroundColor: red[800], width: '20px', height: '20px' }} />
                </Avatar>
              </IconButton>
            </Tooltip>
          </DialogActions>
        </Dialog>
      )}
      {historyDialogOpen && (
        <Dialog open={historyDialogOpen} onClose={handleHistoryDialogClose} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title" sx={{ width: "450px" }}>
            <Alert variant='filled' severity='info'>History</Alert>
          </DialogTitle>
          <DialogContent sx={{ width: "450px" }}>
            <DialogContentText id="alert-dialog-description">
              {historyContent.split('----------').map((history, index) => (
                <div key={index}>{history}</div>
              ))}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleHistoryDialogClose}>Close</Button>
          </DialogActions>
        </Dialog>
      )}
    </>
  );
};

export default RFQDrafts;