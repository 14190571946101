import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
} from '@mui/material';
import { blueGrey, orange } from '@mui/material/colors';
import GetRFQHistoryButton from './RFQChangeHistory';
// Define styles for various table elements
const headerStyle = { fontWeight: 'bold', backgroundColor: orange[100], fontSize: '13px' };
const revisionHeaderStyle = { fontWeight: 'bold', backgroundColor: blueGrey[200], fontSize: '13px' };
const cellStyle = { padding: '8px', fontSize: '13px' };

// Helper function to parse and return the revision-specific description.
function parseRevisionDescription(description, revisionNo) {
  try {
    // Regular expression to capture text for a specific revision up to the next revision or end of text.
    const revisionPattern = new RegExp(`Revision ${revisionNo} Changes:(.*?)(?=Revision \\d+ Changes|$)`, 'gs');
    const match = revisionPattern.exec(description);
    // If a match is found, return the matched text; otherwise, return a default message.
    return match ? match[1].trim() : 'No changes for this revision.';
  } catch (error) {
    console.error("Error parsing revision description:", error);
    return 'Error retrieving revision details.';
  }
}

// Main component to display SKU revision history
const RevisionDialog = ({ getMoreInfo, row, allVersions, handleClose,quotesArray }) => 
  
  (
  getMoreInfo && (
    <Dialog
      maxWidth="x-lg"
      key={row.RFQQuoteNumber}
      open={getMoreInfo}
      sx={{ justifyContent: 'stretch' }}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      {/* Dialog Title */}
      <DialogTitle id="alert-dialog-title">
        <Alert
          variant="filled"
          sx={{ bgcolor: blueGrey[400], fontSize: '11px' }}
        >
          SKU REVISION HISTORY: {row.Opportunity_Name || 'N/A'}
        </Alert>
      </DialogTitle>

      {/* Dialog Content */}
      <DialogContent sx={{ flexGrow: 'inherit', justifyContent: 'inherit' }}>
        <DialogContentText id="alert-dialog-description">
          {/* Table for displaying revision data */}
          <Table sx={{ flexGrow: 'inherit', width: '100%' }}>
            <TableHead>
              <TableRow>
                {/* Table headers */}
                <TableCell sx={headerStyle} align="center">Vendor/Partner</TableCell>
                <TableCell sx={headerStyle} align="center">SKU ID</TableCell>
                <TableCell sx={headerStyle} align="center">SKU Desc</TableCell>
                <TableCell sx={headerStyle} align="center">QTY</TableCell>
                <TableCell sx={headerStyle} align="center">Aspetto's Cost($)</TableCell>
                <TableCell sx={headerStyle} align="center">Margin(%)</TableCell>
                <TableCell sx={headerStyle} align="center">Customer's Cost($)</TableCell>
                <TableCell sx={headerStyle} align="center">Total Cost($)</TableCell>
                <TableCell sx={headerStyle} align="center">Created Date</TableCell>
                <TableCell sx={headerStyle} align="center">Updated Date</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Display revisions in descending order */}
              {allVersions && allVersions.length > 0 ? (
                Object.entries(
                  allVersions.reduce((acc, P) => {
                    acc[P.Version_No] = acc[P.Version_No] || [];
                    acc[P.Version_No].push(P);
                    return acc;
                  }, {})
                )
                  .sort((a, b) => b[0] - a[0]) // Sort by Version_No in descending order
                  .map(([versionNo, orders], index, allRevisions) => {
                    const previousOrders = allRevisions[index + 1] ? allRevisions[index + 1][1] : [];

                    // Identify deleted SKUs (present in previous revision but not in the current one)
                    const deletedSKUs = previousOrders.filter(
                      prev => !orders.some(order => order.SKU_ID === prev.SKU_ID)
                    );

                    return (
                      <React.Fragment key={index}>
                        {/* Revision header displaying the current revision number */}
                        <TableRow>
        
                          <TableCell colSpan={10} sx={revisionHeaderStyle} align="center">
                            Revision: {versionNo}<br/>Quote No:{quotesArray[versionNo]}
                          </TableCell>
                        </TableRow>
                        {/* <TableRow>
                          <TableCell colSpan={10} sx={revisionHeaderStyle} align="center">
                            <pre>{parseRevisionDescription(row?.Update_History, versionNo)}</pre>
                          </TableCell>
                        </TableRow> */}

                        {/* Render each SKU row within this revision */}
                        {orders.map((P, orderIndex) => {
                          const previousOrder = previousOrders.find(prev => prev.SKU_ID === P.SKU_ID);
                          const isNew = !previousOrder;

                          // Check for changes in QTY, Aspetto's Cost, Customer's Cost, and Margin
                          const qtyChanged = previousOrder && previousOrder.QTY !== P.QTY;
                          const aspettoCostChanged = previousOrder && previousOrder.Aspetto_Unit_Cost !== P.Aspetto_Unit_Cost;
                          const customerCostChanged = previousOrder && previousOrder.Customer_Unit_Cost !== P.Customer_Unit_Cost;
                          const marginChanged = previousOrder &&  previousOrder.Margin_Percent !== P.Margin_Percent;
                          const totalSKUCostChanged = previousOrder &&  previousOrder.Total_SKU_Cost !== P.Total_SKU_Cost;
                          return (
                            <TableRow key={orderIndex}>
                              {/* Display cells with conditional change indication */}
                              <TableCell sx={cellStyle} align="center">{P.Vendor_Partner_name || 'N/A'}</TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {P.SKU_ID} 
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">{P.SKU_Description || 'N/A'} {isNew && <span style={{ color: 'green', fontWeight: 'bold' }}>New</span>}</TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {qtyChanged ? (
                                  <>
                                    <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
                                      {previousOrder.QTY}
                                    </span>
                                    {P.QTY}
                                  </>
                                ) : P.QTY}
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {aspettoCostChanged ? (
                                  <>
                                    <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
                                      {previousOrder.Aspetto_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                    {P.Aspetto_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </>
                                ) : P.Aspetto_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {marginChanged ? (
                                  <>
                                    <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
                                      {previousOrder.Margin_Percent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                    {P.Margin_Percent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </>
                                ) : P.Margin_Percent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {customerCostChanged ? (
                                  <>
                                    <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
                                      {previousOrder.Customer_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                    {P.Customer_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </>
                                ) : P.Customer_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">
                                {totalSKUCostChanged ? (
                                  <>
                                    <span style={{ textDecoration: 'line-through', color: 'red', marginRight: '5px' }}>
                                      {previousOrder.Total_SKU_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                    </span>
                                    {P.Total_SKU_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                                  </>
                                ) : P.Total_SKU_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                              </TableCell>
                              <TableCell sx={cellStyle} align="center">{String(P.SKU_Created_Date).split('T')[0]}</TableCell>
                              <TableCell sx={cellStyle} align="center">{String(P.SKU_Last_Updated_Date).split('T')[0]}</TableCell>
                            </TableRow>
                          );
                        })}

                        {/* Display deleted SKUs with full strike-through */}
                        {deletedSKUs.map((deleted, delIndex) => (
                          <TableRow key={`deleted-${delIndex}`} sx={{ textDecoration: 'line-through', color: 'red' }}>
                            <TableCell sx={cellStyle} align="center">{String(deleted.SKU_Created_Date).split('T')[0]}</TableCell>
                            <TableCell sx={cellStyle} align="center">{String(deleted.SKU_Last_Updated_Date).split('T')[0]}</TableCell>
                            <TableCell sx={cellStyle} align="center">{deleted.Vendor_Partner_name || 'N/A'}</TableCell>
                            <TableCell sx={cellStyle} align="center">{deleted.SKU_ID}</TableCell>
                            <TableCell sx={cellStyle} align="center">{deleted.SKU_Description || 'N/A'}</TableCell>
                            <TableCell sx={cellStyle} align="center">{deleted.QTY}</TableCell>
                            <TableCell sx={cellStyle} align="center">
                              {deleted.Aspetto_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell sx={cellStyle} align="center">
                              {deleted.Margin_Percent.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell sx={cellStyle} align="center">
                              {deleted.Customer_Unit_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                            <TableCell sx={cellStyle} align="center">
                              {deleted.Total_SKU_Cost.toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}
                            </TableCell>
                          </TableRow>
                        ))}
                 
                      </React.Fragment>
                    );
                  })
              ) : (
                <TableRow>
                  <TableCell colSpan={10} sx={revisionHeaderStyle} align="center">
                    No revision history available.
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </DialogContentText>
      </DialogContent>

      {/* Dialog Action */}
      <DialogActions>
        <Button
          onClick={handleClose}
          variant="outlined"
          size="small"
          color="warning"
          sx={{ ':hover': { boxShadow: '10px 10px 20px #ccc' } }}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  )
)

export default RevisionDialog;
