import React, { useState, useEffect } from 'react';
import {
  Container,
  Typography,
  Button,
  Checkbox,
  FormControlLabel,
  Dialog,
  DialogTitle,
  DialogContent,
  Alert
} from '@mui/material';
import AxiosFunction from '../../../axiosCustomInstance';
import AddSKUComponent from './AddSKUPricing';
import BulkUploadSKU from './BulkUpload';
import SKUDisplayTable from './SKUsDisplayTable'; // Component to display SKUs in a table format
import LoadingDialog from '../../Loading';
const SKUManagement = ({ contractTypeID, contractTypeName }) => {
  const [skus, setSkus] = useState([]); // List of SKUs
  const [errorMessage, setErrorMessage] = useState(''); // Error message
  const [loading, setLoading] = useState(false); // Loading state
  const [addIndividually, setAddIndividually] = useState(false); // Toggle for individual SKU addition
  const [bulkUpload, setBulkUpload] = useState(false); // Toggle for bulk upload

  const AxiosAPIInstance = AxiosFunction(); // Axios instance for API calls
  const [successMessage, setSuccessMessage] = useState(''); // Error message
  /**
   * Fetch SKUs for the given contract type ID
   */
  useEffect(() => {
    if (!contractTypeID) {
      setErrorMessage('Contract Type ID is required to load SKUs.');
      setSkus([]); // Clear existing SKUs if any
      return;
    }

    setLoading(true);
    setErrorMessage(''); // Clear previous errors

    AxiosAPIInstance.get('/ContractTypes/SKUs/getSkus', {
      params: { contractTypeID },
    })
      .then((res) => {
        if (res?.data?.length) {
          setSkus(res.data);
        } else {
          setSkus([]);
        }
      })
      .catch((err) => {
        setErrorMessage(err?.response?.data || 'Error fetching SKUs.');
        setSkus([]);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [contractTypeID,setLoading]);

  /**
   * Add a new SKU
   */
  const handleSaveSKU = async (newSKUs) => {
    setLoading(true);
    setErrorMessage(''); // Clear previous errors

    try {
    await AxiosAPIInstance.post('/ContractTypes/SKUs/newSKU', {
        contractTypeContractTypeID: contractTypeID,
        contractTypeName,
        skus: newSKUs, // Pass the SKUs from AddSKUComponent
      }).then(async(response)=>{
        if (response?.data) {
          setSkus((prev) => [...prev, ...response.data]); // Update the display table with the new SKUs
          setSuccessMessage('SKU added Succesfully')
        } else {
          setErrorMessage('Failed to add SKU. No response data.');
        }
        
      }).catch(async(error)=>{
        setErrorMessage(error?.response?.data ||'Failed to add SKU. No response data.');
      })


    } catch (err) {
      setErrorMessage('Error adding SKU.');
    } finally {
      setLoading(false);
    }
  };


  return (
    <Container>
      <LoadingDialog open={loading}/>
      {/* Error Message */}
      {errorMessage && <Alert severity="error">{errorMessage}</Alert>}
      {/* Error Message */}
      {successMessage && <Alert severity="success">{successMessage}</Alert>}
      {/* Checkboxes to choose functionality */}
      <FormControlLabel
        control={
          <Checkbox
            checked={addIndividually}
            onChange={(e) => {
              setAddIndividually(e.target.checked);
              if (e.target.checked === true) {
                setBulkUpload(false);
              }
            }}
          />
        }
        label="Add SKUs Individually"
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={bulkUpload}
            onChange={(e) => {
              setBulkUpload(e.target.checked);
              if (e.target.checked === true) {
                setAddIndividually(false);
                
              }
            }}
          />
        }
        label="Bulk Upload SKUs"
      />

      {/* Add SKUs Individually */}
      {addIndividually && (
        <AddSKUComponent
          handleSave={handleSaveSKU} // Pass the save handler
        />
      )}

      {/* Bulk Upload */}
      {bulkUpload && <BulkUploadSKU contractTypeID={contractTypeID} contractTypeName={contractTypeName} successMessage={successMessage} setSuccessMessage={setSuccessMessage} />}

      {/* Display SKUs */}
      <SKUDisplayTable skus={skus} contractTypeID={contractTypeID} contractTypeName={contractTypeName} successMessage={successMessage} setSuccessMessage={setSuccessMessage} />


    </Container>
  );
};

export default SKUManagement;
