import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, List, ListItem, ListItemText,
  ListItemAvatar, Avatar, Typography, Box, IconButton, Tooltip, Alert, Link, TextField
} from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { blue, blueGrey, orange } from '@mui/material/colors';
import { MentionsInput, Mention } from 'react-mentions';
import moment from 'moment-timezone';
import Axios from 'axios';
import UserAuth from '../ProtectedRoute/userAuth';
import AxiosFunction from '../../axiosCustomInstance';

// Function to generate a random light hexadecimal color code.
// This is used to assign each user a unique color for their avatar.
// The color generated will be from the light side of the color spectrum.
const getRandomColor = () => {
  const letters = '89ABCDEF'; // Using only letters from 8 to F for lighter shades
  let color = '#'; // Hex color starts with #
  for (let i = 0; i < 6; i++) { // Generate 6 characters to form a complete color code
    color += letters[Math.floor(Math.random() * 8)]; // Randomly pick one of the 'lighter' letters
  }
  return color; // Return the generated color code
};

// Custom styles for the MentionsInput component.
// These styles are applied to the input field where users can type mentions.
const useStyles = {
  mentionsInput: {
    width: '100%', // Ensure the input takes full width
    '&multiLine': { // Specific styles for multiline input
      input: { // Styles for the input field itself
        fontSize: '14px',
        fontFamily: 'Arial, sans-serif',
        padding: '10px',
        minWidth: '300px', // Minimum width of the input field
        minHeight: '100px', // Minimum height of the input field
      },
      '&highlighter': { // Styles for the highlighter that shows mentions
        padding: '10px',
        border: '1px solid transparent',
      },
      '&input': { // Style for the text input area
        padding: '10px',
        border: '1px solid silver',
      },
    },
  },
  mentionsSuggestions: { // Custom styles for the dropdown suggestions when typing mentions
    list: { // Styles for the list of suggestions
      backgroundColor: 'white', // White background for the dropdown
      border: '1px solid rgba(0,0,0,0.15)', // Light border
      fontSize: '14px', // Font size of the suggestion list
    },
    item: { // Styles for each item in the suggestions list
      padding: '5px 15px',
      borderBottom: '1px solid rgba(0,0,0,0.15)', // Light border between items
      '&focused': { // Style for the item when it's focused or hovered
        backgroundColor: '#cee4e5', // Light blue background for focused item
      },
    },
  },
  mention: {
    color: blue[700], // Mentions are displayed in a blue color
    cursor: 'pointer', // Changes cursor to a pointer when hovering over a mention
  },
};

// Main component for handling conversations, comments, and replies.
const ConversationDialog = ({ open, onClose, conversationId, title }) => {
  // State variable to store the new comment being typed by the user.
  const [newComment, setNewComment] = useState('');
  // State variable to store the list of comments retrieved from the server.
  const [comments, setComments] = useState([]);
  // State variable to store the list of users who can be mentioned.
  const [users, setUsers] = useState([]);
  // State variable to keep track of the comment being edited (if any).
  const [editingCommentId, setEditingCommentId] = useState(null);
  // State variable to store the text of the comment currently being edited.
  const [editingCommentText, setEditingCommentText] = useState('');
  // State variable to keep track of the reply being edited (if any).
  const [editingReplyId, setEditingReplyId] = useState(null);
  // State variable to store the text of the reply currently being edited.
  const [editingReplyText, setEditingReplyText] = useState('');
  // State variable to track the comment ID to which the user is currently replying.
  const [replyingCommentId, setReplyingCommentId] = useState(null);
  // State variable to track the reply ID to which the user is currently replying.
  const [replyingReplyId, setReplyingReplyId] = useState(null);
  // State variable to store the reply text being typed by the user.
  const [replyText, setReplyText] = useState('');
  // State variable to manage whether the edit history dialog is open.
  const [historyDialogOpen, setHistoryDialogOpen] = useState(false);
  // State variable to store the content of the edit history.
  const [historyDialogContent, setHistoryDialogContent] = useState([]);
  // Retrieve the authenticated user's name and email from the UserAuth function.
  const { UserName, UserEmail } = UserAuth();
  // Retrieve an instance of Axios with custom configuration from AxiosFunction.
  const AxiosAPIInstance = AxiosFunction();
  // State variable to store a mapping of user emails to assigned colors for avatars.
  const [userColors, setUserColors] = useState({});

  // useEffect hook to fetch or create a conversation and fetch users when the component is mounted or when conversationId or title changes.
  useEffect(() => {
    if (conversationId) { // If there's a valid conversationId, fetch the conversation
      fetchOrCreateConversation(conversationId, title);
    }
    fetchUsers(); // Fetch the list of users who can be mentioned
  }, [conversationId, title]); // Dependencies for the effect: conversationId and title

  // Function to fetch the conversation from the server or create a new one if it doesn't exist.
  const fetchOrCreateConversation = async (conversationId, title) => {
    try {
      // Send a POST request to the API to fetch or create a conversation by its ID and title.
      const response = await AxiosAPIInstance.post('/Conversations/conversation', { conversationId, title });
      // Sort the comments by their creation date in ascending order (oldest first).
      const sortedComments = (response.data.comments || []).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
      setComments(sortedComments); // Update the state with the sorted comments
    } catch (error) {
      console.error('Failed to fetch or create conversation:', error); // Log any errors encountered during the API call
    }
  };

  // Function to fetch the list of users from the API who can be mentioned in the conversation.
  const fetchUsers = async () => {
    try {
      // Send a GET request to fetch the users (API endpoint may need to be adjusted).
      const response = await AxiosAPIInstance.get("/UserAuth/users/comments"); // Update this with the actual API endpoint
      setUsers(response.data); // Update the state with the list of users
    } catch (error) {
      console.error('Failed to fetch users:', error); // Log any errors encountered during the API call
    }
  };

  // Handler for updating the state when the user types a new comment.
  const handleNewCommentChange = (event, newValue, newPlainTextValue) => {
    setNewComment(newPlainTextValue); // Update the state with the new comment text (plain text value)
  };

 // Function to trigger a new comment submission
const handleSendComment = async (e) => {
  e.preventDefault(); // Prevent the default form submission behavior

  if (newComment.trim() === '') {
    console.error('Cannot submit an empty comment.');
    return; // Ensure the comment is not empty before proceeding
  }

  try {
    // Send a POST request to add a new comment
    const response = await AxiosAPIInstance.post('/Conversations/conversation/comment', {
      conversationId,      // ID of the current conversation
      userEmail: UserEmail, // Email of the logged-in user
      userName: UserName,   // Name of the logged-in user
      text: newComment.trim(), // Trimmed comment text
    });

    // Add the new comment to the state
    setComments((prevComments) => [
      ...prevComments,
      response.data, // Append the new comment
    ].sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))); // Sort by date

    setNewComment(''); // Clear the input field
  } catch (error) {
    console.error('Error while posting a comment:', error.response?.data || error.message); // Log detailed error
  }
};

// Function to trigger a reply submission
const handleSendReply = async (commentId, parentId = null) => {
  if (replyText.trim() === '') {
    console.error('Cannot submit an empty reply.');
    return; // Ensure the reply is not empty before proceeding
  }

  try {
    // Send a POST request to add a new reply
    const response = await AxiosAPIInstance.post('/Conversations/conversation/comment/reply', {
      commentId,           // ID of the comment being replied to
      parentId,            // Parent ID (if nested reply)
      userEmail: UserEmail, // Email of the logged-in user
      userName: UserName,   // Name of the logged-in user
      text: replyText.trim(), // Trimmed reply text
    });

    // Update the comments state with the new reply
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === commentId
          ? { ...comment, replies: [...(comment.replies || []), response.data] } // Append reply
          : comment
      ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort by date
    );

    setReplyText(''); // Clear the reply input field
    setReplyingCommentId(null); // Reset the replying state
    setReplyingReplyId(null);   // Reset the nested replying state
  } catch (error) {
    console.error('Error while posting a reply:', error.response?.data || error.message); // Log detailed error
  }
};

  // Handler to initiate editing a comment. The comment's ID and existing text are passed in.
  const handleEditComment = (commentId, text) => {
    setEditingCommentId(commentId); // Set the comment being edited
    setEditingCommentText(text); // Set the text of the comment being edited
  };

  // Handler for updating the text of the comment being edited.
  const handleEditCommentChange = (event) => {
    setEditingCommentText(event.target.value); // Update the state with the new comment text
  };

  // Function to handle editing a comment
const handleSaveEditComment = async () => {
  try {
    // Send a PATCH request to update the comment
    const response = await AxiosAPIInstance.patch('/Conversations/conversation/comment/edit', {
      commentId: editingCommentId, // ID of the comment being edited
      newText: editingCommentText, // New text for the comment
      userEmail: UserEmail,        // Email of the logged-in user
      userName: UserName,          // Name of the logged-in user
    });

    // Update the state with the edited comment
    setComments((prevComments) =>
      prevComments.map((comment) =>
        comment.id === response.data.id ? response.data : comment // Replace edited comment
      ).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort comments by date
    );

    // Reset editing state
    setEditingCommentId(null);
    setEditingCommentText('');
  } catch (error) {
    console.error('Error editing comment:', error.response?.data || error.message); // Log detailed error
  }
};

// Function to handle changes in the reply text input during editing
const handleEditReplyChange = (event) => {
  setEditingReplyText(event.target.value); // Update the state with the new reply text
};
 // Function to handle editing a reply
const handleSaveEditReply = async () => {
  try {
    // Send a PATCH request to update the reply
    const response = await AxiosAPIInstance.patch('/Conversations/conversation/comment/reply/edit', {
      replyId: editingReplyId,     // ID of the reply being edited
      newText: editingReplyText,   // New text for the reply
      userEmail: UserEmail,        // Email of the logged-in user
    });

    // Update the state with the edited reply
    setComments((prevComments) =>
      prevComments.map((comment) => {
        const updatedReplies = comment.replies?.map((reply) =>
          reply.id === response.data.id ? response.data : reply // Replace edited reply
        );
        return comment.replies ? { ...comment, replies: updatedReplies } : comment;
      }).sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt)) // Sort comments by date
    );

    // Reset editing state
    setEditingReplyId(null);
    setEditingReplyText('');
  } catch (error) {
    console.error('Error editing reply:', error.response?.data || error.message); // Log detailed error
  }
};

  // Handler to close the dialog and reset all editing and replying states.
  const handleClose = () => {
    setEditingCommentId(null); // Reset the editing comment state
    setEditingCommentText(''); // Clear the editing comment text
    setReplyingCommentId(null); // Reset the replying comment state
    setReplyingReplyId(null); // Reset the replying reply state
    setReplyText(''); // Clear the reply text input
    onClose(); // Call the parent component's onClose function to close the dialog
  };

  // Function to open the history dialog and display the edit history of a comment or reply.
  const handleOpenHistoryDialog = (editHistory) => {
    setHistoryDialogContent(editHistory); // Set the content of the history dialog
    setHistoryDialogOpen(true); // Open the history dialog
    setReplyText(''); // Clear the reply text input
    setEditingCommentText(''); // Clear the editing comment text
  };

  // Function to close the history dialog.
  const handleCloseHistoryDialog = () => {
    setHistoryDialogOpen(false); // Close the history dialog
    setHistoryDialogContent([]); // Clear the history dialog content
    setEditingCommentText(''); // Clear the editing comment text
    setReplyText(''); // Clear the reply text input
  };
// Function to set the editing state for a reply
const handleEditReply = (replyId, text) => {
  setEditingReplyId(replyId); // Set the ID of the reply being edited
  setEditingReplyText(text);  // Set the current text of the reply being edited
};
  // Function to group comments by their creation date.
  // This organizes the comments by date for easier reading.
  const groupCommentsByDate = (comments) => {
    return comments.reduce((groups, comment) => {
      const date = moment(comment.createdAt).format('YYYY-MM-DD'); // Format the creation date as 'YYYY-MM-DD'
      if (!groups[date]) { // If there's no group for this date yet, create one
        groups[date] = [];
      }
      groups[date].push(comment); // Add the comment to the group for that date
      return groups; // Return the grouped comments
    }, {});
  };

  // Function to assign a random color to a user based on their email.
  // This color is used to create a unique avatar color for each user.
  const assignColorToUser = (userEmail) => {
    if (!userColors[userEmail]) { // If the user doesn't have a color assigned yet
      setUserColors(prevColors => ({ ...prevColors, [userEmail]: getRandomColor() })); // Assign a new random color
    }
    return userColors[userEmail]; // Return the assigned color
  };

  // Function to render the list of replies for a given comment.
  // This handles both comments and replies to replies (nested replies).
  const renderReplies = (replies, parentCommentId) => {
    if (!replies || !replies.length) return null; // If there are no replies, return null
    return replies.map((reply) => ( // Map over each reply and render it
      <ListItem key={reply.id} alignItems="flex-start" sx={{ ml: 4 }}> {/* Shift replies right for a nested look */}
        <ListItemAvatar>
          <Avatar sx={{ bgcolor: assignColorToUser(reply.userEmail), color: blueGrey[900], border: 0.5 }}>
            {reply.userEmail && String(reply.userEmail).toUpperCase().split('.')[0][0] + String(reply.userEmail).toUpperCase().split('.')[1][0]} {/* Generate initials from user email */}
          </Avatar>
        </ListItemAvatar>
        <ListItemText
          primary={
            <>
              <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }} component="span">
                {reply.userName
                  .split(' ')
                  .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Format the name as "Firstname Lastname"
                  .join(' ')}
              </Typography>
              <Typography component="span"> </Typography>
              <Typography variant="body2" component="span">
                {moment(reply.createdAt).tz(moment.tz.guess()).format('hh:mm A')} {/* Format the timestamp */}
              </Typography>
            </>
          }
          secondary={
            <>
              <Typography sx={{ fontSize: '13px' }} color="textPrimary" component="p">
                {reply.id === editingReplyId ? ( // If the reply is being edited, show the text field
                  <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <TextField
                      value={editingReplyText}
                      onChange={handleEditReplyChange} // Update the edited reply text
                      fullWidth
                      variant="outlined"
                      size="small"
                    />
                    <Tooltip title="Save">
                      <IconButton size="small" onClick={handleSaveEditReply}> {/* Save the edited reply */}
                        <Typography variant="caption" color="textSecondary">
                          Save
                        </Typography>
                      </IconButton>
                    </Tooltip>
                  </Box>
                ) : (
                  parseAndRenderMentions(reply.text) // Display the reply text with mentions highlighted
                )}
              </Typography>
              <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                {reply.id === editingReplyId ? null : reply.userEmail === UserEmail && (
                  <Link
                    component="button"
                    variant="caption"
                    color="primary"
                    onClick={() => handleEditReply(reply.id, reply.text)} // Allow the user to edit their reply
                  >
                    Edit
                  </Link>
                )}
                {reply.editHistory && reply.editHistory.length > 0 && (
                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                    <Link
                      component="button"
                      variant="caption"
                      color="primary"
                      onClick={() => handleOpenHistoryDialog(reply.editHistory)} // Open the history dialog if the reply was edited
                    >
                      Edited
                    </Link>
                  </Box>
                )}
                <Link
                  component="button"
                  variant="caption"
                  color="primary"
                  onClick={() => setReplyingReplyId(reply.id)} // Set the state to reply to this reply
                  sx={{ ml: 1 }}
                >
                  Reply
                </Link>
                <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                  {Array.isArray(reply.reactions) && reply.reactions.map((reaction, index) => ( // Display reactions to the reply
                    <Tooltip
                      key={index}
                      title={reaction.users.map(user => user.name).join(', ')}
                    >
                      <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                        <Typography variant="body2" component="span">
                          {reaction.emoji} {/* Display the reaction emoji */}
                        </Typography>
                        <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                          {reaction.users.length} {/* Display the number of users who reacted */}
                        </Typography>
                      </Box>
                    </Tooltip>
                  ))}
                </Box>
              </Box>
              {replyingReplyId === reply.id && (
                <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
                  <MentionsInput
                    value={replyText} // Controlled input for the reply text
                    onChange={(e, newValue, newPlainTextValue) => setReplyText(newPlainTextValue)} // Update the reply text as the user types
                    style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
                  >
                    <Mention
                      trigger="@" // Trigger mentions when the user types "@"
                      data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName }))}
                      renderSuggestion={(suggestion, search, highlightedDisplay) => ( // Show the suggestion when a mention is typed
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{suggestion.display}</Typography>
                              Email: {suggestion.id}<br />
                              Role: {suggestion.role} {/* Replace this with actual role */}
                            </React.Fragment>
                          }
                          arrow
                          placement="top"
                        >
                          <Box sx={{ display: 'inline-block' }}>
                            {highlightedDisplay}
                          </Box>
                        </Tooltip>
                      )}
                      markup="@{{__type__||__id__||__display__}}" // Format for the mention
                      displayTransform={(id, display) => ( // Display format for mentions
                        <Tooltip
                          title={
                            <React.Fragment>
                              <Typography color="inherit">{display}</Typography>
                              Email: {id}<br />
                              Role: {/* Replace this with actual role */}
                            </React.Fragment>
                          }
                          arrow
                          placement="top"
                        >
                          <Box sx={{ display: 'inline-block', color: blue[700], cursor: 'pointer' }}>
                            {`@${display}`}
                          </Box>
                        </Tooltip>
                      )}
                    />
                  </MentionsInput>
                  <IconButton onClick={() => handleSendReply(parentCommentId, reply.id)} sx={{ ml: 1 }}> {/* Send the reply */}
                    <SendIcon color="warning" />
                  </IconButton>
                </Box>
              )}
              {renderReplies(reply.replies, parentCommentId)} {/* Recursively render nested replies */}
            </>
          }
        />
      </ListItem>
    ));
  };
// Function to parse and render mentions in the comment or reply text
const parseAndRenderMentions = (text) => {
  // Split the input text into words to analyze each word for mentions
  return text.split(' ').map((word, index) => {
    if (word.startsWith('@')) { // Check if the word starts with '@', indicating a mention
      // Extract the mentioned user based on the format of '@FirstName LastName'
      const mentionedUser = users.find(user => `@${user.User_FirstName} ${user.User_LastName}` === word);

      if (mentionedUser) {
        // Render a styled tooltip with user information and clickable mention
        return (
          <Tooltip
            key={`${mentionedUser.User_email}-${index}`} // Unique key for each tooltip
            title={
              <React.Fragment>
                <Typography color="inherit">{mentionedUser.User_FirstName} {mentionedUser.User_LastName}</Typography>
                Email: {mentionedUser.User_email}<br />
                Role: {mentionedUser.User_Role}<br />
                Active: {mentionedUser.IsActive ? 'Yes' : 'No'}
              </React.Fragment>
            }
            arrow
            placement="top"
          >
            <Box
              component="span"
              sx={{
                color: blue[700],           // Mention text in blue
                cursor: 'pointer',         // Pointer cursor for clickable look
                fontWeight: 'bold',        // Bold text for mentions
                textDecoration: 'underline' // Underlined mention text
              }}
            >
              {word}{' '}
            </Box>
          </Tooltip>
        );
      }
    }
    // Return the word as-is if it's not a mention
    return `${word} `;
  });
};


  // Group comments by their creation date for easier reading.
  const groupedComments = groupCommentsByDate(comments);

  return (
    <>
      {/* Main dialog for displaying comments */}
      <Dialog open={open} onClose={handleClose} maxWidth="sm" fullWidth>
        <DialogTitle variant="filled">
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontWeight: 'bold', fontFamily: 'arial' }}>
            Comments :{title} {/* Display the title of the conversation */}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <List>
            {Object.keys(groupedComments).map((date) => ( // For each date group of comments
              <React.Fragment key={date}>
                <Typography align="center" sx={{ mt: 2, mb: 2, bgcolor: blueGrey[100], padding: 1, borderRadius: 1, fontsize: '14px' }}>
                  {moment(date).format('dddd, MMMM Do YYYY')} {/* Display the date */}
                </Typography>
                {groupedComments[date].map((comment) => ( // Render each comment
                  <ListItem key={comment.id} alignItems="flex-start">
                    <ListItemAvatar>
                      <Avatar sx={{ bgcolor: assignColorToUser(comment.userEmail), color: blueGrey[900], border: 0.5 }}>
                        {comment.userEmail && String(comment.userEmail).toUpperCase().split('.')[0][0] + String(comment.userEmail).toUpperCase().split('.')[1][0]} {/* Generate initials */}
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText
                      primary={
                        <>
                          <Typography sx={{ fontSize: '15px', fontWeight: 'bold' }} component="span">
                            {comment.userName
                              .split(' ')
                              .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Format name as "Firstname Lastname"
                              .join(' ')}
                          </Typography>
                          <Typography component="span"> </Typography>
                          <Typography variant="body2" component="span">
                            {moment(comment.createdAt).tz(moment.tz.guess()).format('hh:mm A')} {/* Display the timestamp */}
                          </Typography>
                        </>
                      }
                      secondary={
                        <>
                          <Typography sx={{ fontSize: '13px' }} color="textPrimary" component="p">
                            {comment.id === editingCommentId ? ( // If the comment is being edited, show the text field
                              <Box sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                  value={editingCommentText}
                                  onChange={handleEditCommentChange} // Update the edited comment text
                                  fullWidth
                                  variant="outlined"
                                  size="small"
                                />
                                <Tooltip title="Save">
                                  <IconButton size="small" onClick={handleSaveEditComment}> {/* Save the edited comment */}
                                    <Typography variant="caption" color="textSecondary">
                                      Save
                                    </Typography>
                                  </IconButton>
                                </Tooltip>
                              </Box>
                            ) : (
                              parseAndRenderMentions(comment.text) // Display the comment text with mentions
                            )}
                          </Typography>
                          <Box sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                            {comment.id === editingCommentId ? null : comment.userEmail === UserEmail && (
                              <Link
                                component="button"
                                variant="caption"
                                color="primary"
                                onClick={() => handleEditComment(comment.id, comment.text)} // Allow the user to edit their comment
                              >
                                Edit
                              </Link>
                            )}
                            {comment.editHistory && comment.editHistory.length > 0 && (
                              <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                <Link
                                  component="button"
                                  variant="caption"
                                  color="primary"
                                  onClick={() => handleOpenHistoryDialog(comment.editHistory)} // Open the history dialog if the comment was edited
                                >
                                  Edited
                                </Link>
                              </Box>
                            )}
                            <Link
                              component="button"
                              variant="caption"
                              color="primary"
                              onClick={() => setReplyingCommentId(comment.id)} // Set the state to reply to this comment
                              sx={{ ml: 1 }}
                            >
                              Reply
                            </Link>
                            <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                              {Array.isArray(comment.reactions) && comment.reactions.map((reaction, index) => ( // Display reactions to the comment
                                <Tooltip
                                  key={index}
                                  title={reaction.users.map(user => user.name).join(', ')} // Show the names of users who reacted
                                >
                                  <Box sx={{ display: 'flex', alignItems: 'center', ml: 1 }}>
                                    <Typography variant="body2" component="span">
                                      {reaction.emoji} {/* Display the reaction emoji */}
                                    </Typography>
                                    <Typography variant="body2" component="span" sx={{ ml: 0.5 }}>
                                      {reaction.users.length} {/* Display the number of users who reacted */}
                                    </Typography>
                                  </Box>
                                </Tooltip>
                              ))}
                            </Box>
                          </Box>
                          {replyingCommentId === comment.id && (
                            <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
                              <MentionsInput
                                value={replyText} // Controlled input for the reply text
                                onChange={(e, newValue, newPlainTextValue) => setReplyText(newPlainTextValue)} // Update the reply text as the user types
                                style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
                              >
                                <Mention
                                  trigger="@" // Trigger mentions when the user types "@"
                                  data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName, role: user.User_Role, active: user.IsActive }))}
                                  renderSuggestion={(suggestion, search, highlightedDisplay) => ( // Show the suggestion when a mention is typed
                                    <Tooltip
                                      title={
                                        <React.Fragment>
                                          <Typography color="inherit">{suggestion.display}</Typography>
                                          Email: {suggestion.id}<br />
                                          Role: {suggestion.role}<br />
                                          Active: {suggestion.active ? 'Yes' : 'No'} {/* Show if the user is active */}
                                        </React.Fragment>
                                      }
                                      arrow
                                      placement="top"
                                    >
                                      <Box sx={{ display: 'inline-block' }}>
                                        {highlightedDisplay}
                                      </Box>
                                    </Tooltip>
                                  )}
                                />
                              </MentionsInput>
                              <IconButton onClick={handleSendReply(comment.id)} sx={{ ml: 1 }}> {/* Send the reply */}
                                <SendIcon color="warning" />
                              </IconButton>
                            </Box>
                          )}
                          {renderReplies(comment.replies, comment.id)} {/* Recursively render nested replies */}
                        </>
                      }
                    />
                  </ListItem>
                ))}
              </React.Fragment>
            ))}
          </List>
          {/* Input area for adding a new comment */}
          <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, width: '100%' }}>
            <MentionsInput
              value={newComment} // Controlled input for the comment text
              onChange={handleNewCommentChange} // Update the comment text as the user types
              style={{ width: '100%', minWidth: '300px', minHeight: '40px' }}
            >
              <Mention
                trigger="@" // Trigger mentions when the user types "@"
                data={users.map(user => ({ id: user.User_email, display: user.User_FirstName + ' ' + user.User_LastName, role: user.User_Role, active: user.IsActive }))}
                renderSuggestion={(suggestion, search, highlightedDisplay) => ( // Show the suggestion when a mention is typed
                  <Tooltip
                    title={
                      <React.Fragment>
                        <Typography color="inherit">{suggestion.display}</Typography>
                        Email: {suggestion.id}<br />
                        Role: {suggestion.role}<br />
                        Active: {suggestion.active ? 'Yes' : 'No'} {/* Show if the user is active */}
                      </React.Fragment>
                    }
                    arrow
                    placement="top"
                  >
                    <Box sx={{ display: 'inline-block' }}>
                      {highlightedDisplay}
                    </Box>
                  </Tooltip>
                )}
              />
            </MentionsInput>
            <IconButton onClick={handleSendComment} sx={{ ml: 1 }}> {/* Send the comment */}
              <SendIcon color="warning" />
            </IconButton>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button> {/* Close the dialog */}
        </DialogActions>
      </Dialog>

      {/* Dialog for displaying the edit history of comments and replies */}
      <Dialog open={historyDialogOpen} onClose={handleCloseHistoryDialog} maxWidth="sm" fullWidth>
        <DialogTitle>
          <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontWeight: 'bold', fontFamily: 'arial' }}>
            Edit History
          </Alert>
        </DialogTitle>
        <DialogContent>
          <List>
            {historyDialogContent.map((history, index) => (
              <ListItem key={index}>
                <ListItemText
                  /* Display the edit timestamp */
                  primary={`Edited at ${moment(history.editedAt).tz(moment.tz.guess()).format('YYYY-MM-DD hh:mm A')}`} 
                  secondary={history.text} /* Display the edited text */
                />
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseHistoryDialog}>Close</Button> {/* Close the history dialog */}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ConversationDialog; // Export the component
