import React, { useState } from 'react';
import {
  Card,
  CardContent,
  Typography,
  Divider,
  Grid,
  Box,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  Avatar,
  Button,
  Alert
} from '@mui/material';
import { blue, green, orange, red, grey, blueGrey } from '@mui/material/colors';
import VisibilityIcon from '@mui/icons-material/Visibility';
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';
import { selectIsAdmin } from '../../../Login_Registration/authSlice';
import UserAuth from '../../../ProtectedRoute/userAuth';
import { Line } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Tooltip,
  Legend
} from 'chart.js';
import { useSelector } from "react-redux";
import LoadingDialog from '../../../Loading';
// Register necessary Chart.js components for Line charts
ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Tooltip, Legend);

const RFQsMetricsDashboard = ({ totalData, salesHeadEmail,dateFiltersfromMainPage}) => {
  // Extracting the current year for calculations
  const currentYear = new Date().getFullYear();

  // State management for dialog visibility and filtered data
  const [detailsDialogOpen, setDetailsDialogOpen] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [selectedMetric, setSelectedMetric] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [avatarColors, setAvatarColors] = useState({});
  const [loading,setLoading]=useState(false)
  // Fetch user email and admin status
  const { UserEmail } = UserAuth();
  const isAdmin = useSelector(selectIsAdmin);

  // Helper function to assign random colors to avatars based on names
  const assignRandomColor = (name) => {
    if (!avatarColors[name]) {
      const colors = [blue[500], green[500], orange[500], red[500]];
      const randomColor = colors[Math.floor(Math.random() * colors.length)];
      setAvatarColors((prevColors) => ({ ...prevColors, [name]: randomColor }));
    }
    return avatarColors[name];
  };

 // Define metrics for admin or individual user based on the `isAdmin` flag
let wonQuotes, total, pending, pastDue, totalDifferentCustomers;

// Exit early if there is no data to process
if (!totalData || totalData.length === 0) return;

// Extract the start and end dates from the provided date filters
// `dateFiltersfromMainPage.createdDateFrom` and `dateFiltersfromMainPage.createdDateTo`
// are assumed to be string representations of dates
const startDate = new Date(dateFiltersfromMainPage.createdDateFrom); // Convert start date to a Date object
const endDate = new Date(dateFiltersfromMainPage.createdDateTo);     // Convert end date to a Date object

// Filter the `totalData` array to only include entries that fall within the specified date range
const totalFilteredDataInRange = totalData.filter((entry) => {
  const createdDate = new Date(entry.Created_Date); // Convert `Created_Date` to a Date object
  return createdDate >= startDate && createdDate <= endDate; // Check if it falls within the range
});

// Apply additional filtering based on the user's role
let totalFilteredData=totalFilteredDataInRange
if (isAdmin) {
  totalFilteredData = [...totalFilteredDataInRange]; // Admins can see all filtered data
} else {
  // Non-admins only see data associated with their email
  totalFilteredData = totalFilteredDataInRange.filter(
    (item) => item.Sales_Head_Email === UserEmail
  );
}

// Calculate metrics for admin or non-admin users
if (isAdmin&&String(salesHeadEmail).toLocaleLowerCase()!=='all') {
  // Filter quotes with status 'WON' for the admin's sales head email
  wonQuotes = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'WON' &&
      quote.Sales_Head_Email === String(salesHeadEmail) // Ensure matching sales head email
  );

  total = wonQuotes.length; // Total count of won quotes

  // Filter pending quotes for the admin's sales head email
  pending = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'PENDING' &&
      quote.Sales_Head_Email === String(salesHeadEmail)
  ).length;

  // Count past-due quotes
  pastDue = totalFilteredData.filter(
    (quote) =>
      new Date(quote.Due_date) < new Date() && // Due date is in the past
      quote.RFQ_Status === 'PENDING' &&
      quote.Sales_Head_Email === String(salesHeadEmail)
  ).length;

  // Count unique customers for won quotes
  totalDifferentCustomers = [
    ...new Set(wonQuotes.map((quote) => quote.KO_email)) // Use Set to get unique KO_email values
  ].length;
} // Calculate metrics for admin or non-admin users
else if (isAdmin && String(salesHeadEmail).toLocaleLowerCase()==='all') {
  // Filter quotes with status 'WON' for the admin's sales head email
  wonQuotes = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'WON'
  );

  total = wonQuotes.length; // Total count of won quotes

  // Filter pending quotes for the admin's sales head email
  pending = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'PENDING'
  ).length;

  // Count past-due quotes
  pastDue = totalFilteredData.filter(
    (quote) =>
      new Date(quote.Due_date) < new Date() && // Due date is in the past
      quote.RFQ_Status === 'PENDING'
  ).length;

  // Count unique customers for won quotes
  totalDifferentCustomers = [
    ...new Set(wonQuotes.map((quote) => quote.KO_email)) // Use Set to get unique KO_email values
  ].length;
}
else {
  // Non-admin logic
  wonQuotes = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'WON' &&
      quote.Sales_Head_Email === UserEmail // Ensure matching sales head email
  );

  total = wonQuotes.length; // Total count of won quotes

  // Filter pending quotes for the current user
  pending = totalFilteredData.filter(
    (quote) =>
      quote.RFQ_Status === 'PENDING' &&
      quote.Sales_Head_Email === UserEmail
  ).length;

  // Count past-due quotes
  pastDue = totalFilteredData.filter(
    (quote) =>
      new Date(quote.Due_date) < new Date() && // Due date is in the past
      quote.RFQ_Status === 'PENDING' &&
      quote.Sales_Head_Email === UserEmail
  ).length;

  // Count unique customers for won quotes
  totalDifferentCustomers = [
    ...new Set(wonQuotes.map((quote) => quote.KO_email)) // Use Set to get unique KO_email values
  ].length;
}


  // Helper function to format numbers as currency
  const formatNumber = (num) =>
    num.toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

  // Function to calculate sales and profit metrics based on weekly or monthly data
  const calculateMetrics = (quotes, type) => {
    const getPeriod = (date, periodType) =>
      periodType === 'week'
        ? moment(date).year(currentYear).week()
        : moment(date).format('YYYY-MM');

    const metrics = quotes.reduce(
      (acc, quote) => {
        const period = getPeriod(quote.Created_Date, type);
        const salesValue = parseFloat(quote.Actual_Total_Cost_After_Margin);
        const netProfit =
          salesValue - parseFloat(quote.Actual_Total_Cost_Before_Margin);

        acc.sales[period] = (acc.sales[period] || 0) + salesValue;
        acc.profits[period] = (acc.profits[period] || 0) + netProfit;

        return acc;
      },
      { sales: {}, profits: {} }
    );

    const bestSales = Object.entries(metrics.sales).reduce(
      (best, [period, value]) =>
        value > best.value ? { period, value } : best,
      { period: null, value: 0 }
    );

    const bestProfit = Object.entries(metrics.profits).reduce(
      (best, [period, value]) =>
        value > best.value ? { period, value } : best,
      { period: null, value: 0 }
    );

    return { bestSales, bestProfit };
  };

  // Metrics for best week and best month
  const bestWeek = calculateMetrics(wonQuotes, 'week');
  const bestMonth = calculateMetrics(wonQuotes, 'month');

  // Prepare sales trend data for current year
  const prepareTrendData = (quotes) => {
    return quotes.reduce((acc, quote) => {
      const month = moment(quote.Created_Date).month(); // Get the month (0-indexed)
      acc[month] = (acc[month] || 0) + parseFloat(quote.Actual_Total_Cost_After_Margin);
      return acc;
    }, {});
  };

  const salesHeadTrend = prepareTrendData(wonQuotes);

  // Labels and data for trendline chart
  const chartLabels = Array.from({ length: 12 }, (_, i) => moment().month(i).format('MMMM'));
  const salesHeadData = chartLabels.map((_, index) => salesHeadTrend[index] || 0);

  const trendlineData = {
    labels: chartLabels,
    datasets: [
      {
        label: 'Your Total Sale Value',
        data: salesHeadData,
        fill: false,
        borderColor: blue[500],
        backgroundColor: blue[300],
        tension: 0.4,
      },
    ],
  };

  const trendlineOptions = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        display: true,
        position: 'top',
        labels: {
          font: {
            size: 12,
          },
        },
      },
      tooltip: {
        callbacks: {
          label: (context) =>
            `Total Sales: $${context.raw.toLocaleString(undefined, {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2,
            })}`,
        },
      },
    },
    scales: {
      x: {
        title: {
          display: true,
          text: 'Months',
          font: {
            size: 10,
          },
        },
      },
      y: {
        title: {
          display: true,
          text: 'Total Sale Value ($)',
          font: {
            size: 10,
          },
        },
      },
    },
  };

// Function to open details dialog based on the selected metric (sales or profit, week or month)
const openDetailsDialog = (metric) => {
  if (metric.type === 'customers') {
    // For customer details, use all wonQuotes
    setFilteredData(wonQuotes);
  } else {
    // Filter data for the selected metric and period
    const periodFilter = (quoteDate, period, type) => {
      if (type === 'week') {
        const startOfWeek = moment().year(currentYear).week(period).startOf('week');
        const endOfWeek = moment().year(currentYear).week(period).endOf('week');
        return moment(quoteDate).isBetween(startOfWeek, endOfWeek, null, '[]');
      } else if (type === 'month') {
        const startOfMonth = moment(period, 'YYYY-MM').startOf('month');
        const endOfMonth = moment(period, 'YYYY-MM').endOf('month');
        return moment(quoteDate).isBetween(startOfMonth, endOfMonth, null, '[]');
      }
      return false;
    };

    const filtered = wonQuotes.filter((quote) =>
      periodFilter(quote.Created_Date, metric.period, metric.type)
    );

    setFilteredData(filtered);
  }

  setSelectedMetric(metric); // Set the selected metric for context in the dialog
  setDetailsDialogOpen(true); // Open the details dialog
};


  // Handler for search input within the dialog
  const handleSearchChange = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredData(
      filteredData.filter(
        (quote) =>
          quote.Opportunity_Name.toLowerCase().includes(query) ||
          quote.KO_name.toLowerCase().includes(query) ||
          quote.Customer_Name.toLowerCase().includes(query)
      )
    );
  };

  // Close the dialog and reset search state
  const handleDialogClose = () => {
    setDetailsDialogOpen(false);
    setSearchQuery('');
  };

  return (
    <>
            <Divider sx={{ marginY: 1 }} />
      <Grid container spacing={2} justifyContent="center">
        {/* Total Quotes Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${blue[700]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Total Quotes (WON)
              </Typography>
              <Typography sx={{fontSize:'12.5px'}} color={blue[700]}>
                {total}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Pending Quotes Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${orange[700]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Pending Quotes
              </Typography>
              <Typography sx={{fontSize:'12.5px'}} color={orange[700]}>
                {pending}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Past Due Quotes Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${red[700]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Past Due Quotes
              </Typography>
              <Typography sx={{fontSize:'12.5px'}} color={red[700]}>
                {pastDue}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Total Customers Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${green[700]}`, boxShadow: 3 }}>
            <CardContent>
              <Box display="flex" alignItems="center">
                <Typography color={grey[800]} sx={{ flex: 1,fontSize:'12.5px' }}>
                  Total Customers
                </Typography>
                <IconButton onClick={() => openDetailsDialog({ type: 'customers' })}>
                  <VisibilityIcon color="action" />
                </IconButton>
              </Box>
              <Typography sx={{ fontSize:'12.5px'}} color={green[700]}>
                {totalDifferentCustomers}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Week Sales Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${blue[500]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Best Week (Sales)
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography  color={blue[500]} sx={{ flex: 1  ,fontSize:'12.5px'}}>
                  {bestWeek.bestSales.period
                    ? moment().year(currentYear).week(bestWeek.bestSales.period).startOf('week').format('MMM D')
                    : 'N/A'}
                </Typography>
                {/* <IconButton onClick={() => openDetailsDialog({ type: 'week', period: bestWeek.bestSales.period })}>
                  <VisibilityIcon color="action" />
                </IconButton> */}
              </Box>
              <Typography sx={{ fontSize:'12.5px'}} color={blue[700]}>
                ${formatNumber(bestWeek.bestSales.value)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Week Profit Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${green[500]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}}color={grey[800]} gutterBottom>
                Best Week (Profit)
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography  color={green[500]} sx={{ flex: 1 ,fontSize:'12.5px' }}>
                  {bestWeek.bestProfit.period
                    ? moment().year(currentYear).week(bestWeek.bestProfit.period).startOf('week').format('MMM D')
                    : 'N/A'}
                </Typography>
                {/* <IconButton onClick={() => openDetailsDialog({ type: 'week', period: bestWeek.bestProfit.period })}>
                  <VisibilityIcon color="action" />
                </IconButton> */}
              </Box>
              <Typography sx={{fontSize:'12.5px'}} color={green[700]}>
                ${formatNumber(bestWeek.bestProfit.value)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Month Sales Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${orange[500]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Best Month (Sales)
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography  color={orange[500]} sx={{ flex: 1 ,fontSize:'12.5px' }}>
                  {bestMonth.bestSales.period
                    ? moment(bestMonth.bestSales.period, 'YYYY-MM').format('MMMM')
                    : 'N/A'}
                </Typography>
                <IconButton onClick={() => openDetailsDialog({ type: 'month', period: bestMonth.bestSales.period })}>
                  <VisibilityIcon color="action" />
                </IconButton>
              </Box>
              <Typography sx={{fontSize:'12.5px'}} color={orange[700]}>
                ${formatNumber(bestMonth.bestSales.value)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>

        {/* Best Month Profit Card */}
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <Card variant="outlined" sx={{ borderLeft: `6px solid ${red[500]}`, boxShadow: 3 }}>
            <CardContent>
              <Typography sx={{fontSize:'12.5px'}} color={grey[800]} gutterBottom>
                Best Month (Profit)
              </Typography>
              <Box display="flex" alignItems="center">
                <Typography  color={red[500]} sx={{ flex: 1 ,fontSize:'12.5px'}}>
                  {bestMonth.bestProfit.period
                    ? moment(bestMonth.bestProfit.period, 'YYYY-MM').format('MMMM')
                    : 'N/A'}
                </Typography>
                <IconButton onClick={() => openDetailsDialog({ type: 'month', period: bestMonth.bestProfit.period })}>
                  <VisibilityIcon color="action" />
                </IconButton>
              </Box>
              <Typography sx={{fontSize:'12.5px'}} color={red[700]}>
                ${formatNumber(bestMonth.bestProfit.value)}
              </Typography>
            </CardContent>
          </Card>
        </Grid>
      </Grid>

      {/* Details Dialog for filtering data */}
      <Dialog open={detailsDialogOpen} onClose={handleDialogClose} maxWidth="md" fullWidth>
        <DialogTitle>
          <Alert variant="filled" sx={{ backgroundColor: orange[200], color: 'black' }}>
            {selectedMetric.type === 'week'
              ? 'Best Week Quotes'
              : selectedMetric.type === 'month'
              ? 'Best Month Quotes'
              : 'Total Customers'}
          </Alert>
        </DialogTitle>
        <DialogContent>
          <TextField
            placeholder="Search by Opportunity Name, KO Name, or Customer Name"
            variant="outlined"
            size="small"
            fullWidth
            margin="dense"
            value={searchQuery}
            onChange={handleSearchChange}
          />
          <Grid container spacing={2}>
            {filteredData.map((quote, index) => (
              <Grid item xs={12} md={6} key={index}>
<Card variant="outlined" sx={{ borderColor: blue[300], boxShadow: 3, borderRadius: 4, padding: 2 }}>
  <CardContent>
    {/* Header Section with Avatar and Customer Name */}
    <Box display="flex" alignItems="center" mb={2}>
      <Avatar
        sx={{
          bgcolor: assignRandomColor(quote.KO_name),
          mr: 2,
          width: 36,
          height: 36,
          fontSize: 24,
        }}
      >
        {quote?.KO_name[0]?.toUpperCase()}
      </Avatar>
      <Typography
        variant="h3"
        sx={{ fontWeight: 'bold', color: blueGrey[800], textTransform: 'capitalize' }}
      >
        {quote?.KO_name}
      </Typography>
    </Box>

    {/* Opportunity Details Section */}
    <Box sx={{ marginBottom: 1 }}>
      <Typography variant="body1" sx={{ fontWeight: '200', mb: 0.5,fontSize:'12.5px'}}>
        <strong>Opportunity Name:</strong> {quote?.Opportunity_Name}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: '200', mb: 0.5,fontSize:'12.5px' }}>
        <strong>Sale Value:</strong> ${formatNumber(quote?.Actual_Total_Cost_After_Margin)}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: '200', mb: 0.5,fontSize:'12.5px' }}>
        <strong>Quote No:</strong> {quote?.Invoice_No}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: '200', mb: 0.5, color: green[600],fontSize:'12.5px' }}>
        <strong>Net Profit:</strong> ${formatNumber(
          parseFloat(quote?.Actual_Total_Cost_After_Margin) -
          parseFloat(quote?.Actual_Total_Cost_Before_Margin)
        )}
      </Typography>
      <Typography variant="body1" sx={{ fontWeight: '200', mb: 0.5,fontSize:'12.5px' }}>
        <strong>Created Date:</strong> {String(quote.Created_Date).split('T')[0]}
      </Typography>
    </Box>

    {/* Email Section */}
    <Box sx={{ mt: 1 }}>
      <Typography variant="body2" sx={{fontSize:'12.5px'}}>
        <strong>Email:</strong>{' '}
        <a
          href={`mailto:${quote?.KO_email}`}
          style={{
            color: blue[500],
            textDecoration: 'none',
            fontWeight: '200',
            fontSize: '12.5px',
          }}
        >
          {quote.KO_email}
        </a>
      </Typography>
    </Box>
  </CardContent>
         </Card>

              </Grid>
            ))}
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default RFQsMetricsDashboard;
