import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  Stack,
  Box,
  Typography,
  Alert,MenuItem,Select,FormControl,FormControlLabel,InputLabel
} from '@mui/material';
import { orange, blueGrey, blue, green, purple, red } from '@mui/material/colors';
import QuoteNoLetterCodesContractTypes from './QuoteNoLetterCodesContractTypes';

const AddContractTypeDialog = ({ open, onClose, onSave }) => {
  const [newContractType, setNewContractType] = useState('');
  const [contractTypeDescription, setContractTypeDescription] = useState('');
  const [quoteNoLetterCode, setQuoteNoLetterCode] = useState('');

  const handleSave = () => {
    const data = {
      newContractType,
      contractTypeDescription,
      quoteNoLetterCode,
    };
    onSave(data); // Pass data back to parent function
    setNewContractType('');
    setContractTypeDescription('');
    setQuoteNoLetterCode('');
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>
        <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900] }}>
          Add Contract Type
        </Alert>
      </DialogTitle>
      <DialogContent>
        <Stack direction="column" spacing={4} sx={{ marginTop: '20px' }} justifyContent="center">
          <TextField
            sx={{ marginTop: '20px' }}
            size="small"
            label="New Contract Type"
            required
            value={newContractType}
            onChange={(e) => setNewContractType(e.target.value)}
          />
          <TextField
            size="small"
            label="Description"
            multiline
            required
            minRows={3}
            name="description"
            value={contractTypeDescription}
            onChange={(e) => setContractTypeDescription(e.target.value)}
          />
          <Stack direction="row">
          <FormControl  fullWidth>
              <InputLabel id="Letter_Code">Letter Code For Quote No</InputLabel>
              <Select
                labelId="Letter_Code"
                id="Letter-Code-id"
                value={quoteNoLetterCode}
                size="small"
                label="Letter Code For Quote No"
                onChange={(e) => setQuoteNoLetterCode(e.target.value)}
                required
              >
                <MenuItem value="G">G</MenuItem>
                <MenuItem value="C">C</MenuItem>
                <MenuItem value="M">M</MenuItem>
                <MenuItem value="F">F</MenuItem>
                <MenuItem value="E">E</MenuItem>
                <MenuItem value="T">T</MenuItem>
              </Select>
            </FormControl>
          </Stack>
          <Box sx={{ mt: 2, p: 2, border: '1px dashed grey', borderRadius: '8px' }}>
            <Typography variant="subtitle1" gutterBottom>
              Example Quote No: <strong>F240911JB.1R1</strong>
            </Typography>
            <Stack direction="column" spacing={4}>
              <Stack direction="row" alignItems="center" spacing={1}>
                <Box sx={{ bgcolor: orange[200], p: 1, borderRadius: '4px' }}>
                  <Typography variant="body2">
                    <strong>F</strong>: Contract Type (e.g., Federal)
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: blue[200], p: 1, borderRadius: '4px' }}>
                  <Typography variant="body2">
                    <strong>240911</strong>: Date (YYMMDD)
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: green[200], p: 1, borderRadius: '4px' }}>
                  <Typography variant="body2">
                    <strong>JB</strong>: User Initials
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: purple[200], p: 1, borderRadius: '4px' }}>
                  <Typography variant="body2">
                    <strong>.1</strong>: Quote number of that user on any given day
                  </Typography>
                </Box>
                <Box sx={{ bgcolor: red[200], p: 1, borderRadius: '4px' }}>
                  <Typography variant="body2">
                    <strong>R1</strong>: Revision number
                  </Typography>
                </Box>
              </Stack>
            </Stack>
          </Box>
          <QuoteNoLetterCodesContractTypes/>
        </Stack>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="secondary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddContractTypeDialog;
