import React, { useState, useEffect } from 'react';
import { Bar, Doughnut } from 'react-chartjs-2';
import {
    TextField,
    Button,
    Typography,
    Grid,
    Paper,
    Box,
    FormControl,
    InputLabel,
    Select,
    MenuItem,
    CircularProgress,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton, Alert,Stack
} from '@mui/material';
import CircleIcon from '@mui/icons-material/Circle';
import InfoIcon from '@mui/icons-material/Info';
import CloseIcon from '@mui/icons-material/Close';
import AxiosFunction from '../../axiosCustomInstance';
import { blueGrey, orange } from '@mui/material/colors';
import { selectCurrentUser } from '../Login_Registration/authSlice';
import { selectIsAdmin,selectUserRole } from '../Login_Registration/authSlice';
import { useDispatch, useSelector } from "react-redux";
import UserAuth from '../ProtectedRoute/userAuth';
const PerformanceGoalsMetricsDashboard = ({user,contractVehicle,year}) => {
    // State variables to manage the data and UI
    const [wonQuotes, setWonQuotes] = useState([]); // Holds the data for won quotes
    const [division, setDivision] = useState('All'); // Selected division
    const [salesHeadEmail, setSalesHeadEmail] = useState(user); // Selected sales head email
    const [contractType, setContractType] = useState(contractVehicle); // Selected contract type
    const [selectedYear, setSelectedYear] = useState(year); // Default to the current year
    const [loading, setLoading] = useState(false); // Loading state for data fetching
    const [dialogOpen, setDialogOpen] = useState({ open: false, chart: '' }); // State for managing dialog
    const [usersData, setUsersData] = useState([]); // List of users for the sales head email dropdown
    const AxiosAPIInstance = AxiosFunction(); // Axios instance for API calls
    const isAdmin = useSelector(selectIsAdmin);
    const userRole = useSelector(selectUserRole);
    const {UserName,UserEmail}=UserAuth()
    // Handle year selection change
    const handleYearChange = (event) => {
        setSelectedYear(event.target.value); // Update the selected year
    };

    // Handle opening of the dialog for detailed chart explanations
    const handleDialogOpen = (chart) => {
        setDialogOpen({ open: true, chart }); // Open the dialog and set the chart name
    };

    // Handle closing of the dialog
    const handleDialogClose = () => {
        setDialogOpen({ open: false, chart: '' }); // Close the dialog
    };

    // Fetch Sales Head Emails from the server
    useEffect(() => {
        const fetchUsersData = async () => {
            setLoading(true); // Set loading to true while fetching data
            try {
                const result = await AxiosAPIInstance.get('/UserAuth/users'); // API call to fetch users
                const users = result?.data.map(user => user.User_email); // Extract email from user data
                setUsersData(['All', ...users]); // Add 'All' to the dropdown options
            } catch (error) {
                console.error('Error fetching users data:', error); // Log error if API call fails
            } finally {
                setLoading(false); // Set loading to false after fetching data
            }
        };

        fetchUsersData(); // Call the function to fetch users data
    }, []);

    // Fetch combined revenue data based on selected filters
    const fetchCombinedRevenueData = async () => {
        setLoading(true); // Set loading to true while fetching data
        try {
            const response = await AxiosAPIInstance.get('/RevenueGoals/combined-revenue-data', {
                params: {
                    division: division || 'All', // Include division filter if set
                    goalYear: year, // Include selected year
                    salesHeadEmail: salesHeadEmail,
                    contractType: contractType
                },
            });

            if (response.data) {
                const mappedQuotes = response.data.map(quote => ({
                    ...quote,
                    ActualRevenueGoalMet: quote.ActualRevenueGoalMet || 0, // Set default value for ActualRevenueGoalMet
                    ProfitMarginPercentage: quote.ProfitMarginPercentage || 0, // Set default value for ProfitMarginPercentage
                    TotalProfitMargin: quote.TotalProfitMargin || 0, // Set default value for TotalProfitMargin
                }));
                setWonQuotes(mappedQuotes); // Update the state with fetched data
            } else {
                console.error('Failed to fetch combined revenue data'); // Log error if no data received
            }
        } catch (error) {
            console.error('Error fetching combined revenue data:', error); // Log error if API call fails
        } finally {
            setLoading(false); // Set loading to false after fetching data
        }
    };

    // Fetch combined revenue data whenever filters change
    useEffect(() => {
        fetchCombinedRevenueData(); // Call the function to fetch data
    }, [division, salesHeadEmail, contractType, selectedYear]);

    // Generate data for Revenue Comparison Bar Chart
    const generateRevenueComparisonData = () => {
        const labels = wonQuotes.map(quote => quote.Contract_Vehicle); // X-axis labels
        const revenueGoals = wonQuotes.map(quote => quote.TotalRevenueGoal); // Data for revenue goals
        const revenueMet = wonQuotes.map(quote => quote.ActualRevenueGoalMet); // Data for revenue met

        return {
            labels,
            datasets: [
                {
                    label: 'Revenue Goal Set ($)',
                    data: revenueGoals,
                    backgroundColor: 'rgba(60, 141, 188, 0.6)', // Muted Blue color for bars
                },
                {
                    label: 'Revenue Goal Met ($)',
                    data: revenueMet,
                    backgroundColor: 'rgba(0, 166, 90, 0.6)', // Muted Green color for bars
                },
            ],
        };
    };

    // Generate data for Margin Comparison Bar Chart
    const generateMarginComparisonData = () => {
        const labels = wonQuotes.map(quote => quote.Contract_Vehicle); // X-axis labels
        const marginMet = wonQuotes.map(quote => quote.ProfitMarginPercentage); // Data for margin met

        return {
            labels,
            datasets: [
                {
                    label: 'Average Margin Met (%)',
                    data: marginMet,
                    backgroundColor: 'rgba(221, 75, 57, 0.6)', // Muted Red color for bars
                },
            ],
        };
    };

    // Define a distinct color palette for each contract type
    const colorPalette = [
        '#4B0082', // Indigo
        '#2F4F4F', // Dark Slate Gray
        '#8B0000', // Dark Red
        '#556B2F', // Dark Olive Green
        '#FF8C00', // Dark Orange
        '#4682B4', // Steel Blue
        '#6A5ACD', // Slate Blue
        '#20B2AA', // Light Sea Green
        '#DAA520', // Goldenrod
        '#A52A2A', // Brown
        '#8FBC8F', // Dark Sea Green
        '#8B4513', // Saddle Brown
    ];

    // Generate data for Doughnut Chart with two bands (Total data and Contract type data)
    const generateDoughnutData = (totalData, contractTypeData) => {
        return {
            labels: [...new Set(wonQuotes.map(quote => quote.Contract_Vehicle))], // Unique contract vehicles for labels
            datasets: [
                {
                    data: totalData, // Outer band data (Total data)
                    backgroundColor: 'rgba(60, 141, 188, 0.2)', // Light Blue color
                    borderWidth: 1,
                },
                {
                    data: contractTypeData, // Inner band data (Contract type data)
                    backgroundColor: colorPalette.slice(0, contractTypeData.length), // Use color palette
                    borderWidth: 1,
                },
            ],
        };
    };

    // Prepare data for Doughnut Charts
    const totalRevenue = wonQuotes.reduce((acc, quote) => acc + quote.ActualRevenueGoalMet, 0); // Calculate total revenue
    const totalProfitMargin = wonQuotes.reduce((acc, quote) => acc + quote.TotalProfitMargin, 0); // Calculate total profit margin
    const totalProfitMarginPercentage = wonQuotes.reduce((acc, quote) => acc + quote.ProfitMarginPercentage, 0); // Calculate total profit margin percentage

    const contractTypeRevenue = wonQuotes.map(quote => quote.ActualRevenueGoalMet); // Data for contract type revenue
    const contractTypeProfitMargin = wonQuotes.map(quote => quote.TotalProfitMargin); // Data for contract type profit margin
    const contractTypeProfitMarginPercentage = wonQuotes.map(quote => quote.ProfitMarginPercentage); // Data for contract type profit margin percentage

    return (
        <Stack sx={{ marginTop: '20px', padding: '20px'}}>
        <Typography gutterBottom sx={{fontWeight: 'bold', fontSize: '25px' }}>
            Goal Metrics
          </Typography>

                {/* Division Selection Dropdown */}
                <Grid item xs={12} sm={4}>
                    <FormControl fullWidth variant='standard'>
                        <InputLabel>Division</InputLabel>
                        <Select
                            value={division}
                            onChange={(e) => setDivision(e.target.value)}
                        >
                            <MenuItem value="All">All Divisions</MenuItem>
                            <MenuItem value="Tactical">Tactical</MenuItem>
                            <MenuItem value="Engineering">Engineering</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

            <Grid container spacing={3}>
                {/* Doughnut Chart for Contract Type Contribution to Total Revenue */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                Contract Type Contribution to Total Revenue
                            </Typography>
                            <IconButton onClick={() => handleDialogOpen('Contract Type Contribution')}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={14}>
                                <Doughnut 
                                    data={generateDoughnutData([totalRevenue], contractTypeRevenue)} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <List sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', paddingTop: '10px' }}>
                                    {wonQuotes.map((quote, index) => (
                                        <ListItem key={index} sx={{ width: 'auto', padding: '2px 8px' }}>
                                            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
                                                <CircleIcon sx={{ color: colorPalette[index % colorPalette.length], fontSize: '10px' }} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ fontSize: '10px' }} primary={`${quote.Contract_Vehicle}: $${quote.ActualRevenueGoalMet.toLocaleString()}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Doughnut Chart for Profit Margin Contribution to Total Revenue */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                Profit Margin Contribution to Total Revenue
                            </Typography>
                            <IconButton onClick={() => handleDialogOpen('Profit Margin Contribution')}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={14}>
                                <Doughnut 
                                    data={generateDoughnutData([totalProfitMargin], contractTypeProfitMargin)} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <List sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', paddingTop: '10px' }}>
                                    {wonQuotes.map((quote, index) => (
                                        <ListItem key={index} sx={{ width: 'auto', padding: '2px 8px' }}>
                                            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
                                                <CircleIcon sx={{ color: colorPalette[index % colorPalette.length], fontSize: '10px' }} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ fontSize: '10px' }} primary={`${quote.Contract_Vehicle}: $${quote.TotalProfitMargin.toLocaleString()}`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Doughnut Chart for Profit Margin Percentage Contribution by Contract Type */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: '20px' }}>
                        <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                                Profit Margin Percentage Contribution by Contract Type
                            </Typography>
                            <IconButton onClick={() => handleDialogOpen('Profit Margin Percentage Contribution')}>
                                <InfoIcon />
                            </IconButton>
                        </Box>
                        <Grid container spacing={2} justifyContent="center">
                            <Grid item xs={15}>
                                <Doughnut 
                                    data={generateDoughnutData([totalProfitMarginPercentage], contractTypeProfitMarginPercentage)} 
                                    options={{
                                        responsive: true,
                                        plugins: {
                                            legend: {
                                                display: false,
                                            },
                                        },
                                    }} 
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <List sx={{ display: 'flex', justifyContent: 'center', flexWrap: 'wrap', paddingTop: '10px' }}>
                                    {wonQuotes.map((quote, index) => (
                                        <ListItem key={index} sx={{ width: 'auto', padding: '2px 8px' }}>
                                            <ListItemIcon sx={{ minWidth: 'auto', marginRight: '8px' }}>
                                                <CircleIcon sx={{ color: colorPalette[index % colorPalette.length], fontSize: '10px' }} />
                                            </ListItemIcon>
                                            <ListItemText primaryTypographyProps={{ fontSize: '10px' }} primary={`${quote.Contract_Vehicle}: ${quote.ProfitMarginPercentage.toFixed(2)}%`} />
                                        </ListItem>
                                    ))}
                                </List>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

                {/* Bar Chart for Revenue Goal vs Met */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: '20px' }}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                            Revenue Goal vs Met
                        </Typography>
                        <Bar data={generateRevenueComparisonData()} options={{ responsive: true }} />
                    </Paper>
                </Grid>

                {/* Bar Chart for Margin Goal vs Met */}
                <Grid item xs={12} md={6}>
                    <Paper elevation={3} sx={{ padding: '20px' }}>
                        <Typography variant="h6" gutterBottom sx={{ textAlign: 'center' }}>
                            Margin Goal vs Met
                        </Typography>
                        <Bar data={generateMarginComparisonData()} options={{ responsive: true }} />
                    </Paper>
                </Grid>
            </Grid>

            {/* Dialog for detailed explanations of charts */}
            <Dialog open={dialogOpen.open} onClose={handleDialogClose}>
                <DialogTitle>
                    <Alert variant="filled" sx={{ bgcolor: orange[200], color: blueGrey[900], fontSize: '15px', fontWeight: 'bold', fontFamily: 'arial' }}>
                        {dialogOpen.chart}
                    </Alert>
                    <IconButton
                        aria-label="close"
                        onClick={handleDialogClose}
                        sx={{ position: 'absolute', right: 8, top: 8, color: (theme) => theme.palette.grey[500] }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent dividers>
                    {dialogOpen.chart === 'Contract Type Contribution' && (
                        <Typography>
                            <strong>Contract Type Contribution to Total Revenue:</strong>
                            <br />
                            This pie chart shows how much each contract type contributes to the total revenue. It helps in understanding which contract types are generating the most revenue and their relative importance in the overall revenue mix.
                            <br /><br />
                            <strong>Example:</strong>
                            <br />
                            Suppose your company has three contract types: Federal, GSA, and Tactical. The revenue generated by these contract types is as follows:
                            <ul>
                                <li><span style={{ color: '#4B0082' }}>Federal:</span> $1,000,000</li>
                                <li><span style={{ color: '#2F4F4F' }}>GSA:</span> $2,000,000</li>
                                <li><span style={{ color: '#8B0000' }}>Tactical:</span> $3,000,000</li>
                            </ul>
                            The pie chart will show that Federal contributes 16.67%, GSA contributes 33.33%, and Tactical contributes 50% to the total revenue. This indicates that Tactical is the largest revenue generator.
                        </Typography>
                    )}

                    {dialogOpen.chart === 'Profit Margin Contribution' && (
                        <Typography>
                            <strong>Profit Margin Contribution to Total Revenue:</strong>
                            <br />
                            This pie chart calculates and visualizes the total profit margin contributed by each contract type. It provides insight into which contract types are most profitable in terms of actual profit dollars.
                            <br /><br />
                            <strong>Example:</strong>
                            <br />
                            Suppose your company has three contract types: Federal, GSA, and Tactical. The profit margin generated by these contract types is as follows:
                            <ul>
                                <li><span style={{ color: '#4B0082' }}>Federal:</span> $200,000</li>
                                <li><span style={{ color: '#2F4F4F' }}>GSA:</span> $300,000</li>
                                <li><span style={{ color: '#8B0000' }}>Tactical:</span> $500,000</li>
                            </ul>
                            The pie chart will show that Federal contributes 20%, GSA contributes 30%, and Tactical contributes 50% to the total profit margin. This indicates that Tactical is the most profitable contract type.
                        </Typography>
                    )}

                    {dialogOpen.chart === 'Profit Margin Percentage Contribution' && (
                        <Typography>
                            <strong>Profit Margin Percentage Contribution by Contract Type:</strong>
                            <br />
                            This pie chart calculates and visualizes the percentage of profit margin each contract type contributes relative to the total profit margin percentage across all contract types.
                            <br /><br />
                            <strong>Example:</strong>
                            <br />
                            Suppose your company has three contract types: Federal, GSA, and Tactical. Their profit margins are as follows:
                            <ul>
                                <li><span style={{ color: '#4B0082' }}>Federal:</span> 20%</li>
                                <li><span style={{ color: '#2F4F4F' }}>GSA:</span> 25%</li>
                                <li><span style={{ color: '#8B0000' }}>Tactical:</span> 30%</li>
                            </ul>
                            The pie chart will show how much each of these contract types contributes to the overall profit margin percentage, considering their relative weight.
                        </Typography>
                    )}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Close
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
};

export default PerformanceGoalsMetricsDashboard;
