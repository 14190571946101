import React, { useState } from "react";
import { Button, Dialog, DialogTitle, DialogContent, Table, TableBody, TableRow, TableCell, Typography, TableHead,Alert,DialogActions,Box,Stack } from "@mui/material";
import { blue, blueGrey, green, indigo, orange, purple, red } from "@mui/material/colors";

const QuoteNoLetterCodesContractTypes = () => {
  const [open, setOpen] = useState(false);

  // Toggle Dialog
  const handleToggle = () => setOpen(!open);
  const handleCloseDialog = () => setOpen(!open);

  return (
    <>
      <Button variant="contained"  sx={{fontSize:'12px',bgcolor:indigo[800],":hover":{boxShadow:"10px 10px 20px #ccc",bgcolor:indigo[500]}}} size="small" onClick={handleToggle}>
       View Exisiting Quote No Letter Codes
      </Button>
      <Dialog open={open} onClose={handleToggle} maxWidth="sm" fullWidth>
      <Alert variant='filled' sx={{bgcolor:orange[200],color:blueGrey[900]}}>Quote No: Letter Code Descriptions</Alert>
        <DialogContent>
          <Table>
            <TableHead>
                <TableRow>
                    <TableCell>
                     Letter Code
                    </TableCell>
                    <TableCell>
                    Description
                    </TableCell>
                    <TableCell>
                    Contarct Type
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell><strong>G</strong></TableCell>
                <TableCell>GSA: General Services Administration agreements.</TableCell>
                <TableCell>GSA</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>C</strong></TableCell>
                <TableCell>Commercial: Non-government or private agreements.</TableCell>
                <TableCell>Commercial</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>M</strong></TableCell>
                <TableCell>Military : Specific to military (requires `.mil` email).</TableCell>
                <TableCell>Military</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>F</strong></TableCell>
                <TableCell>Federal : Government contracts (requires `.gov` email).</TableCell>
                <TableCell>Federal/DHS-CBP/FBOP</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>E</strong></TableCell>
                <TableCell>Engineering: Engineering-related projects.</TableCell>
                <TableCell>Engineering</TableCell>
              </TableRow>
              <TableRow>
                <TableCell><strong>T</strong></TableCell>
                <TableCell>Tactical and all others: Default tactical contracts.</TableCell>
                <TableCell>Tactical/Unison/Direct Enquiry/Sam or Open Market</TableCell>
              </TableRow>
            </TableBody>
          </Table>
          <Box sx={{ mt: 2, p: 2, border: "1px dashed grey", borderRadius: "8px" }}>
        <Typography variant="subtitle1" gutterBottom>
          Example Quote No: <strong>F240911JB.1R1</strong>
        </Typography>
        <Stack direction='column' spacing={2}>
        <Stack direction="row" alignItems="center" spacing={1}>
          <Box sx={{ bgcolor: orange[200], p: 1, borderRadius: "4px" }}>
            <Typography variant="body2">
              <strong>F</strong>: Contract Type (e.g., Federal)
            </Typography>
          </Box>
          <Box sx={{ bgcolor: blue[200], p: 1, borderRadius: "4px" }}>
            <Typography variant="body2">
              <strong>240911</strong>: Date (YYMMDD)
            </Typography>
          </Box>
          <Box sx={{ bgcolor: green[200], p: 1, borderRadius: "4px" }}>
            <Typography variant="body2">
              <strong>JB</strong>: User Initials
            </Typography>
          </Box>
          <Box sx={{ bgcolor: purple[200], p: 1, borderRadius: "4px" }}>
            <Typography variant="body2">
              <strong>.1</strong>: Quote number of that user on any given day
            </Typography>
          </Box>
          <Box sx={{ bgcolor: red[200], p: 1, borderRadius: "4px" }}>
            <Typography variant="body2">
              <strong>R1</strong>: Revision number
            </Typography>
          </Box>
        </Stack>
        </Stack>
      </Box>
        </DialogContent>
        <DialogActions>
            <Button onClick={handleCloseDialog} size='small' variant="outlined" color="secondary">close</Button>
          </DialogActions>
      </Dialog>
    </>
  );
};

export default QuoteNoLetterCodesContractTypes;
