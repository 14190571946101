import React from "react";
import {Box,Button,TextField,Paper, Stack,Typography,AppBar,Toolbar,IconButton,Menu,MenuItem,Divider} from '@mui/material';
import InputAdornment from '@mui/material/InputAdornment';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import { useState} from "react";
import { DatePicker } from "@mui/x-date-pickers";
import DescriptionIcon from '@mui/icons-material/Description';
import { orange,indigo,green,yellow,red, blueGrey,grey,teal,deepOrange,deepPurple, blue} from '@mui/material/colors';
import Tooltip from '@mui/material/Tooltip';
import ModeEditIcon from '@mui/icons-material/ModeEdit';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert} from '@mui/material'
import { useNavigate } from "react-router-dom";
import ApplicationBar from "../AppBar/ApplicationBar";
import AxiosFunction from "../../axiosCustomInstance";
import AddTaskIcon from '@mui/icons-material/AddTask';
import userAuth from "../ProtectedRoute/userAuth"
import SaveIcon from '@mui/icons-material/Save';
import FormGroup from '@mui/material/FormGroup';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormLabel from '@mui/material/FormLabel';
import { useSearchParams } from "react-router-dom";
import { Link,createSearchParams} from 'react-router-dom';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import NextPlanIcon from '@mui/icons-material/NextPlan';
import LoadingDialog from "../Loading";
import { EmailRounded, Person2 } from "@mui/icons-material";

function RMAFollowUpCloseOut({RMARow,refreshData})
{
const PD= new Date()
const AxiosAPIInstance=AxiosFunction()
const {
  UserName,
  UserEmail
} = userAuth()
  const [Form_Value,Set_Form_Value]=useState({Agency_Name:"",
  RMARMAID:"",
  Satisfied:"",
  Other_Customers_Affected:"",
  Affected_Customers_Description:"",
  Customer_Notified_Date:"",
  Corrective_Action_Description:"",
  Return_Address:"",
  Resolution_Stage:"Follow UP & Close Out",
  Closed_By_Name:UserName,
  Closed_By_Email:UserEmail,
  Closed_By_Phone:"540-547-8487",
  Created_Date:PD.toDateString(),
  Last_Updated_Date:PD.toDateString(),
  Updated_By:UserEmail,
  Version_No:Number(0),
  Updating_Comments:""})
  
  const [dis,setDis]=useState(false)
  const navigate=useNavigate()
  const[rmaCloseOutMssge,setRMACloseOutMssge]=useState('')
  const [successDialog,setSuccessDialog]=useState(false)
  const [errorDialog,setErrorDialog]=useState(false)
  const [edit,setEdit]=useState(true)
  const [allFieldsActivate,setAllFieldsActivate]=useState(false)
  const [satisfiedYESChecked,setSatisfiedYESChecked]=useState(false)
  const [satisfiedNOChecked,setsatisfiedNOChecked]=useState(false)
  const [otherCustomersAffectedYESChecked,setOtherCustomersAffectedYESChecked]=useState(false)
  const [otherCustomersAffectedNOChecked,setOtherCustomersAffectedNOChecked]=useState(false)
  const [RMACLosedFlag,setRMAClosedFlag]=useState(false)
  const [loading, setLoading] = useState(false);
  const [searchParams]=useSearchParams();


  React.useEffect(()=>{
    setLoading(true)
    async function fetchData(){
      await AxiosAPIInstance.get("/RMAs/GetRMACloseOutByID",{params:{RMARMAID:String(RMARow?.RMA_ID)}}).then(async(result)=>{
        Set_Form_Value({...result?.data})
        setLoading(false)
        console.log(Form_Value)
        if(result?.data.Other_Customers_Affected==="NO")
        {
          setOtherCustomersAffectedNOChecked(true)
          setOtherCustomersAffectedYESChecked(false)
        }
        else
        {
          setOtherCustomersAffectedNOChecked(false)
          setOtherCustomersAffectedYESChecked(true)
        }
  
        if(result?.data.Satisfied==="NO")
        {
          setsatisfiedNOChecked(true)
          setSatisfiedYESChecked(false)
        }
        else
        {
          setsatisfiedNOChecked(false)
          setSatisfiedYESChecked(true)
        }
      })
    }
    fetchData();
    },[searchParams])
//-------------------Dialog Box Closed and Route to RMAs Search---------------------------
  const handleErrorSuccessDialogClose = (e) => {

      setErrorDialog(false)
      setSuccessDialog(false)
      setRMACloseOutMssge("")

    if(RMACLosedFlag===true)
    {
      navigate("/Dashboard/RMAsSearch")
    }
  };
  //-------------------------Enabling the Edit Button and Fields-------------------------------------
  const handleEdit=()=>{
    setDis(true)
    setAllFieldsActivate(true)
    setEdit(false)

  }
  
  const handleSatisfiedChange=(e)=>{
    if(e.target.name==="NO" && e.target.checked===true)
    {
      setsatisfiedNOChecked(true)
      setSatisfiedYESChecked(false)
      Set_Form_Value({...Form_Value,Satisfied:"NO"})
    }
    else
    {
      setsatisfiedNOChecked(false)
      setSatisfiedYESChecked(true)
      Set_Form_Value({...Form_Value,Satisfied:"YES"})
    }

  }

  const handleOtherCustomersAffectedChange=(e)=>{
    if(e.target.name==="NO" && e.target.checked===true)
    {
      setOtherCustomersAffectedNOChecked(true)
      setOtherCustomersAffectedYESChecked(false)
      Set_Form_Value({...Form_Value,Other_Customers_Affected:"NO"})
    }
    else
    {
      setOtherCustomersAffectedNOChecked(false)
      setOtherCustomersAffectedYESChecked(true)
      Set_Form_Value({...Form_Value,Other_Customers_Affected:"YES"})
    }

  }
//----------------------------------Handling the Update of RMA Followup and Close out------------------------------
  const HandleFollowUpSave = async(event) => {
     setDis(false)
     setEdit(false)
      event.preventDefault()
      setLoading(true)
        await AxiosAPIInstance.patch("/RMAs/UpdateRMAFollowUp",Form_Value).then(
          async(response)=>{
            setLoading(false)
            setSuccessDialog(true)
            setErrorDialog(false)
            setRMACloseOutMssge(response?.data)
            setAllFieldsActivate(false)
            setDis(false)
            setEdit(true)
            refreshData()
          }
      ).catch((error)=>{
        setLoading(false)
        setSuccessDialog(false)
        setErrorDialog(true)
        setRMACloseOutMssge(error.response?.data)
        setAllFieldsActivate(true)
        setDis(true)
        setEdit(false)
      })
   
  }
  const HandleRMAClose = async(event) => {
      setDis(true)
      event.preventDefault()
      setLoading(true)
      await AxiosAPIInstance.patch("/RMAs/RMAClose",Form_Value).then(
          async(response)=>{
            setLoading(false)
            setRMAClosedFlag(true)
            setSuccessDialog(true)
            setErrorDialog(false)
            setRMACloseOutMssge(response?.data)
            setDis(false)
            setAllFieldsActivate(false)
          }
      ).catch((error)=>{
        setLoading(false)
        setSuccessDialog(false)
        setErrorDialog(true)
        setRMACloseOutMssge(error.response?.data)
        setDis(false)
        setAllFieldsActivate(true)
      })
  }
  
      return(
          <>
              <LoadingDialog open={loading} />
           {successDialog===true&&(<Dialog
          open={successDialog}
          onClose={handleErrorSuccessDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rmaCloseOutMssge && <Alert  variant='filled' severity='success'>SUCCESS</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rmaCloseOutMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes" onClick={(e)=>{
            handleErrorSuccessDialogClose();
            e.preventDefault();
            setDis(false)
            setEdit(true)
            setAllFieldsActivate(false)
            }
            } href="/Dashboard/NewRMA">OK</Button>
          </DialogActions>
        </Dialog>)}
        {errorDialog===true&&(<Dialog
          open={errorDialog}
          onClose={handleErrorSuccessDialogClose}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description">
          <DialogTitle id="alert-dialog-title">
          {rmaCloseOutMssge && <Alert variant='filled' severity='error'>ERROR</Alert>}
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description" sx={{textAlign:'center'}}>
            {rmaCloseOutMssge}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button name="Yes"  onClick={(e)=>{
            handleErrorSuccessDialogClose();
            e.preventDefault();
            setEdit(false)
            setAllFieldsActivate(true)
            setDis(true);}} href="/Dashboard/NewRMA">OK</Button>
          </DialogActions>
        </Dialog>)}
          {/* <ApplicationBar></ApplicationBar> */}
          <Stack component="form" onSubmit={HandleFollowUpSave} justifyContent="center" >
          <Stack direction="column" spacing={3}>
          <Stack direction="row" justifyContent="center">
          <Typography
          sx={{
            fontSize: "22px",
            fontWeight: "bold",
            fontFamily: "Verdana, sans-serif",
            textAlign: "center",
            color: blueGrey[900],
          }}
          >
               Follow Up
        </Typography>
          </Stack>
          <Stack direction="row" spacing={3} justifyContent="center">
         
        <DatePicker label="Customer Notified Date" sx={{width:"250px"}} value={Form_Value.Customer_Notified_Date} disabled={!allFieldsActivate} renderInput={(params)=><TextField size="small" required variant="standard" sx={{width:"250px"}} {...params}/>} onChange={(value)=>Set_Form_Value({...Form_Value,Customer_Notified_Date:value})}>
        </DatePicker>
          </Stack>
                 <Stack direction="row" spacing={3} justifyContent="center">
                  <FormLabel sx={{width:"300px",fontSize:"16px",fontWeight:"bold"}} required >Customer Satisfied? </FormLabel>
                 <FormGroup>
               <FormControlLabel sx={{color:'red'}} disabled={!allFieldsActivate} control={<Checkbox size="small" name="YES" sx={{width:'30px',height:'10px'}} checked={satisfiedYESChecked} onChange={(e)=>{handleSatisfiedChange(e)}}/>} label="YES" />
                 </FormGroup>
                 <FormGroup>
                <FormControlLabel sx={{color:'red'}}  disabled={!allFieldsActivate} control={<Checkbox size="small" name="NO"  sx={{width:'30px',height:'10px'}} checked={satisfiedNOChecked} onChange={(e)=>{handleSatisfiedChange(e)}}/>} label="NO" />
                 </FormGroup>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent="center">
                 <FormLabel sx={{width:"300px",fontSize:"16px",fontWeight:"bold"}}  required >Are Other Customers affected? </FormLabel>
                 <FormGroup>
               <FormControlLabel sx={{color:'red'}} disabled={!allFieldsActivate} control={<Checkbox size="small" name="YES" sx={{width:'30px',height:'10px'}} disabled={!allFieldsActivate} checked={otherCustomersAffectedYESChecked} onChange={(e)=>{handleOtherCustomersAffectedChange(e)}}/>} label="YES" />
                 </FormGroup>
                 <FormGroup>
                <FormControlLabel sx={{color:'red'}} disabled={!allFieldsActivate}  control={<Checkbox size="small" name="NO"  sx={{width:'30px',height:'10px'}} disabled={!allFieldsActivate} checked={otherCustomersAffectedNOChecked} onChange={(e)=>{handleOtherCustomersAffectedChange(e)}}/>} label="NO" />
                 </FormGroup>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent="center">
                 <FormLabel  disabled={!allFieldsActivate} sx={{width:"320px",fontSize:"17px",}} required={otherCustomersAffectedYESChecked?true:false}>If yes, explain how others got affected </FormLabel>
                 <TextField size="small" variant="standard" disabled={!allFieldsActivate} sx={{width:"800px"}}  multiline={20} value={Form_Value.Other_Customers_Affected} required={otherCustomersAffectedYESChecked?true:false} onChange={(e)=>Set_Form_Value({...Form_Value,Other_Customers_Affected:e.target.value})}
                 InputProps={{startAdornment:(<InputAdornment position="start">
                  <DescriptionIcon />
                 </InputAdornment>)}}>
                 </TextField>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent="center">
                 <FormLabel sx={{width:"320px",fontSize:"17px",}} required>Return Address </FormLabel>
                 <TextField size="small" variant="standard" required disabled={!allFieldsActivate} sx={{width:"800px"}}  multiline={20} value={Form_Value.Return_Address}  onChange={(e)=>Set_Form_Value({...Form_Value,Return_Address:e.target.value})}
                 InputProps={{startAdornment:(<InputAdornment position="start">
                  <ContactMailIcon />
                 </InputAdornment>)}}>
                 </TextField>
                 </Stack>
                 <Stack direction="row" justifyContent="center">
                 <Typography
                  sx={{
                   fontSize: "16px",
                   fontWeight: "bold",
                   fontFamily: "Verdana, sans-serif",
                   textAlign: "center",
                   color: blueGrey[900],
                  }}
                  >
                 Closed By
                </Typography>

                 </Stack>
          
         
                 <Stack direction="row" spacing={3} justifyContent="center">
                 <TextField size="small" variant="standard" sx={{width:"300px"}} label="Name" value={Form_Value?.Closed_By_Name!==null?Form_Value?.Closed_By_Name:UserName} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Name:UserName})}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <Person2/>
                  </InputAdornment>
                ),readOnly:"true"
              }}></TextField>
               <TextField size="small" variant="standard" sx={{width:"300px"}} label="Email" value={Form_Value?.Closed_By_Email!==null?Form_Value?.Closed_By_Email:UserEmail} required={true}  onChange={(e)=>Set_Form_Value({...Form_Value,Completed_By_Email:UserEmail})}
               InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailRounded/>
                  </InputAdornment>
                ),readOnly:"true"
              }}></TextField>
                 <DatePicker readOnly label="Completed Date" sx={{width:"300px"}} value={Form_Value.Created_Date} minDate={new Date()} renderInput={(params)=><TextField  size="small" variant="standard" required   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
          <DatePicker readOnly label="Updated Date" sx={{width:"300px"}} value={Form_Value.Last_Updated_Date} minDate={new Date()} renderInput={(params)=><TextField  size="small" variant="standard"   sx={{width:"300px"}} {...params}/>}>
          </DatePicker>
                 </Stack>
                 <Stack direction="row" spacing={3} justifyContent="center">
                 </Stack>
                
                 <Stack direction="row" spacing={2} justifyContent="center">
                 {/* <Tooltip title="Go Back">
                  <Button
                    variant="contained"
                    onClick={(e)=>{      
                      e.preventDefault()
                      navigate({
                      pathname:"/Dashboard/RMAResolution",
                      search:createSearchParams({RMAID:Form_Value.RMARMAID}).toString()
                    })}}
                    size='small'
                    sx={{bgcolor:blue[700],width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[blue[500]]}}}
                    startIcon={<ArrowBackIcon/>}>
                   BACK
                  </Button>
                  </Tooltip> */}
                  <Tooltip title="Edit">
               <Button variant='contained' color="warning" size='small' sx={{width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[orange[500]]}}} disabled={!edit} onClick={handleEdit} startIcon={<ModeEditIcon/>}>Edit</Button>
              </Tooltip>
              <Tooltip title="Save">
              <Button variant='contained' color="success" size='small' sx={{width:"100px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[green[500]]}}} disabled={!dis} onClick={HandleFollowUpSave}  startIcon={<SaveIcon/>}> Save</Button>
              </Tooltip>
                <Tooltip title="Close RMA">
                  <Button
                    variant="contained"
                    onClick={HandleRMAClose}
                    size='small'
                    sx={{bgcolor:red[700],width:"300px",":hover":{boxShadow:"10px 10px 20px #ccc",backgroundColor:[red[500]]}}}
                    startIcon={<NextPlanIcon/>}>
                   CLOSE RMA
                  </Button>
                  </Tooltip>
                 </Stack>
                 </Stack >
    
                 </Stack> 
                 
         </>
  );
}
export default RMAFollowUpCloseOut