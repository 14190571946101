import React, { useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Alert,
  IconButton,
  Tooltip,
  Avatar,
  Collapse,
} from '@mui/material';
import ChangeHistoryIcon from '@mui/icons-material/ChangeHistory';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import moment from 'moment';
import AxiosFunction from '../../../../axiosCustomInstance';
import { blueGrey, indigo, orange } from '@mui/material/colors';
import LoadingDialog from '../../../Loading';


const GetRFQHistoryButton = ({ RFQ_ID }) => {
  const [open, setOpen] = useState(false);
  const [historyData, setHistoryData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [expandedGroups, setExpandedGroups] = useState({});
  const AxiosAPIInstance = AxiosFunction();

  // Function to fetch RFQ history
  const fetchRFQHistory = async () => {
    try {
      setLoading(true);
      const response = await AxiosAPIInstance.get("/RFQsSearch/RFQChangeHistory", { params: { RFQ_ID } });
      setHistoryData(response.data.RFQ_Change_History);
      setOpen(true);
      setError(null);
    } catch (err) {
      console.error("Error fetching RFQ history:", err);
      setError("No RFQ history Available For this Quote.");
    } finally {
      setLoading(false);
    }
  };

  // Group by `Updated_At` and `Updated_By`
  const groupByUpdatedAtAndUpdatedBy = (data) => {
    return data.reduce((acc, change) => {
      const updatedAt = moment(change.updatedAt).format('YYYY-MM-DD HH:mm');
      const updatedBy = change.updatedBy;

      // Create a unique key for each `Updated_At` + `Updated_By` group
      const groupKey = `${updatedAt} - ${updatedBy}`;

      // Initialize group if it doesn't exist
      if (!acc[groupKey]) {
        acc[groupKey] = [];
      }

      // Add change to the group
      acc[groupKey].push(change);
      return acc;
    }, {});
  };

  const groupedHistoryData = groupByUpdatedAtAndUpdatedBy(historyData);

  // Toggle expand/collapse state for a specific group
  const toggleExpand = (groupKey) => {
    setExpandedGroups((prevState) => ({
      ...prevState,
      [groupKey]: !prevState[groupKey],
    }));
  };

  return (
    <>
      {loading && <LoadingDialog />}

      <Tooltip title="Non-SKU Quote Update History ">
        <IconButton
          onClick={fetchRFQHistory}
          sx={{
            color: 'white',
            padding: '10px',
            borderRadius: 1,
            transition: 'transform 0.2s ease-in-out',
            ":hover": { backgroundColor: indigo[100], transform: 'scale(1.1)' }
          }}
          disabled={loading}
        >
          <Avatar
            sx={{
              backgroundColor: indigo[800],
              width: '25px',
              height: '25px',
              transition: 'transform 0.2s ease-in-out',
              ":hover": { backgroundColor: indigo[800], transform: 'scale(1.1)' }
            }}
            variant="rounded"
          >
            <ChangeHistoryIcon sx={{ color: 'white', width: '20px', height: '20px' }} />
          </Avatar>
        </IconButton>
      </Tooltip>

      <Dialog open={!!error} onClose={() => setError(null)} aria-labelledby="error-dialog-title">
        <DialogTitle id="error-dialog-title">
          <Alert variant="filled" severity="error">ERROR</Alert>
        </DialogTitle>
        <DialogContent>
          <p style={{ textAlign: 'center' }}>{error}</p>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setError(null)}>OK</Button>
        </DialogActions>
      </Dialog>

      <Dialog open={open && !error} onClose={() => setOpen(false)} maxWidth="md" fullWidth>
        <DialogTitle id="history-dialog-title">
          <Alert variant="filled" sx={{ backgroundColor: orange[200], color: 'black' }}>
            RFQ Update History (Non-SKU Changes)
          </Alert>
        </DialogTitle>
        <DialogContent>
          {Object.keys(groupedHistoryData).length > 0 ? (
            Object.keys(groupedHistoryData).map((groupKey, index) => (
              <div key={index}>
                {/* Header with updated timestamp and user info */}
                <Alert
                  variant="outlined"
                  sx={{ display: 'flex', 
                    justifyContent: 'space-between', 
                    alignItems: 'center',
                    cursor: 'pointer', 
                    fontWeight: 'bold',
                    mb: 1,backgroundColor:blueGrey[100]}}
                    onClick={() => toggleExpand(groupKey)}
                >
                  {`Updated On: ${groupKey}`}
                  <IconButton size="small">
                    {expandedGroups[groupKey] ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </IconButton>
                </Alert>

                {/* Collapsible content for each group */}
                <Collapse in={expandedGroups[groupKey]}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell><strong>Field</strong></TableCell>
                        <TableCell><strong>Old Value</strong></TableCell>
                        <TableCell><strong>New Value</strong></TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {groupedHistoryData[groupKey].map((change, idx) => (
                        Object.keys(change.changes).map((field) => (
                          <TableRow key={`${index}-${idx}-${field}`}>
                            <TableCell>{field}</TableCell>
                            <TableCell>{change.changes[field].old || 'N/A'}</TableCell>
                            <TableCell>{change.changes[field].new || 'N/A'}</TableCell>
                          </TableRow>
                        ))
                      ))}
                    </TableBody>
                  </Table>
                </Collapse>
              </div>
            ))
          ) : (
            <p>No change history available for this RFQ.</p>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default GetRFQHistoryButton;
